import * as types from "./constants";
import { Product } from "../../common/types/Product";
import {
  IFetchAcn,
  IFirstPageAcn,
  ILastPageAcn,
  INextPageAcn,
  IPreviousPageAcn,
  ISelectedPageAcn,
  IChangeViewModeAcn,
  ISearchProductsMoreFetchAcn,
  ISearchParamsAcn,
  ISearchParamsCategoryAcn,
  ISetFilterAcn,
  ISetMinCostAcn,
  ISetMaxCostAcn,
  ISetLoadingAcn,
  ISetErrorAcn,
} from "./actionsTypes";
import { CategoryItem } from "../../common/types/Category";

export function fetchAllProducts(
  products: Product[],
  isThereAgain: boolean
): IFetchAcn {
  return { type: types.RECEIVE_ALL_PRODUCTS, data: { products, isThereAgain } };
}

export function firstPage(products: Product[], _page: number): IFirstPageAcn {
  return { type: types.FIRST_PAGE, products };
}

export function lastPage(products: Product[], _page: number): ILastPageAcn {
  return { type: types.LAST_PAGE, data: { products, _page } };
}

export function selectedPage(
  products: Product[],
  _page: number
): ISelectedPageAcn {
  return { type: types.SELECTED_PAGE, data: { products, _page } };
}

export function nextPage(products: Product[], _page: number): INextPageAcn {
  return { type: types.NEXT_PAGE, data: { products, _page } };
}

export function previousPage(
  products: Product[],
  _page: number
): IPreviousPageAcn {
  return { type: types.PREVIOUS_PAGE, data: { products, _page } };
}

export function changeViewMode(view_mode: string): IChangeViewModeAcn {
  return { type: types.CHANGE_VIEW_MODE, view_mode };
}

export function fetchMoreSearchProducts(
  products: Product[],
  _page: number,
  isThereAgain: boolean
): ISearchProductsMoreFetchAcn {
  return {
    type: types.RECEIVE_MORE_PRODUCTS,
    data: { products, _page, isThereAgain },
  };
}

export function saveSearchParams(name: string): ISearchParamsAcn {
  return { type: types.SAVE_SEARCH_PARAMS, name };
}

export function saveSearchParamsCategory(
  category: CategoryItem
): ISearchParamsCategoryAcn {
  return { type: types.SAVE_SEARCH_PARAMS_CATEGORY, category };
}

export function setFilterAction(payload: string): ISetFilterAcn {
  return {
    type: types.SET_FILTER,
    payload,
  };
}

export function setMinCostAction(payload: number): ISetMinCostAcn {
  return {
    type: types.SET_MIN_COST,
    payload,
  };
}

export function setMaxCostAction(payload: number): ISetMaxCostAcn {
  return {
    type: types.SET_MAX_COST,
    payload,
  };
}

export function setSetAllProductsLoadingAction(
  loading: boolean
): ISetLoadingAcn {
  return {
    type: types.SET_ALLPRODUCTS_LOADING,
    loading,
  };
}

export function setAllProductsErrorAction(error: string | null): ISetErrorAcn {
  return {
    type: types.SET_ALL_PRODUCTS_ERROR,
    error,
  };
}
