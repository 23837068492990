import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Header from "../../header/Header";
import HelmetComponent from "../../_temp/helmet";
import { useSelector, useDispatch } from "react-redux";
import {
  getMarketsList,
  getSingleMarket,
  setMarketsError,
  setMarketsLoading,
} from "../store/actions";
// import { changeViewMode } from "../store/actions";
import {
  SHOPS_SORT_BY_OPEN,
  SHOPS_SORT_BY_CLOSE,
  SHOPS_SORT_BY_RATING_ASC,
  SHOPS_SORT_BY_NAME_ASC,
  SHOPS_SORT_BY_NAME_DESC,
} from "../store/constants";
//styles
import "./shops.css";
import ShopsCard from "./MarketsCard";
import Messages from "../../common/messages/Messages";
import axios from "../../common/api/BuyingApi";
import ShopsCardAsList from "./MarketCardAsList";
import ProductsNotFound from "../../_temp/productNotFound";
import Error from "../../_temp/error";
import SkeletonLoader from "../../_temp/skeletonLoader";
import SkeletonLoaderList from "../../_temp/skeletonLoader/loaderList";

const AllMarkets = () => {
  const { markets, view_mode, markets_page, loading, error } = useSelector(
    (state: any) => state.MarketReducer
  );
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("");
  const dispatch = useDispatch();
  const VIEW_MODE_GRID = "GRID";
  const VIEW_MODE_LIST = "LIST";
  const [isShowMenu, setIsShowMenu] = useState(false);
  let start: number = 0;

  // const getMarkets = async () => {
  //   try {
  //     dispatch(setMarketsLoading(true));
  //     const res = await axios.get(`https://hammol.uz/api/commodity/place`);
  //     dispatch(getMarketsList(res.data.items));
  //   } catch (error) {
  //     console.log(error);
  //     dispatch(setMarketsError(Messages.t("FETCHING_ERROR")));
  //   } finally {
  //     dispatch(setMarketsLoading(false));
  //   }
  // };
  const getMarketsWithParams = async (query: string, sort: string) => {
    try {
      dispatch(setMarketsLoading(true));
      const res = await axios.get(
        `https://hammol.uz/api/commodity/place?name=${query}&sortBy=${sort}&start=${0}`
      );
      dispatch(getMarketsList(res.data.items));
    } catch (error) {
      console.log(error);
      dispatch(setMarketsError(Messages.t("FETCHING_ERROR")));
    } finally {
      dispatch(setMarketsLoading(false));
    }
  };

  const fetchMoreShops = async () => {
    const offset = markets_page + 20;
    const res = await axios.get<any, any>(
      `https://hammol.uz/api/commodity/shop?name=${query}&sortBy=${sort}&start=${offset}`
    );
    // dispatch(receiveMoreShops(res.data.items, offset));
  };

  // const setSortBy = (sort_by: string) => {
  //   // dispatch(setShopsSortBy(sort_by));
  // };
  // const changeMode = (view_mode: string) => {
  //   // dispatch(changeViewMode(view_mode));
  // };

  useEffect(() => {
    getMarketsWithParams(query, sort);
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    getMarketsWithParams(query, sort);
  };

  return (
    <div>
      <HelmetComponent title="HAMMOL" />
      <Header />
      <main className="main">
        <section className="products-section">
          <div className="container">
            <div className="searchform__container">
              <form className="header__search-form" onSubmit={handleSubmit}>
                <input
                  className="header__search-input"
                  id="inp_name"
                  type="text"
                  placeholder={Messages.t("Search_by_allMarkets")}
                  value={query}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setQuery(e.target.value)
                  }
                />
                <button className="header__search-btn" type="submit">
                  {Messages.t("Search")}
                </button>
              </form>
            </div>
            {/* <div className="show-filter-options">
              <ul className="show-filter">
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_OPEN);
                    setSort(SHOPS_SORT_BY_OPEN);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_OPEN && "active")
                  }
                >
                  {Messages.t("open")}
                </li>
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_CLOSE);
                    setSort(SHOPS_SORT_BY_CLOSE);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_CLOSE && "active")
                  }
                >
                  {Messages.t("closed")}
                </li>
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_RATING_ASC);
                    setSort(SHOPS_SORT_BY_RATING_ASC);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_RATING_ASC && "active")
                  }
                >
                  {Messages.t("ratingAsc")}
                </li>
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_NAME_ASC);
                    setSort(SHOPS_SORT_BY_NAME_ASC);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_NAME_ASC && "active")
                  }
                >
                  {Messages.t("nameAsc")}
                </li>
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_NAME_DESC);
                    setSort(SHOPS_SORT_BY_NAME_DESC);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_NAME_DESC && "active")
                  }
                >
                  {Messages.t("nameDesc")}
                </li>
              </ul>
              <ul className="mobile__filter-list">
                <li className="header__menu-item" id="langToggle">
                  <span
                    className="header__menu-link"
                    onClick={(k) => {
                      setIsShowMenu(!isShowMenu);
                    }}
                  >
                    {Messages.t("FILTER")}
                    <svg
                      className="icon change-lang-arrow"
                      width="5"
                      height="8"
                    >
                      <use href="static/img/icons/sprite/sprite.svg#arrow" />
                    </svg>
                  </span>
                  <div
                    className={
                      isShowMenu
                        ? "header__lang header__lang--open"
                        : "header__lang"
                    }
                  >
                    <ul className="header__lang-list">
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_OPEN);
                          setSort(SHOPS_SORT_BY_OPEN);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("open")}
                        </span>
                      </li>
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_CLOSE);
                          setSort(SHOPS_SORT_BY_CLOSE);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("closed")}
                        </span>
                      </li>
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_RATING_ASC);
                          setSort(SHOPS_SORT_BY_RATING_ASC);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("ratingAsc")}
                        </span>
                      </li>
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_NAME_ASC);
                          setSort(SHOPS_SORT_BY_NAME_ASC);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("nameAsc")}
                        </span>
                      </li>
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_NAME_DESC);
                          setSort(SHOPS_SORT_BY_NAME_DESC);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("nameDesc")}
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

              <div className="view-filter">
                <div
                  className={
                    view_mode === VIEW_MODE_LIST
                      ? "view-filter__option view_mode view-filter__option--selected"
                      : "view-filter__option view_mode"
                  }
                >
                  <svg
                    className="icon"
                    width="20"
                    height="20"
                    onClick={() => changeMode(VIEW_MODE_LIST)}
                  >
                    <use href="static/img/icons/sprite/sprite.svg#list-view" />
                  </svg>
                </div>
                <div
                  className={
                    view_mode === VIEW_MODE_GRID
                      ? "view-filter__option view_mode view-filter__option--selected"
                      : "view-filter__option view_mode"
                  }
                >
                  <svg
                    className="icon"
                    width="20"
                    height="20"
                    onClick={() => changeMode(VIEW_MODE_GRID)}
                  >
                    <use href="static/img/icons/sprite/sprite.svg#grid-view" />
                  </svg>
                </div>
              </div>
            </div> */}
            <div className="top-products">
              {view_mode === VIEW_MODE_GRID ? (
                <div className="top-products__row">
                  {loading ? (
                    <SkeletonLoader className="market" number={5} />
                  ) : error ? (
                    <Error />
                  ) : markets && markets.length > 0 ? (
                    markets.map((item: any, i: number) => (
                      <ShopsCard item={item} key={item.name + i} />
                    ))
                  ) : (
                    <ProductsNotFound message={Messages.t("NO_MARKETS")} />
                  )}
                </div>
              ) : (
                <ul className="shop-products__list">
                  {loading ? (
                    <SkeletonLoaderList className="market" number={5} />
                  ) : error ? (
                    <Error />
                  ) : (
                    markets &&
                    markets.length > 0 &&
                    markets.map((item: any, i: number) => (
                      <ShopsCardAsList item={item} key={item.name + i} />
                    ))
                  )}
                </ul>
              )}
              {!(markets.length < +markets_page + 20) && (
                <div
                  className="top-products__more"
                  onClick={() => fetchMoreShops()}
                >
                  {Messages.t("Show_more_products")}
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AllMarkets;
