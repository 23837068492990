import * as types from "./constants";
import {
  FetchMarketsAcn,
  FetchSingleShopAcn,
  ISearchProductsMoreFetchAcn,
  ISetSortBy,
  ISetShopsSortBy,
  ISearchProductsFetchAcn,
  IReceiveMoreShops,
  ISetMarketCategoryModalOpen,
  IChangeMarketItemsType,
  ISetMarketCategoryHeader,
  ISetMarketCategoryItems,
  ISetMarketCategoryParams,
  IChangeMarketViewModeAcn,
  IMarketsLoading,
  IMarketProductsLoading,
  IMarketShopsLoading,
  IMarketsError,
} from "./actionsTypes";

export function getMarketsList(markets: any): FetchMarketsAcn {
  return { type: types.RECEIVE_ALL_MARKETS, markets };
}

export function getSingleMarket(singleMarket: any): FetchSingleShopAcn {
  return { type: types.RECEIVE_SINGLE_MARKET, singleMarket };
}

// export function fetchMoreSearchProducts({
//   items,
//   _page,
// }: {
//   items: any[];
//   _page: number;
// }): ISearchProductsMoreFetchAcn {
//   return {
//     type: types.RECEIVE_MORE_SHOP_PRODUCTS,
//     payload: { items, _page },
//   };
// }

// export function fetchSearchProducts({
//   items,
//   _page,
// }: {
//   items: any[];
//   _page: number;
// }): ISearchProductsFetchAcn {
//   return {
//     type: types.RECEIVE_SEARCH_PRODUCTS,
//     payload: { items, _page },
//   };
// }

export function changeMarketViewMode(
  view_mode: string
): IChangeMarketViewModeAcn {
  return { type: types.CHANGE_VIEW_MODE, view_mode };
}

// export function setSortBy(sort_by: string): ISetSortBy {
//   return { type: types.SET_SORT_BY, sort_by };
// }

// export function setShopsSortBy(sort_by: string): ISetShopsSortBy {
//   return { type: types.SET_SHOPS_SORT_BY, sort_by };
// }

// export function receiveMoreShops(shops: any, page: number): IReceiveMoreShops {
//   return { type: types.RECEIVE_MORE_SHOPS, shops, page };
// }

export function setMarketCategoryModalOpen(
  payload: boolean
): ISetMarketCategoryModalOpen {
  return {
    type: types.SET_CATEGORYMODAL_OPEN,
    payload,
  };
}

export const changeMarketItemsType = (
  payload: any
): IChangeMarketItemsType => ({
  type: types.CHANGE_MARKET_ITEMS_TYPE,
  payload,
});

export function setMarketCategoryHeader(
  payload: any
): ISetMarketCategoryHeader {
  return {
    type: types.SET_MARKET_CATEGORY_HEADER,
    payload,
  };
}

export function setMarketCategoryItems(payload: any): ISetMarketCategoryItems {
  return {
    type: types.SET_MARKET_CATEGORY_ITEMS,
    payload,
  };
}

export function setMarketCategoryParams(
  payload: any
): ISetMarketCategoryParams {
  return {
    type: types.SET_MARKET_CATEGORY_PARAMS,
    payload,
  };
}

export function setMarketsLoading(loading: boolean): IMarketsLoading {
  return {
    type: types.SET_MARKETS_LOADING,
    loading,
  };
}

export function setMarketShopsLoading(loading: boolean): IMarketShopsLoading {
  return {
    type: types.SET_MARKET_SHOPS_LOADING,
    loading,
  };
}
export function setMarketProductsLoading(
  loading: boolean
): IMarketProductsLoading {
  return {
    type: types.SET_MARKET_PRODUCTS_LOADING,
    loading,
  };
}

export function setMarketsError(error: string | null): IMarketsError {
  return {
    type: types.SET_MARKETS_ERROR,
    error,
  };
}
