import {
  RECEIVE_MORE_PRODUCTS,
  RECEIVE_TOP_PRODUCTS,
  SET_TOP_PRODUCTS_LOADING,
} from "./constants";
import { ITopProductsState } from "./types";
import { TopProductsActions } from "./actionsTypes";

const initialState: ITopProductsState = {
  _page: 1,
  products: [],
  loading: true,
};

export default function TopProductsReducer(
  state: any = initialState,
  action: TopProductsActions
): any {
  switch (action.type) {
    case RECEIVE_TOP_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };

    case RECEIVE_MORE_PRODUCTS:
      return {
        ...state,
        _page: action.data._page,
        products: [...state.products, ...action.data.products],
      };
    case SET_TOP_PRODUCTS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
}
