import React, { Component } from "react";
import "./Step2.css";
import { bindActionCreators } from "redux";
import * as AuthActions from "../store/actions";
import { connect } from "react-redux";
import store from "../../app/store/reducers";
import signUpApi from "../../common/api/SignUpApi";
import { UserReg } from "../../common/types/UserReg";
import Messages from "../../common/messages/Messages";
import axios from "axios";
import PhoneNumber from "../../common/components/PhoneNumber";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

class Step2 extends Component<any, any> {
  MALE = "male";
  FEMALE = "female";
  userNamePattern = /^[a-zA-Zа-яёА-ЯË]+(?:[ -][a-zA-Zа-яёА-ЯË]+)*$/;
  userCodePattern = /^[0-9]{6}$/;

  constructor(props: any) {
    super(props);

    // this.goHome = this.goHome.bind(this);
    this.sendCodeNumber = this.sendCodeNumber.bind(this);
    this.setName = this.setName.bind(this);
    this.setMale = this.setMale.bind(this);
    this.setCode = this.setCode.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateCode = this.validateCode.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);

    this.state = {
      notValidName: "",
      notValidCode: "",
      phoneNumber: "",
      isEyeClose: true,
    };
  }

  // goHome(e: any) {
  //     e.preventDefault();
  //     const {actions} = this.props;
  //     actions.authResetStateAcn();
  //     window.location.href = '#';
  // }

  setName(e: any) {
    e.preventDefault();
    const { actions } = this.props;
    actions.authSetUserRegNameAcn(e.target.value);
    let { userReg } = store.getState().RegistrationReducer;
    this.validateName(userReg);
  }

  setPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length > 0) {
      this.setState((state: any, props: any) => {
        return {
          phoneNumber: phoneNumber,
          //   btnDisabled: !phoneNumber,
        };
      });
    }
  };

  setMale(e: any) {
    const { actions } = this.props;
    actions.authSetUserRegMaleAcn(e.target.value === this.MALE);
  }

  setCode(e: any) {
    const { actions } = this.props;
    actions.authSetUserRegCodeAcn(e.target.value);
    let { userReg } = store.getState().RegistrationReducer;
    this.validateCode(userReg);
  }

  validateForm(userReg: UserReg): boolean {
    return this.validateName(userReg) && this.validateCode(userReg);
  }

  validateName(userReg: UserReg): boolean {
    let result = true;
    let notValidName = "";

    if (!userReg.name || !userReg.name.toString().match(this.userNamePattern)) {
      notValidName = "not-valid";
      result = false;
    }

    this.setState({
      ...this.state,
      notValidName,
    });
    return result;
  }

  validateCode(userReg: UserReg): boolean {
    let result = true;
    let notValidCode = "";

    if (!userReg.code || !userReg.code.toString().match(this.userCodePattern)) {
      notValidCode = "not-valid";
      result = result && false;
    }

    this.setState({
      ...this.state,
      notValidCode,
    });
    return result;
  }

  sendCodeNumber(e: any) {
    e.preventDefault();

    const userReg = store.getState().RegistrationReducer.userReg || "";
    const { actions } = this.props;
    if (this.validateForm(userReg)) {
      axios
        .post("https://hammol.uz/api/auth/v1/signup/step2", {
          phone: `998${this.state.phoneNumber}`,
          name: userReg.name,
          male: userReg.male,
          code: userReg.code,
        })
        .then((result: any) => {
          if (result) {
            actions.authSetStepToAcn(3);
            toast(Messages.t("You_successfully_registered"));
            window.location.href = "#/login";
            // if (!result.error) {
            // } else {
            //   alert(Messages.t("Registration_error"));
            //   // window.location.href = "#/errror503";
            // }
          }
        })
        .catch((error) => {
          console.error(error);
          toast(Messages.t("Registration_error"));
          // actions.authSetStepToAcn(3);
          // window.location.href = '#/login';
        });
    }
  }

  render = () => {
    const currentYear = new Date().getFullYear();
    return (
      <>
        <MyToaster />
        <div className="form-section">
          {/* <a className="back-btn" href="/" onClick={this.goHome}>
          <svg className="icon" width="6" height="10">
            <use href="static/img/icons/sprite/sprite.svg#arrow" />
          </svg>
          {Messages.t("Come_back")}
        </a> */}
          <div className="form-wrap">
            <div className="form-title-wrap">
              <span className="form-title">
                {Messages.t("Registration")}, {Messages.t("_Registration_step")}{" "}
                - 2
              </span>
              {/*<span className="form-subtitle">Можно вернуться к - <a href="#/login"> Входу</a></span>*/}
            </div>
            <form className="form">
              <div className="form__input-wrap mb-3">
                <PhoneNumber
                  setPhoneNumber={this.setPhoneNumber}
                  setState={this.setState}
                />
              </div>
              <label>{Messages.t("What_is_your_name?")}</label>
              <div className="form__input-wrap mb-2">
                <input
                  className={"form__input " + this.state.notValidName}
                  type="text"
                  onChange={this.setName}
                />
              </div>
              <label>{Messages.t("Gender")}</label>
              <div className="profile-info__gender">
                <div className="profile-info__gender-option">
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value={this.MALE}
                    onChange={this.setMale}
                  />
                  <label className="profile-info__label" htmlFor="male">
                    <span className="profile-info__gender-checkbox"> </span>
                    <span className="profile-info__gender-text">
                      {Messages.t("Male")}
                    </span>
                  </label>
                </div>
                <div className="profile-info__gender-option">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value={this.FEMALE}
                    onChange={this.setMale}
                  />
                  <label className="profile-info__label" htmlFor="female">
                    <span className="profile-info__gender-checkbox"> </span>
                    <span className="profile-info__gender-text">
                      {Messages.t("Female")}
                    </span>
                  </label>
                </div>
              </div>
              <label>{Messages.t("SMS_code_of_registration")}</label>
              <div className="form__input-wrap mb-3">
                <input
                  className={"form__input " + this.state.notValidCode}
                  type={this.state.isEyeClose ? "password" : "text"}
                  onChange={this.setCode}
                />
                {this.state.isEyeClose ? (
                  <img
                    onClick={() => {
                      console.log(12132);
                      this.setState({ isEyeClose: !this.state.isEyeClose });
                    }}
                    className="form__img"
                    src="/static/img/icons/eye-closed.svg"
                    alt="eye"
                  />
                ) : (
                  <img
                    onClick={() =>
                      this.setState({ isEyeClose: !this.state.isEyeClose })
                    }
                    className="form__img"
                    src="/static/img/icons/eye-open.svg"
                    alt="eye"
                  />
                )}
              </div>
              <p className="form__copy-text">
                {Messages.t("Approve_licence_")}
                <a href="/"> {Messages.t("_licence_condition")} </a>
                {Messages.t("_condition_site")}
              </p>
              <button
                className={`form__button`}
                type="submit"
                onClick={this.sendCodeNumber}
              >
                {Messages.t("Registration")}
              </button>
            </form>
          </div>
          <div className="site-copyright">©Hammol.uz - {currentYear}</div>
        </div>
      </>
    );
  };
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
