import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AllProductsActions from "../store/actions";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import AllProducts from "../components/AllProducts";
import * as CategoryActions from "../../category/store/actions";

// TO DO any
const mapStateToProps = (state: any) => {
  return {
    _page: state.AllProductsReducer._page,
    products: state.AllProductsReducer.products,
    view_mode: state.AllProductsReducer.view_mode,
    name: state.AllProductsReducer.name,
    category: state.AllProductsReducer.category,
    isThereAgain: state.AllProductsReducer.isThereAgain,
    shops: state.ShopReducer.shops,
    categories: state.CategoryReducer.categories,
    cartProducts: state.ShoppingCartReducer.shops,
    activeShop: state.ShoppingCartReducer.shop,
    messages: state.LangReducer.messages,
    opensidebar: state.CategoryReducer.opensidebar,
    filter: state.AllProductsReducer.filter,
    min_cost: state.AllProductsReducer.minCost,
    max_cost: state.AllProductsReducer.maxCost,
    productsLoading: state.AllProductsReducer.loading,
    error: state.AllProductsReducer.error,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AllProductsActions, dispatch),
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
  actionsCategory: bindActionCreators(CategoryActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllProducts);
