import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ProductDetailActions from "../store/actions";
import ProductDetail from "../components/ProductDetail";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";

const mapStateToProps = (state: any) => {
  return {
    product: state.ProductDetailReducer.product,
    cartState: state.ShoppingCartReducer.shops,
    shops: state.ShopReducer.shops,
    cartProducts: state.ShoppingCartReducer.products,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(ProductDetailActions, dispatch),
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
