export const LANG_UZK = "uz";
export const LANG_UZ = "oz";
export const LANG_RU = "ru";
export const LANG_CAPTIONS: any = {
  [LANG_UZK]: "Ўзбекча",
  [LANG_UZ]: "Oʻzbekcha",
  [LANG_RU]: "Русский",
};
export const ALL_PRODUCT_ITEMS_LIMIT = 15; //12
export const SHOP_PRODUCTS_LIMIT = 15;
export const TOKEN_TIME = "tokenTime";
export const LS_LAST_TIME_ITEM = "lastRequestTime";
export const WAIT_MS = 300;

export const DEFAULT_LANG = "uz";

export const LANG_ALIAS = "app_lang";

export const ALREADY_EXISTS = "ALREADY_EXISTS";

export const LANG_VAR_NAME_WE = "jadas.languageList = ";
export const CATEGORY_ITEM_PREFIX = "product_item_";
export const CATEGORY_TYPE_PREFIX = "product_type_";
export const SITE_HOME_ALIAS = "home";
export const YANDEX_MAP_API_KEY = "da970ba8-7d11-4251-9bbf-535fbab48e1d";
export const HEADER_WITH_API_KEY = {
  "Content-Type": "application/x-www-form-urlencoded",
  "api-key": "dc802dae89a0268b6c4cf443f839ej02",
  lang: localStorage.getItem("app_lang") || DEFAULT_LANG,
};

export const LANG_FILE_PATH_PREFIX = "/tmp/lang-";
