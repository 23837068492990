import { AbstractApi } from './AbstractApi';
import axios from 'axios';

class ShopApi extends AbstractApi {
  getAll = (params: any = {}) => {
    const PATH = 'commodity/shop';
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET, params);
  };
  getSingleShop = (id: string) => {
    const PATH = `https://hammol.uz/api/commodity/shop/${id}`;
    return axios.get(PATH);
  };
  getSingleShopProducts = (params: any = {}) => {
    const PATH = `https://hammol.uz/api/commodity/product/all`;
    return axios.get(PATH, params);
  };
}

const shopApi = new ShopApi();
export default shopApi;
