import React, { Component } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import "./shop-info.css";

import Messages from "../../common/messages/Messages";
import { LatLngTuple } from "leaflet";
import Rating from "../../product_detail/components/Rating";
import shopRatingApi from "../../common/api/ShopRatingApi";
import MainLoader from "../../_temp/hammolLoader/MainLoader";
import Error from "../../_temp/error";

// {
//   "id": 10005,
//   "logo": {
//       "src": "base64",
//       "bytes": 23153,
//       "width": 512,
//       "height": 154
//   },
//   "link": null,
//   "phone": null,
//   "working": {},
//   "summary": "id_158280859022323189036",
//   "currency": 1,
//   "discount": "0.00",
//   "shopactive": false,
//   "name": "Ecobozor",
//   "lat": "41.353506",
//   "lon": "69.351400",
//   "address": "Узбекистан, Ташкент, Мирзо-Улугбекский район, улица Тимура Малика, 3А",
//   "rate": "0.00"
// }

export default class MarketInfo extends Component<{
  data: {
    id: number;
    logo: {
      src: string;
      bytes: number;
      width: number;
      height: number;
    };
    link: null | string;
    phone: null | string;
    working: {};
    note: string;
    currency: number;
    discount: string;
    shopactive: boolean;
    name: string;
    point: any;
    lat: string;
    lon: string;
    address: string;
    rate: string;
  };
  loading: boolean;
  error: string | null;
}> {
  render() {
    const { data, loading, error } = this.props;

    if (!data) return null;
    // const location: LatLngTuple = [+data.lat, +data.lon];
    const location: LatLngTuple | null = Object.keys(data).length
      ? [data?.point?.x, data?.point?.y]
      : null;

    // const setRatingShop = (rate: number) => {
    //   console.log(rate);
    //   shopRatingApi.setRating(data.id, { rate: rate });
    // };

    return (
      <div className="container shop-info__container">
        {error ? (
          <Error className="mt-2" />
        ) : (
          <div className="shop-info__content">
            {loading ? (
              <MainLoader />
            ) : (
              <>
                {data.logo && (
                  <img
                    className="shop-info__logo"
                    src={`data:image/png;base64,${data.logo.src}`}
                    style={{ width: data.logo.width }}
                    alt={`${data.name} logo`}
                  />
                )}
                <div className="shop-info__details">
                  <h2 className="shop-info__title">
                    <span>{data.name}</span>
                    <span className="shop-info__status">
                      {data.shopactive
                        ? Messages.t("Open")
                        : Messages.t("Close")}
                    </span>
                  </h2>
                  <p className="shop-info__summary">{data.note}</p>
                  <ul className="shop-info__details">
                    <li
                      className="shop-info__detail"
                      style={{ marginBottom: "10px" }}
                    >
                      {/* <Rating initialState={data.rate} postHandler={setRatingShop} /> */}
                    </li>
                    {data.link && (
                      <li className="shop-info__detail">
                        <b>{Messages.t("WebSite")}: </b>
                        <a className="shop-info__link" href={data.link}>
                          {data.link}
                        </a>
                      </li>
                    )}
                    {data.phone && (
                      <li className="shop-info__detail">
                        <b>{Messages.t("Phone")}: </b>
                        <a
                          className="shop-info__link"
                          href={`tel:${data.phone}`}
                        >
                          {data.phone}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                {location && (
                  <MapContainer
                    className="shop-info__map"
                    center={location}
                    zoom={15}
                    scrollWheelZoom={true}
                  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <svg
                      className="icon"
                      width="30"
                      height="30"
                      style={{
                        zIndex: 999,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <use href="static/img/icons/sprite/sprite.svg#map"></use>
                    </svg>
                  </MapContainer>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}
