import { IRecommendedProductsState } from "./types";
import { RecommendedProductsActions } from "./actionsTypes";
import {
  RECEIVE_RECOMMENDED_PRODUCTS,
  SET_RECOMMENDED_LOADING,
} from "./constants";

const initialState: IRecommendedProductsState = {
  products: [],
  loading: false,
};

export default function RecommendedProductsReducer(
  state: any = initialState,
  action: RecommendedProductsActions
): any {
  switch (action.type) {
    case RECEIVE_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case SET_RECOMMENDED_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    // RECEIVE_TOP_PRODUCTS
    default:
      return state;
  }
}
