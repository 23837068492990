import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import Buy from "../components/Buy";
import * as BuyingActions from "../store/actions";

// To DO any
const mapStateToProps = (state: any) => {
  return {
    shop: state.ShoppingCartReducer.shops,
    products: state.ShoppingCartReducer.products,
    totalCost: state.ShoppingCartReducer.totalCost,
    totalQuantity: state.ShoppingCartReducer.totalQuantity,
    shoppingCart: state.ShoppingCartReducer,
    authUser: state.AuthReducer,
    activeShop: state.BuyProductsReducer.activeShop,
    driverInfo: state.BuyProductsReducer.driverInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
  actionsBuying: bindActionCreators(BuyingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Buy);
