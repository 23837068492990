import React, { Component } from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import productDetailApi from "../../common/api/ProductDetailApi";
import Breadcrumb from "../../header/containers/Breadcrumb";
import config from "../../common/config/config";
import Swiper from "swiper";
import Messages from "../../common/messages/Messages";
import ProductBasket from "../../common/components/details/ProductBasket";
import { productShopAndBasket } from "../../common/helpers/Helper";
import HelmetComponent from "../../_temp/helmet";
import Rating from "./Rating";
import productRatingApi from "../../common/api/ProductRatingApi";
import { Link, NavLink } from "react-router-dom";
import MainLoader from "../../_temp/hammolLoader/MainLoader";
import Error from "../../_temp/error";

interface IImage {
  width: number;
  height: number;
  src: string;
}

class ProductDetail extends Component<any, any> {
  hostUrl = config.hostUrl;

  constructor(props: any) {
    super(props);
    const product_id = this.props.match.params.id;
    this.state = {
      id: product_id,
      product: {},
      loading: true,
      error: null,
    };
  }

  componentDidMount(): void {
    this.getProductDetail(this.state.id);
    window.scroll(0, 0);
    // this.swiperContainerOnLoad();
  }

  swiperContainerOnLoad = () => {
    const galleryThumbs = new Swiper(
      ".product-info__img-gallery .swiper-container",
      {
        spaceBetween: 10,

        slidesPerView: "auto",
        freeMode: true,
        direction: "vertical",
        navigation: {
          nextEl: ".product-info__gallery-btn-next",
          prevEl: ".product-info__gallery-btn-prev",
        },

        breakpoints: {
          200: {
            direction: "horizontal",
          },
          1171: {
            direction: "vertical",
          },
        },
      }
    );

    new Swiper(".product-info__img-main .swiper-container", {
      spaceBetween: 10,
      direction: "vertical",
      loopedSlides: 5, // looped slides should be the same
      thumbs: {
        swiper: galleryThumbs,
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      breakpoints: {
        200: {
          direction: "horizontal",
        },
        1171: {
          direction: "vertical",
        },
      },
    });
  };

  getProductDetail = async (id: string) => {
    let self = this;
    self.setState({ loading: true });
    await productDetailApi
      .getDetail(id)
      .then((result) => result.json())
      .then((product) => {
        if (product.error) {
          throw product;
        } else {
          self.setState({
            ...self.state,
            product: product,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ error: Messages.t("FETCHING_ERROR") });
      })
      .finally(() => self.setState({ loading: false }));
  };

  setProductRating = (rate: number) => {
    productRatingApi.setRating(this.state.id, { rate: rate });
  };

  render = () => {
    let { product, from } = this.state;
    let { shops, actionsShoppingCart, cartState } = this.props;
    if (product) {
      product = {
        ...productShopAndBasket(product, cartState),
      };
    }

    return !product ? null : (
      <div>
        <HelmetComponent title={`${product?.name || "HAMMOL"}`} />
        <Header />
        <Breadcrumb />
        <main className="main">
          {this.state.error ? (
            <div className="mb-5">
              <Error />
            </div>
          ) : (
            <section className="product-info">
              {this.state.loading ? (
                <MainLoader />
              ) : (
                <div className="container">
                  <div className="product-info__top">
                    {product.category && product.category.category && (
                      <div className="product-info__top-breadcrumbs">
                        <p>
                          {product.category.category} <span>/</span>
                        </p>
                        <p>
                          {product.category.group} <span>/</span>
                        </p>
                        <p>
                          {product.category.class} <span>/</span>
                        </p>
                        <Link
                          to={`/allproducts?category=${product.category.position_id}`}
                        >
                          {product.category.position}
                        </Link>
                      </div>
                    )}
                    <h1 className="product-info__title">{product?.name}</h1>
                    <div className="product-info__rating-large">
                      {product?.rating ? (
                        <Rating
                          initialState={product?.rating}
                          postHandler={this.setProductRating}
                        />
                      ) : null}

                      {/* <div className="product-card__rating">{ratings}</div> */}

                      {/*TO DO*/}
                      {/*<span className="product-info__feedback">*/}
                      {/*    12 отзывов<a href="#">написать отзыв</a>*/}
                      {/*</span>*/}
                    </div>
                  </div>
                  <div className="grid-container-product">
                    <div className="content-product">
                      <div className="product-info__main product-in-favorite">
                        <div className="product-info__img">
                          <div className="product-info__img-gallery">
                            <div className="swiper-container">
                              <div
                                className="swiper-wrapper"
                                onLoad={this.swiperContainerOnLoad}
                              >
                                {typeof product?.images === "object" ? (
                                  <>
                                    {product?.images?.map(
                                      (image: IImage, index: number) => {
                                        // TO DO
                                        return (
                                          <div
                                            key={index}
                                            className="swiper-slide"
                                          >
                                            <div className="product-info__img-item">
                                              {typeof image === "object" ? (
                                                image.src ? (
                                                  image.src.includes(
                                                    "temp/"
                                                  ) ? (
                                                    <img
                                                      src={
                                                        this.hostUrl + image.src
                                                      }
                                                      alt="rasm"
                                                    />
                                                  ) : (
                                                    <>
                                                      {typeof image ===
                                                      "object" ? (
                                                        <img
                                                          src={
                                                            "data:image/png;base64, " +
                                                            image.src
                                                          }
                                                          alt="rasm"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            "https://hammol.uz/temp/noimage2.png"
                                                          }
                                                          alt="rasm"
                                                        />
                                                      )}
                                                    </>
                                                  )
                                                ) : (
                                                  <img
                                                    src="https://hammol.uz/temp/noimage2.png"
                                                    alt=""
                                                  />
                                                )
                                              ) : (
                                                <img
                                                  src="https://hammol.uz/temp/noimage2.png"
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div className="swiper-slide">
                                    <div className="product-info__img-item">
                                      <img
                                        src="https://hammol.uz/temp/noimage2.png"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="product-info__gallery-btn product-info__gallery-btn-prev">
                              <svg className="icon" width="6" height="10">
                                <use href="static/img/icons/sprite/sprite.svg#arrow" />
                              </svg>
                            </div>
                            <div className="product-info__gallery-btn product-info__gallery-btn-next">
                              <svg className="icon" width="6" height="10">
                                <use href="static/img/icons/sprite/sprite.svg#arrow" />
                              </svg>
                            </div>
                          </div>
                          <div className="product-info__img-main">
                            <div className="swiper-container">
                              <div className="swiper-wrapper">
                                {/* {product?.images?.map(
                           (
                             image: IImage,
                             index: number,
                           ) => {
                             //TO DO
                             return (
                               <div
                                 key={
                                   image.src +
                                   image.height
                                 }
                                 className="swiper-slide">
                                 <div className="product-info__img-active">
                                   <img
                                     src={
                                       this.hostUrl +
                                       image.src
                                     }
                                     alt=""
                                   />
                                 </div>
                               </div>
                             );
                           },
                         )} */}
                                <>
                                  {typeof product?.images === "object" ? (
                                    <>
                                      {product?.images?.map(
                                        (image: IImage, index: number) => {
                                          return (
                                            <React.Fragment key={index}>
                                              {typeof image === "object" ? (
                                                <div className="swiper-slide">
                                                  <div
                                                    key={
                                                      image.src + image.width
                                                    }
                                                    className="product-info__img-active"
                                                  >
                                                    {image.src ? (
                                                      image.src.includes(
                                                        "temp/"
                                                      ) ? (
                                                        <img
                                                          src={
                                                            this.hostUrl +
                                                            image.src
                                                          }
                                                          alt="rasm"
                                                        />
                                                      ) : (
                                                        <>
                                                          {typeof image ===
                                                          "object" ? (
                                                            <img
                                                              src={
                                                                "data:image/png;base64, " +
                                                                image.src
                                                              }
                                                              alt="rasm"
                                                            />
                                                          ) : (
                                                            <img
                                                              src={
                                                                "https://hammol.uz/temp/noimage2.png"
                                                              }
                                                              alt="rasm"
                                                            />
                                                          )}
                                                        </>
                                                      )
                                                    ) : (
                                                      <div className="product-info-desc__img">
                                                        <img
                                                          src="https://hammol.uz/temp/noimage2.png"
                                                          alt=""
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="product-info-desc__img">
                                                  <img
                                                    src="https://hammol.uz/temp/noimage2.png"
                                                    alt=""
                                                  />
                                                </div>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <div className="product-info-desc__img">
                                      <img
                                        src="https://hammol.uz/temp/noimage2.png"
                                        alt=""
                                      />
                                    </div>
                                  )}
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="product-info__desc">
                      <div className="product-info__desc-articul">
                        <span>
                          {Messages.t("Shop") + ": "}
                          {product?.shop_name}
                        </span>
                        <span className="product-info__desc-favorite">
                          <svg className="icon" width="20" height="20">
                            <use href="img/icons/sprite/sprite.svg#heart" />
                          </svg>
                        </span>
                      </div>
                      <ul className="product-info__basic-list">
                        <li className="product-info__list-item">
                          <span className="product-info__list-name">
                            Производитель:
                          </span>
                          <span className="product-info__list-value">
                            Samsung
                          </span>
                        </li>
                        <li className="product-info__list-item">
                          <span className="product-info__list-name">
                            Модель:
                          </span>
                          <span className="product-info__list-value">
                            Curved 4K UHD
                          </span>
                        </li>
                        <li className="product-info__list-item">
                          <span className="product-info__list-name">
                            Диагональ:
                          </span>
                          <span className="product-info__list-value">65”</span>
                        </li>
                        <li className="product-info__list-item">
                          <span className="product-info__list-name">
                            Разрешение:
                          </span>
                          <span className="product-info__list-value">
                            4K QHD
                          </span>
                        </li>
                      </ul>
                      <p className="product-info__desc-text">{product?.note}</p>
                      <a className="product-info__desc-read" href="#desc">
                        Прочитать описание
                      </a>
                    </div> */}
                      </div>
                    </div>
                    <div className="product-info-tabs">
                      <div className="product-info-tabs__names">
                        <button
                          className="product-info-tabs__button product-info-tabs__button--active"
                          id="desc"
                          data-for-tab="1"
                        >
                          Описание
                        </button>
                        {/*TO DO*/}
                        {/*<button className="product-info-tabs__button" data-for-tab="2">*/}
                        {/*    Характеристики*/}
                        {/*</button>*/}
                        {/*<button className="product-info-tabs__button" data-for-tab="3"> Отзывы</button>*/}
                      </div>
                      <div
                        className="product-info-tabs__content product-info-tabs__content--active"
                        data-tab="1"
                      >
                        <div className="product-info-desc">
                          <div className="product-info-desc__top">
                            <h1 className="product-info-desc__title">
                              {product?.name}
                            </h1>
                            {/*TO DO*/}
                            {/*<span className="product-info-desc__subtitle">Например: О нас, доставка и оплата, поддержка</span>*/}
                          </div>
                          {/*TO DO*/}
                          {/*<h2 className="product-info-desc__heading">Заголовок</h2>*/}
                          <p className="product-info-desc__text">
                            {product?.note}
                          </p>
                          <>
                            {typeof product?.images === "object" ? (
                              <>
                                {product?.images?.map(
                                  (image: IImage, index: number) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {typeof image === "object" ? (
                                          <div
                                            key={image.src + image.width}
                                            className="product-info-desc__img"
                                          >
                                            {image.src ? (
                                              image.src.includes("temp/") ? (
                                                <img
                                                  src={this.hostUrl + image.src}
                                                  alt="rasm"
                                                />
                                              ) : (
                                                <>
                                                  {typeof image === "object" ? (
                                                    <img
                                                      src={
                                                        "data:image/png;base64, " +
                                                        image.src
                                                      }
                                                      alt="rasm"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={
                                                        "https://hammol.uz/temp/noimage2.png"
                                                      }
                                                      alt="rasm"
                                                    />
                                                  )}
                                                </>
                                              )
                                            ) : (
                                              <div className="product-info-desc__img">
                                                <img
                                                  src="https://hammol.uz/temp/noimage2.png"
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="product-info-desc__img">
                                            <img
                                              src="https://hammol.uz/temp/noimage2.png"
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <div className="product-info-desc__img">
                                <img
                                  src="https://hammol.uz/temp/noimage2.png"
                                  alt=""
                                />
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                    <aside className="aside-product">
                      <div className="product-cost">
                        {/*TO DO*/}
                        <div className="product-cost__current">
                          {product?.cost}
                          <span>сум</span>
                        </div>
                        {/*TO DO*/}
                        {/*<div className="product-cost__prev">*/}
                        {/*    <div className="product-cost__row">*/}
                        {/*        <span className="product-cost__prev-value">*/}
                        {/*            <span>{product?.old_cost}</span>*/}
                        {/*        </span>*/}
                        {/*        <span className="product-cost__benefit">Выгода 849 900 сум</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                      </div>
                      {/*<div className="no-cart-btn mt-2 openModal">*/}
                      {/*TO DO*/}
                      {/*<button className="add-cart-btn"><span>В КОРЗИНУ</span>*/}
                      {/*    <svg className="icon" width="17.5" height="17.5">*/}
                      {/*        <use href="img/icons/sprite/sprite.svg#add-shopping-cart"/>*/}
                      {/*    </svg>*/}
                      {/*</button>*/}
                      {product && (
                        <>
                          <div
                            className={`product-card ${
                              product?.in_basket ? "product-in-cart" : ""
                            }`}
                          >
                            <ProductBasket
                              actions={{
                                addOneProductToShoppingCart:
                                  actionsShoppingCart.addOneProductToShoppingCart,
                              }}
                              item={product}
                            />
                          </div>
                        </>
                      )}
                      {/*</div>*/}
                      <div className="store-info">
                        <div className="store-info__top">
                          <span>{Messages.t("Shop")}</span>
                          <div className="store-info__img">
                            <img
                              src={
                                product?.logo
                                  ? this.hostUrl + `/${product?.logo}`
                                  : "https://hammol.uz/temp/noimage2.png"
                              }
                              alt=""
                            />
                            {/*TO DO*/}
                            {/*<a href="#"></a>*/}
                          </div>
                        </div>
                        <div className="store-info__top">
                          <NavLink
                            to={`/shop/${product?.shop}`}
                            className="text-center"
                          >
                            {product?.shop_name}
                          </NavLink>
                        </div>
                        <ul className="store-info__list">
                          {/*TO DO*/}
                          {/*<li className="store-info__list-item">*/}
                          {/*    <svg className="icon" width="20" height="20">*/}
                          {/*        <use href="img/icons/sprite/sprite.svg#delivery"/>*/}
                          {/*    </svg>*/}
                          {/*    Бесплатная доставка*/}
                          {/*</li>*/}
                          {/*<li className="store-info__list-item">*/}
                          {/*    <svg className="icon" width="20" height="20">*/}
                          {/*        <use href="img/icons/sprite/sprite.svg#package"/>*/}
                          {/*    </svg>*/}
                          {/*    Есть в наличии*/}
                          {/*</li>*/}
                          <li className="store-info__list-item">
                            {/* <svg className="icon" width="20" height="20">
                          <use href="img/icons/sprite/sprite.svg#address" />
                        </svg> */}
                            <svg
                              width={25}
                              height={25}
                              className="icon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" />
                            </svg>
                            {product?.address}
                          </li>
                          {/*<li className="store-info__list-item">*/}
                          {/*    <svg className="icon" width="20" height="20">*/}
                          {/*        <use href="img/icons/sprite/sprite.svg#up"/>*/}
                          {/*    </svg>*/}
                          {/*    Самовывоз*/}
                          {/*</li>*/}
                        </ul>
                      </div>
                      {/*TO DO*/}
                      {/*<div className="aside-sales">*/}
                      {/*    <div className="simple-title"><span className="simple-title__text">С этим товаром покупают</span>*/}
                      {/*    </div>*/}
                      {/*    <div className="sale-products">*/}
                      {/*        <div className="sale-product">*/}
                      {/*            <div className="sale-product__img"><img src="img/tv2.png" alt=""></div>*/}
                      {/*            <span className="sale-product__summary">Iphone 11 Pro Max 256GB + Airpods второго поколения</span>*/}
                      {/*            <div className="sale-product__cost">16 599 990<span>сум</span></div>*/}
                      {/*            <a href="#"></a>*/}
                      {/*        </div>*/}
                      {/*        <div className="sale-product">*/}
                      {/*            <div className="sale-product__img"><img src="img/tv2.png" alt=""></div>*/}
                      {/*            <span className="sale-product__summary">Iphone 11 Pro Max 256GB + Airpods второго поколения</span>*/}
                      {/*            <div className="sale-product__cost">16 599 990<span>сум</span></div>*/}
                      {/*            <a href="#"></a>*/}
                      {/*        </div>*/}
                      {/*        <div className="sale-product">*/}
                      {/*            <div className="sale-product__img"><img src="img/tv2.png" alt=""></div>*/}
                      {/*            <span className="sale-product__summary">Iphone 11 Pro Max 256GB + Airpods второго поколения</span>*/}
                      {/*            <div className="sale-product__cost">16 599 990<span>сум</span></div>*/}
                      {/*            <a href="#"></a>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                    </aside>
                  </div>
                </div>
              )}
            </section>
          )}

          {/*<AbstractProductDetail item={item}/>*/}
        </main>
        <Footer />
      </div>
    );
  };
}

export default ProductDetail;
