import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import store from "./app/store/reducers";
import App from './app/containers/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render((
        <Provider store={store}>
            <App/>
        </Provider>
    )
    , document.getElementById('app'));

serviceWorker.unregister();
