import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allProductsApi from '../../../common/api/AllProductsApi';
import shopFilterCategoryApi from '../../../common/api/ShopFilterCategory';
import {
  changeMarketItemsType,
  setMarketCategoryHeader,
  setMarketCategoryItems,
  setMarketCategoryModalOpen,
  setMarketCategoryParams,
} from '../../store/actions';
import { fetchSearchProducts } from '../../../shop/store/actions';

import './filtercategory.css';

type Props = {};

interface IState {
  category: null | number;
  group: null | number;
  class: null | number;
}

const initialState: IState = {
  category: null,
  group: null,
  class: null,
};

const FilterCategoryModal: FC = (props: Props) => {
  const dispatch = useDispatch();
  const {
    singleMarket: { id: marketId },
    categoryFilter: { headers, items, params: categoryParams },
  } = useSelector(({ MarketReducer }: any) => MarketReducer);
  const state = useSelector(({ ShopReducer }: any) => ShopReducer);

  const handleGetCategory = async () => {
    try {
      const { data } = await shopFilterCategoryApi.getMarketFilterCategory(
        marketId,
        categoryParams,
      );
      if (data.error === null) {
        dispatch(setMarketCategoryItems(data.item));
        if (data?.parent && data?.parent.length > 0) {
          dispatch(setMarketCategoryHeader(data.parent));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetParams = (field: string, value: number) => {
    switch (field) {
      case 'category':
        dispatch(
          setMarketCategoryParams({ ...categoryParams, [field]: value }),
        );
        break;
      case 'group':
        dispatch(
          setMarketCategoryParams({ ...categoryParams, [field]: value }),
        );

        break;
      case 'class':
        dispatch(
          setMarketCategoryParams({ ...categoryParams, [field]: value }),
        );

        break;
      case 'position':
        let params = {
          type: value,
          place: marketId,
          start: 0,
          name: '',
          sortBy: state.filter.sort,
        };
        allProductsApi
          .getAll(params)
          .then((res: any) => res.json())
          .then((result: any) => {
            dispatch(changeMarketItemsType(value));
            dispatch(fetchSearchProducts({ items: result.products, _page: 0 }));
          });
    }
  };

  const handleSetHeaders = (field: string, value: number) => {
    switch (field) {
      case 'all':
        dispatch(setMarketCategoryParams(initialState));
        dispatch(setMarketCategoryHeader([]));
        break;
      case 'category':
        dispatch(setMarketCategoryParams({ ...initialState, [field]: value }));
        break;
      case 'group':
        dispatch(
          setMarketCategoryParams({
            ...categoryParams,
            [field]: value,
            class: null,
          }),
        );
        break;
      case 'class':
        return;
    }
  };

  const handleCloseModal = () => {
    dispatch(setMarketCategoryModalOpen(false));
  };

  React.useEffect(() => {
    handleGetCategory();
  }, [categoryParams]);

  return (
    <div className="filter__category-modal">
      <div className="category__modal-title">
        <h1>Категории</h1>
        <span className="category__modal-close" onClick={handleCloseModal}>
          Отменить
        </span>
      </div>
      {headers.length > 0 ? (
        <ul className="category__modalheaders">
          <li
            className="category__modalheaders-item"
            onClick={() => handleSetHeaders('all', 1)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              className="ui-icon ">
              <path d="M12 8C12.3107 8.00895 12.5948 8.12524 12.8257 8.37573L18.4481 14.1929C18.6435 14.3897 18.75 14.6402 18.75 14.9354C18.75 15.5259 18.2883 16 17.7024 16C17.4183 16 17.143 15.8837 16.9388 15.6779L12.0089 10.5496L7.06116 15.6779C6.85696 15.8748 6.59061 16 6.29763 16C5.71167 16 5.25 15.5259 5.25 14.9354C5.25 14.6402 5.35654 14.3897 5.55186 14.1929L11.1832 8.37573C11.4229 8.12524 11.6893 8 12 8Z"></path>
            </svg>
            Барчаси
          </li>
          {headers.map((el: any, i: number) => (
            <li
              className={`category__modalheaders-item ${
                i === headers.length - 1 ? 'active' : ''
              }`}
              key={el.id}
              onClick={() => handleSetHeaders(el.type, el.id)}>
              {i !== headers.length - 1 ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ui-icon ">
                  <path d="M12 8C12.3107 8.00895 12.5948 8.12524 12.8257 8.37573L18.4481 14.1929C18.6435 14.3897 18.75 14.6402 18.75 14.9354C18.75 15.5259 18.2883 16 17.7024 16C17.4183 16 17.143 15.8837 16.9388 15.6779L12.0089 10.5496L7.06116 15.6779C6.85696 15.8748 6.59061 16 6.29763 16C5.71167 16 5.25 15.5259 5.25 14.9354C5.25 14.6402 5.35654 14.3897 5.55186 14.1929L11.1832 8.37573C11.4229 8.12524 11.6893 8 12 8Z"></path>
                </svg>
              ) : null}
              {el.name}
            </li>
          ))}
        </ul>
      ) : null}
      <div className="categoryfilter__divider" />
      {items.length > 0 ? (
        <ul className="category__modalitems">
          {items.map((el: any) => (
            <li
              className="category__modalitems-item"
              key={el.id}
              onClick={() => {
                handleSetParams(el.type, el.id);
              }}>
              {el.name}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default FilterCategoryModal;
