import React, { Component } from 'react';
import MobileApp from '../common/components/static/MobileApp';
import Bubbles from '../common/components/static/Bubbles';

import { bindActionCreators } from 'redux';
import * as AuthActions from '../login/store/actions';
import { connect } from 'react-redux';
import LoginForm from './component/LoginForm';
import Messages from '../common/messages/Messages';
import HelmetComponent from '../_temp/helmet';

class Login extends Component<any, any> {
  constructor(props: any) {
    super(props);

    const { authUser } = this.props;
    if (authUser) {
      window.location.href = '#/';
    }
  }

  render = () => {
    const { actions } = this.props;

    return (
      <>
        <HelmetComponent title={Messages.t('LOGIN')} />
        <section className="reg-section">
          <div className="container">
            <div className="reg-container">
              <div className="form-section">
                <a className="back-btn" href="#/">
                  <svg
                    className="icon"
                    width="6"
                    height="10">
                    <use href="static/img/icons/sprite/sprite.svg#arrow" />
                  </svg>
                  {Messages.t('Come_back')}
                </a>
                <div className="form-wrap">
                  <div className="form-title-wrap">
                    <span className="form-title">
                      {Messages.t('Login')}{' '}
                    </span>
                  </div>
                  <LoginForm actions={actions} />
                </div>
              </div>
              <MobileApp />
            </div>
            <Bubbles />
          </div>
        </section>
      </>
    );
  };
}

const mapStateToProps = (state: any) => ({
  authUser: state.AuthReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
