import React, { Component } from "react";
import { formInputActiveClassManage } from "../helpers/Helper";
import Messages from "../messages/Messages";

class Password extends Component<any, any> {
  state = {
    isEyeClose: true,
  };
  inpPasswordChange = (el: any) => {
    const { setPassword } = this.props;
    setPassword(el.target.value);
  };

  componentDidMount(): void {
    formInputActiveClassManage();
  }

  render = () => {
    const self = this;
    return (
      <>
        <input
          className="form__input"
          type={this.state.isEyeClose ? "password" : "text"}
          autoComplete={"off"}
          onChange={self.inpPasswordChange}
        />
        <label className="form__label">{Messages.t("Password")}</label>
        {this.state.isEyeClose ? (
          <img
            onClick={() => {
              console.log(12132);
              this.setState({ isEyeClose: !this.state.isEyeClose });
            }}
            className="form__img"
            src="/static/img/icons/eye-closed.svg"
            alt="eye"
          />
        ) : (
          <img
            onClick={() =>
              this.setState({ isEyeClose: !this.state.isEyeClose })
            }
            className="form__img"
            src="/static/img/icons/eye-open.svg"
            alt="eye"
          />
        )}
      </>
    );
  };
}

export default Password;
