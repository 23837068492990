import {
  DEFAULT_VIEW_MODE,
  CHANGE_VIEW_MODE,
  DEFAULT_SORT_BY,
  SET_SORT_BY,
  SET_SHOPS_SORT_BY,
  SHOPS_DEFAULT_SORT_BY,
  RECEIVE_MORE_SHOPS,
  RECEIVE_SEARCH_PRODUCTS,
  RECEIVE_SINGLE_MARKET,
  RECEIVE_ALL_MARKETS,
  CHANGE_MARKET_ITEMS_TYPE,
  SET_CATEGORYMODAL_OPEN,
  SET_MARKET_CATEGORY_HEADER,
  SET_MARKET_CATEGORY_ITEMS,
  SET_MARKET_CATEGORY_PARAMS,
  SET_MARKETS_LOADING,
  SET_MARKET_SHOPS_LOADING,
  SET_MARKET_PRODUCTS_LOADING,
  SET_MARKETS_ERROR,
} from "./constants";
import { IAllMarkets } from "./types";
import { MarketsActions } from "./actionsTypes";

const initialState: IAllMarkets = {
  markets: [],
  singleMarket: {},
  view_mode: DEFAULT_VIEW_MODE,
  sort_by: DEFAULT_SORT_BY,
  markets_sort_by: "",
  _page: 0,
  markets_page: 0,
  marketCategoryModalOpen: false,
  marketItemsType: "",
  categoryFilter: {
    headers: [],
    items: [],
    params: {
      category: null,
      group: null,
      class: null,
    },
  },
  loading: false,
  shopsLoading: false,
  productsLoading: false,
  error: null,
};

export default function MarketReducer(
  state: any = initialState,
  action: MarketsActions
): IAllMarkets {
  switch (action.type) {
    case RECEIVE_ALL_MARKETS:
      return {
        ...state,
        markets: action.markets,
        markets_page: 0,
      };
    case RECEIVE_SINGLE_MARKET:
      return {
        ...state,
        singleMarket: action.singleMarket,
      };

    case SET_CATEGORYMODAL_OPEN:
      return {
        ...state,
        marketCategoryModalOpen: action.payload,
      };
    case CHANGE_MARKET_ITEMS_TYPE:
      return {
        ...state,
        marketItemsType: action.payload,
      };
    case SET_MARKET_CATEGORY_HEADER:
      return {
        ...state,
        categoryFilter: {
          ...state.categoryFilter,
          headers: action.payload,
        },
      };
    case SET_MARKET_CATEGORY_ITEMS:
      return {
        ...state,
        categoryFilter: {
          ...state.categoryFilter,
          items: action.payload,
        },
      };

    case SET_MARKET_CATEGORY_PARAMS:
      return {
        ...state,
        categoryFilter: {
          ...state.categoryFilter,
          params: action.payload,
        },
      };
    case CHANGE_VIEW_MODE:
      return {
        ...state,
        view_mode: action.view_mode,
      };
    case SET_MARKETS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_MARKET_SHOPS_LOADING:
      return {
        ...state,
        shopsLoading: action.loading,
      };
    case SET_MARKET_PRODUCTS_LOADING:
      return {
        ...state,
        productsLoading: action.loading,
      };
    case SET_MARKETS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    // case RECEIVE_MORE_SHOP_PRODUCTS:
    //   return {
    //     ...state,
    //     singleShop: {
    //       ...state.singleShop,
    //       items: [...state.singleShop.items, ...action.payload.items],
    //     },
    //     _page: action.payload._page,
    //   };

    // case RECEIVE_SEARCH_PRODUCTS:
    //   return {
    //     ...state,
    //     singleShop: {
    //       ...state.singleShop,
    //       items: action.payload.items,
    //     },
    //     _page: action.payload._page,
    //   };
    // case RECEIVE_MORE_SHOPS:
    //   return {
    //     ...state,
    //     shops: [...state.shops, ...action.shops],
    //     shops_page: action.page,
    //   };
    // case SET_SORT_BY:
    //   return {
    //     ...state,
    //     sort_by: action.sort_by,
    //   };
    // case SET_SHOPS_SORT_BY:
    //   return {
    //     ...state,
    //     shops_sort_by: action.sort_by,
    //   };
    default:
      return state;
  }

  // return action.type === RECEIVE_ALL_SHOPS ? {
  //     ...state,
  //     shops: action.shops
  // } : state;
}
