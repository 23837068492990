import { AbstractApi } from './AbstractApi';

class ProductRatingApi extends AbstractApi {
  setRating = (id: string, param: { rate: number }) => {
    const PATH = 'commodity/product/' + id + '/rate';
    return this.fetchApi(PATH, this.REQUEST_METHOD_POST, param);
  };
}

const productRatingApi = new ProductRatingApi();
export default productRatingApi;
