import {CHANGE_LANG, INIT_LANG, SET_LANG_MESSAGES} from './constants'
import {ILangAndMessages} from "./types";
import {DEFAULT_LANG, LANG_ALIAS} from '../../constants/constants';
import {LangActions} from "./actionsTypes";

const initialState: ILangAndMessages = {
    lang: localStorage.getItem(LANG_ALIAS) || DEFAULT_LANG,
    messages: {},
};

export default function LangReducer(state: any = initialState, action: LangActions): ILangAndMessages {
    switch (action.type) {

        case INIT_LANG:
            let lang = localStorage.getItem(LANG_ALIAS) || DEFAULT_LANG;

            return {
                ...state,
                lang: lang
            }

        case SET_LANG_MESSAGES:
            return {
                ...state,
                messages: action.messages
            }

        case CHANGE_LANG:
            localStorage.setItem(LANG_ALIAS, action.lang);

            return {
                ...state,
                lang: action.lang
            }

        default:
            return state;
    }
};
