import { AbstractApi } from "./AbstractApi";

class BannerApi extends AbstractApi {
  getAll = (params: any = {}) => {
    const PATH = "commodity/banner";
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET, params);
  };
}
const bannerApi = new BannerApi();
export default bannerApi;
