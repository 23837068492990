import React, {Component} from 'react';
import config from "../config/config";
import Messages from "../messages/Messages";

type Props = {
    item: any,
    quantity: number
};

class ProductAsOrderListItem extends Component<Props> {

    hostUrl = config.hostUrl;

    render = () => {
        const {item, quantity} = this.props;

        return (
            <div key={item.id} className="order-product">
                <div className="order-product__img">
                    <img src={this.hostUrl + item.image} alt="rasm"/>
                </div>
                <div className="order-product__content">
                    <h3 className="order-product__title">{item.name}</h3>
                    <p className="order-product__desc">{item.summary}</p>
                    <span className="order-product__quantity">{quantity} {Messages.t('pc')}</span><span
                    className="order-product__cost">{+item.cost * +quantity}<span>сум</span></span>
                </div>
            </div>
        );
    };
}

export default ProductAsOrderListItem;
