import React from "react";
import { MapContainer, TileLayer, useMap, useMapEvent } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useDispatch } from "react-redux";
import { changeModalStateAcn } from "../../store/actions";
import Messages from "../../../common/messages/Messages";

const savedAddress = JSON.parse(localStorage.getItem("userAddress"));

const initialState = {
  lat: savedAddress?.point[0] ? savedAddress?.point[0] : 41.311123,
  lon: savedAddress?.point[1] ? savedAddress?.point[1] : 69.279712,
  display_name: savedAddress?.fullName ? savedAddress?.fullName : "",
  address: savedAddress?.address ? savedAddress?.address : "",
  floor: savedAddress?.floor ? savedAddress?.floor : "",
  porch: savedAddress?.porch ? savedAddress?.porch : "",
  flat: savedAddress?.flat ? savedAddress?.flat : "",
};

const MyComponent = (props) => {
  const map = useMapEvent("dragend", (e) => {
    let center = e.target.getCenter();
    props.setCenter(center);
  });

  return null;
};
const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap();
  React.useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);
  return null;
};

const MapComponent = ({ showModal, setShowModal }) => {
  const [location, setLocation] = React.useState(initialState);
  const dispatch = useDispatch();
  let getCenter = async (center) => {
    console.log(center);
    try {
      let latitude = center.lat;
      let longitude = center.lng;
      let location = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=uz`
      );
      let locationJson = await location.json();
      const { lat, lon, display_name, address } = locationJson;
      setLocation((prev) => ({
        ...prev,
        lat: lat,
        lon: lon,
        display_name: display_name,
        address: `${address.county}, ${address.road}, ${
          address.house_number ? address.house_number : ""
        }`,
      }));
    } catch {
      throw new Error("error in fetching location");
    }
  };

  const handleClose = () => {
    dispatch(changeModalStateAcn(false));
  };

  const getMyCurrentLocation = () => {
    window.navigator.geolocation.getCurrentPosition((p) => {
      if (p.coords) {
        const { latitude, longitude } = p.coords;
        getCenter({ lat: latitude, lng: longitude });
      }
    });
  };

  const handleAccept = () => {
    if (location.lat && location.lon) {
      localStorage.setItem(
        "userAddress",
        JSON.stringify({
          address: location.address,
          flat: location.flat,
          floor: location.floor,
          fullName: location.display_name,
          porch: location.porch,
          point: [+location.lat, +location.lon],
        })
      );
      if (showModal) {
        setShowModal(false);
        window.location.reload();
      } else {
        handleClose();
      }
    }
  };

  const handleChange = (key, value) => {
    setLocation((prev) => ({ ...prev, [key]: value }));
  };

  React.useEffect(() => {
    getCenter({ lat: location.lat, lng: location.lon });
  }, []);

  return (
    <>
      <div
        className="myaddress__container"
        style={{
          width: showModal ? "95vw" : "",
          height: showModal ? "95vh" : "",
        }}
      >
        <div className="myaddress__container-header">
          <h2 className="myaddress__title">УКАЖИТЕ СВОЙ АДРЕС</h2>
          <div className="myaddress__close">
            <button
              className="myaddress__close-btn"
              onClick={() => {
                if (showModal) {
                  setShowModal(false);
                } else {
                  handleClose();
                }
              }}
            >
              {" "}
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 1024 1024"
                height="2em"
                width="2em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="myaddress__info">
          <div className="myaddress__info-location">
            <div
              className="find__me-btn"
              onClick={() => getMyCurrentLocation()}
            >
              НАЙТИ МЕНЯ
            </div>
            <div className="myaddress__info-location-info">
              <span>
                {location?.display_name ? location?.display_name : "..."}
              </span>
            </div>
          </div>
          <div className="location__accept" onClick={handleAccept}>
            ПОДТВЕРДИТЬ
          </div>
        </div>
        {!showModal && (
          <div className="myaddress__detail">
            <div className="myaddress__detail-input">
              <input
                type="text"
                value={location.porch}
                onChange={(e) => handleChange("porch", e.target.value)}
                placeholder={Messages.t("Entrance_house")}
              />
            </div>
            <div className="myaddress__detail-input">
              <input
                type="text"
                value={location.floor}
                onChange={(e) => handleChange("floor", e.target.value)}
                placeholder={Messages.t("Floor")}
              />
            </div>
            <div className="myaddress__detail-input">
              <input
                type="text"
                value={location.flat}
                onChange={(e) => handleChange("flat", e.target.value)}
                placeholder={Messages.t("apartment")}
              />
            </div>
          </div>
        )}
        <div className="myaddress__map">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M12 2C8.13 2 5 5.13 5 9c0 1.74.5 3.37 1.41 4.84.95 1.54 2.2 2.86 3.16 4.4.47.75.81 1.45 1.17 2.26.26.55.47 1.5 1.26 1.5s1-.95 1.25-1.5c.37-.81.7-1.51 1.17-2.26.96-1.53 2.21-2.85 3.16-4.4C18.5 12.37 19 10.74 19 9c0-3.87-3.13-7-7-7zm0 9.75a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path>
          </svg>

          <MapContainer
            center={[+location?.lat, +location?.lon]}
            zoom={17}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MyComponent setCenter={getCenter} />
            <RecenterAutomatically lat={location?.lat} lng={location?.lon} />
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default MapComponent;
