import * as types from './constants';
import {
  IActionChangeLanguage,
  IActionInitLanguage,
  IActionModalState,
} from './actionsTypes';
import { LangTypes } from '../../common/messages/store/types';
import { type } from 'os';

export function changeLanguageAcn(lang: LangTypes): IActionChangeLanguage {
  return { type: types.CHANGE_LANGUAGE, lang };
}

export function initLanguageAcn(lang: LangTypes): IActionInitLanguage {
  return { type: types.INIT_LANGUAGE, lang };
}

export function changeModalStateAcn(payload: boolean): IActionModalState {
  return {
    type: types.CHANGE_MODAL_STATE,
    payload,
  };
}
