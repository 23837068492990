import React, { Component } from "react";
import { Product } from "../../types/Product";
import { AddOneProductAcn } from "../../../shoppingcart/store/actionsTypes";
import Messages from "../../messages/Messages";
import { toast } from "react-hot-toast";
import MyToaster from "../../../_temp/toaster/MyToaster";

// TO DO any
type Props = {
  actions: {
    addOneProductToShoppingCart: (
      item: Product,
      count: number
    ) => AddOneProductAcn;
    getFirstProducts?: any | null;
  };
  item: Product;
  myRef?: any;
  // activeShop: any;
  onlyNoCartStyle?: boolean;
};
let addBtn, cart;
const speed = 1000,
  curveDelay = 300,
  position = "fixed";

class ProductBasket extends Component<Props> {
  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
  }
  addItem = (e) => {
    if (
      !this.props?.item?.in_basket ||
      this.props?.item?.inventory >= this.props?.item?.in_basket + 1
    ) {
      let btnY =
          position === "fixed"
            ? e.currentTarget.getBoundingClientRect().y
            : e.currentTarget.offsetTop,
        btnX =
          position === "fixed"
            ? e.currentTarget.getBoundingClientRect().x
            : e.currentTarget.offsetLeft,
        flyingBtn = e.currentTarget.cloneNode();

      const img = document.createElement("img");
      img.src = "https://hammol.uz/temp/noimage2.png";
      img.style.width = "100%";
      img.style.height = "100%";
      img.style.objectFit = "cover";
      img.style.zIndex = "999";

      flyingBtn.classList.add("in-cart-btn");
      flyingBtn.style.position = position;
      flyingBtn.style.top = `${btnY}px`;
      flyingBtn.style.left = `${btnX}px`;
      flyingBtn.style.opacity = "1";
      flyingBtn.style.display = "block";
      flyingBtn.style.backgroundColor = "white";
      flyingBtn.style.height = "100px";
      flyingBtn.style.width = "100px";
      flyingBtn.style.borderRadius = "100%";

      flyingBtn.style.zIndex = 100;
      flyingBtn.style.transition = `all ${speed / 1000}s ease, top ${
        (speed + curveDelay) / 1000
      }s ease, left ${speed / 1000}s ease, transform ${speed / 1000}s ease ${
        (speed - 10) / 1000
      }s`;

      flyingBtn.appendChild(img);
      document.body.appendChild(flyingBtn);

      flyingBtn.style.top = `${cart.getBoundingClientRect().y - 16}px`;
      flyingBtn.style.left = `${cart.getBoundingClientRect().x - 16}px`;
      flyingBtn.style.MinHeight = "100px";
      flyingBtn.style.MinWidth = "100px";
      flyingBtn.style.transform = "scale(0)";

      setTimeout(() => {
        flyingBtn.remove();
      }, speed * 1.5);
    }
  };
  init = () => {
    addBtn = this.myRef.current;
    cart = document.querySelector("#cart svg");
    addBtn.addEventListener("click", this.addItem);
  };
  componentDidMount() {
    this.init();
  }

  render = () => {
    const { actions, item, onlyNoCartStyle = false } = this.props;

    return (
      <>
        <MyToaster />
        {item && item?.in_basket ? (
          <div
            className={
              onlyNoCartStyle ? "no-cart-btn mt-1" : "in-cart-btn mt-1"
            }
          >
            <button
              ref={this.myRef}
              disabled={
                +item.inventory <= 0 || +item.in_basket >= +item.inventory
                  ? true
                  : item.itemactive !== undefined
                  ? !item.shopactive || !item.itemactive
                  : !item.shopactive || !item.productactive
              }
              className={`add-cart-btn ${
                +item.inventory <= 0 || +item.in_basket >= item.inventory
                  ? "disabled"
                  : item.itemactive !== undefined
                  ? !item.shopactive || !item.itemactive
                    ? "disabled"
                    : ""
                  : !item.shopactive || !item.productactive
                  ? "disabled"
                  : ""
              }`}
              onClick={(el) => {
                el.preventDefault();
                if (+item.inventory > 0) {
                  if (+item.inventory >= item?.in_basket + 1) {
                    actions.addOneProductToShoppingCart(item, 1);
                  } else {
                    toast("Do'konda buncha mahsulot yo'q!");
                  }
                } else {
                  toast("Do'konda mahsulot yo'q!");
                }
                // if (!activeShop) {
                //   actions.getFirstProducts();
                // }
              }}
            >
              {item.itemactive !== undefined ? (
                !item.shopactive || !item.itemactive ? (
                  <span>
                    {Messages.t("NOT-ACTIVE")}
                    {/* ({item.in_basket}) */}
                  </span>
                ) : (
                  <span>
                    {+item.inventory <= 0 || +item.in_basket >= +item.inventory
                      ? Messages.t("not_enough")
                      : Messages.t("IN_SHOPPING_CART")}
                    ({item.in_basket})
                  </span>
                )
              ) : !item.shopactive || !item.productactive ? (
                <span>
                  {Messages.t("NOT-ACTIVE")}
                  {/* ({item.in_basket}) */}
                </span>
              ) : (
                <span>
                  {+item.inventory <= 0 || +item.in_basket >= +item.inventory
                    ? Messages.t("not_enough")
                    : Messages.t("IN_SHOPPING_CART")}
                  ({item.in_basket})
                </span>
              )}
              <svg className="icon" width="11" height="9">
                <use href="static/img/icons/sprite/sprite.svg#check" />
              </svg>
              <svg className="icon" width="17.5" height="17.5">
                <use href="static/img/icons/sprite/sprite.svg#shopping-cart" />
              </svg>
            </button>
          </div>
        ) : (
          <div className="no-cart-btn mt-1">
            <button
              ref={this.myRef}
              disabled={
                +item.inventory <= 0 || +item.in_basket >= +item.inventory
                  ? true
                  : item.itemactive !== undefined
                  ? !item.shopactive || !item.itemactive
                  : !item.shopactive || !item.productactive
              }
              className={`add-cart-btn ${
                +item.inventory <= 0 || +item.in_basket >= +item.inventory
                  ? "disabled"
                  : item.itemactive !== undefined
                  ? !item.shopactive || !item.itemactive
                    ? "disabled"
                    : ""
                  : !item.shopactive || !item.productactive
                  ? "disabled"
                  : ""
              }`}
              onClick={(el) => {
                el.preventDefault();
                actions.addOneProductToShoppingCart(item, 1);
                // if (!activeShop) {
                //   actions.getFirstProducts();
                // }
              }}
            >
              {+item.inventory <= 0 || +item.in_basket >= +item.inventory ? (
                <span>
                  {Messages.t("not_enough")}
                  {/* ({item.in_basket}) */}
                </span>
              ) : item.itemactive !== undefined ? (
                !item.shopactive || !item.itemactive ? (
                  <span>
                    {Messages.t("NOT-ACTIVE")}
                    {/* ({item.in_basket}) */}
                  </span>
                ) : (
                  <span>{Messages.t("TO_SHOPPING_CART")}</span>
                )
              ) : !item.shopactive || !item.productactive ? (
                <span>
                  {Messages.t("NOT-ACTIVE")}
                  {/* ({item.in_basket}) */}
                </span>
              ) : (
                <span>{Messages.t("TO_SHOPPING_CART")}</span>
              )}

              <svg className="icon" width="17.5" height="17.5">
                <use href="static/img/icons/sprite/sprite.svg#add-shopping-cart" />
              </svg>
            </button>
          </div>
        )}
      </>
    );
  };
}

export default ProductBasket;
