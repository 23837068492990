import {DEFAULT_LANG, LANG_ALIAS} from "../constants/constants";

import {langUIMessages} from "./store/messages";

class Messages {
    lang = '';
    messages: any = {};

    constructor() {
        this.lang = localStorage.getItem(LANG_ALIAS) || DEFAULT_LANG;
        this.messages = langUIMessages;
    }

    t = (msg: string) => {
        return this.messages.hasOwnProperty(msg) ? (this.messages[msg][this.lang] ? this.messages[msg][this.lang] : '') : '';
    }
}

export default new Messages();

