import {
  ADD_TO_SHOPPING_CART,
  ADD_ONE_PRODUCT_TO_SHOPPINGCART,
  DELETE_FROM_SHOPPING_CART,
  INIT_SHOPPING_CART_FROM_LOCAL_STORAGE,
  REMOVE_ONE_PRODUCT_FROM_SHOPPING_CART,
  RESET_SHOPPING_CART,
  REMOVE_SHOP,
} from "./constants";
import { IShops, ShoppingCartType } from "./types";
import { ShoppingCartActions } from "./actionsTypes";

const anotherState: ShoppingCartType = {
  shops: [],
  totalCost: null,
};

export default function ShoppingCartReducer(
  state: ShoppingCartType = anotherState,
  action: ShoppingCartActions
) {
  let shoppingCart: ShoppingCartType;
  let shoppingCartStr: any;
  const result: any = [];
  let parsedProducts: any;
  switch (action.type) {
    case INIT_SHOPPING_CART_FROM_LOCAL_STORAGE:
      let localStorageItems = localStorage.getItem("shoppingCart");
      if (localStorageItems) {
        parsedProducts = JSON.parse(localStorageItems);
        if (parsedProducts) {
          return parsedProducts;
        }
      }
      return state;
    case ADD_TO_SHOPPING_CART:
      let ret: any;
      const { shop: shopId, id: productId } = action.product;
      const orgStateLocal: any = { ...state };

      for (const [shopIndex, shop] of orgStateLocal.shops.entries()) {
        const { shop: shopId, id: productId } = action.product;

        if (shop.id === shopId) {
          for (const [productIndex, product] of shop.products.entries()) {
            if (product.id === productId) {
              const newState: IShops[] = [...state.shops];
              newState[shopIndex] = { ...shop };
              newState[shopIndex].products = [...shop.products];
              newState[shopIndex].products[productIndex] = {
                ...product,
                quantity:
                  product.quantity && action.count ? Math.abs(action.count) : 1,
              };
              newState[shopIndex].products[productIndex].totalCost = newState[
                shopIndex
              ].products.reduce(
                (acc: any, i: any) => i.quantity * +product.product.cost + acc,
                0
              );
              newState[shopIndex].totalCost = newState[
                shopIndex
              ].products.reduce(
                (acc: any, i: any) => i.quantity * +i.product.cost + acc,
                0
              );

              const calcAllDataCost = [...state.shops].reduce(
                (acc, el) => el.totalCost + acc,
                +action.product.cost
              );
              ret = {
                ...state,
                shops: newState,
                totalCost: +calcAllDataCost.toFixed(2),
              };

              break;
            }
          }

          if (ret) break;

          const newState: IShops[] = [...state.shops];

          newState[shopIndex] = { ...shop };
          newState[shopIndex].products = [
            ...shop.products,
            {
              id: action.product.id,
              product: action.product,
              quantity: 1,
              totalCost: +action.product.cost,
              shop_id: action.product.shop,
              updated_at: new Date().getTime(),
            },
          ];
          newState[shopIndex].totalCost = newState[shopIndex].products.reduce(
            (acc: any, i: any) => i.quantity * +i.product.cost + acc,
            0
          );

          newState[shopIndex].totalQuantity =
            newState[shopIndex].products.length;

          const calcAllDataCost = [...state.shops].reduce(
            (acc, el) => el.totalCost + acc,
            +action.product.cost
          );
          ret = {
            ...state,
            shops: newState,
            totalCost: +calcAllDataCost.toFixed(2),
          };
          break;
        }
      }
      const allCosts = [...state.shops].reduce(
        (acc, el) => el.totalCost + acc,
        +action.product.cost
      );

      if (!ret) {
        ret = {
          ...state,
          shops: [
            ...state.shops,
            {
              id: action.product.shop,
              shopName: action.product.shopname,
              products: [
                {
                  id: action.product.id,
                  product: action.product,
                  quantity: 1,
                  totalCost: +action.product.cost,
                  shop_id: action.product.shop,
                  updated_at: new Date().getTime(),
                },
              ],
              totalQuantity: 1,
              totalCost: +action.product.cost,
            },
          ],
          totalCost: +allCosts.toFixed(2),
        };
      }

      localStorage.setItem("shoppingCart", JSON.stringify(ret));
      return ret;
    case ADD_ONE_PRODUCT_TO_SHOPPINGCART:
      let retval: any;
      const orgStateInLocal: any = { ...state };

      for (const [shopIndex, shop] of orgStateInLocal.shops.entries()) {
        const { shop: shopId, id: productId } = action.product;

        if (shop.id === shopId) {
          for (const [productIndex, product] of shop.products.entries()) {
            if (product.id === productId) {
              const newState: IShops[] = [...state.shops];
              newState[shopIndex] = { ...shop };
              newState[shopIndex].products = [...shop.products];
              newState[shopIndex].products[productIndex] = {
                ...product,
                quantity:
                  product.quantity && action.count
                    ? product.quantity + action.count
                    : 1,
              };
              newState[shopIndex].products[productIndex].totalCost = newState[
                shopIndex
              ].products.reduce(
                (acc: any, i: any) => i.quantity * +product.product.cost + acc,
                0
              );
              newState[shopIndex].totalCost = newState[
                shopIndex
              ].products.reduce(
                (acc: any, i: any) => i.quantity * +i.product.cost + acc,
                0
              );

              const calcAllDataCost = [...state.shops].reduce(
                (acc, el) => el.totalCost + acc,
                +action.product.cost
              );
              retval = {
                ...state,
                shops: newState,
                totalCost: +calcAllDataCost.toFixed(2),
              };

              break;
            }
          }

          if (retval) break;

          const newState: IShops[] = [...state.shops];

          newState[shopIndex] = { ...shop };
          newState[shopIndex].products = [
            ...shop.products,
            {
              id: action.product.id,
              product: action.product,
              quantity: 1,
              totalCost: +action.product.cost,
              shop_id: action.product.shop,
              updated_at: new Date().getTime(),
            },
          ];
          newState[shopIndex].totalCost = newState[shopIndex].products.reduce(
            (acc: any, i: any) => i.quantity * +i.product.cost + acc,
            0
          );

          newState[shopIndex].totalQuantity =
            newState[shopIndex].products.length;

          const calcAllDataCost = [...state.shops].reduce(
            (acc, el) => el.totalCost + acc,
            +action.product.cost
          );
          retval = {
            ...state,
            shops: newState,
            totalCost: +calcAllDataCost.toFixed(2),
          };
          break;
        }
      }
      const allCost = [...state.shops].reduce(
        (acc, el) => el.totalCost + acc,
        +action.product.cost
      );

      if (!retval) {
        retval = {
          ...state,
          shops: [
            ...state.shops,
            {
              id: action.product.shop,
              shopName: action.product.shopname,
              products: [
                {
                  id: action.product.id,
                  product: action.product,
                  quantity: 1,
                  totalCost: +action.product.cost,
                  shop_id: action.product.shop,
                  updated_at: new Date().getTime(),
                },
              ],
              totalQuantity: 1,
              totalCost: +action.product.cost,
            },
          ],
          totalCost: +allCost.toFixed(2),
        };
      }

      localStorage.setItem("shoppingCart", JSON.stringify(retval));
      return retval;

    case REMOVE_ONE_PRODUCT_FROM_SHOPPING_CART:
      let res: any;

      const orgState: any = { ...state };
      for (const [shopIndex, shop] of orgState.shops.entries()) {
        if (shop.id === action.shop.shop_id) {
          for (const [productIndex, product] of shop.products.entries()) {
            if (product.id === action.product.id) {
              const newState: IShops[] = [...state.shops];
              newState[shopIndex] = { ...shop };
              newState[shopIndex].products = [...shop.products];
              if (newState[shopIndex].products[productIndex].quantity <= 1) {
                newState[shopIndex].products.splice(productIndex, 1);
              } else {
                newState[shopIndex].products[productIndex] = {
                  ...product,
                  quantity:
                    product.quantity &&
                    product.quantity > 1 &&
                    action.count <= product.quantity
                      ? product.quantity - action.count
                      : 0,
                };
                if (newState[shopIndex].products[productIndex].quantity === 0) {
                  newState[shopIndex].products.splice(productIndex, 1);
                }
                newState[shopIndex].totalCost = newState[
                  shopIndex
                ].products.reduce(
                  (acc: any, i: any) => i.quantity * +i.product.cost + acc,
                  0
                );
              }
              if (res) break;
              if (!newState[shopIndex].products.length) {
                newState.splice(shopIndex, 1);
              }
              const calcAllDataCost: number = newState.reduce(
                (acc: number, el: any) => el.totalCost + acc,
                0
              );
              res = {
                ...state,
                shops: newState,
                totalCost: +calcAllDataCost.toFixed(2),
              };

              break;
            }
          }
        }
      }
      localStorage.setItem("shoppingCart", JSON.stringify(res));
      return res;
    case DELETE_FROM_SHOPPING_CART:
      let result: any;

      const orgStateLoc: any = { ...state };

      for (const [shopIndex, shop] of orgStateLoc.shops.entries()) {
        if (shop.id === action.shop.shop_id) {
          for (const [productIndex, product] of shop.products.entries()) {
            const newState: IShops[] = [...state.shops];
            newState[shopIndex] = { ...shop };
            newState[shopIndex].products = [...shop.products];
            if (product.id === action.product.id) {
              newState[shopIndex].products.splice(productIndex, 1);
              const calcAllDataCost = newState.reduce(
                (acc: number, el: any) => el.totalCost + acc,
                0
              );
              result = {
                ...state,
                shops: newState,
                totalCost: +calcAllDataCost.toFixed(2),
              };
            }
            if (!newState[shopIndex].products.length) {
              newState.splice(shopIndex, 1);
              const calcAllDataCost = newState.reduce(
                (acc: number, el: any) => el.totalCost + acc,
                0
              );
              result = {
                ...state,
                shops: newState,
                totalCost: +calcAllDataCost.toFixed(2),
              };
              break;
            }
          }
        }
      }
      localStorage.setItem("shoppingCart", JSON.stringify(result));
      return result;
    case REMOVE_SHOP:
      const data: IShops[] = [...state.shops];
      let totalCost: any = 0;
      const newData: IShops[] = data.filter((item) => {
        if (item.id === action.shop_id) {
          if (state.totalCost) {
            totalCost = state.totalCost - item.totalCost;
          }
          return null;
        }
        return item;
      });
      localStorage.setItem(
        "shoppingCart",
        JSON.stringify({ shops: newData, totalCost })
      );
      return { ...state, shops: newData, totalCost };
    case RESET_SHOPPING_CART:
      let cleanedState: ShoppingCartType = {
        ...anotherState,
      };
      localStorage.setItem("shoppingCart", JSON.stringify(cleanedState));
      return cleanedState;
    default:
      return state;
  }
}
