export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART';
export const DELETE_FROM_SHOPPING_CART =
  'DELETE_FROM_SHOPPING_CART';
export const RESET_SHOPPING_CART = 'RESET_SHOPPING_CART';
export const INIT_SHOPPING_CART_FROM_LOCAL_STORAGE =
  'INIT_SHOPPING_CART_FROM_LOCAL_STORAGE';
export const REMOVE_ONE_PRODUCT_FROM_SHOPPING_CART =
  'REMOVE_ONE_PRODUCT_FROM_SHOPPING_CART';
export const REMOVE_SHOP = 'REMOVE_SHOP';
export const ADD_ONE_PRODUCT_TO_SHOPPINGCART =
  'ADD_ONE_PRODUCT_TO_SHOPPINGCART';
