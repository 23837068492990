import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import ProductNameSummary from "../../common/components/details/ProductNameSummary";
import ProductRating from "../../common/components/details/ProductRating";
import Messages from "../../common/messages/Messages";

type Props = {
  height?: number;
  id: number;
  logo: {
    src: string | null;
  };
  name: string;
  place: string;
  width?: number;
  working: string;
  rate: string;
  shopactive: boolean;
  address: string;
};

const MarketsCardAsList: FC<{ item: Props }> = ({ item }) => {
  const hostUrl = "https://hammol.uz";
  const { id, logo, name, address, shopactive, rate } = item;
  let history = useHistory();
  return (
    <li
      className="all-products__list-item"
      key={id}
      onClick={() => history.push(`/market/${id}`)}
    >
      <div className="product-card-list">
        <div className="product-card-list__img">
          <>
            {logo ? (
              <>
                {logo.src && logo.src.includes("temp/") ? (
                  <img src={hostUrl + logo.src} alt="rasm" />
                ) : (
                  <>
                    {typeof logo === "object" ? (
                      <img
                        src={"data:image/png;base64, " + logo.src}
                        alt="rasm"
                      />
                    ) : (
                      <img
                        src={"https://hammol.uz/temp/noimage2.png"}
                        alt="rasm"
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <img src={"https://hammol.uz/temp/noimage2.png"} alt="rasm" />
              </>
            )}
          </>
        </div>
        <div className="product-card-list__content">
          <div className="product-card__content-list">
            <h3>{name}</h3>
            <p>{address}</p>
            <ProductRating rating={+rate} />
          </div>
        </div>
        <div className="product-card-list__actions">
          <div className="product-card-list__price">
            <div className="isactive__shop list">
              <span className="shop-status">
                {shopactive ? Messages.t("Open") : Messages.t("Close")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default MarketsCardAsList;
