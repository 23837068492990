import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as AuthActions from "./store/actions";
import { connect } from "react-redux";
import Step1 from "./forms/Step1";
import Step2 from "./forms/Step2";
import signUpApi from "../common/api/SignUpApi";
import MobileApp from "../common/components/static/MobileApp";
import Bubbles from "../common/components/static/Bubbles";
import HelmetComponent from "../_temp/helmet";
import Messages from "../common/messages/Messages";

class Registration extends Component<any, any> {
  componentDidMount(): void {
    const { actions } = this.props;
    signUpApi
      .seek()
      .then((res: any) => res.json())
      .then((result: any) => {
        if (result && result.hasOwnProperty("grant")) {
          actions.authSignUpSeekAcn(result.grant);
        }
        // TO DO else
      });
  }

  render = () => {
    const { token, authStep } = this.props;

    return token ? (
      <Redirect to={{ pathname: "/" }} />
    ) : (
      <section className="reg-section">
        <HelmetComponent title={Messages.t("Registration")} />

        <div className="container">
          <div className="reg-container">
            {authStep === 1 ? <Step1 /> : null}
            {authStep === 2 ? <Step2 /> : null}
            <MobileApp />
          </div>

          <Bubbles />
        </div>
      </section>
    );
  };
}

const mapStateToProps = (state: any) => ({
  authStep: state.RegistrationReducer.authStep,
  token: state.RegistrationReducer.token,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
