import {
  CHANGE_LANGUAGE,
  CHANGE_MODAL_STATE,
  INIT_LANGUAGE,
} from './constants';
import { IHeaderState } from './types';
import { IHeaderActions } from './actionsTypes';
import config from '../../common/config/config';

const initialState: IHeaderState = {
  lang: config.lang,
  isOpenModal: false,
};

export default function HeaderReducer(
  state: any = initialState,
  action: IHeaderActions,
): any {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      localStorage.setItem('lang', action.lang);
      return {
        ...state,
        lang: action.lang,
      };

    case INIT_LANGUAGE:
      let lang = localStorage.getItem('lang');

      if (lang) {
        return {
          ...state,
          lang: lang,
        };
      } else {
        return {
          ...state,
          lang: 'uz',
        };
      }
    case CHANGE_MODAL_STATE:
      return {
        ...state,
        isOpenModal: action.payload,
      };

    default:
      return state;
  }
}
