export const RECEIVE_ALL_PRODUCTS = `RECEIVE_ALL_PRODUCTS`;
export const RECEIVE_MORE_PRODUCTS = `RECEIVE_MORE_PRODUCTS`;
export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const LAST_PAGE = "LAST_PAGE";
export const FIRST_PAGE = "FIRST_PAGE";
export const SELECTED_PAGE = `SELECTED_PAGE`;
export const CHANGE_VIEW_MODE = `CHANGE_VIEW_MODE`;
export const SAVE_SEARCH_PARAMS = `SAVE_SEARCH_PARAMS`;
export const SAVE_SEARCH_PARAMS_CATEGORY = `SAVE_SEARCH_PARAMS_CATEGORY`;
export const SET_FILTER = "SET_FILTER";
export const SET_MIN_COST = "SET_MIN_COST";
export const SET_MAX_COST = "SET_MAX_COST";
export const SET_ALLPRODUCTS_LOADING = "SET_ALL_PRODUCTS_LOADING";
export const SET_ALL_PRODUCTS_ERROR = "SET_ALL_PRODUCTS_ERROR";

export const DEFAULT_VIEW_MODE = "GRID";
