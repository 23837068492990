import { LANG_RU, LANG_UZ, LANG_UZK } from "../../constants/constants";

export const langUIMessages = {
  Select: {
    [LANG_RU]: "Выбрать",
    [LANG_UZ]: "Tanlash",
    [LANG_UZK]: "Танлаш",
  },
  Delete: {
    [LANG_RU]: "Удалить",
    [LANG_UZ]: "O'chirish",
    [LANG_UZK]: "Ўчириш",
  },
  ByPrice: {
    [LANG_RU]: "По цене",
    [LANG_UZ]: "Narxi bo'yicha",
    [LANG_UZK]: "Нархи бўйича",
  },
  ByPopularity: {
    [LANG_RU]: "По популярности",
    [LANG_UZ]: "Ommabopligi bo'yicha",
    [LANG_UZK]: "Оммабоплиги бўйича",
  },
  ByDate: {
    [LANG_RU]: "По новизне",
    [LANG_UZ]: "Yangiligi bo'yicha",
    [LANG_UZK]: "Янгилиги бўйича",
  },
  ByRating: {
    [LANG_RU]: "По рейтингу",
    [LANG_UZ]: "Reyting bo'yicha",
    [LANG_UZK]: "Рейтинг бўйича",
  },
  Address: {
    [LANG_RU]: "Адрес",
    [LANG_UZ]: "Manzil",
    [LANG_UZK]: "Манзил",
  },
  WebSite: {
    [LANG_RU]: "Веб-сайт",
    [LANG_UZ]: "Veb-sayt",
    [LANG_UZK]: "Веб-сайт",
  },
  Close: {
    [LANG_RU]: "Закрыто",
    [LANG_UZ]: "Yopiq",
    [LANG_UZK]: "Ёпиқ",
  },
  Open: {
    [LANG_RU]: "Открыто",
    [LANG_UZ]: "Ochiq",
    [LANG_UZK]: "Очиқ",
  },
  "Loading...": {
    [LANG_RU]: "Загрузка...",
    [LANG_UZ]: "Yuklanayabdi",
    [LANG_UZK]: "Юкланаябди",
  },
  Top_sellers: {
    [LANG_RU]: "Лидеры продаж",
    [LANG_UZ]: "Savdo birinchilari",
    [LANG_UZK]: "Савдо биринчилари",
  },
  From_all_shops: {
    [LANG_RU]: "Со всех магазинов",
    [LANG_UZ]: "Barcha doʻkonlardan",
    [LANG_UZK]: "Барча дўконлардан",
  },
  Show_more_products: {
    [LANG_RU]: "Показать еще товары",
    [LANG_UZ]: "Yana mahsulotlarni koʻrsatish",
    [LANG_UZK]: "Яна маҳсулотларни кўрсатиш",
  },

  Show_more_products_loading: {
    [LANG_RU]: "Загрузка продуктов...",
    [LANG_UZ]: "Mahsulotlar yuklanmoqda...",
    [LANG_UZK]: "Маҳсулотлар юкланмоқда...",
  },
  You_are_in_shop: {
    [LANG_RU]: "Вы в магазине: ",
    [LANG_UZ]: "Siz tanlagan doʻkon: ",
    [LANG_UZK]: "Сиз танлаган дўкон: ",
  },
  Search_by_all_shops: {
    [LANG_RU]: "Поиск по всем магазинам",
    [LANG_UZ]: "Barcha doʻkonlar boʻyicha qidiruv",
    [LANG_UZK]: "Барча дўконлар бўйича қидирув",
  },
  Search_by_shop: {
    [LANG_RU]: "Поиск по магазину ",
    [LANG_UZ]: "Mahsulotlar ushbu doʻkondan qidiriladi: ",
    [LANG_UZK]: "Маҳсулотлар ушбу дўкондан қидирилади: ",
  },
  Search: {
    [LANG_RU]: "Найти",
    [LANG_UZ]: "Izlash",
    [LANG_UZK]: "Излаш",
  },
  cancel: {
    [LANG_RU]: "отменить",
    [LANG_UZ]: "bekor",
    [LANG_UZK]: "бекор",
  },
  Profile: {
    [LANG_RU]: "Профиль",
    [LANG_UZ]: "Yoʻnalish",
    [LANG_UZK]: "Йўналиш",
  },
  My_orders: {
    [LANG_RU]: "Мои заказы",
    [LANG_UZ]: "Mening buyurtmalarim",
    [LANG_UZK]: "Менинг буюртмаларим",
  },
  Settings: {
    [LANG_RU]: "Настройки",
    [LANG_UZ]: "Sozlashlar",
    [LANG_UZK]: "Созлашлар",
  },
  Logout: {
    [LANG_RU]: "Выйти",
    [LANG_UZ]: "Chiqish",
    [LANG_UZK]: "Чиқиш",
  },
  Login: {
    [LANG_RU]: "Войти",
    [LANG_UZ]: "Kirish",
    [LANG_UZK]: "Кириш",
  },
  LOGIN: {
    [LANG_RU]: "ВОЙТИ",
    [LANG_UZ]: "KIRISH",
    [LANG_UZK]: "КИРИШ",
  },
  Registration: {
    [LANG_RU]: "Регистрация",
    [LANG_UZ]: "Roʻyhatdan oʻtish",
    [LANG_UZK]: "Рўйҳатдан ўтиш",
  },
  Wrong_data: {
    [LANG_RU]:
      "Номер телефона или пароль введен неверно. Пожалуйста, попробуйте еще раз.",
    [LANG_UZ]:
      "Telefon raqam yoki parol notoʻgʻri kiritilgan. Iltimos qaytadan urinib ko'ring.",
    [LANG_UZK]:
      "Телефон рақам ёки парол нотўғри киритилган. Илтимос қайтадан уриниб кўринг.",
  },
  Shopping_cart: {
    [LANG_RU]: "Корзина",
    [LANG_UZ]: "Savatcha",
    [LANG_UZK]: "Саватча",
  },
  Your_shopping_cart_is_empty: {
    [LANG_RU]: "Ваша корзина пока пуста",
    [LANG_UZ]: "Savatcha boʻsh",
    [LANG_UZK]: "Саватча бўш",
  },
  Add_products_to_shopping_cart_and_buy: {
    [LANG_RU]: "Добавляйте товары в корзину и совершайте покупки",
    [LANG_UZ]: "Mahsulotlarni savatcha qoʻshib, sotib oling",
    [LANG_UZK]: "Маҳсулотларни саватчага қўшиб, сотиб олинг",
  },
  Sum: {
    [LANG_RU]: "Сумма",
    [LANG_UZ]: "Jami narx",
    [LANG_UZK]: "Жами нарх",
  },
  GO_TO_SHOPPING_CART: {
    [LANG_RU]: "ПЕРЕЙТИ В КОРЗИНУ",
    [LANG_UZ]: "SAVDO SAVATCHASIGA OʻTISH",
    [LANG_UZK]: "САВДО САВАТЧАСИГА ЎТИШ",
  },
  Shopping_cart_is_: {
    [LANG_RU]: "В корзине",
    [LANG_UZ]: "Savatcha",
    [LANG_UZK]: "Саватча",
  },
  _is_empty_BR: {
    [LANG_RU]: "пусто",
    [LANG_UZ]: "boʻsh",
    [LANG_UZK]: "бўш",
  },
  IN_SHOPPING_CART: {
    [LANG_RU]: "В КОРЗИНЕ",
    [LANG_UZ]: "SAVATCHADA",
    [LANG_UZK]: "САВАТЧАДА",
  },
  TO_SHOPPING_CART: {
    [LANG_RU]: "В КОРЗИНУ",
    [LANG_UZ]: "SAVATCHAGA",
    [LANG_UZK]: "САВАТЧАГА",
  },
  All_products: {
    [LANG_RU]: "Все товары",
    [LANG_UZ]: "Barcha mahsulotlar",
    [LANG_UZK]: "Барча маҳсулотлар",
  },
  Shop_products: {
    [LANG_RU]: "Товары магазина",
    [LANG_UZ]: "Do'kon mahsulotlari",
    [LANG_UZK]: "Дўкон маҳсулотлари",
  },
  Categories: {
    [LANG_RU]: "Категории",
    [LANG_UZ]: "Toifalar",
    [LANG_UZK]: "Тоифалар",
  },
  // Product_categories: {
  //   [LANG_RU]: "Категории товаров",
  //   [LANG_UZ]: "Mahsulotlar toifalari",
  //   [LANG_UZK]: "Маҳсулотлар тоифалари",
  // },
  Shop: {
    [LANG_RU]: "Магазин",
    [LANG_UZ]: "Doʻkon",
    [LANG_UZK]: "Дўкон",
  },
  Order: {
    [LANG_RU]: "Заказ",
    [LANG_UZ]: "Buyurtma",
    [LANG_UZK]: "Буюртма",
  },
  ORDER_IT: {
    [LANG_RU]: "Заказать",
    [LANG_UZ]: "Buyurtma qilish",
    [LANG_UZK]: "Буюртма қилиш",
  },
  Orders: {
    [LANG_RU]: "Заказы",
    [LANG_UZ]: "Buyurtmalar",
    [LANG_UZK]: "Буюртмалар",
  },
  "Total orders": {
    [LANG_RU]: "Всего заказов",
    [LANG_UZ]: "Jami buyurtmalar",
    [LANG_UZK]: "Жами буюртмалар",
  },
  Shops: {
    [LANG_RU]: "Магазины",
    [LANG_UZ]: "Doʻkonlar",
    [LANG_UZK]: "Дўконлар",
  },
  Log_in_to_place_an_order: {
    [LANG_RU]: "Авторизуйтесь, чтобы оформить заказ",
    [LANG_UZ]: "Buyurtma berish uchun tizimga kiring",
    [LANG_UZK]: "Буюртма бериш учун тизимга киринг",
  },
  Formalization_order: {
    [LANG_RU]: "ОФОРМИТЬ ЗАКАЗ",
    [LANG_UZ]: "BUYURTMA BERISH",
    [LANG_UZK]: "БУЮРТМА БЕРИШ",
  },
  Waiting_order: {
    [LANG_RU]: "Ваш запрос обрабатывается...",
    [LANG_UZ]: "Sizning so'rovingiz qayta ishlanmoqda...",
    [LANG_UZK]: "Сизнинг сўровингиз қайта ишланмоқда...",
  },
  Total: {
    [LANG_RU]: "Итого",
    [LANG_UZ]: "Jami",
    [LANG_UZK]: "Жами",
  },
  "Undefined Error": {
    [LANG_RU]: "Undefined Error",
    [LANG_UZ]: "Undefined Error",
    [LANG_UZK]: "Undefined Error",
  },
  "Forgot_your_password?": {
    [LANG_RU]: "Забыли пароль?",
    [LANG_UZ]: "Parolni unutdingizmi?",
    [LANG_UZK]: "Паролни унутдингизми?",
  },
  Your_phone_number: {
    [LANG_RU]: "Ваш номер телефона",
    [LANG_UZ]: "Sizning telefon raqamingiz",
    [LANG_UZK]: "Сизнинг телефон рақамингиз",
  },
  Friend_phone_number: {
    [LANG_RU]: "Номер телефона пользователя, который принимает карту",
    [LANG_UZ]: "Kartani qabul qiladigan foydalanuvchining telefon raqami",
    [LANG_UZK]: "Картани қабул қиладиган фойдаланувчининг телефон рақами",
  },
  Password: {
    [LANG_RU]: "Пароль",
    [LANG_UZ]: "Parol",
    [LANG_UZK]: "Парол",
  },
  Count: {
    [LANG_RU]: "Количество",
    [LANG_UZ]: "Adadi",
    [LANG_UZK]: "Адади",
  },
  This_number_registered_try_log_in: {
    [LANG_RU]: "Этот номер регистрирован у нас, попробуйте войти!",
    [LANG_UZ]: "Ushbu raqam bizda roʻyhatga olingan, kirishga urinib koʻring!",
    [LANG_UZK]: "Ушбу рақам бизда рўйҳатга олинган, киришга уриниб кўринг!",
  },
  Come_back: {
    [LANG_RU]: "Вернуться назад",
    [LANG_UZ]: "Ortga qaytish",
    [LANG_UZK]: "Ортга қайтиш",
  },
  "You_can_go_back_to_-": {
    [LANG_RU]: "Можно вернуться к - ",
    [LANG_UZ]: "",
    [LANG_UZK]: "",
  },
  "-Entrance": {
    [LANG_RU]: "Входу",
    [LANG_UZ]: "Kirish",
    [LANG_UZK]: "Кириш",
  },
  Approve_licence_: {
    [LANG_RU]: "Нажимая «Зарегистрироваться» вы соглашаетесь с",
    [LANG_UZ]: '"Roʻyhatdan oʻtish"ni bosib siz',
    [LANG_UZK]: '"Рўйҳатдан ўтиш"ни босиб сиз',
  },
  _licence_condition: {
    [LANG_RU]: "условиями пользования",
    [LANG_UZ]: "foydalanish shartlariga",
    [LANG_UZK]: "фойдаланиш шартларига",
  },
  _condition_site: {
    [LANG_RU]: "сайта",
    [LANG_UZ]: "roziligingizni bildirasiz",
    [LANG_UZK]: "розилигингизни билдирасиз",
  },

  Approve_buying_: {
    [LANG_RU]: "Нажимая «Оформить заказ» вы соглашаетесь с",
    [LANG_UZ]: '"Buyurtma berish"ni bosib siz',
    [LANG_UZK]: '"Буюртма бериш"ни босиб сиз',
  },
  _buying_condition: {
    [LANG_RU]: "условиями пользования",
    [LANG_UZ]: "foydalanish shartlariga",
    [LANG_UZK]: "фойдаланиш шартларига",
  },
  Get_SMS_code_for_registration: {
    [LANG_RU]: "Получить СМС код для регистрации",
    [LANG_UZ]: "Roʻyhatdan oʻtish uchun SMS olish",
    [LANG_UZK]: "Рўйҳатдан ўтиш учун SMS олиш",
  },
  Get_SMS_code_to_order: {
    [LANG_RU]: "Получить СМС код",
    [LANG_UZ]: "SMS kod olish",
    [LANG_UZK]: "СМС код олиш",
  },
  You_successfully_registered: {
    [LANG_RU]:
      "Вы успешно зарегистрированы! Можете войти в систему с помощью СМС кодом!",
    [LANG_UZ]:
      "Siz roʻyhatdan muvaffaqiyatli oʻtdingiz! Tizimga SMM kod yordamida kirishingiz mumkin!",
    [LANG_UZK]:
      "Сиз рўйҳатдан муваффақиятли ўтдингиз! Тизимга SMS код ёрдамида киришингиз мумкин!",
  },
  Registration_error: {
    [LANG_RU]:
      "Произошла ошибка при регистрации. Пожалуйста, попробуйте еще раз.",
    [LANG_UZ]:
      "Roʻyhatdan oʻtishda xatolik yuz berdi. Iltimos qaytadan urinib koʻring.",
    [LANG_UZK]:
      "Рўйҳатдан ўтишда хатолик юз берди. Илтимос қайтадан уриниб кўринг.",
  },
  _Registration_step: {
    [LANG_RU]: "шаг",
    [LANG_UZ]: "qadam",
    [LANG_UZK]: "қадам",
  },
  "What_is_your_name?": {
    [LANG_RU]: "Как вас зовут?",
    [LANG_UZ]: "Sizning ismingiz?",
    [LANG_UZK]: "Сизнинг исмингиз?",
  },
  Gender: {
    [LANG_RU]: "Пол",
    [LANG_UZ]: "Jinsingiz",
    [LANG_UZK]: "Жинсингиз",
  },
  Male: {
    [LANG_RU]: "Мужской",
    [LANG_UZ]: "Erkak",
    [LANG_UZK]: "Эркак",
  },
  Female: {
    [LANG_RU]: "Женский",
    [LANG_UZ]: "Ayol",
    [LANG_UZK]: "Аёл",
  },
  SMS_code_of_registration: {
    [LANG_RU]: "СМС код регистрации",
    [LANG_UZ]: "Roʻyhatdan oʻtish SMS kodi",
    [LANG_UZK]: "Рўйҳатдан ўтиш SMS коди",
  },
  Clearance_of_product: {
    [LANG_RU]: "Оформление заказа",
    [LANG_UZ]: "Buyurtmani roʻyhatga olish",
    [LANG_UZK]: "Буюртмани рўйҳатга олиш",
  },
  Name: {
    [LANG_RU]: "Имя",
    [LANG_UZ]: "Ism",
    [LANG_UZK]: "Исм",
  },
  Personal_data_you_can_change: {
    [LANG_RU]:
      "Личную информацию вы можете изменить только в настройках профиля",
    [LANG_UZ]:
      "Shaxsiy maʻlumotlaringizni oʻzgartirishni siz faqat sozlashlar boʻlimida amalga oshirishingiz mumkin",
    [LANG_UZK]:
      "Шахсий маълумотларингизни ўзгартиришни сиз фақат созлашлар бўлимида амалга оширишингиз мумкин",
  },
  Get_product: {
    [LANG_RU]: "Получение товара",
    [LANG_UZ]: "Mahsulotni olish",
    [LANG_UZK]: "Махсулотни олиш",
  },
  Delivery_to_home: {
    [LANG_RU]: "Доставка на дом (офис)",
    [LANG_UZ]: "Uyga (ofisga) yetkazib berish",
    [LANG_UZK]: "Уйга (офисга) етказиб бериш",
  },
  Indicate_the_address_to_which_you_want_to_deliver_the_goods: {
    [LANG_RU]: "Укажите адрес, на который нужно доставить товар",
    [LANG_UZ]: "Mahsulot yetkazilishi kerak boʻlgan manzilni koʻrsating",
    [LANG_UZK]: "Маҳсулот етказилиши керак бўлган манзилни кўрсатинг",
  },
  Specify_address: {
    [LANG_RU]: "Указать адрес",
    [LANG_UZ]: "Manzilni koʻrsatish",
    [LANG_UZK]: "Манзилни кўрсатиш",
  },
  Street_mahalla_house: {
    [LANG_RU]: "Улица, махалля, дом",
    [LANG_UZ]: "Koʻcha, mahalla, uy",
    [LANG_UZK]: "Кўча, маҳалла, уй",
  },
  Entrance_house: {
    [LANG_RU]: "Подъезд",
    [LANG_UZ]: "Kirish (podyezd) ",
    [LANG_UZK]: "Кириш (подъезд)",
  },
  Floor: {
    [LANG_RU]: "Этаж",
    [LANG_UZ]: "Qavat ",
    [LANG_UZK]: "Қават",
  },
  apartment: {
    [LANG_RU]: "Квартира",
    [LANG_UZ]: "Xonadon",
    [LANG_UZK]: "Хонадон",
  },
  Payment_method: {
    [LANG_RU]: "Способ оплаты",
    [LANG_UZ]: "Toʻlov uslubi",
    [LANG_UZK]: "Тўлов услуби",
  },
  Cash_and_plastic_cards: {
    [LANG_RU]: "Наличные или пластиковые карты",
    [LANG_UZ]: "Naqd yoki plastik karta",
    [LANG_UZK]: "Нақд ёки пластик карта",
  },
  "You pay upon receipt of goods in cash or through the terminal": {
    [LANG_RU]: "Оплачиваете при получении товара наличными или через терминал",
    [LANG_UZ]:
      "Mahsulotni olayotganingizda naqd yoki terminal yordamida toʻlov qilasiz",
    [LANG_UZK]:
      "Маҳсулотни олаётганингизда нақд ёки терминал ёрдамида тўлов қиласиз",
  },
  "Delivery amount depends on the address": {
    [LANG_RU]: "Сумма доставки зависить от адреса",
    [LANG_UZ]: "Yetkazib berish narxi manzilga bogʻliq",
    [LANG_UZK]: "Етказиб бериш нархи манзилга боғлиқ",
  },
  "Total for goods": {
    [LANG_RU]: "Итого за товаром",
    [LANG_UZ]: "Jami mahsulot uchun",
    [LANG_UZK]: "Жами маҳсулот учун",
  },
  "Please enter an address": {
    [LANG_RU]: "Пожалуйста введите адрес",
    [LANG_UZ]: "Iltimos manzilni kiriting",
    [LANG_UZK]: "Илтимос манзилни киритинг",
  },
  "Date and time of order": {
    [LANG_RU]: "Дата и время заказа",
    [LANG_UZ]: "Buyurtma sana va vaqti",
    [LANG_UZK]: "Буюртма сана ва вақти",
  },
  "Amount without delivery": {
    [LANG_RU]: "Сумма без доставки",
    [LANG_UZ]: "Yetkazib berishsiz hisob",
    [LANG_UZK]: "Етказиб беришсиз ҳисоб",
  },
  Payment: {
    [LANG_RU]: "Оплата",
    [LANG_UZ]: "Toʻlov",
    [LANG_UZK]: "Тўлов",
  },
  "In cash": {
    [LANG_RU]: "Наличными",
    [LANG_UZ]: "Naqd pulda",
    [LANG_UZK]: "Нақд пулда",
  },
  "WITH-CARD": {
    [LANG_RU]: "Картой",
    [LANG_UZ]: "Karta orqali",
    [LANG_UZK]: "Карта орқали",
  },
  "WITH-NUMBER": {
    [LANG_RU]: "Номером телефона",
    [LANG_UZ]: "Telefon raqam orqali",
    [LANG_UZK]: "Телефон рақам орқали",
  },
  IS_SHOPS: {
    [LANG_RU]: "Магазины",
    [LANG_UZ]: "Do'konlar",
    [LANG_UZK]: "Дўконлар",
  },
  IS_PRODUCTS: {
    [LANG_RU]: "Продукты",
    [LANG_UZ]: "Mahsulotlar",
    [LANG_UZK]: "Маҳсулотлар",
  },
  QRCODE: {
    [LANG_RU]: "QR кодом",
    [LANG_UZ]: "QR kod orqali",
    [LANG_UZK]: "QR код орқали",
  },
  pc: {
    [LANG_RU]: "шт",
    [LANG_UZ]: "dona",
    [LANG_UZK]: "дона",
  },
  "Add to favourites": {
    [LANG_RU]: "Добавить в избранное",
    [LANG_UZ]: "Saralanganlarga qoʻshish",
    [LANG_UZK]: "Сараланганларга қўшиш",
  },
  favourites: {
    [LANG_RU]: "Избранное",
    [LANG_UZ]: "Saralanganlar",
    [LANG_UZK]: "Сараланганлар",
  },
  "Order is rejected": {
    [LANG_RU]: "заказ был отменен",
    [LANG_UZ]: "Buyurtma bekor qilindi",
    [LANG_UZK]: "Буюртма бекор қилинди",
  },
  "Order is accepted": {
    [LANG_RU]: "Заказ принят",
    [LANG_UZ]: "Buyurtma qabul qilindi",
    [LANG_UZK]: "Буюртма қабул қилинди",
  },
  Successfully: {
    [LANG_RU]: "Успешно",
    [LANG_UZ]: "Muvaffaqiyatli",
    [LANG_UZK]: "Муваффақиятли",
  },
  Expectation: {
    [LANG_RU]: "Ожидание",
    [LANG_UZ]: "Kutilmoqda",
    [LANG_UZK]: "Кутилмоқда",
  },
  Checking: {
    [LANG_RU]: "Заказ готовится",
    [LANG_UZ]: "Buyurtma tayyorlanmoqda",
    [LANG_UZK]: "Буюртма тайёрланмоқда",
  },
  Formalization: {
    [LANG_RU]: "Водитель принимает заказ",
    [LANG_UZ]: "Haydovchi buyurtmani olmoqda",
    [LANG_UZK]: "Ҳайдовчи буюртмани олмоқда",
  },
  Sending: {
    [LANG_RU]: "Водитель принял заказ",
    [LANG_UZ]: "Haydovchi buyurtmani qabul qildi",
    [LANG_UZK]: "Ҳайдовчи буюртмани қабул қилди",
  },
  Delivered: {
    [LANG_RU]: "Водитель прибыл",
    [LANG_UZ]: "Haydovchi yetib keldi",
    [LANG_UZK]: "Ҳайдовчи етиб келди",
  },
  Confirmed: {
    [LANG_RU]: "Подтвержденный",
    [LANG_UZ]: "Tasdiqlandi",
    [LANG_UZK]: "Тасдиқланди",
  },
  "Your data successfully saved": {
    [LANG_RU]: "Ваше данные успешно сохранены",
    [LANG_UZ]: "Sizning maʻlumotlaringiz muvaffaqiyatli saqlandi",
    [LANG_UZK]: "Сизнинг маълумотларингиз муваффақиятли сақланди",
  },
  "UPLOAD PHOTO": {
    [LANG_RU]: "ЗАГРУЗИТЬ ФОТО",
    [LANG_UZ]: "RASMNI YUKLASH",
    [LANG_UZK]: "РАСМНИ ЮКЛАШ",
  },
  Phone: {
    [LANG_RU]: "Телефон",
    [LANG_UZ]: "Telefon",
    [LANG_UZK]: "Телефон",
  },
  SAVE: {
    [LANG_RU]: "СОХРАНИТЬ",
    [LANG_UZ]: "SAQLASH",
    [LANG_UZK]: "САҚЛАШ",
  },
  Category: {
    [LANG_RU]: "Категория",
    [LANG_UZ]: "Toifa",
    [LANG_UZK]: "Тоифа",
  },
  SUM: {
    [LANG_RU]: "Сум",
    [LANG_UZ]: "So'm",
    [LANG_UZK]: "Сўм",
  },
  "DELIVER-PRICE": {
    [LANG_RU]: "Сумма доставки",
    [LANG_UZ]: "Yetkazib berish narxi",
    [LANG_UZK]: "Етказиб бериш нархи",
  },
  "CARD-DATA": {
    [LANG_RU]: "Данные вашей карты",
    [LANG_UZ]: "Karta malumotlaringiz",
    [LANG_UZK]: "Карта малумотларингиз",
  },
  "404": {
    [LANG_RU]: "Страница не доступна",
    [LANG_UZ]: "Sahifa mavjud emas",
    [LANG_UZK]: "Сахифа мавжуд емас",
  },
  "PAYMENT-TABS": {
    [LANG_RU]: "Купить все",
    [LANG_UZ]: "Barchasini sotib olish",
    [LANG_UZK]: "Барчасини сотиб олиш",
  },
  "NOT-ACTIVE": {
    [LANG_RU]: "НЕ АКТИВНО",
    [LANG_UZ]: "FAOL EMAS",
    [LANG_UZK]: "ФАОЛ ЭМАС",
  },
  not_enough: {
    [LANG_RU]: "НЕ ДОСТАТОЧНО",
    [LANG_UZ]: "YETARLI EMAS",
    [LANG_UZK]: "ЕТАРЛИ ЭМАС",
  },
  Search_by_allShops: {
    [LANG_RU]: "Поиск магазинов",
    [LANG_UZ]: "Do'konlarni izlash",
    [LANG_UZK]: "Дўконларни излаш",
  },
  Search_by_allMarkets: {
    [LANG_RU]: "Поиск рынки",
    [LANG_UZ]: "Bozorlarni izlash",
    [LANG_UZK]: "Бозорларни излаш",
  },
  closed: {
    [LANG_RU]: "Закрытые",
    [LANG_UZ]: "Yopiq",
    [LANG_UZK]: "Йопик",
  },
  open: {
    [LANG_RU]: "Открытые",
    [LANG_UZ]: "Ochiq",
    [LANG_UZK]: "Очик",
  },
  ratingAsc: {
    [LANG_RU]: "Рейтинг по возрастанию",
    [LANG_UZ]: "Reyting o'sish tartibida",
    [LANG_UZK]: "Рейтинг усиш тартибида",
  },
  nameAsc: {
    [LANG_RU]: "Имя а - я",
    [LANG_UZ]: "Nomi a - z",
    [LANG_UZK]: "Номи а - з",
  },
  nameDesc: {
    [LANG_RU]: "Имя я - a",
    [LANG_UZ]: "Nomi z - a",
    [LANG_UZK]: "Номи з - а",
  },
  NO_DATA: {
    [LANG_RU]: "Товаров по вашему запросу не найдено",
    [LANG_UZ]: "So'rovingiz bo'yicha mahsulotlar topilmadi",
    [LANG_UZK]: "Сўровингиз бўйича маҳсулотлар топилмади",
  },
  NO_SHOPS: {
    [LANG_RU]: "Магазины по вашему запросу не найдено",
    [LANG_UZ]: "So'rovingiz bo'yicha do'konlar topilmadi",
    [LANG_UZK]: "Сўровингиз бўйича дўконлар топилмади",
  },
  NO_WISHES_DATA: {
    [LANG_RU]: "У вас нет изборанных продуктов",
    [LANG_UZ]: "Sizda tanlangan mahsulotlar yo'q",
    [LANG_UZK]: "Сизда танланган маҳсулотлар йўқ",
  },
  NO_MARKETS: {
    [LANG_RU]: "Точки продаж по вашему запросу не найдено",
    [LANG_UZ]: "So'rovingiz bo'yicha bozorlar topilmadi",
    [LANG_UZK]: "Сўровингиз бўйича бозорлар топилмади",
  },
  ADD_WISHES_DATA: {
    [LANG_RU]: "Добавьте то, что понравилось",
    [LANG_UZ]: "Sizga yoqqanini qo'shing",
    [LANG_UZK]: "Сизга ёққанини қўшинг",
  },
  FETCHING_ERROR: {
    [LANG_RU]: "Произошла ошибка при получении данных",
    [LANG_UZ]: "Ma'lumotlarni olishda xatolik yuz berdi",
    [LANG_UZK]: "Маълумотларни олишда хатолик юз берди",
  },
  TO_ALL: {
    [LANG_RU]: "Все",
    [LANG_UZ]: "Barchasi",
    [LANG_UZK]: "Барчаси",
  },
  NO_SUBCATEGORIES: {
    [LANG_RU]: "Категории не найдены!",
    [LANG_UZ]: "Kategoriyalar mavjud emas!",
    [LANG_UZK]: "Категориялар мавжуд эмас!",
  },
  KATALOG: {
    [LANG_RU]: "каталог",
    [LANG_UZ]: "katalog",
    [LANG_UZK]: "каталог",
  },
  notes: {
    [LANG_RU]: "Заметки",
    [LANG_UZ]: "Qaydlar",
    [LANG_UZK]: "Қайдлар",
  },
  "": {
    [LANG_RU]: "",
    [LANG_UZ]: "",
    [LANG_UZK]: "",
    [LANG_RU]: "каталог",
    [LANG_UZ]: "katalog",
    [LANG_UZK]: "каталог",
  },
  CHATNOTAUTH: {
    [LANG_RU]: "Пожалуйста регистрируйтесь!",
    [LANG_UZ]: "Iltimos ro'hatdan o'ting!",
    [LANG_UZK]: "Илтимос Рўйхатдан ўтинг!",
  },
  NOTES: {
    [LANG_RU]: "Добавить новую карточку",
    [LANG_UZ]: "Yangi karta qo'shish",
    [LANG_UZK]: "Янги карта қўшиш",
  },
  ADD_NOTES: {
    [LANG_RU]: "Добавить карточку",
    [LANG_UZ]: "Karta qo'shish",
    [LANG_UZK]: "Карта қўшиш",
  },
  ADD_NOTES_NAME: {
    [LANG_RU]: "Введите название карточки.",
    [LANG_UZ]: "Karta nomini kiriting.",
    [LANG_UZK]: "Карта номини киритинг.",
  },
  NO_ITEMS: {
    [LANG_RU]: "Продукты отсутствуют",
    [LANG_UZ]: "Mahsulotlar mavjud emas",
    [LANG_UZK]: "Маҳсулотлар мавжуд эмас",
  },
  NO_LISTS: {
    [LANG_RU]: "Списки отсутствуют",
    [LANG_UZ]: "Ro'yxatlar mavjud emas",
    [LANG_UZK]: "Рўйхатлар мавжуд эмас",
  },
  ADD_LIST: {
    [LANG_RU]: "Добавить список",
    [LANG_UZ]: "Ro'yxat qo'shish",
    [LANG_UZK]: "Рўйхат қўшиш",
  },

  ADD_LIST_PLACEHOLDER: {
    [LANG_RU]: "Добавьте новый список",
    [LANG_UZ]: "Yangi ro'yxat qo'shing",
    [LANG_UZK]: "Янги рўйхат қўшинг",
  },
  ADD_LIST_NAME: {
    [LANG_RU]: "Введите название списка.",
    [LANG_UZ]: "Ro'yxat nomini kiriting.",
    [LANG_UZK]: "Рўйхат номини киритинг.",
  },
  ADD_ITEM_NAME: {
    [LANG_RU]: "Введите название продукта.",
    [LANG_UZ]: "Mahsulot nomini kiriting.",
    [LANG_UZK]: "Маҳсулот номини киритинг.",
  },
  EMPTY_CART: {
    [LANG_RU]: "Корзинка пустая",
    [LANG_UZ]: "Savatcha bo'sh",
    [LANG_UZK]: "Саватча бўш",
  },
  FILTER: {
    [LANG_RU]: "Фильтры",
    [LANG_UZ]: "Filtrlar",
    [LANG_UZK]: "Филтрлар",
  },
  BY_COST: {
    [LANG_RU]: "По цене",
    [LANG_UZ]: "Narxi bo'yicha",
    [LANG_UZK]: "Нархи бўйича",
  },
  BY_NAME: {
    [LANG_RU]: "По имени",
    [LANG_UZ]: "Nomi bo'yicha",
    [LANG_UZK]: "Номи бўйича",
  },
  BY_SALES: {
    [LANG_RU]: "По продажам",
    [LANG_UZ]: "Sotilishi bo'yicha",
    [LANG_UZK]: "Сотилиши бўйича",
  },
  BY_RATING: {
    [LANG_RU]: "По рейтингу",
    [LANG_UZ]: "Reytingi bo'yicha",
    [LANG_UZK]: "Рейтинги бўйича",
  },

  // '': {
  //   [LANG_RU]: '',
  //   [LANG_UZ]: '',
  //   [LANG_UZK]: '',
  // },
};
