import { AbstractApi } from './AbstractApi';

class LoadMoreChats extends AbstractApi {
  getAll = (id: string, firstElId: string) => {
    const PATH = `commodity/chat/${id}?offset_id=${firstElId}`;
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET, true);
  };
  getGuest = (guestId: string, firstElId: string) => {
    const PATH = `commodity/chat/guest/${guestId}?offset_id=${firstElId}`;
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET, true);
  };
}
const loadMoreChats = new LoadMoreChats();
export default loadMoreChats;
