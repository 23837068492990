import React, { ChangeEvent, Component } from "react";
import { Product } from "../../common/types/Product";
import allProductsApi from "../../common/api/AllProductsApi";
import ProductAsItem from "../../common/components/ProductAsItem";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import ProductAsListItem from "../../common/components/ProductAsListItem";
import { withRouter } from "react-router";
import { ALL_PRODUCT_ITEMS_LIMIT } from "../../common/constants/constants";
import {
  asideMenuListClickItem,
  productShopAndBasket,
} from "../../common/helpers/Helper";
import Messages from "../../common/messages/Messages";
import { ROUTE_PATHS } from "../../common/config/route_paths";
import HelmetComponent from "../../_temp/helmet";
import { Link } from "react-router-dom";
import ProductsNotFound from "../../_temp/productNotFound";
import Error from "../../_temp/error";
import SkeletonLoader from "../../_temp/skeletonLoader";
import SkeletonLoaderList from "../../_temp/skeletonLoader/loaderList";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

const ArrowDown = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 464c114.87 0 208-93.13 208-208S370.87 48 256 48 48 141.13 48 256s93.13 208 208 208zm-91.36-212.65a16 16 0 0122.63-.09L240 303.58V170a16 16 0 0132 0v133.58l52.73-52.32A16 16 0 11347.27 274l-80 79.39a16 16 0 01-22.54 0l-80-79.39a16 16 0 01-.09-22.65z"></path>
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 48C141.13 48 48 141.13 48 256s93.13 208 208 208 208-93.13 208-208S370.87 48 256 48zm91.36 212.65a16 16 0 01-22.63.09L272 208.42V342a16 16 0 01-32 0V208.42l-52.73 52.32A16 16 0 11164.73 238l80-79.39a16 16 0 0122.54 0l80 79.39a16 16 0 01.09 22.65z"></path>
    </svg>
  );
};

// TO DO any
class AllProducts extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { showMenu: false, loading: false };
  }
  VIEW_MODE_GRID = "GRID";
  VIEW_MODE_LIST = "LIST";
  itemsLimit = ALL_PRODUCT_ITEMS_LIMIT;
  // generate = async () => {
  //   // await this.getCategoriesList();
  // };
  // getQueries = () => {
  //   let name = new URLSearchParams(this.props.location.search).get("name");
  // };
  // componentDidMount() {
  //   // this.generate();
  //   this.getQueries();
  // }

  // shouldComponentUpdate(
  //   nextProps: Readonly<any>,
  //   nextState: Readonly<any>,
  //   nextContext: any
  // ): boolean {
  //   if (nextProps.category !== this.props.category) {
  //     console.log("change", nextProps.category, this.props.category);
  //     // this.saveCategoryToStore(nextProps.name, nextProps.category);
  //     return true;
  //   } else {
  //     if (nextProps.category === "") {
  //       return true;
  //     }
  //     return false;
  //   }
  // }

  componentDidUpdate(prevProps: Readonly<any>): void {
    if (prevProps.category.id !== this.props.category.id) {
      const { actions } = this.props;
      actions.setMaxCostAction("");
      actions.setMinCostAction("");
      this.getFirstProductsWithoutCost();
    }

    if (prevProps.products.length !== this.props.products.length) {
      this.setState({ loading: false });
    }
    if (prevProps.filter.sort !== this.props.filter.sort) {
      const { actions } = this.props;
      actions.setMaxCostAction("");
      actions.setMinCostAction("");
      this.getFirstProductsWithoutCost();
    }
  }

  changeMode = (view_mode: string) => {
    const { actions } = this.props;
    actions.changeViewMode(view_mode);
  };

  // changeCategory = async (name: string, category: string) => {
  //   // let { name, category } = this.props;
  //   let searchUrl: any = {
  //     pathname: ROUTE_PATHS.ALL_PRODUCTS,
  //   };
  //   searchUrl = await changeSearchUrl(name, category, searchUrl);
  //   console.log(searchUrl);
  //   await this.getFirstProducts();
  //   await this.props.history.push(searchUrl);
  // };

  fetchMoreProducts = () => {
    const { actions, name, category, _page, filter, min_cost, max_cost } =
      this.props;
    let offset = _page + this.itemsLimit;
    let sendParams: any = {
      name,
      type: category.id ? category.id : "",
      limit: this.itemsLimit,
      start: offset,
      sortBy: filter.sort,
      min_cost,
      max_cost,
    };
    this.setState({ loading: true });
    allProductsApi
      .getAll(sendParams)
      .then((res: any) => res.json())
      .then((result: any) => {
        if (result.products && result.products.length > 0) {
          this.setState({ loading: false });
          actions.fetchMoreSearchProducts(
            result.products,
            offset,
            result.products.length === this.itemsLimit
          );
        } else {
          this.setState({ loading: false });
          actions.fetchMoreSearchProducts([], offset);
        }
      });
  };

  getFirstProducts = () => {
    const { actions, name, category, filter, min_cost, max_cost } = this.props;
    let sendParams: any = {
      name,
      type: category.id ? category.id : "",
      limit: this.itemsLimit,
      start: 0,
      sortBy: filter.sort,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
    };
    // this.setState({ loading: true });
    actions.setSetAllProductsLoadingAction(true);
    allProductsApi
      .getAll(sendParams)
      .then((res: any) => res.json())
      .then((result: any) => {
        if (result.products && result.products.length > 0) {
          this.setState({ loading: false });
          actions.fetchAllProducts(
            result.products,
            result.products.length === this.itemsLimit
          );
        } else {
          this.setState({ loading: false });
          actions.fetchAllProducts(
            result.products,
            result.products.length === this.itemsLimit
          );
        }
      })
      .finally(() => {
        actions.setSetAllProductsLoadingAction(false);
      });
  };

  getFirstProductsWithoutCost = () => {
    const { actions, name, category, filter } = this.props;
    let sendParams: any = {
      name,
      type: category.id ? category.id : "",
      limit: this.itemsLimit,
      start: 0,
      sortBy: filter.sort,
    };
    // this.setState({ loading: true });
    actions.setSetAllProductsLoadingAction(true);

    allProductsApi
      .getAll(sendParams)
      .then((res: any) => res.json())
      .then((result: any) => {
        if (result.products && result.products.length > 0) {
          this.setState({ loading: false });
          actions.fetchAllProducts(
            result.products,
            result.products.length === this.itemsLimit
          );
        } else {
          this.setState({ loading: false });
          actions.fetchAllProducts(
            result.products,
            result.products.length === this.itemsLimit
          );
        }
      })
      .catch((error) => {
        if (error && error.data?.msg) {
          actions.setAllProductsErrorAction(error.data?.msg);
        } else {
          actions.setAllProductsErrorAction(Messages.t("FETCHING_ERROR"));
        }
      })
      .finally(() => {
        actions.setSetAllProductsLoadingAction(false);
      });
  };

  // saveCategoryToStore = async (name: any, category: any) => {
  //   // el.preventDefault();

  //   // let category_id = el.target.getAttribute("data-category");
  //   let { actions } = this.props;

  //   await actions.saveSearchParamsCategory(category);
  //   await actions.changeCategory(name, category);
  // };

  // fetchCategory = async () => {
  //     let noting:any
  //     let obj = {
  //         method: 'GET',
  //         headers: {
  //             'Content-Type': 'application/x-www-form-urlencoded',
  //             'token': '2902103933201511980447427086634252927727',
  //         }
  //     }
  //     const {actionsCategory, messages} = this.props;
  //     await fetch("http://hammol.uz:444/types",obj)
  //         .then(res => res.json())
  //         .then(categories => {
  //             if (categories && Array.isArray(categories)) {
  //
  //                 categories.forEach((item: Category) => {
  //
  //                     let itemObjects: CategoryItem[] = [];
  //
  //                     item.items?.split(',')
  //                         .forEach((id) => {
  //                             itemObjects.push({id: +id, name: messages[CATEGORY_ITEM_PREFIX + id]});
  //                         });
  //
  //                     item.itemsAsObjects = itemObjects;
  //                 });
  //             }
  //
  //             return categories;
  //         })
  //     return noting
  // }
  // getCategoriesList = async () => {
  //
  //     const {actionsCategory, messages} = this.props;
  //
  //     await categoryApi.getAll()
  //         .then((response) => response.json())
  //         .then((categories) => {
  //
  //             if (categories && Array.isArray(categories)) {
  //
  //                 categories.forEach((item: Category) => {
  //
  //                     let itemObjects: CategoryItem[] = [];
  //
  //                     item.items?.split(',')
  //                         .forEach((id) => {
  //                             itemObjects.push({id: +id, name: messages[CATEGORY_ITEM_PREFIX + id]});
  //                         });
  //
  //                     item.itemsAsObjects = itemObjects;
  //                 });
  //             }
  //
  //             return categories;
  //         })
  //         .then((categories) => {
  //             return actionsCategory.getCategoriesList(categories);
  //         })
  // };

  render = () => {
    const {
      view_mode,
      actions,
      cartProducts,
      products,
      actionsShoppingCart,
      filter,
      min_cost,
      max_cost,
      productsLoading,
      error,
    } = this.props;
    const self = this;
    // actions.changeCategory = this.changeCategory;
    // actions.saveCategoryToStore = this.saveCategoryToStore;
    asideMenuListClickItem();

    const handleFilter = (name: string) => {
      actions.setFilterAction(name);
    };

    const getFilterClass = (a: string, b: string) => {
      if (filter.sort === a || filter.sort === b) return "active";
      return "";
    };

    const getFilterIcons = (a: string, b: string) => {
      if (filter.sort === a || filter.sort === b) {
        if (filter.type === true) {
          return <ArrowDown />;
        }
        return <ArrowUp />;
      }
    };

    const handleChangeMinCost = (value: number | string) => {
      const num = Number(value);
      if (value <= 0) actions.setMinCostAction("");
      if (num) actions.setMinCostAction(num);
    };

    const handleChangeMaxCost = (value: number | string) => {
      const num = Number(value);
      if (value <= 0) actions.setMaxCostAction("");
      if (num) actions.setMaxCostAction(num);
    };

    const getProdsWithMinMaxCost = () => {
      if (!min_cost || !max_cost) {
        this.getFirstProducts();
      } else if (min_cost > 0 && max_cost > 0 && min_cost <= max_cost) {
        this.getFirstProducts();
      } else {
        toast("Iltimos Narxlarni to'g'ri kiriting");
      }
    };
    return (
      <div>
        <HelmetComponent title={Messages.t("All_products")} />
        <Header />
        <MyToaster />
        <main className="main">
          <div className="all-products-top">
            <div className="container">
              <div className="all-products-top__row">
                <div className="all-products-top__text">
                  <span className="all-products-top__title">
                    {Messages.t("All_products")}
                  </span>
                  <span className="all-products-top__subtitle">
                    {Messages.t("Categories")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="wrapper">
              <div className="main-content">
                <div className="show-filter-options">
                  <ul className="show-filter">
                    {/*TO DO*/}
                    <li
                      className={`show-filter__item ${getFilterClass(
                        "priceAsc",
                        "priceDesc"
                      )}`}
                      onClick={() => handleFilter("price")}
                    >
                      {Messages.t("BY_COST")}{" "}
                      {getFilterIcons("priceAsc", "priceDesc")}
                    </li>
                    <li
                      className={`show-filter__item ${getFilterClass(
                        "nameAsc",
                        "nameDesc"
                      )}`}
                      onClick={() => handleFilter("name")}
                    >
                      {Messages.t("BY_NAME")}{" "}
                      {getFilterIcons("nameAsc", "nameDesc")}
                    </li>
                    <li
                      className={`show-filter__item ${getFilterClass(
                        "soldAsc",
                        "soldDesc"
                      )}`}
                      onClick={() => handleFilter("sold")}
                    >
                      {Messages.t("BY_SALES")}{" "}
                      {getFilterIcons("soldAsc", "soldDesc")}
                    </li>
                    <li
                      className={`show-filter__item ${getFilterClass(
                        "ratingAsc",
                        "ratingDesc"
                      )}`}
                      onClick={() => handleFilter("rating")}
                    >
                      {Messages.t("BY_RATING")}{" "}
                      {getFilterIcons("ratingAsc", "ratingDesc")}
                    </li>
                  </ul>
                  <ul className="mobile__filter-list">
                    <li className="header__menu-item" id="langToggle">
                      <span
                        className="header__menu-link"
                        onClick={(k) => {
                          this.setState({
                            showMenu: !this.state.showMenu,
                          });
                        }}
                      >
                        {Messages.t("FILTER")}
                        <svg
                          className="icon change-lang-arrow"
                          width="5"
                          height="8"
                        >
                          <use href="static/img/icons/sprite/sprite.svg#arrow" />
                        </svg>
                      </span>
                      <div
                        className={
                          self.state.showMenu
                            ? "header__lang header__lang--open"
                            : "header__lang"
                        }
                      >
                        <ul className="header__lang-list">
                          <li className="header__lang-item">
                            <span
                              className="header__lang-link"
                              onClick={() => handleFilter("price")}
                            >
                              {Messages.t("BY_COST")}{" "}
                              {getFilterIcons("priceAsc", "priceDesc")}
                            </span>
                          </li>
                          <li className="header__lang-item">
                            <span
                              className="header__lang-link"
                              onClick={() => handleFilter("name")}
                            >
                              {Messages.t("BY_NAME")}{" "}
                              {getFilterIcons("nameAsc", "nameDesc")}
                            </span>
                          </li>
                          <li className="header__lang-item">
                            <span
                              className="header__lang-link"
                              onClick={() => handleFilter("sold")}
                            >
                              {Messages.t("BY_SALES")}{" "}
                              {getFilterIcons("soldAsc", "soldDesc")}
                            </span>
                          </li>
                          <li className="header__lang-item">
                            <span
                              className="header__lang-link"
                              onClick={() => handleFilter("rating")}
                            >
                              {Messages.t("BY_RATING")}
                              {getFilterIcons("ratingAsc", "ratingDesc")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <div className="view-filter">
                    <div
                      className={
                        view_mode === this.VIEW_MODE_LIST
                          ? "view-filter__option view_mode view-filter__option--selected"
                          : "view-filter__option view_mode"
                      }
                    >
                      <svg
                        className="icon"
                        width="20"
                        height="20"
                        onClick={this.changeMode.bind(
                          null,
                          this.VIEW_MODE_LIST
                        )}
                      >
                        <use href="static/img/icons/sprite/sprite.svg#list-view" />
                      </svg>
                    </div>
                    <div
                      className={
                        view_mode === this.VIEW_MODE_GRID
                          ? "view-filter__option view_mode view-filter__option--selected"
                          : "view-filter__option view_mode"
                      }
                    >
                      <svg
                        className="icon"
                        width="20"
                        height="20"
                        onClick={this.changeMode.bind(
                          null,
                          this.VIEW_MODE_GRID
                        )}
                      >
                        <use href="static/img/icons/sprite/sprite.svg#grid-view" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="show-filter-options">
                  <div className="filter__by__cost">
                    <div className="filter__bycost-input">
                      <label>Минимальная цена</label>
                      <input
                        type="number"
                        placeholder="0.00"
                        value={min_cost}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeMinCost(e.target.value)
                        }
                      />
                    </div>
                    <div className="filter__bycost-input">
                      <label>Максимальная цена</label>
                      <input
                        type="number"
                        placeholder="0.00"
                        value={max_cost}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeMaxCost(e.target.value)
                        }
                      />
                    </div>
                    <button
                      className="filter__bycost-send"
                      onClick={() => getProdsWithMinMaxCost()}
                    >
                      Отправить
                    </button>
                  </div>
                </div>
                <div className="all-products">
                  {view_mode === this.VIEW_MODE_GRID ? (
                    <div className="all-products__row">
                      {productsLoading ? (
                        <SkeletonLoader
                          className="all-products__skeleton"
                          number={15}
                        />
                      ) : error ? (
                        <Error />
                      ) : products && products.length ? (
                        products.map((item: Product) => {
                          item = productShopAndBasket(
                            item,
                            // shops,
                            cartProducts
                          );

                          if (item.itemactive) {
                            return (
                              item.itemactive &&
                              item.shopactive && (
                                <ProductAsItem
                                  key={item.id}
                                  item={item}
                                  actions={{
                                    ...actionsShoppingCart,
                                    getFirstProducts: this.getFirstProducts,
                                  }}
                                  from={ROUTE_PATHS.ALL_PRODUCTS}
                                />
                              )
                            );
                          } else {
                            return (
                              item.productactive &&
                              item.shopactive && (
                                <ProductAsItem
                                  key={item.id}
                                  item={item}
                                  actions={{
                                    ...actionsShoppingCart,
                                    getFirstProducts: this.getFirstProducts,
                                  }}
                                  from={ROUTE_PATHS.ALL_PRODUCTS}
                                />
                              )
                            );
                          }
                        })
                      ) : (
                        <ProductsNotFound />
                      )}
                    </div>
                  ) : (
                    <ul className="all-products__list">
                      {productsLoading ? (
                        <SkeletonLoaderList
                          className="all-products__skeleton-list"
                          number={15}
                        />
                      ) : error ? (
                        <Error />
                      ) : products && products.length ? (
                        products.map((item: Product) => {
                          item = productShopAndBasket(
                            item,
                            // shops,
                            cartProducts
                          );
                          return (
                            <ProductAsListItem
                              key={item.id}
                              item={item}
                              actions={{
                                ...actionsShoppingCart,
                                // getFirstProducts: this.getFirstProducts,
                              }}
                              from={ROUTE_PATHS.ALL_PRODUCTS}
                            />
                          );
                        })
                      ) : (
                        <div className="show-filter-options w-100 p-3 text-center">
                          <h2 className="mt-1">{Messages.t("NO_ITEMS")}</h2>
                        </div>
                      )}
                    </ul>
                  )}
                  {!productsLoading && this.state.loading ? (
                    <div className="top-products__more">
                      <div>
                        <div className="lds-dual-ring"></div>
                      </div>
                      {Messages.t("Show_more_products_loading")}
                    </div>
                  ) : (
                    products.length >=
                      this.props._page + this.itemsLimit - 1 && (
                      <div
                        className="top-products__more"
                        onClick={this.fetchMoreProducts}
                      >
                        {Messages.t("Show_more_products")}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="all-products_aside-wrapper">
                <div className="all-products_aside">
                  <div className="all-products_aside-inner">
                    <Link to="/shops">
                      <div className="all-products_aside-inner-title">
                        <h3>Все Магазины</h3>
                      </div>
                      <div className="all-products_aside-inner-image">
                        <img src="/static/img/all_png.png" />
                      </div>
                    </Link>
                  </div>
                  {/* <div className="all-products_aside-inner">
                    <Link to="/shops">
                      {/* <div className="hero__card">
                      <span className="hero__card-title">Все Магазины</span>
                      <span className="hero__card-subtitle">Категории</span>
                      <span className="hero__card-link">
                        <svg
                          className="hero__card-link-icon"
                          width="6"
                          height="10"
                        >
                          <use href="static/img/icons/sprite/sprite.svg#arrow"></use>
                        </svg>
                      </span>
                      <img
                        style={{ transform: "rotateY(180deg)" }}
                        src="static/img/5.png"
                        alt=""
                      />
                    </div> */}
                  {/* <div className="all-products_aside-inner-title">
                        <h3>Молочные продукты</h3>
                      </div>
                      <div className="all-products_aside-inner-image">
                        <img src="/static/img/milk.png" />
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  };
}

export default withRouter(AllProducts);
