import React, { Suspense, Component } from 'react';
const Order = React.lazy(() => import('../../order/Order'));
const Notes = React.lazy(() => import('../../notes/Notes'));
const Profile = React.lazy(() => import('../../profile/Profile'));
/* eslint-disable import/first */
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Notfound from '../../errors/404/Notfound';
import Home from '../../home/Home';
import AllProducts from '../../allproducts/containers/AllProducts';
import Login from '../../login/Login';
import ShoppingCart from '../../shoppingcart/containers/ShoppingCart';
import Registration from '../../registration/Registration';
import { IAuthState, IAppProps } from '../store/types';
import Buy from '../../buy/containers/Buy';
import Restore from '../../login/Restore';
import { Category, CategoryItem } from '../../common/types/Category';
import Messages from '../../common/messages/Messages';
import ProductDetail from '../../product_detail/containers/ProductDetail';

import { ROUTE_PATHS } from '../../common/config/route_paths';
import {
  CATEGORY_ITEM_PREFIX,
  LANG_VAR_NAME_WE,
} from '../../common/constants/constants';

import shopApi from '../../common/api/ShopApi';
import statusApi from '../../common/api/StatusApi';
import categoryApi from '../../common/api/CategoryApi';
import langApi from '../../common/api/LangApi';
import Favorites from '../../_temp/favorites/Favorites';
import About from '../../_temp/about/About';
import ServiceUnavailable from '../../errors/503/ServiceUnavailable';

// TO DO
import { PrivateRoute } from '../../common/components/PrivateRoute';
import ShopProducts from '../../shop/containers/ShopProducts';
import AllShops from '../../shop/components/AllShops';
import NotesShare from '../../notes/NotesShare';
import Chat from '../../common/components/Chat/Chat';
import SingleNote from '../../notes/SingleNote';
import AllMarkets from '../../markets/components/AllMarkets';
import RestoreConfirm from '../../login/component/RestoreConfirm';
import Privacy from '../../privacy';
import MarketShops from '../../markets/containers/MarketShops';
import HammolLoader from '../../_temp/hammolLoader';
// import AllCollections from '../../collection/containers/AllCollections';

export default class App extends Component<IAppProps, IAuthState> {
  componentDidMount() {
    this.getStatus();
  }

  initLang = () => {
    const { actionsLang } = this.props;
    actionsLang.initLang();
  };

  getLangFile = async () => {
    const { lang, actionsLang } = this.props;
    fetch('https://hammol.uz/tmp/lang-uz.js')
      .then((result) => {
        result.text();
      })
      .then((lang) => {
        console.log('LANG', lang);
      });
    await langApi
      .getFile(lang)
      .then((result) => result.text())
      .then((langText) => {
        let langList = langText.substring(LANG_VAR_NAME_WE.length);
        // eslint-disable-next-line no-new-func
        let langMessages = new Function('return ' + langList)();
        actionsLang.setLangMessages(langMessages);
      });
  };

  getStatus = async () => {
    const self = this;
    let statusRead: any = localStorage.getItem('statusRead');
    statusRead = null;
    if (statusRead === null) {
      localStorage.setItem('statusRead', 'true');
      await statusApi
        .status()
        .then((result: any) => result.json())
        .then((result) => {
          // localStorage.setItem('host', "https://hammol.uz:443/api/");
          localStorage.setItem('authUser', JSON.stringify(result.user));
          // if (result.user && Object.keys(result.user).length === 0) {
          //   self.resetLogin();
          // }
        });
    }
    // await this.getLangFile();
    await this.initLang();
    await this.initAuthUser();
    await this.initShoppingCartState();
    await this.getCategoriesList();
  };

  resetLogin = () => {
    const { actionsAuth } = this.props;
    actionsAuth.authLogoutAcn();
  };

  initAuthUser = () => {
    const { actionsAuth } = this.props;
    actionsAuth.authInitAcn();
  };

  initShoppingCartState = () => {
    const { actionsShoppingCart } = this.props;
    actionsShoppingCart.initShoppingCartFromLocStorage();
  };

  getCategoriesList = async () => {
    const { actionsCategory } = this.props;

    await categoryApi
      .getAll('')
      .then((response) => response.json())
      .then((categories) => {
        return categories.items;
      })
      .then((categories) => {
        return actionsCategory.getCategoriesList(categories);
      });
  };

  render = () => {
    return (
      <Suspense fallback={<div>{Messages.t('Loading...')}</div>}>
        <Router>
          <Switch>
            <Route exact path={ROUTE_PATHS.HOME} component={Home} />
            <Route path={ROUTE_PATHS.DETAIL} component={ProductDetail} />
            <Route path={ROUTE_PATHS.LOGIN} component={Login} />
            <Route path={ROUTE_PATHS.REGISTRATION} component={Registration} />
            <Route path={ROUTE_PATHS.SHOPPING_CART} component={ShoppingCart} />
            <Route path={ROUTE_PATHS.ALL_PRODUCTS} component={AllProducts} />
            <Route path={ROUTE_PATHS.ALL_MARKETS} component={AllMarkets} />
            <Route path={ROUTE_PATHS.ALL_SHOPS} component={AllShops} />
            <Route path={ROUTE_PATHS.SHOP_PRODUCTS} component={ShopProducts} />
            <Route path={ROUTE_PATHS.MARKET_SHOPS} component={MarketShops} />
            <Route path={ROUTE_PATHS.BUY} component={Buy} />
            <Route path={ROUTE_PATHS.MY_ORDERS} component={Order} />
            <Route path={ROUTE_PATHS.PROFILE} component={Profile} />
            <Route path={ROUTE_PATHS.RESTORE} component={Restore} />
            <Route
              path={ROUTE_PATHS.RESTORE_CONFIRM}
              component={RestoreConfirm}
            />
            <Route exact path={ROUTE_PATHS.NOTES} component={Notes} />
            <Route path={ROUTE_PATHS.SINGLE_NOTE} component={SingleNote} />
            <Route path={ROUTE_PATHS.NOTES_SHARE} component={NotesShare} />
            {/*TO DO*/}
            <PrivateRoute
              exact
              path="/"
              isAuthenticated={true}
              component={ShoppingCart}
            />
            <Route path="/about" component={About} />
            {/* <Route path="/all-collections" component={AllCollections}/> */}
            <Route path="/favorites" component={Favorites} />
            <Route path="/error503" component={ServiceUnavailable} />
            <Route path="/privacy" component={Privacy} />
            <Route component={Notfound} />
          </Switch>
          <Chat />
        </Router>
      </Suspense>
    );
  };
}
