import * as types from './constants';
import {Product} from "../../common/types/Product";
import {
    IChooseProductAcn,
    IReceiveProductDetailAcn
} from "./actionsTypes";

export function receiveProductDetail(details: any): IReceiveProductDetailAcn {
    return {type: types.RECEIVE_PRODUCT_DETAIL, details}
}

export function chooseProduct(product: Product): IChooseProductAcn {
    return {type: types.CHOOSE_PRODUCT, product}
}
