import React, { Component } from "react";
import { Product } from "../types/Product";
import config from "../config/config";
import Messages from "../messages/Messages";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

type Props = {
  item: any;
  actions: any;
  quantity?: number;
  totalCost?: number;
};
type IState = {
  count?: number | undefined;
};

class ShoppingCartProductAsListItem extends Component<Props, IState> {
  hostUrl = config.hostUrl;
  constructor(props: any) {
    super(props);
    this.state = {
      count: this.props.quantity,
    };
  }

  render = () => {
    const { item, quantity = 0, actions } = this.props;

    return (
      <>
        <MyToaster />
        <Link
          className="product-list__item"
          to={{
            pathname: "/detail/" + item.id,
            state: {
              product: item,
            },
          }}
        >
          <div className="product">
            <div className="product__img">
              {Array.isArray(item.product.images) ? (
                <>
                  {item.product.images[0] &&
                  item.product.images[0].src &&
                  item.product.images[0].src.includes("temp/") ? (
                    <img
                      src={"https://hammol.uz" + item.product.images[0].src}
                      alt="rasm"
                    />
                  ) : (
                    <>
                      {console.log(item.product.image[0])}
                      {item.product.image[0] && item.product.image[0].src ? (
                        <img
                          src={
                            "data:image/png;base64, " +
                            item.product.images[0].src
                          }
                          alt="rasm"
                        />
                      ) : (
                        <img
                          src={"https://hammol.uz/temp/noimage2.png"}
                          alt="rasm"
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {Array.isArray(item.product.image) &&
                  item.product.image[0] &&
                  item.product.image[0].src &&
                  item.product.image[0].src.includes("temp/") ? (
                    <img
                      src={"https://hammol.uz" + item.product.image[0].src}
                      alt="rasm"
                    />
                  ) : (
                    <>
                      {Array.isArray(item.product.image) &&
                      item.product.image[0] &&
                      item.product.image[0].src ? (
                        <img
                          src={
                            "data:image/png;base64, " +
                            item.product.image[0].src
                          }
                          alt="rasm"
                        />
                      ) : (
                        <img
                          src={"https://hammol.uz/temp/noimage2.png"}
                          alt="rasm"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="product__content">
              <p className="product__summary">{item.product.name}</p>
              <span className="product__cost product__cost--desktop">
                {item.product.cost * item.quantity}
                <span>{item.product.currency}</span>
              </span>
            </div>
            <span
              className="product__delete"
              onClick={(e) => {
                e.preventDefault();
                actions.deleteFromShoppingCart(item.product, item);
              }}
            >
              <svg className="icon" width="10" height="10">
                <use href="static/img/icons/sprite/sprite.svg#close" />
              </svg>
            </span>
            <div className="product__count">
              <span className="product__count-title">
                {Messages.t("Count")}
              </span>
              <form className="product__count-form">
                <div className="top">
                  <span className="product__cost product__cost--mobile">
                    {+item.product.cost * item.quantity}
                    <span>{item.product.currency}</span>
                  </span>
                  <div className="product__count-wrap">
                    <button
                      className="product__count-btn"
                      onClick={(el) => {
                        el.preventDefault();
                        actions.deleteOneProductFromShoppingCart(
                          item.product,
                          item,
                          1
                        );
                      }}
                    >
                      -
                    </button>
                    <input
                      className="product__count-value"
                      type="number"
                      value={this.props.quantity}
                      onChange={(e: any) => {
                        // e.persist();
                        if (+e.target.value > 0) {
                          if (+e.target.value < item.product.inventory) {
                            console.log(item);
                            actions.addToShoppingCart(
                              item.product,
                              +e.target.value
                            );
                          } else {
                            toast("Do'konda buncha mahsulot yo'q!");
                          }
                        }
                      }}
                    />
                    <button
                      className="product__count-btn"
                      onClick={(el) => {
                        el.preventDefault();
                        el.persist();
                        console.log(item.quantity, item.product.inventory);
                        if (item.quantity < item.product.inventory) {
                          actions.addOneProductToShoppingCart(item.product, 1);
                        } else {
                          toast("Do'konda buncha mahsulot yo'q!");
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                  <label className="product__count-label">
                    {Messages.t("pc")}
                  </label>
                </div>
              </form>
            </div>
            <span
              className="product__delete-mob"
              onClick={(e) => {
                e.preventDefault();
                actions.deleteFromShoppingCart(item.product, item);
              }}
            >
              <svg className="icon" width="15" height="15">
                <use href="static/img/icons/sprite/sprite.svg#trash" />
              </svg>
            </span>
          </div>
        </Link>
      </>
    );
  };
}

export default ShoppingCartProductAsListItem;
