import * as types from "./constants";
import { Product } from "../../../common/types/Product";

export const fetchFavoriteProducts = (products: Product[]) => {
  return { type: types.RECEIVE_FAVORITE_PRODUCTS, products };
};

export const removeFavoriteProduct = (id: string) => {
  return { type: types.REMOVE_FAVORITE_PRODUCT, id };
};
