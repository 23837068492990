import * as types from "./constants";
import {
  IActionAuthInit,
  IActionAuthLogin,
  IActionAuthLogout,
} from "./actionsTypes";
import { User } from "../../common/types/User";

export function authInitAcn(): IActionAuthInit {
  return { type: types.AUTH_INIT };
}

export function authLoginAcn(user: User, token: string): IActionAuthLogin {
  return { type: types.AUTH_LOGIN, user, token };
}

export function authLogoutAcn(): IActionAuthLogout {
  return { type: types.AUTH_LOGOUT };
}
