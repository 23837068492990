import React, { Component } from "react";
import { Product } from "../../common/types/Product";
import ShopApi from "../../common/api/ShopApi";
import ProductAsItem from "../../common/components/ProductAsItem";
import ProductAsListItem from "../../common/components/ProductAsListItem";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { withRouter } from "react-router";
import { SHOP_PRODUCTS_LIMIT } from "../../common/constants/constants";
import {
  asideMenuListClickItem,
  // changeSearchUrl,
  productShopAndBasket,
} from "../../common/helpers/Helper";
// import Categories from "../../category/components/Categories";
import Messages from "../../common/messages/Messages";
import { ROUTE_PATHS } from "../../common/config/route_paths";
// import { Category, CategoryItem } from "../../common/types/Category";
// import categoryApi from "../../common/api/CategoryApi";
import HelmetComponent from "../../_temp/helmet";

import "./shop-products.css";

import ShopInfo from "./ShopInfo";
import allProductsApi from "../../common/api/AllProductsApi";
import FilterCategoryActivate from "./FilterCategory/FilterCategoryActivate";
import FilterCategoryModal from "./FilterCategory/FilterCategoryModal";
import MainLoader from "../../_temp/hammolLoader/MainLoader";
import ProductsNotFound from "../../_temp/productNotFound";
import Error from "../../_temp/error";
import SkeletonLoader from "../../_temp/skeletonLoader";
import SkeletonLoaderList from "../../_temp/skeletonLoader/loaderList";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

const ArrowDown = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 464c114.87 0 208-93.13 208-208S370.87 48 256 48 48 141.13 48 256s93.13 208 208 208zm-91.36-212.65a16 16 0 0122.63-.09L240 303.58V170a16 16 0 0132 0v133.58l52.73-52.32A16 16 0 11347.27 274l-80 79.39a16 16 0 01-22.54 0l-80-79.39a16 16 0 01-.09-22.65z"></path>
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 48C141.13 48 48 141.13 48 256s93.13 208 208 208 208-93.13 208-208S370.87 48 256 48zm91.36 212.65a16 16 0 01-22.63.09L272 208.42V342a16 16 0 01-32 0V208.42l-52.73 52.32A16 16 0 11164.73 238l80-79.39a16 16 0 0122.54 0l80 79.39a16 16 0 01.09 22.65z"></path>
    </svg>
  );
};

// TO DO any
class ShopProducts extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      noMoreProducts: false,
      nameValue: "",
      isShowMenu: false,
      loading: false,
    };
  }
  VIEW_MODE_GRID = "GRID";
  VIEW_MODE_LIST = "LIST";
  itemsLimit = SHOP_PRODUCTS_LIMIT;

  // getQueries = () => {
  //   let name = new URLSearchParams(this.props.location.search).get("name");
  // };
  componentDidMount() {
    // this.getQueries();
    this.getShopInfo();
    this.getFirstProducts();
  }
  componentDidUpdate(prev: Readonly<any>) {
    if (prev.filter.sort !== this.props.filter.sort) {
      this.getFirstProducts();
    }
  }

  changeMode = (view_mode: string) => {
    const { actions } = this.props;
    actions.changeViewMode(view_mode);
  };

  fetchMoreProducts = () => {
    const { _page, actions, shopitems_type, min_cost, max_cost } = this.props;

    let offset = _page + this.itemsLimit;
    let sendParams: any = {
      start: offset,
      name: this.state.nameValue,
      shop: this.props?.singleShop?.shopInfo?.id,
      sortBy: this.props.filter.sort,
      type: shopitems_type,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
    };
    this.setState({ loading: true });
    // actions.setShopProductsLoadingAction(true);
    allProductsApi
      .getAll(sendParams)
      .then((res: any) => res.json())
      .then((result: any) => {
        actions.fetchMoreSearchProducts({
          items: result.products,
          _page: offset,
        });
      })
      .catch((error) => {
        console.log(error);
        actions.setShopProductsErrorAction(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => this.setState({ loading: false }));
  };

  getShopInfo = () => {
    const { actions } = this.props;
    const { id } = this.props.match.params;

    actions.setShopsLoadingAction(true);

    ShopApi.getSingleShop(id)
      .then((result: any) => {
        if (result.data) {
          if (result.data.items.length < 10) {
            this.setState({ noMoreProducts: true });
          }
          actions.getSingleShop(result.data);
        }
      })
      .catch((error) => {
        actions.setShopsErrorAction(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => actions.setShopsLoadingAction(false));
  };

  getFirstProducts = () => {
    const { actions, min_cost, max_cost, shopitems_type } = this.props;
    const { id } = this.props.match.params;

    let sendParams: any = {
      start: 0,
      sortBy: this.props.filter.sort,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
      shop: id,
      type: shopitems_type,
      name: this.state.nameValue,
    };

    actions.setShopProductsLoadingAction(true);
    allProductsApi
      .getAll(sendParams)
      .then((res: any) => res.json())
      .then((result: any) => {
        actions.fetchSearchProducts({
          items: result.products,
          _page: 0,
        });
      })
      .catch((error) => {
        console.log(error);
        actions.setShopProductsErrorAction(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => actions.setShopProductsLoadingAction(false));
  };

  findShopProducts = async (e: any) => {
    e.preventDefault();
    let { nameValue: name, min_cost, max_cost } = this.state;
    const { actions, shopitems_type } = this.props;

    let params = {
      name,
      shop: this.props?.singleShop?.shopInfo?.id,
      sortBy: this.props.filter.sort,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
      type: shopitems_type,
    };

    actions.setShopProductsLoadingAction(true);
    allProductsApi
      .getAll(params)
      .then((res: any) => res.json())
      .then((result: any) => {
        actions.fetchSearchProducts({
          items: result.products,
          _page: 0,
        });
      })
      .catch((error) => {
        console.log(error);
        actions.setShopProductsErrorAction(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => actions.setShopProductsLoadingAction(false));
  };

  render = () => {
    const {
      view_mode,
      filter,
      actions,
      min_cost,
      max_cost,
      is_modal_open,
      loading,
      error,
      shopProductsLoading,
      shopProductsError,
    } = this.props;
    const { cartState } = this.props;
    const { items: shopProducts, shopInfo } = this.props.singleShop;
    const { actionsShoppingCart } = this.props;

    const handleFilter = (name: string) => {
      actions.setFilterAction(name);
    };

    const getFilterClass = (a: string, b: string) => {
      if (filter.sort === a || filter.sort === b) return "active";
      return "";
    };

    const getFilterIcons = (a: string, b: string) => {
      if (filter.sort === a || filter.sort === b) {
        if (filter.type === true) {
          return <ArrowDown />;
        }
        return <ArrowUp />;
      }
    };
    const handleChangeMinCost = (value: number | string) => {
      const num = Number(value);
      if (value <= 0) actions.setMinCostAction("");
      if (num) actions.setMinCostAction(num);
    };

    const handleChangeMaxCost = (value: number | string) => {
      const num = Number(value);
      if (value <= 0) actions.setMaxCostAction("");
      if (num) actions.setMaxCostAction(num);
    };

    const getProdsWithMinMaxCost = () => {
      if (!min_cost || !max_cost) {
        this.getFirstProducts();
      } else if (min_cost > 0 && max_cost > 0 && min_cost <= max_cost) {
        this.getFirstProducts();
      } else {
        toast("Iltimos Narxlarni to'g'ri kiriting");
      }
    };

    asideMenuListClickItem();
    return (
      <div>
        <HelmetComponent title="HAMMOL" />
        <Header />
        <MyToaster />
        {is_modal_open ? (
          <FilterCategoryModal searchQuery={this.state.nameValue} />
        ) : null}
        <div className="container mt-2">
          <div className="show-filter-options p-2">
            <div className="shop__search w-100">
              <h2 className="mb-1">Поиск по этому магазину</h2>
              <form
                className="header__search-form"
                onSubmit={(e) => this.findShopProducts(e)}
              >
                <input
                  className="header__search-input"
                  id="inp_name"
                  type="text"
                  placeholder="Поиск по этому магазину"
                  value={this.state.nameValue}
                  onChange={(e) =>
                    this.setState({
                      nameValue: e.target.value,
                    })
                  }
                />
                <button className="header__search-btn" type="submit">
                  {Messages.t("Search")}
                </button>
              </form>
            </div>
          </div>
        </div>
        <ShopInfo error={error} loading={loading} data={shopInfo} />
        <main className="main shop-products">
          {/* <Hero /> */}
          <section className="products-section">
            <div className="container">
              <div className="show-filter-options">
                <ul className="show-filter">
                  {/*TO DO*/}
                  <li
                    className={`show-filter__item ${getFilterClass(
                      "priceAsc",
                      "priceDesc"
                    )}`}
                    onClick={() => handleFilter("price")}
                  >
                    {Messages.t("BY_COST")}{" "}
                    {getFilterIcons("priceAsc", "priceDesc")}
                  </li>
                  <li
                    className={`show-filter__item ${getFilterClass(
                      "nameAsc",
                      "nameDesc"
                    )}`}
                    onClick={() => handleFilter("name")}
                  >
                    {Messages.t("BY_NAME")}{" "}
                    {getFilterIcons("nameAsc", "nameDesc")}
                  </li>
                  <li
                    className={`show-filter__item ${getFilterClass(
                      "soldAsc",
                      "soldDesc"
                    )}`}
                    onClick={() => handleFilter("sold")}
                  >
                    {Messages.t("BY_SALES")}{" "}
                    {getFilterIcons("soldAsc", "soldDesc")}
                  </li>
                  <li
                    className={`show-filter__item ${getFilterClass(
                      "ratingAsc",
                      "ratingDesc"
                    )}`}
                    onClick={() => handleFilter("rating")}
                  >
                    {Messages.t("BY_RATING")}{" "}
                    {getFilterIcons("ratingAsc", "ratingDesc")}
                  </li>
                </ul>
                <ul className="mobile__filter-list">
                  <li className="header__menu-item" id="langToggle">
                    <span
                      className="header__menu-link"
                      onClick={(k) => {
                        this.setState({
                          showMenu: !this.state.showMenu,
                        });
                      }}
                    >
                      {Messages.t("FILTER")}
                      <svg
                        className="icon change-lang-arrow"
                        width="5"
                        height="8"
                      >
                        <use href="static/img/icons/sprite/sprite.svg#arrow" />
                      </svg>
                    </span>
                    <div
                      className={
                        this.state.showMenu
                          ? "header__lang header__lang--open"
                          : "header__lang"
                      }
                    >
                      <ul className="header__lang-list">
                        <li className="header__lang-item">
                          <span
                            className="header__lang-link"
                            onClick={() => handleFilter("price")}
                          >
                            {Messages.t("BY_COST")}{" "}
                            {getFilterIcons("priceAsc", "priceDesc")}
                          </span>
                        </li>
                        <li className="header__lang-item">
                          <span
                            className="header__lang-link"
                            onClick={() => handleFilter("name")}
                          >
                            {Messages.t("BY_NAME")}{" "}
                            {getFilterIcons("nameAsc", "nameDesc")}
                          </span>
                        </li>
                        <li className="header__lang-item">
                          <span
                            className="header__lang-link"
                            onClick={() => handleFilter("sold")}
                          >
                            {Messages.t("BY_SALES")}{" "}
                            {getFilterIcons("soldAsc", "soldDesc")}
                          </span>
                        </li>
                        <li className="header__lang-item">
                          <span
                            className="header__lang-link"
                            onClick={() => handleFilter("rating")}
                          >
                            {Messages.t("BY_RATING")}
                            {getFilterIcons("ratingAsc", "ratingDesc")}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                {is_modal_open ? null : (
                  <div className="filterBy__category">
                    <FilterCategoryActivate />
                  </div>
                )}

                <div className="view-filter">
                  <div
                    className={
                      view_mode === this.VIEW_MODE_LIST
                        ? "view-filter__option view_mode view-filter__option--selected"
                        : "view-filter__option view_mode"
                    }
                  >
                    <svg
                      className="icon"
                      width="20"
                      height="20"
                      onClick={this.changeMode.bind(null, this.VIEW_MODE_LIST)}
                    >
                      <use href="static/img/icons/sprite/sprite.svg#list-view" />
                    </svg>
                  </div>
                  <div
                    className={
                      view_mode === this.VIEW_MODE_GRID
                        ? "view-filter__option view_mode view-filter__option--selected"
                        : "view-filter__option view_mode"
                    }
                  >
                    <svg
                      className="icon"
                      width="20"
                      height="20"
                      onClick={this.changeMode.bind(null, this.VIEW_MODE_GRID)}
                    >
                      <use href="static/img/icons/sprite/sprite.svg#grid-view" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="show-filter-options">
                <div className="filter__by__cost">
                  <div className="filter__bycost-input">
                    <label>Минимальная цена</label>
                    <input
                      type="number"
                      placeholder="0.00"
                      value={min_cost}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeMinCost(e.target.value)
                      }
                    />
                  </div>
                  <div className="filter__bycost-input">
                    <label>Максимальная цена</label>
                    <input
                      type="number"
                      placeholder="0.00"
                      value={max_cost}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeMaxCost(e.target.value)
                      }
                    />
                  </div>
                  <button
                    className="filter__bycost-send"
                    onClick={() => getProdsWithMinMaxCost()}
                  >
                    Отправить
                  </button>
                </div>
              </div>
              <div className="top-products">
                {shopProductsLoading ? (
                  view_mode === this.VIEW_MODE_GRID ? (
                    <div className="top-products__row">
                      <SkeletonLoader number={15} />
                    </div>
                  ) : (
                    <ul className="shop-products__list">
                      <SkeletonLoaderList number={15} />
                    </ul>
                  )
                ) : shopProductsError ? (
                  <Error />
                ) : (
                  <>
                    {view_mode === this.VIEW_MODE_GRID ? (
                      <div className="top-products__row">
                        {shopProducts && shopProducts.length > 0 ? (
                          shopProducts.map((item: Product, i: number) => {
                            item = productShopAndBasket(item, cartState);
                            return (
                              <ProductAsItem
                                key={item.id}
                                item={item}
                                actions={{
                                  ...actionsShoppingCart,
                                }}
                                from={ROUTE_PATHS.SHOP_PRODUCTS}
                              />
                            );
                          })
                        ) : (
                          <ProductsNotFound />
                        )}
                      </div>
                    ) : (
                      <ul className="shop-products__list">
                        {shopProducts && shopProducts.length > 0 ? (
                          shopProducts.map((item: Product) => {
                            item = productShopAndBasket(item, cartState);
                            return (
                              <ProductAsListItem
                                key={item.id}
                                item={item}
                                actions={{
                                  ...actionsShoppingCart,
                                }}
                                from={ROUTE_PATHS.ALL_PRODUCTS}
                              />
                            );
                          })
                        ) : (
                          <ProductsNotFound />
                        )}
                      </ul>
                    )}
                    {
                      /* shopProducts &&
                !(shopProducts.length < 10 * this.props._page) && */
                      // !this.state.noMoreProducts &&
                      // shopProducts?.length &&
                      // shopProducts?.length >= 10 ?
                    }
                    {shopProducts &&
                      shopProducts.length >= this.props._page + 10 &&
                      (this.state.loading ? (
                        <div className="top-products__more">
                          <div>
                            <div className="lds-dual-ring"></div>
                          </div>
                          {Messages.t("Show_more_products_loading")}
                        </div>
                      ) : (
                        <div
                          className="top-products__more"
                          onClick={this.fetchMoreProducts}
                        >
                          {Messages.t("Show_more_products")}
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  };
}

export default withRouter(ShopProducts);
