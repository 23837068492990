import React, {Component} from 'react';

class Bubbles extends Component {
    render = () => {
        return (
            <div className="bubbles-container">
                <div className="bubble bubble--green"></div>
                <div className="bubble bubble--azure"></div>
                <div className="bubble bubble--blue bubble--blue-1"></div>
                <div className="bubble bubble--blue bubble--blue-2"></div>
            </div>
        );
    }
}

export default Bubbles;
