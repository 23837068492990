import React from "react";
import Messages from "../../common/messages/Messages";

const Error = ({
  message,
  className,
  ...props
}: {
  message?: string;
  className?: string;
}) => {
  return (
    <div className={`no-products ${className ? className : ""}`} {...props}>
      <img style={{ height: 250 }} src="/static/img/error.png" alt="" />
      <h4>{message ? message : Messages.t("FETCHING_ERROR")}</h4>
    </div>
  );
};

export default Error;
