import React, { FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useHistory, useParams } from "react-router-dom";
import noteApi from "../common/api/NoteApi";
import Messages from "../common/messages/Messages";
import Header from "../header/Header";
import MainLoader from "../_temp/hammolLoader/MainLoader";
import MyToaster from "../_temp/toaster/MyToaster";

interface IItem {
  id: number;
  title: string;
  list_id: number;
}
interface INote {
  id: number;
  title: string;
}
interface INoteList {
  id: number;
  title: string;
  items: IItem[];
  note_id: number;
}
interface INoteUsers {
  id: number;
  note_id: number;
  user_id: string;
  user_name: string;
  user_phone: string;
}

const SingleNote: FC = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const [note, setNote] = useState<INote | null>(null);
  const [noteUsers, setNoteUsers] = useState<INoteUsers[] | null>(null);
  const [noteList, setNoteList] = useState<INoteList[]>([]);
  const [editListTitle, setListTitle] = useState<number | null>(null);
  const [editItemTitle, setItemTitle] = useState<number | null>(null);
  const [currentList, setCurrentList] = useState<INoteList | null>(null);
  const [currentItem, setCurrentItem] = useState<IItem | null>(null);
  const [loading, setLoading] = useState(false);
  const getNoteListAndUsersHandler = (id: number) => {
    setLoading(true);
    noteApi
      .getNoteList(id)
      .then(({ note, lists }) => {
        setNote(note[0]);
        setNoteList(lists);
      })
      .finally(() => setLoading(false));
    noteApi.getNoteUsers(+id).then(({ items }) => setNoteUsers(items));
  };
  const deleteNoteUserHandler = (id: number) => {
    if (window.confirm("Rostdan ham o`chirmoqchimisiz?")) {
      if (note) {
        noteApi.deleteNoteUser(note.id, id).then(({ id: userId }) => {
          if (noteUsers) {
            setNoteUsers(noteUsers.filter((user) => user.id != userId));
          } else {
            toast.error("Foydalanuvchi topilmadi.");
          }
        });
      }
    }
  };

  const createNoteListHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let currentTarget = e.currentTarget as typeof e.currentTarget & {
      elements: { name: { value: string } };
    };
    let title = currentTarget.elements.name.value;
    if (title) {
      if (note) {
        noteApi.createNoteList(note.id, title).then(({ items }) => {
          setNoteList((prev) => [...prev, items[0]]);
          currentTarget.elements.name.value = "";
        });
      }
    } else {
      toast("✍ " + Messages.t("ADD_LIST_NAME"));
    }
  };

  const deleteNoteListHandler = async (id: number) => {
    if (note) {
      noteApi
        .deleteNoteList(note.id, id)
        .then(({ id }) =>
          setNoteList((prev) => prev.filter((list) => list.id !== id))
        );
    }
  };

  const editListHandler = async (e: any, id: number) => {
    if (note && (e.key === "Enter" || e.keyCode === 13 || e.which === 13)) {
      noteApi.editNoteList(note.id, id, e.target.value).then(({ items }) => {
        setNoteList((prev) =>
          prev.map((list) => {
            if (list.id === items[0].id) {
              list.title = items[0].title;
            }
            return list;
          })
        );
        setListTitle(null);
      });
    }
  };

  const addProductHandler = async (
    e: React.FormEvent<HTMLFormElement>,
    list: INoteList
  ) => {
    e.preventDefault();
    let currentTarget = e.currentTarget as typeof e.currentTarget & {
      elements: { name: { value: string } };
    };
    let title = currentTarget.elements.name.value;
    if (title) {
      noteApi.addProduct(list.note_id, list.id, title).then(({ items }) => {
        setNoteList((prev) =>
          prev.map((list) => {
            if (items[0].list_id === list.id) {
              if (!list.items) list.items = [];
              list.items.push(items[0]);
            }
            return list;
          })
        );
        currentTarget.elements.name.value = "";
      });
    } else {
      toast("✍ " + Messages.t("ADD_ITEM_NAME"));
    }
  };

  const deleteNoteListItemHandler = async (listId: number, itemId: number) => {
    if (note) {
      noteApi.deleteNoteListItem(note.id, listId, itemId).then(() =>
        setNoteList((prev) =>
          prev.map((list) => {
            if (list.id === listId) {
              list.items = list.items.filter((item) => item.id !== itemId);
              return list;
            }
            return list;
          })
        )
      );
    }
  };

  const editItemHandler = async (e: any, listId: number, itemId: number) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      let title = e.target.value;
      if (title) {
        if (note) {
          noteApi.editNoteListItem(note.id, listId, itemId, title).then(() => {
            setNoteList((prev) =>
              prev.map((list) => {
                if (list.id === listId) {
                  list.items = list.items.map((item) => {
                    if (item.id === itemId) {
                      item.title = title;
                    }
                    return item;
                  });
                  return list;
                }
                return list;
              })
            );
            setItemTitle(null);
          });
        }
      } else {
        toast(Messages.t("✍ " + "ADD_ITEM_NAME"));
      }
    }
  };

  useEffect(() => {
    getNoteListAndUsersHandler(+id);
  }, []);

  const dragStartHandler = (e: any, list: INoteList, item: IItem) => {
    setCurrentList(list);
    setCurrentItem(item);
  };

  const dropHandler = (e: any, list: INoteList) => {
    e.preventDefault();
    if (e.target.className === "notes__list-body-item")
      e.target.style.boxShadow = `0px 5px 10px rgb(0 0 0 / 15%)`;
    if (note && currentList && currentItem) {
      if (currentList.id !== list.id) {
        const currentIndex = currentList.items.indexOf(currentItem);
        currentList.items.splice(currentIndex, 1);
        if (!list.items) {
          list.items = [];
        }
        list.items.push(currentItem);
        setNoteList(
          noteList.map((noteList) => {
            if (noteList.id === list.id) {
              return list;
            }
            if (noteList.id === currentList.id) {
              return currentList;
            }
            return noteList;
          })
        );
        noteApi.editItemId(note.id, currentList.id, currentItem.id, list.id);
      }
    }
  };

  const dragOverHandler = (e: any) => {
    e.preventDefault();
    if (e.target.className === "notes__list-body-item")
      e.target.style.boxShadow = `2px 4px 10px gray`;
  };

  const dragLeaveHandler = (e: any) => {
    e.preventDefault();
    e.target.style.boxShadow = `0px 5px 10px rgb(0 0 0 / 15%)`;
  };

  return (
    <>
      <Header />
      <MyToaster />
      <section className="py-2">
        <div className="container notes">
          {loading ? (
            <div className="notes__main-wrapper">
              <MainLoader />
            </div>
          ) : (
            !!note && (
              <div className="notes__main-wrapper">
                <Link className="breadcrumbs__back" to="/notes">
                  <svg className="breadcrumbs__back-arrow" width="5" height="8">
                    <use href="static/img/icons/sprite/sprite.svg#arrow" />
                  </svg>
                  Вернуться назад
                </Link>
                <ul className="notes__main-users">
                  {noteUsers?.map((user) => (
                    <li key={user.id}>
                      <h4>
                        Foydalanuvchi ismi: <span>{user.user_name}</span>
                      </h4>
                      <h4>
                        Telefon raqam: <span>{user.user_phone}</span>
                      </h4>
                      <img
                        src="static/img/trash.png"
                        alt="trash.png"
                        className="notes__list-trash notes__icon"
                        onClick={() => deleteNoteUserHandler(user.id)}
                      />
                    </li>
                  ))}
                </ul>
                <h2 className="notes__main-title">{note.title}</h2>
                <div>
                  {noteList.length ? (
                    <div className="notes__main">
                      {noteList.map((list) => (
                        <div key={list.id} className="notes__list">
                          <div className="notes__list-header">
                            {editListTitle === list.id ? (
                              <input
                                defaultValue={list.title}
                                onKeyPress={(e) => editListHandler(e, list.id)}
                                name="name"
                                type="text"
                                autoFocus
                              />
                            ) : (
                              <h2>{list.title}</h2>
                            )}
                            <img
                              src="static/img/trash.png"
                              alt="trash.png"
                              className="notes__list-trash notes__icon"
                              onClick={() => deleteNoteListHandler(list.id)}
                            />
                            <img
                              onClick={() => setListTitle(list.id)}
                              src="static/img/edit.png"
                              alt="trash.png"
                              className="notes__list-edit notes__icon"
                            />
                          </div>
                          {list.items && !!list.items.length ? (
                            <ul
                              className="notes__list-body"
                              onDragOver={(e) => dragOverHandler(e)}
                              onDrop={(e) => dropHandler(e, list)}
                            >
                              {list.items.map((item) =>
                                item.id === editItemTitle ? (
                                  <input
                                    key={item.id}
                                    defaultValue={item.title}
                                    onKeyPress={(e) =>
                                      editItemHandler(e, list.id, item.id)
                                    }
                                    name="name"
                                    type="text"
                                    autoFocus
                                  />
                                ) : (
                                  <li
                                    className="notes__list-body-item"
                                    key={item.id}
                                    draggable={true}
                                    onDragStart={(e) =>
                                      dragStartHandler(e, list, item)
                                    }
                                    onDragLeave={(e) => dragLeaveHandler(e)}
                                    onDragEnd={(e) => dragLeaveHandler(e)}
                                  >
                                    {item.title}
                                    <img
                                      onClick={() =>
                                        history.push(
                                          `/allproducts?name=${item.title}`
                                        )
                                      }
                                      src="static/img/search.png"
                                      alt="trash.png"
                                      className="notes__list-item__search notes__icon"
                                    />
                                    <img
                                      onClick={() => setItemTitle(item.id)}
                                      src="static/img/edit.png"
                                      alt="trash.png"
                                      className="notes__list-item__edit notes__icon"
                                    />
                                    <img
                                      src="static/img/trash.png"
                                      alt="trash.png"
                                      className="notes__list-item__trash notes__icon"
                                      onClick={() =>
                                        deleteNoteListItemHandler(
                                          list.id,
                                          item.id
                                        )
                                      }
                                    />
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <div
                              className="notes__list-body"
                              onDragOver={(e) => dragOverHandler(e)}
                              onDrop={(e) => dropHandler(e, list)}
                            >
                              <h3
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                }}
                              >
                                {Messages.t("NO_ITEMS")}
                              </h3>
                            </div>
                          )}
                          <form
                            className="notes__list-footer"
                            onSubmit={(e) => addProductHandler(e, list)}
                          >
                            <input
                              name="name"
                              type="text"
                              placeholder="Input..."
                            />
                            <button type="submit">
                              <img src="/static/img/send.png" alt="send.png" />
                            </button>
                          </form>
                        </div>
                      ))}
                      <form
                        onSubmit={createNoteListHandler}
                        className="notes__main-create__btn"
                      >
                        <input
                          type="text"
                          name="name"
                          placeholder={Messages.t("ADD_LIST_PLACEHOLDER")}
                        />
                        <button type="submit">{Messages.t("ADD_LIST")}</button>
                      </form>
                    </div>
                  ) : (
                    <h2
                      style={{
                        textAlign: "center",
                        fontSize: 30,
                        margin: "3rem",
                      }}
                    >
                      {Messages.t("NO_LISTS")}
                    </h2>
                  )}
                  {!noteList.length && (
                    <form
                      onSubmit={createNoteListHandler}
                      className="notes__main-create__btn"
                    >
                      <input
                        type="text"
                        name="name"
                        placeholder="Добавьте еще одну колонку"
                      />
                      <button type="submit">Добавить список</button>
                    </form>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </>
  );
};

export default SingleNote;
