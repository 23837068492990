import React, {Component} from 'react';

class FooterMain extends Component {
    render = () => {
        return (
            <div className="footer__main">
                <div className="footer__app">
                    <div className="footer__app-img">
                        <img src="static/img/telephone.png" alt=""/>
                    </div>
                    <div className="footer__app-text">
                        <span className="footer__app-title">Удобства в смартфонах</span>
                        <p className="footer__app-desc">Загрузите наши мобильные приложения и получайте бонусы</p>
                        <div className="footer__app-stores">
                            <a className="footer__app-store" href="#/">
                                <span className="footer__app-from">ЗАГРУЗИТЬ ИЗ</span>
                                <span className="footer__app-store-name"> APP STORE</span>
                            </a>
                            <a className="footer__app-store" href="#/">
                                <span className="footer__app-from">ЗАГРУЗИТЬ ИЗ</span>
                                <span className="footer__app-store-name">GOOGLE PLAY</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer__text-wrap">
                    <ul className="footer__menu">
                        <li className="footer__menu-item"><a className="footer__menu-link" href="#/">О нас</a></li>
                        <li className="footer__menu-item">
                            <a className="footer__menu-link" href="#/">
                                Доставка и оплата
                            </a>
                        </li>
                        {/*TO DO*/}
                        {/*<li className="footer__menu-item"><a className="footer__menu-link" href="#/">Поддержка</a></li>*/}
                        {/*<li className="footer__menu-item"><a className="footer__tel" href="tel:998712000002">(998) 71*/}
                        {/*    200 00 02</a>*/}
                        {/*    <svg className="icon footer__tel-icon" width="17" height="17">*/}
                        {/*        <use href="static/img/icons/sprite/sprite.svg#support-center"></use>*/}
                        {/*    </svg>*/}
                        {/*</li>*/}
                    </ul>
                    <p className="footer__text">....</p>
                    <div className="footer__social">
                        <a className="footer__social-item" href="#/">
                            <svg className="icon" width="30" height="30">
                                <use href="static/img/icons/sprite/sprite.svg#fb"/>
                            </svg>
                        </a>
                        <a className="footer__social-item" href="#/">
                            <svg className="icon" width="30" height="30">
                                <use href="static/img/icons/sprite/sprite.svg#telegram"/>
                            </svg>
                        </a>
                        <a className="footer__social-item" href="#/">
                            <svg className="icon" width="30" height="30">
                                <use href="static/img/icons/sprite/sprite.svg#twitter"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default FooterMain;
