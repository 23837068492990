import * as types from "./constants";
import { Shop } from "../../common/types/Shop";
import {
  FetchShopsAcn,
  FetchSingleShopAcn,
  ISearchProductsMoreFetchAcn,
  IChangeViewModeAcn,
  ISetSortBy,
  ISetShopsSortBy,
  ISearchProductsFetchAcn,
  IReceiveMoreShops,
  ISetFilter,
  ISetMinCost,
  ISetMaxCost,
  ISetCategoryModalOpen,
  IChangeShopItemsType,
  ISetShopCategoryFilterHeaders,
  ISetShopCategoryFilterItems,
  ISetCategoryParams,
  ISetShopsLoading,
  ISetShopsError,
  ISetShopProductsLoading,
  ISetShopProductsError,
} from "./actionsTypes";

export function getShopsList(shops: Shop[]): FetchShopsAcn {
  return { type: types.RECEIVE_ALL_SHOPS, shops };
}

export function getSingleShop(singleShop: any): FetchSingleShopAcn {
  return { type: types.RECEIVE_SINGLE_SHOP, singleShop };
}

export function fetchMoreSearchProducts({
  items,
  _page,
}: {
  items: any[];
  _page: number;
}): ISearchProductsMoreFetchAcn {
  return {
    type: types.RECEIVE_MORE_SHOP_PRODUCTS,
    payload: { items, _page },
  };
}

export function fetchSearchProducts({
  items,
  _page,
}: {
  items: any[];
  _page: number;
}): ISearchProductsFetchAcn {
  return {
    type: types.RECEIVE_SEARCH_PRODUCTS,
    payload: { items, _page },
  };
}

export function changeViewMode(view_mode: string): IChangeViewModeAcn {
  return { type: types.CHANGE_VIEW_MODE, view_mode };
}

export function setSortBy(sort_by: string): ISetSortBy {
  return { type: types.SET_SORT_BY, sort_by };
}

export function setShopsSortBy(sort_by: string): ISetShopsSortBy {
  return { type: types.SET_SHOPS_SORT_BY, sort_by };
}

export function receiveMoreShops(shops: any, page: number): IReceiveMoreShops {
  return { type: types.RECEIVE_MORE_SHOPS, shops, page };
}

export function setFilterAction(payload: string): ISetFilter {
  return {
    type: types.SET_FILTER,
    payload,
  };
}

export function setMinCostAction(payload: string): ISetMinCost {
  return {
    type: types.SET_MIN_COST,
    payload,
  };
}

export function setMaxCostAction(payload: string): ISetMaxCost {
  return {
    type: types.SET_MAX_COST,
    payload,
  };
}
export function setCategoryModalOpen(payload: boolean): ISetCategoryModalOpen {
  return {
    type: types.SET_CATEGORYMODAL_OPEN,
    payload,
  };
}

export const changeShopItemsType = (payload: any): IChangeShopItemsType => ({
  type: types.CHANGE_SHOPITEMS_TYPE,
  payload,
});

export const setShopCategoryFilterHeaders = (
  payload: any
): ISetShopCategoryFilterHeaders => ({
  type: types.SET_SHOPCATEGORY_HEADER,
  payload,
});

export const setShopCategoryFilterItems = (
  payload: any
): ISetShopCategoryFilterItems => ({
  type: types.SET_SHOPCATEGORY_ITEMS,
  payload,
});

export function setCategoryParams(payload: any): ISetCategoryParams {
  return {
    type: types.SET_CATEGORY_PARAMS,
    payload,
  };
}

export function setShopsLoadingAction(loading: boolean): ISetShopsLoading {
  return {
    type: types.SET_SHOPS_LOADING,
    loading,
  };
}

export function setShopsErrorAction(error: string | null): ISetShopsError {
  return {
    type: types.SET_SHOPS_ERROR,
    error,
  };
}

export function setShopProductsLoadingAction(
  loading: boolean
): ISetShopProductsLoading {
  return {
    type: types.SET_SHOP_PRODUCTS_LOADING,
    loading,
  };
}

export function setShopProductsErrorAction(
  error: string | null
): ISetShopProductsError {
  return {
    type: types.SET_SHOP_PRODUCTS_ERROR,
    error,
  };
}
