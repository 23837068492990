import React, { Component } from 'react';
import TopProducts from '../topproducts/containers/TopProducts';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { bindActionCreators } from 'redux';
import * as AllProductsActions from '../allproducts/store/actions';
import { connect } from 'react-redux';
import { SITE_HOME_ALIAS } from '../common/constants/constants';

import Hero from '../hero/Hero';
import Recommendations from '../_temp/recommendations/Recommendations';
import HelmetComponent from '../_temp/helmet';
// import Collection from "../collection/Collection";

class Home extends Component<any, any> {
  componentDidMount(): void {
    const { actions } = this.props;
    actions.saveSearchParams('');
  }

  render = () => {
    return (
      <div>
        <HelmetComponent title="HAMMOL" />
        <Header />
        <main className="main">
          <Hero />
          <TopProducts from={SITE_HOME_ALIAS} />
          <Recommendations />
          {/* <Collection/> */}
        </main>
        <Footer />
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AllProductsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Home);
