import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RemoveFavoriteProduct from "../../../_temp/favorites/store/actions";
import { Product } from "../../types/Product";

type Props = {
  id: string;
  actions?: any;
  dispatch?: any;
  _page?: number;
};

type State = {
  isFavorite: string;
};

type IWishList = string[];

class ProductFavorite extends Component<Props, State> {
  // DO NOT TOUCH, Error was when using state so I refactor code

  constructor(props: any) {
    super(props);
    this.state = {
      isFavorite: this.getFavoriteStateSprite(this.props.id),
    };
  }

  getWishList = (): IWishList => {
    const localWishList = localStorage.getItem("wishList");
    return localWishList ? JSON.parse(localWishList) : [];
  };

  addToStorage = (id: string) => {
    const { actions } = this.props;

    let wishlist: IWishList = this.getWishList();
    let favorite_state_sprite: string;

    let foundElIndex = wishlist.findIndex((element: string) => element === id);
    if (foundElIndex !== -1) {
      wishlist.splice(foundElIndex, 1);
      favorite_state_sprite = "sprite/sprite.svg#heart";
      actions.removeFavoriteProduct(id);
    } else {
      wishlist.push(id);
      favorite_state_sprite = "sprite/sprite.svg#redheart";
    }

    localStorage.setItem("wishList", JSON.stringify(wishlist));
    this.setState({ isFavorite: favorite_state_sprite });
  };

  getFavoriteStateSprite = (id: string) => {
    let LocalWish: IWishList = this.getWishList();
    let favorite_state_sprite: string = "sprite/sprite.svg#heart";

    if (
      LocalWish.length !== 0 &&
      LocalWish.find((element: string) => element === id)
    ) {
      favorite_state_sprite = "sprite/sprite.svg#redheart";
    }

    return favorite_state_sprite;
  };

  render = () => {
    const { id } = this.props;
    let favorite_state_sprite: any = this.state.isFavorite;
    return (
      <div
        onClick={(el) => {
          el.preventDefault();
          this.addToStorage(id);
        }}
        className="product-card__favorite"
      >
        <svg className="icon" width="20" height="20">
          <use href={`static/img/icons/${favorite_state_sprite}`} />
        </svg>
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  actions: bindActionCreators(RemoveFavoriteProduct, dispatch),
  ...ownProps,
});

const mapStateToProps = (state: any) => ({
  _page: state.TopProducts._page,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductFavorite);
