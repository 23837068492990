import { AbstractApi } from './AbstractApi';

class AllProductsApi extends AbstractApi {
  getAll = (params: any = {}) => {
    const PATH = 'commodity/product/all';
    return this.fetchApi(
      PATH,
      this.REQUEST_METHOD_POST,
      params,
    );
  };
}
const allProductsApi = new AllProductsApi();
export default allProductsApi;
