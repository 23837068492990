import React, { Component } from "react";
import "./LoginForm.css";
import PhoneNumber from "../../common/components/PhoneNumber";
import authApi from "../../common/api/AuthApi";
import Password from "../../common/components/Password";
import Messages from "../../common/messages/Messages";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

class LoginForm extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      phoneNumber: "",
      password: "",
      btnDisabled: true,
      count: 60,
    };
  }

  timer: any;

  login = (el: any) => {
    el.preventDefault();
    let params = {
      login: "998" + this.state.phoneNumber,
      password: this.state.password,
    };
    const { actions } = this.props;

    authApi
      .login(params)
      .then((res: any) => {
        return res.json();
      })
      .then((result: any) => {
        localStorage.setItem("token", result.token);
        actions.authLoginAcn(result.user, result.token);
        localStorage.removeItem("guestId");

        this.props.history.push("/");
      })
      .catch((error) => {
        console.log(error);
        toast.error(Messages.t("Wrong_data"));
      });
  };

  setPhoneNumber = (phoneNumber: string) => {
    this.setState({
      ...this.state,
      phoneNumber: phoneNumber,
      btnDisabled: !(this.state.password && phoneNumber),
    });
  };

  setPassword = (password: string) => {
    this.setState({
      ...this.state,
      password: password,
      btnDisabled: !(this.state.phoneNumber && password),
    });
  };

  render = () => {
    return (
      <>
        <MyToaster />
        <form className="form" autoComplete="off">
          <div className="form__input-wrap mb-2">
            <PhoneNumber setPhoneNumber={this.setPhoneNumber} />
          </div>
          <div className="form__input-wrap mb-4">
            <Password setPassword={this.setPassword} />
          </div>
          <div className="form__login-problem form__login-problem__restore">
            <Link to="/restore">{Messages.t("Forgot_your_password?")} </Link>
          </div>
          <button
            className={`form__button ${
              this.state.btnDisabled ? "disabled" : ""
            }`}
            type="submit"
            disabled={this.state.btnDisabled}
            onClick={(el) => {
              this.login(el);
            }}
          >
            {Messages.t("LOGIN")}
          </button>
          <div className="cart-aside__reg">
            <Link to="/registration">{Messages.t("Registration")}</Link>
            <br />
          </div>
        </form>
      </>
    );
  };
}

export default withRouter(LoginForm);
