import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as AuthActions from "../../login/store/actions";
import * as ShopActions from "../../shop/store/actions";
import * as CategoryActions from "../../category/store/actions";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import * as LangActions from "../../common/messages/store/actions";
import App from "../components/App";

window.localStorage.removeItem('statusRead');

// TO DO any
const mapStateToProps = (state: any) => ({
    user: state.user,
    userReg: state.userReg,
    authState: state.authState,
    phoneReg: state.phoneReg,
    authUser: state.AuthReducer,
    lang: state.LangReducer.lang,
    messages: state.LangReducer.messages
});

const mapDispatchToProps = (dispatch: any) => {
    return ({
        actionsAuth: bindActionCreators(AuthActions, dispatch),
        actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
        actionsShop: bindActionCreators(ShopActions, dispatch),
        actionsCategory: bindActionCreators(CategoryActions, dispatch),
        actionsLang: bindActionCreators(LangActions, dispatch)
    });
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
