import { AbstractApi } from "./AbstractApi";

class AuthApi extends AbstractApi {
  login = (params: any = {}) => {
    const PATH = "auth/v1/signin/";
    this.header_mode = this.HEADER_MODE_API_KEY;
    return this.fetchApi(PATH, this.REQUEST_METHOD_POST, params);
  };
}

const authApi = new AuthApi();
export default authApi;
