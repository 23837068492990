import React from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from './Chat';

const ChatMessage = ({ message, isLast }) => {
  const user = useSelector((state) => state?.AuthReducer);
  const guestId = localStorage.getItem('guestId');

  return (
    <>
      <div
        className={`chat__message ${
          message.sender === getUserInfo(user, guestId)
            ? 'chat__message_out'
            : 'chat__message_in'
        }`}
        id={isLast ? 'last' : ''}>
        {message.msg}
      </div>
      {message.sender !== getUserInfo(user, guestId) && (
        <span className={`chatname`}>{message.name}</span>
      )}
    </>
  );
};

export default ChatMessage;
