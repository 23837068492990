import { AbstractApi } from './AbstractApi';

class FavoriteProductsApi extends AbstractApi {
  getAll = (params: string) => {
    const PATH = `commodity/product/${params}`;
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET);
  };
}
const favoriteProductsApi = new FavoriteProductsApi();
export default favoriteProductsApi;
