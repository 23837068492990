import React, {Component} from 'react';
import './MobileApp.css';


class MobileApp extends Component {
    render = () => {
        return (
            <div className="app-section">
                <div className="app">
                    <div className="app__image"><img src="static/img/reg-app.png" alt=""/></div>

                    <span className="app__title app__title_fsize">Удобства в смартфонах</span>
                    <p className="app__desc">Загрузите наши мобильные приложения и получайте бонусы</p>
                    <div className="app__stores-wrap">
                        <div className="app__store">
                            <img src="static/img/icons/appstore.svg" alt=""/>
                            <a href="#/"> </a>
                        </div>
                        <div className="app__store">
                            <img src="static/img/icons/playmarket.svg" alt=""/>
                            <a href="#/"> </a>
                        </div>
                    </div>
                    <div className="site-copyright">©Hammol.uz - {(new Date()).getFullYear()}</div>
                </div>

            </div>
        );
    }
}

export default MobileApp;
