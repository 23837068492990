import {
  CHANGE_VIEW_MODE,
  DEFAULT_VIEW_MODE,
  FIRST_PAGE,
  LAST_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  RECEIVE_ALL_PRODUCTS,
  RECEIVE_MORE_PRODUCTS,
  SAVE_SEARCH_PARAMS,
  SAVE_SEARCH_PARAMS_CATEGORY,
  SELECTED_PAGE,
  SET_ALLPRODUCTS_LOADING,
  SET_ALL_PRODUCTS_ERROR,
  SET_FILTER,
  SET_MAX_COST,
  SET_MIN_COST,
} from "./constants";
import { IAllProductsState } from "./types";
import { AllProductsActions } from "./actionsTypes";
import { CategoryItem } from "../../common/types/Category";

function setFilter(state: IAllProductsState, key: string) {
  switch (key) {
    case "name":
      return {
        ...state,
        filter: {
          sort: state.filter.type ? "nameAsc" : "nameDesc",
          type: !state.filter.type,
        },
      };
    case "rating":
      return {
        ...state,
        filter: {
          sort: state.filter.type ? "ratingAsc" : "ratingDesc",
          type: !state.filter.type,
        },
      };
    case "price":
      return {
        ...state,
        filter: {
          sort: state.filter.type ? "priceAsc" : "priceDesc",
          type: !state.filter.type,
        },
      };
    case "sold":
      return {
        ...state,
        filter: {
          sort: state.filter.type ? "soldAsc" : "soldDesc",
          type: !state.filter.type,
        },
      };
    default:
      return state;
  }
}

const initialState: IAllProductsState = {
  _page: 1,
  products: [],
  view_mode: DEFAULT_VIEW_MODE,
  name: "",
  category: {} as CategoryItem,
  isThereAgain: false,
  filter: {
    sort: "ratingDesc",
    type: true,
  },
  minCost: "",
  maxCost: "",
  loading: false,
  error: null,
};

export default function AllProductsReducer(
  state: any = initialState,
  action: AllProductsActions
): IAllProductsState {
  switch (action.type) {
    case RECEIVE_ALL_PRODUCTS:
      return {
        ...state,
        products: action.data.products,
        isThereAgain: action.data.isThereAgain,
        error: null,
      };
    case RECEIVE_MORE_PRODUCTS:
      return {
        ...state,
        _page: action.data._page,
        products: [...state.products, ...action.data.products],
        isThereAgain: action.data.isThereAgain,
        error: null,
      };
    case FIRST_PAGE:
      return {
        ...state,
        _page: 1,
        products: action.products,
      };
    case SAVE_SEARCH_PARAMS_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case LAST_PAGE:
      return {
        ...state,
        _page: action.data._page,
        products: action.data.products,
      };

    case NEXT_PAGE:
      return {
        ...state,
        _page: action.data._page,
        products: action.data.products,
      };

    case PREVIOUS_PAGE:
      return {
        ...state,
        _page: action.data._page,
        products: action.data.products,
      };

    case SELECTED_PAGE:
      return {
        ...state,
        _page: action.data._page,
        products: action.data.products,
      };

    case CHANGE_VIEW_MODE:
      return {
        ...state,
        view_mode: action.view_mode,
      };

    case SAVE_SEARCH_PARAMS:
      return {
        ...state,
        name: action.name,
      };

    case SET_FILTER:
      return setFilter(state, action.payload);
    case SET_MIN_COST:
      return {
        ...state,
        minCost: action.payload,
      };
    case SET_MAX_COST:
      return {
        ...state,
        maxCost: action.payload,
      };
    case SET_ALLPRODUCTS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ALL_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
