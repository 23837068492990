import React, { Component } from 'react';
import MobileApp from '../common/components/static/MobileApp';
import Bubbles from '../common/components/static/Bubbles';
import { bindActionCreators } from 'redux';
import * as AuthActions from '../login/store/actions';
import { connect } from 'react-redux';
import RestoreForm from './component/RestoreForm';
import HelmetComponent from '../_temp/helmet';
import Messages from '../common/messages/Messages';

class Restore extends Component<any, any> {
  constructor(props: any) {
    super(props);

    const { authUser } = this.props;
    if (authUser) {
      window.location.href = '#/';
    }
  }

  render = () => {
    const { actions } = this.props;
    return (
      <section className="reg-section">
        <HelmetComponent title={'Восстановление пароля'} />
        <div className="container">
          <div className="reg-container">
            <div className="form-section">
              <a className="back-btn" href="#/">
                <svg className="icon" width="6" height="10">
                  <use href="static/img/icons/sprite/sprite.svg#arrow" />
                </svg>
                Вернуться назад
              </a>
              <div className="form-wrap">
                <div className="form-title-wrap">
                  <span className="form-title">
                    Восстановление пароля
                  </span>
                </div>

                <RestoreForm actions={actions} />
              </div>
            </div>
            <MobileApp />
          </div>
          <Bubbles />
        </div>
      </section>
    );
  };
}

const mapStateToProps = (state: any) => ({
  authUser: state.AuthReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Restore);
