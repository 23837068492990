import axios from "axios";

const main_url = "https://hammol.uz/payment/";
let token: any = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: main_url,
  headers: {
    "Content-type": "application/json",
    token: token,
  },
});

axiosInstance.interceptors.request.use(
  function (config: any) {
    config.headers.token = localStorage.getItem("token");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
