import { BuyProductsActions } from "./actionTypes";
import {
  RESET_BUY_PRODUCTS,
  SET_ACTIVE_SHOP,
  SET_DRIVER_INFO,
} from "./constants";
import { IBuyProducts } from "./types";

const initialState: IBuyProducts = {
  activeShop: null,
  address: "",
  driverInfo: {},
};

export default function BuyProductsReducer(
  state: any = initialState,
  action: BuyProductsActions
): IBuyProducts {
  switch (action.type) {
    case SET_ACTIVE_SHOP:
      return {
        ...state,
        activeShop: action.activeShop,
      };
    case SET_DRIVER_INFO:
      return {
        ...state,
        driverInfo: action.driverInfo,
      };
    case RESET_BUY_PRODUCTS:
      return { activeShop: null, address: "", driverInfo: {} };
    default:
      return state;
  }
}
