export const RECEIVE_ALL_SHOPS = "RECEIVE_ALL_SHOPS";
export const RECEIVE_SINGLE_SHOP = "RECEIVE_SINGLE_SHOP";
export const RECEIVE_MORE_SHOP_PRODUCTS = "RECEIVE_MORE_SHOP_PRODUCTS";
export const CHANGE_VIEW_MODE = `CHANGE_VIEW_MODE`;
export const CHANGE_SHOPITEMS_TYPE = "CHANGE_SHOPITEMS_TYPE";
export const SET_SHOPS_LOADING = "SET_SHOPS_LOADING";
export const SET_SHOPS_ERROR = "SET_SHOPS_ERROR";

export const DEFAULT_VIEW_MODE = "GRID";

export const SORT_BY_RATING = "SORT_BY_RATING";
export const SORT_BY_PRICE = "SORT_BY_PRICE";
export const SORT_BY_DATE = "SORT_BY_DATE";
export const SORT_BY_POPULARITY = "SORT_BY_POPULARITY";

export const DEFAULT_SORT_BY = SORT_BY_PRICE;
export const SET_SORT_BY = "SORT_BY_PRICE";

export const SHOPS_SORT_BY_OPEN = "open";
export const SHOPS_SORT_BY_CLOSE = "close";
export const SHOPS_SORT_BY_RATING_ASC = "ratingAsc";
export const SHOPS_SORT_BY_RATING_DESC = "ratingDesc";
export const SHOPS_SORT_BY_NAME_ASC = "nameAsc";
export const SHOPS_SORT_BY_NAME_DESC = "nameDesc";

export const SHOPS_DEFAULT_SORT_BY = SHOPS_SORT_BY_OPEN;
export const SET_SHOPS_SORT_BY = "SET_SHOPS_SORT_BY";
export const RECEIVE_MORE_SHOPS = "RECEIVE_MORE_SHOPS";
export const RECEIVE_SEARCH_PRODUCTS = "RECEIVE_SEARCH_PRODUCTS";
export const SET_SHOP_PRODUCTS_LOADING = "SET_SHOP_PRODUCTS_LOADING";
export const SET_SHOP_PRODUCTS_ERROR = "SET_SHOP_PRODUCTS_ERROR";

export const SET_FILTER = "SET_SHOP_PRODUCTS_FILTER";
export const SET_MIN_COST = "SET_MIN_COST";
export const SET_MAX_COST = "SET_MAX_COST";

export const SET_CATEGORYMODAL_OPEN = "SET_CATEGORYMODAL_OPEN";
export const SET_SHOPCATEGORY_HEADER = "SET_SHOPCATEGORY_HEADER";
export const SET_SHOPCATEGORY_ITEMS = "SET_SHOPCATEGORY_ITEMS";
export const SET_CATEGORY_PARAMS = "SET_CATEGORY_PARAMS";
