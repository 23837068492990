import { applyMiddleware, combineReducers, createStore } from "redux";

import LangReducer from "../../common/messages/store/reducers";
import AllProductsReducer from "../../allproducts/store/reducers";
import TopProducts from "../../topproducts/store/reducers";
import RecommendedProducts from "../../_temp/recommendations/store/reducers";
import FavoriteProducts from "../../_temp/favorites/store/reducers";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import AuthReducer from "../../login/store/reducers";
import RegistrationReducer from "../../registration/store/reducers";
import ShopReducer from "../../shop/store/reducers";
import CategoryReducer from "../../category/store/reducers";
import HeaderReducer from "../../header/store/reducers";
import ShoppingCartReducer from "../../shoppingcart/store/reducers";
import ProductDetailReducer from "../../product_detail/store/reducers";
import OrderReducer from "../../order/store/reducers";
import BuyProductsReducer from "../../buy/store/reducers";
import MarketReducer from "../../markets/store/reducers";

export const rootReducer = combineReducers({
  LangReducer,
  OrderReducer,
  AuthReducer,
  ShopReducer,
  ShoppingCartReducer,
  ProductDetailReducer,
  AllProductsReducer,
  HeaderReducer,
  RegistrationReducer,
  TopProducts,
  RecommendedProducts,
  FavoriteProducts,
  CategoryReducer,
  BuyProductsReducer,
  MarketReducer,
});

const configureStore = () => {
  const middleware = [thunk];
  const middleWareEnhancer = applyMiddleware(...middleware);

  return createStore(rootReducer, composeWithDevTools(middleWareEnhancer));
};

const store = configureStore();

export default store;
