import config from "../config/config";
import fetch from "cross-fetch";
import statusApi from "./StatusApi";
import { AUTH_USER } from "../../login/store/constants";
import {
  DEFAULT_LANG,
  LANG_ALIAS,
  HEADER_WITH_API_KEY,
} from "../constants/constants";
import { delayMS } from "../helpers/Helper";

// TO DO refactor
export class AbstractApi {
  protected REQUEST_METHOD_GET = "GET";
  protected REQUEST_METHOD_DELETE = "DELETE";
  protected REQUEST_METHOD_POST = "POST";
  protected REQUEST_METHOD_VIEW = "VIEW";
  protected REQUEST_METHOD_PATCH = "PATCH";
  protected REQUEST_METHOD_PUT = "PUT";

  protected HEADER_MODE_API_KEY = "API_KEY";
  protected HEADER_MODE_TOKEN = "TOKEN";

  header_mode: string = this.HEADER_MODE_TOKEN;
  token = "";
  host = config.apiUrl;

  constructor() {
    this.initToken();
  }

  initToken = () => {
    this.token = localStorage.getItem("token") || "";
    this.host = localStorage.getItem("host") || config.apiUrl;
  };

  generateSendParamsFrom(params: any = {}) {
    return Object.keys(params)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");
  }

  async generateRequestOptions(method: string, params: any) {
    this.initToken();

    if (this.header_mode === this.HEADER_MODE_API_KEY) {
      return {
        method: method,
        headers: HEADER_WITH_API_KEY,
        body: this.generateSendParamsFrom(params),
      };
    }

    if (!this.token || !localStorage.getItem("statusRead")) {
      localStorage.setItem("statusRead", "true");

      await delayMS(300);

      await statusApi
        .status()
        .then((result) => result.json())
        .then((result) => {
          // localStorage.setItem("token", result.token);
          localStorage.setItem("host", "https://hammol.uz:443/api/");

          if (Object.keys(result.user).length === 0) {
            localStorage.removeItem(AUTH_USER);
          }
        })
        .then(() => {
          this.token = localStorage.getItem("token") || "";
          this.host = localStorage.getItem("host") || config.apiUrl;
        });
    }
    if (method == this.REQUEST_METHOD_GET) {
      return {
        method: "GET",

        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.token,
          lang: localStorage.getItem(LANG_ALIAS) || DEFAULT_LANG,
        },
      };
    } else {
      return {
        method: method,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: this.token,
          lang: localStorage.getItem(LANG_ALIAS) || DEFAULT_LANG,
        },
        body: this.generateSendParamsFrom(params),
      };
    }
  }

  fetchApi = async (
    path: string,
    method: string = this.REQUEST_METHOD_GET,
    params: any = {},
    withoutSlash: boolean = false
  ) => {
    let reqOptions;

    await this.generateRequestOptions(method, params).then((options) => {
      reqOptions = options;
    });

    await delayMS(300);
    if (withoutSlash) {
      return await fetch(this.host + path + "/", reqOptions);
    } else {
      return await fetch(this.host + path, reqOptions);
    }
  };
}
