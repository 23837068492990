import React, {Component} from 'react';

type Props = {
    rating: number
};

class ProductRating extends Component<Props> {
    render = () => {
        const {rating} = this.props;

        let ratings = [];
        let i: number;
        for (i = 1; i <= 5; i++) {
            ratings.push(
                <span key={i}
                      className={(i <= rating ? 'product-card__rating-star product-card__rating-star--active' : 'product-card__rating-star')}
                >
                </span>
            );
        }

        return (
            <div className="product-card__rating">
                {ratings}
            </div>
        );
    };
}

export default ProductRating;
