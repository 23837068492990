import React, { Component } from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import favoriteProductsApi from "../../common/api/FavoriteProductsApi";
import { IFavoriteProductsProps, IFavoriteProductsState } from "./store/types";
import { bindActionCreators } from "redux";
import * as RecommendedProductsActions from "../recommendations/store/actions";
import * as FavouriteProductsActions from "./store/actions";
import { connect } from "react-redux";
import ProductAsItem from "../../common/components/ProductAsItem";
import { Product } from "../../common/types/Product";
import Messages from "../../common/messages/Messages";
import HelmetComponent from "../helmet";
// import Recommendations from "../recommendations/Recommendations";

import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import { productShopAndBasket } from "../../common/helpers/Helper";
import MainLoader from "../hammolLoader/MainLoader";

class Favorites extends Component<
  IFavoriteProductsProps,
  IFavoriteProductsState
> {
  constructor(props: IFavoriteProductsProps) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount(): void {
    (async () => {
      const { actions } = this.props;
      const productsId = JSON.parse(localStorage.getItem("wishList") || "[]");

      let products: any = [];
      if (Array.isArray(productsId) && productsId.length) {
        const fetchFavorite = async (id: string) => {
          const res = await favoriteProductsApi.getAll(id);
          const result = await res.json();
          products.push({ ...result, image: result.images[0].src });
        };

        this.setState({ loading: true });
        for (const id of productsId) {
          await fetchFavorite(id);
        }

        actions.FavouriteProductsActions.fetchFavoriteProducts(products);
        this.setState({ loading: false });
      }
    })();
  }

  render = () => {
    const { actions, cartProducts, products } = this.props;
    console.log(this.state.loading);
    return (
      <div>
        <HelmetComponent title={Messages.t("favourites")} />
        <Header />
        <main className="main">
          <section className="favorites-section">
            <div className="container">
              <div className="order-page-top">
                <div className="cart-top favorite-top-bg">
                  <span className="cart-top__title">Избранные</span>
                  <span className="cart-top__store">Магазин</span>
                  <div className="cart-top__store-img">
                    <img src="static/img/texnomart-logo.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="favorite-products mt-3">
                {this.state.loading ? (
                  <MainLoader />
                ) : (
                  <div className="favorite-products__row">
                    {products && products.length ? (
                      products.map((item: Product) => {
                        item = productShopAndBasket(item, cartProducts);
                        return (
                          <ProductAsItem
                            actions={{
                              ...actions.actionsShoppingCart,
                            }}
                            from=""
                            key={item.id}
                            item={item}
                          />
                        );
                      })
                    ) : (
                      <div className="empty-favorite-cart p-3 center">
                        <img
                          src="/static/img/favorite-cart.png"
                          alt="favorite cart"
                        />
                        <h2>{Messages.t("NO_WISHES_DATA")}</h2>
                        <p>{Messages.t("ADD_WISHES_DATA")}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
          {/*<Recommendations/>*/}
        </main>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state: any) => ({
  products: state.FavoriteProducts.products,
  shops: state.ShopReducer.shops,
  cartProducts: state.ShoppingCartReducer.shops,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    RecommendedProductsActions: bindActionCreators(
      RecommendedProductsActions,
      dispatch
    ),
    FavouriteProductsActions: bindActionCreators(
      FavouriteProductsActions,
      dispatch
    ),
    actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
