import React, { Component } from "react";
import { formInputActiveClassManage } from "../helpers/Helper";
import "./css/StaticValue.css";
import Messages from "../messages/Messages";
import { IMaskInput } from "react-imask";

class PhoneNumber extends Component<any, any> {
  phoneNumberPattern = /^\(?([0-9]{2})\)?([0-9]{3})([0-9]{4})$/;

  constructor(props: any) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      phoneInpFocused: false,
    };
  }

  phoneNumberChange = (value: any) => {
    const { setPhoneNumber, message } = this.props;
    let input = this.inputRef.current.element;

    if (value.match(this.phoneNumberPattern)) {
      input.classList.remove("not-valid");
      setPhoneNumber(value);
    } else {
      input.classList.add("not-valid");
      setPhoneNumber("");
    }
  };

  phoneNumberFocus = (el: any) => {
    el.preventDefault();
    this.setState({
      ...this.state,
      phoneInpFocused: true,
    });
  };

  phoneNumberBlur = (el: any) => {
    el.preventDefault();
    if (el.target.value === "") {
      this.setState({
        ...this.state,
        phoneInpFocused: false,
      });
    }
  };

  componentDidMount(): void {
    formInputActiveClassManage();
  }

  render = () => {
    const self = this;
    return (
      <>
        <IMaskInput
          ref={this.inputRef}
          className="form__input my_form__input"
          type="text"
          onFocus={this.phoneNumberFocus}
          onBlur={this.phoneNumberBlur}
          autoComplete="off"
          mask="(00) 000 00 00"
          onAccept={(value, mask) => this.phoneNumberChange(mask.unmaskedValue)}
        />
        <label className="form__label">
          {Messages.t(this.props.message || "Your_phone_number")}
        </label>
        <label
          className={
            self.state.phoneInpFocused
              ? "static-value "
              : "static-value hide-static-value"
          }
        >
          +998
        </label>
      </>
    );
  };
}

export default PhoneNumber;
