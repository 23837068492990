import React, { Component } from "react";
import { Product } from "../types/Product";
import config from "../config/config";

type Props = {
  // item: Product,
  item: any;
  actions: any;
  quantity?: number;
};

class ShoppingCartProductAsItem extends Component<Props> {
  hostUrl = config.hostUrl;

  render = () => {
    const { item, quantity, actions } = this.props;

    return (
      <div className={"tooltip-product"}>
        <div className="tooltip-product__img">
          <>
            {item.product.images ? (
              <>
                {item.product.images[0].src &&
                item.product.images[0].src.includes("temp/") ? (
                  <img
                    src={this.hostUrl + item.product.images[0].src}
                    alt="rasm"
                  />
                ) : (
                  <>
                    {typeof item.product.images === "object" ? (
                      <img
                        src={
                          "data:image/png;base64, " + item.product.images[0].src
                        }
                        alt="rasm"
                      />
                    ) : (
                      <img
                        src={"https://hammol.uz/temp/noimage2.png"}
                        alt="rasm"
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {item.product.image[0].src &&
                item.product.image[0].src.includes("temp/") ? (
                  <img
                    src={this.hostUrl + item.product.image[0].src}
                    alt="rasm"
                  />
                ) : (
                  <>
                    {typeof item.product.image === "object" ? (
                      <img
                        src={
                          "data:image/png;base64, " + item.product.image[0].src
                        }
                        alt="rasm"
                      />
                    ) : (
                      <img
                        src={"https://hammol.uz/temp/noimage2.png"}
                        alt="rasm"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>

        <div className="tooltip-product__content">
          <span className="tooltip-product__store">
            {/*{item.market && item.market.site_url &&*/}
            {/*<a className="product-card__market" href={item.market.site_url}>{item.market.name}</a>*/}
            {/*}*/}
          </span>
          <h1 className="tooltip-product__summary">{item.product.name}</h1>
          <span className="tooltip-product__cost">
            {item.product.cost}
            <span>{item.product.currency}</span>
          </span>
          <span className="tooltip-product__cost">/ {quantity}</span>
        </div>

        <span
          className="tooltip-product__delete"
          onClick={(el) => {
            el.preventDefault();
            actions.deleteFromShoppingCart(item.product, item);
          }}
        >
          <svg className="icon" width="15" height="15">
            <use href="static/img/icons/sprite/sprite.svg#close" />
          </svg>
        </span>
      </div>
    );
  };
}

export default ShoppingCartProductAsItem;
