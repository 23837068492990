import { AbstractApi } from "./AbstractApi";

class SignUpApi extends AbstractApi {
  /**
   * Get seek for getting grant for next step of registration
   * @param params
   */
  seek = (params: any = {}) => {
    const PATH = "auth/v1/signin/recover";
    return this.fetchApi(PATH, this.REQUEST_METHOD_POST, params);
  };

  /**
   * Send phone number and request to get SMS code
   * @param params
   */
  take = (params: any = {}) => {
    const PATH = "signup/take";
    return this.fetchApi(PATH, this.REQUEST_METHOD_POST, params);
  };

  /**
   * Send phone number and request to get SMS code
   * @param params
   */
  lose = (params: any = {}) => {
    const PATH = "signup/lose";
    return this.fetchApi(PATH, this.REQUEST_METHOD_POST, params);
  };

  /**
   * Get seek for getting grant for next step of registration
   * @param params
   */
  signUpKeep = (params: any = {}) => {
    const PATH = "signup/keep";
    return this.fetchApi(PATH, this.REQUEST_METHOD_POST, params);
  };

  vary = (params: any = {}) => {
    const PATH = "auth/v1/signup/";
    return this.fetchApi(PATH, this.REQUEST_METHOD_PUT, params);
  };

  confirm = (params: any = {}) => {
    const PATH = "auth/v1/signin/confirm";
    return this.fetchApi(PATH, this.REQUEST_METHOD_POST, params);
  };
}

const signUpApi = new SignUpApi();
export default signUpApi;
