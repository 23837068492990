import React, { Component } from "react";
import signUpApi from "../../common/api/SignUpApi";
import { bindActionCreators } from "redux";
import * as AuthActions from "../store/actions";
import { connect } from "react-redux";
import { ALREADY_EXISTS } from "../../common/constants/constants";
import { Link } from "react-router-dom";
import PhoneNumber from "../../common/components/PhoneNumber";
import Messages from "../../common/messages/Messages";
import axios from "axios";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

class Step1 extends Component<any, any> {
  phoneNumberPattern = /^\(?([0-9]{2})\)?([0-9]{3})([0-9]{4})$/;

  constructor(props: any) {
    super(props);

    this.sendPhoneNumber = this.sendPhoneNumber.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.state = {
      phoneNumber: "",
      btnDisabled: true,
    };
  }

  sendPhoneNumber(e: any) {
    e.preventDefault();

    const { grant = "", actions } = this.props;

    const params = {
      phone: "998" + this.state.phoneNumber,
      //   grant: grant,
    };

    axios
      .post("https://hammol.uz/api/auth/v1/signup/step1", params)
      .then((result: any) => {
        // if (result.error) {
        //   console.log(result.error.response.data.msg);
        //   switch (result.error.response.data.msg) {
        //     case ALREADY_EXISTS:
        //       alert(Messages.t("This_number_registered_try_log_in"));
        //       window.location.href = "#/login";
        //       break;
        //     default:
        //       window.location.href = "/";
        //   }
        // } else {
        //   // actions.authSignUpTakeAcn(params, result.sent);
        // }
        actions.authSetStepToAcn(2);
        // TO DO else not catch
      })
      .catch((error) => {
        console.log(error.response.data.msg);
        switch (error.response.data.msg) {
          case ALREADY_EXISTS:
            toast(Messages.t("This_number_registered_try_log_in"));
            window.location.href = "#/login";
            break;
          default:
            window.location.href = "/";
        }
      });
  }

  setPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length > 0) {
      this.setState((state: any, props: any) => {
        return {
          phoneNumber: phoneNumber,
          btnDisabled: !phoneNumber,
        };
      });
    }
  };

  render = () => {
    const { btnDisabled } = this.state;
    return (
      <>
        <MyToaster />
        <div className="form-section">
          <Link to="/" className="back-btn">
            <svg className="icon" width="6" height="10">
              <use href="static/img/icons/sprite/sprite.svg#arrow" />
            </svg>
            {Messages.t("Come_back")}
          </Link>
          <div className="form-wrap">
            <div className="form-title-wrap">
              <span className="form-title">{Messages.t("Registration")}</span>
              <span className="form-subtitle">
                {Messages.t("You_can_go_back_to_-")}
                <Link to="/login">{Messages.t("-Entrance")}</Link>
              </span>
            </div>
            <form className="form">
              <div className="form__input-wrap mb-3">
                <PhoneNumber
                  setPhoneNumber={this.setPhoneNumber}
                  setState={this.setState}
                />
              </div>
              <p className="form__copy-text">
                {Messages.t("Approve_licence_")}
                <a href="/"> {Messages.t("_licence_condition")} </a>
                {Messages.t("_condition_site")}
              </p>
              <button
                className={`form__button ${btnDisabled ? "disabled" : ""}`}
                type="submit"
                disabled={btnDisabled}
                onClick={this.sendPhoneNumber}
              >
                {" "}
                {Messages.t("Get_SMS_code_for_registration")}
              </button>
            </form>
          </div>
        </div>
      </>
    );
  };
}

const mapStateToProps = (state: any) => ({
  grant: state.RegistrationReducer.grant,
  phoneNumber: "",
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
