import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CategoryActions from "../store/actions";
import { saveSearchParamsCategory } from "../../allproducts/store/actions";
import Categories from "../components/Categories";

// TO DO any
const mapStateToProps = (state: any) => {
  return {
    categoryLayer: state.CategoryReducer.categoryLayer,
    subCategoriesParent: state.CategoryReducer.subCategoriesParent,
    subCategories: state.CategoryReducer.subCategories,
    opensidebar: state.CategoryReducer.opensidebar,
    openSubCategory: state.CategoryReducer.openSubCategory,
    name: state.AllProductsReducer.name,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actionsCategory: bindActionCreators(CategoryActions, dispatch),
  saveCategoryToStore: bindActionCreators(saveSearchParamsCategory, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
