import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SingleMarketActions from "../store/actions";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import * as ShopsActions from "../../shop/store/actions";
import { setAllProductsErrorAction } from "../../allproducts/store/actions";
import MarketShops from "../components/MarketShops";

// TO DO any
const mapStateToProps = (state: any) => {
  return {
    _page: state.ShopReducer._page,
    view_mode: state.MarketReducer.view_mode,
    cartState: state.ShoppingCartReducer.shops,
    activeShop: state.ShoppingCartReducer.shop,
    messages: state.LangReducer.messages,
    singleMarket: state.MarketReducer.singleMarket,
    shops: state.ShopReducer.shops,
    products: state.ShopReducer.singleShop.items,
    filter: state.ShopReducer.filter,
    shops_sort_by: state.ShopReducer.shops_sort_by,
    min_cost: state.AllProductsReducer.minCost,
    max_cost: state.AllProductsReducer.maxCost,
    marketCategoryModalOpen: state.MarketReducer.marketCategoryModalOpen,
    marketItemsType: state.MarketReducer.marketItemsType,
    loading: state.MarketReducer.loading,
    shopsError: state.ShopReducer.error,
    shopsLoading: state.MarketReducer.shopsLoading,
    productsLoading: state.MarketReducer.productsLoading,
    error: state.MarketReducer.error,
    productsError: state.AllProductsReducer.error,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(SingleMarketActions, dispatch),
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
  actionsShops: bindActionCreators(ShopsActions, dispatch),
  setAllProductsErrorAction: bindActionCreators(
    setAllProductsErrorAction,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketShops);
