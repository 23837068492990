import axios from 'axios';
import { AbstractApi } from './AbstractApi';

export const DEFAULT_LANG = 'uz';
export const LANG_ALIAS = 'app_lang';

class TopProductsApi extends AbstractApi {
  getAll = async (params: any = {}) => {
    const PATH = 'commodity/product/top';

    return this.fetchApi(
      PATH,
      this.REQUEST_METHOD_POST,
      params,
    );
  };
}

const topProductsApi = new TopProductsApi();
export default topProductsApi;
