import React from "react";
import "./Buy.css";
import UserCard from "../../common/components/UserCard";
import Messages from "../../common/messages/Messages";
import axios from "../../common/api/BuyingApi";
import driverAxios from "../../common/api/DriverApi";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

interface BuyWithSavedCardProps {
  activeShop: any;
  driverInfo: any;
}

function BuyWithSaved({ activeShop, driverInfo }: BuyWithSavedCardProps) {
  // const BASE_URL: string = 'https://hammol.uz';
  // let token: any = localStorage.getItem('token');
  const order = async (el: any) => {
    el.preventDefault();
    el.stopPropagation();
    let placeLocal: any = localStorage.getItem("userAddress");
    let placeJson = JSON.parse(placeLocal);
    let userCardLocal: any = localStorage.getItem("activeCardObj");
    let userCardJson = JSON.parse(userCardLocal);

    if (
      placeJson &&
      driverInfo.price > 0 &&
      placeJson.fullName &&
      userCardJson.id
    ) {
      try {
        let productsLocal: any = localStorage.getItem("shoppingCart");
        let productsJson: any = JSON.parse(productsLocal);

        let card = {
          id: userCardJson.id,
          num: userCardJson.num,
          exp: userCardJson.exp,
          name: userCardJson.name,
          balance: userCardJson.balance,
        };

        let cardId = {
          card_id: card.id,
        };

        let address = `Kirish:${
          placeJson.porch ? placeJson.porch : "Yo'q"
        } | Qavat:${placeJson.floor ? placeJson.floor : "Yo'q"} | Xonadon:${
          placeJson.flat ? placeJson.flat : "Yo'q"
        } | ${placeJson.address}`.slice(0, 115);

        let place = {
          address: address,
          route_id: driverInfo.id,
          point: placeJson.point,
        };

        let products: any[] = [];

        if (activeShop === null) {
          productsJson.shops.forEach((shop: any) => {
            shop.products.forEach((product: any) => {
              products.push({
                uuid: product.id,
                count: product.quantity,
                shop: product.shop_id,
              });
            });
          });
        } else {
          productsJson.shops[activeShop].products.forEach((product: any) => {
            products.push({
              uuid: product.id,
              count: product.quantity,
              shop: product.shop_id,
            });
          });
        }

        let data: any = await axios
          .post(`order/v2/instant`, {
            items: products,
            place: place,
            card_info: cardId,
          })
          .then((response: any) => response.json())
          .then((data) => {
            return data;
          });
        if (!data.error) {
          window.location.href = "#/my-orders";
        } else {
          toast.error(data.msg);
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    } else {
      toast.error("Адрес или данные вашей карты не правильны");
    }
  };

  const findDriver = async (e: any, index: number | null) => {
    let elem = e.target;
    let placeLocal: any = localStorage.getItem("userAddress");
    let placeJson = JSON.parse(placeLocal);
    let shoppingCart: any = localStorage.getItem("shoppingCart");
    let shoppingCartObj = JSON.parse(shoppingCart);

    let shops: any = [];
    if (index === null) {
      shoppingCartObj.shops.map((shop: any) => {
        shops.push(shop.id);
      });
    } else {
      shops.push(shoppingCartObj.shops[index].id);
    }
    if (!placeJson.point.length) {
      return toast("Imtimos manzilni belgilang!");
    }
    if (!shops.length) {
      return toast("Savatchangizda mahsulotlar mavjud emas!");
    }
    elem.disabled = true;

    if (driverInfo?.id) {
      try {
        const response = await driverAxios
          .get(
            `route/confirm/${driverInfo.id}?address=${
              placeJson.address
            } | Kirish:${
              placeJson.porch ? placeJson.porch : "Yo'q\n"
            } | Qavat:${
              placeJson.floor ? placeJson.floor : "Yo'q\n"
            } | Xonadon:${placeJson.flat ? placeJson.flat : "Yo'q\n"}`
          )
          .then(({ data }: any) => data);
        if (+response.id === +driverInfo.id) {
          let stop = false;
          const checkTimer = setTimeout(() => {
            if (!driverInfo?.driver) {
              stop = true;
            } else {
              clearTimeout(checkTimer);
            }
          }, 60000);

          const timer = setInterval(async () => {
            if (!stop) {
              try {
                const response = await driverAxios
                  .get(`route/${driverInfo?.id}`)
                  .then(({ data }: any) => data.result);
                if (response.driver) {
                  // this.props.actionsBuying.setDriverInfo(response);
                  // this.setState({ showBtn: true });
                  clearInterval(timer);
                  clearTimeout(checkTimer);
                  // if (this.state.methodOfPayment === "withCard") {
                  //   // order with card
                  // } else {
                  //   this.orderWithCashHandler();
                  // }
                  order(e);
                }
              } catch (error) {
                toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
                clearTimeout(checkTimer);
                clearInterval(timer);
                console.clear();
                console.info(error);
                elem.disabled = false;
                // this.setState({ showBtn: true });
              }
            } else {
              toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
              elem.disabled = false;
              // this.setState({ showBtn: true });
              clearInterval(timer);
              clearTimeout(checkTimer);
            }
          }, 5000);
        }
      } catch (error) {
        toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
        console.clear();
        console.info(error);
        elem.disabled = false;
        // this.setState({ showBtn: true });
      }
    } else {
      elem.disabled = false;
      // this.setState({ showBtn: true });
      toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
    }
  };

  return (
    <div className="instant-container">
      <MyToaster />
      <form
        onSubmit={(e) => findDriver(e, activeShop)}
        className="save-card-item"
      >
        <UserCard />
        <div className="submit-container">
          <input
            value={Messages.t("ORDER_IT")}
            className="order--submit"
            type={"submit"}
          />
        </div>
      </form>
    </div>
  );
}
export default BuyWithSaved;
