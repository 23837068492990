import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMapEvent } from "react-leaflet";
import { useHistory } from "react-router-dom";
import axios from "../../common/api/DriverApi";

import Messages from "../../common/messages/Messages";
import "leaflet/dist/leaflet.css";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

const mapState = { center: [41.311123, 69.279712], zoom: 17 };

const MyComponent = (props: any) => {
  const map: any = useMapEvent("dragend", (e) => {
    let center = e.target.getCenter();
    props.setCenter(center);
  });

  return null;
};

interface MapComponentProps {
  setDriverInfo: any;
  setShowModal: (bool: boolean) => void;
  isModalMap?: boolean;
}

const MapComponent = ({
  setDriverInfo,
  setShowModal,
  isModalMap,
}: MapComponentProps) => {
  // let [map, setMap] = useState(null)
  /*whenCreated={setMap}}*/
  const userDefinedAddress = JSON.parse(
    localStorage.getItem("userAddress") || "{}"
  );
  const [address, setAddress] = useState(
    Object.keys(userDefinedAddress).length
      ? userDefinedAddress
      : {
          address: "",
          porch: "",
          floor: "",
          flat: "",
          point: [],
          fullName: "",
          // price: 0,
        }
  );
  const [newPrice, setNewPrice] = useState(null);
  const history = useHistory();

  let getCenter = async (center: any) => {
    try {
      // let shopLat: string = "41.353401";
      // let shopLon: string = "69.351469";
      let lat: string = center.lat;
      let lon: string = center.lng;
      let userLocation: string[] = [lat, lon];
      let location = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&accept-language=uz`
      );
      let locationJson = await location.json();
      // let delivery = await fetch(
      //   `https://routing.openstreetmap.de/routed-car/route/v1/driving/${shopLon},${shopLat};${lon},${lat}?overview=false&geometries=polyline&steps=true`
      // );
      // let deliveryJson = await delivery.json();

      // let distance: number = Math.floor(deliveryJson.routes[0].distance);
      // let price: number = 0;

      // if (0 < distance && distance < 1000) {
      //   // let distanceInKm = distance / 100
      //   // let floorKm = Math.floor(distanceInKm) /10
      //   price = 5000;
      // } else if (1000 <= distance && distance < 3000) {
      //   let distanceInKm = distance / 100;
      //   let floorKm = Math.floor(distanceInKm) / 10;
      //   price = 3000 * floorKm;
      // } else if (3000 <= distance && distance < 5000) {
      //   let distanceInKm = distance / 100;
      //   let floorKm = Math.floor(distanceInKm) / 10;
      //   price = 2500 * floorKm;
      // } else if (5000 <= distance && distance < 10000) {
      //   let distanceInKm = distance / 100;
      //   let floorKm = Math.floor(distanceInKm) / 10;
      //   price = 2000 * floorKm;
      // } else if (10000 <= distance && distance < 20000) {
      //   let distanceInKm = distance / 100;
      //   let floorKm = Math.floor(distanceInKm) / 10;
      //   price = 1800 * floorKm;
      // } else if (20000 <= distance && distance < 30000) {
      //   let distanceInKm = distance / 100;
      //   let floorKm = Math.floor(distanceInKm) / 10;
      //   price = 1500 * floorKm;
      // }
      // price = Math.floor(price);

      let displayName: string = locationJson.display_name;

      let { neighbourhood, house_number, county, road } = locationJson.address;
      if (road !== undefined) {
        setAddress({
          ...address,
          address: `${county}, ${road}, ${house_number ? house_number : ""}`,
          point: userLocation,
          fullName: displayName,
          // price: price,
        });
        localStorage.removeItem("userAddress");
        localStorage.setItem(
          "userAddress",
          JSON.stringify({
            ...address,
            address: `${county}, ${road}, ${house_number ? house_number : ""}`,
            point: userLocation,
            fullName: displayName,
          })
        );
      } else if (house_number !== undefined) {
        setAddress({
          ...address,
          address: `${county}, ${neighbourhood}, ${
            house_number ? house_number : ""
          }`,
          point: userLocation,
          fullName: displayName,
          // price: price,
        });
        localStorage.removeItem("userAddress");
        localStorage.setItem(
          "userAddress",
          JSON.stringify({
            ...address,
            address: `${county}, ${road}, ${house_number ? house_number : ""}`,
            point: userLocation,
            fullName: displayName,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const changeAddress = (el: React.ChangeEvent<HTMLInputElement>) => {
    el.preventDefault();
    if (el.target.getAttribute("data-myname") === "address") {
      return;
    }
    setAddress({
      ...address,
      [el.target.getAttribute("data-myname")]: el.target.value,
    });
    localStorage.removeItem("userAddress");
    localStorage.setItem(
      "userAddress",
      JSON.stringify({
        ...address,
        [el.target.getAttribute("data-myname")]: el.target.value,
      })
    );
  };

  const getPrice = async () => {
    setNewPrice(null);
    let shoppingCart: any = localStorage.getItem("shoppingCart");
    let shoppingCartObj = JSON.parse(shoppingCart);
    if (shoppingCartObj.shops.length) {
      let shops: any = [];
      shoppingCartObj.shops.map((shop: any) => {
        shops.push(shop.id);
      });

      let newPoint = [...address.point];
      const data = { shops, location: newPoint.reverse() };
      try {
        const response = await axios
          .post("route", data)
          .then(({ data }: any) => data.result);
        setNewPrice(response?.price);
        setDriverInfo(response);
      } catch (error) {
        toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
        console.clear();
        console.info(error);
      }
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    setNewPrice(null);
    // let userAddress: any = address;
    // localStorage.removeItem("userAddress");
    // localStorage.setItem("userAddress", JSON.stringify(userAddress));
    setDriverInfo({});

    if (address.point.length) {
      getPrice();
    }
  }, [address]);
  return (
    <>
      <MyToaster />
      <div
        id="map"
        style={{
          height: 300,
          width: "100%",
          position: "relative",
        }}
      >
        <svg
          className="icon"
          width="30"
          height="30"
          style={{
            zIndex: 999,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <use href="static/img/icons/sprite/sprite.svg#map"></use>
        </svg>
        {!isModalMap && (
          <img
            style={{
              width: 32,
              height: 32,
              zIndex: 999,
              position: "absolute",
              top: 25,
              right: -5,
              transform: "translate(-50%, -50%)",
              borderRadius: 2,
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              cursor: "pointer",
            }}
            onClick={() => setShowModal(true)}
            src="/static/img/icons/resize.svg"
            alt=""
          />
        )}
        <MapContainer
          style={{
            height: "100%",
            width: "100wh",
          }}
          center={
            Object.keys(userDefinedAddress).length
              ? userDefinedAddress.point.length
                ? userDefinedAddress.point
                : mapState.center
              : mapState.center
          }
          zoom={mapState.zoom}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MyComponent setCenter={getCenter} />
        </MapContainer>
      </div>
      <div className="add-new-address__row">
        <div className="add-new-address__col">
          <div className="form__input-wrap mt-2">
            <input
              className="form__input"
              type="text"
              onChange={changeAddress}
              value={address.fullName}
              data-myname={"address"}
              maxLength={120}
            />
            <label className="form__label">
              {!address.fullName ? (
                <>{Messages.t("Street_mahalla_house")}</>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
        <div className="add-new-address__col">
          <div className="add-new-address__info">
            <div className="form__input-wrap add-new-address__input">
              <input
                value={address.porch}
                className="form__input"
                type="text"
                onChange={changeAddress}
                data-myname={"porch"}
                maxLength={2}
              />
              <label className="form__label">
                {Messages.t("Entrance_house")}
              </label>
            </div>
            <div className="form__input-wrap add-new-address__input">
              <input
                value={address.floor}
                className="form__input"
                type="text"
                onChange={changeAddress}
                data-myname={"floor"}
                maxLength={2}
              />
              <label className="form__label">{Messages.t("Floor")}</label>
            </div>
            <div className="form__input-wrap add-new-address__input">
              <input
                value={address.flat}
                className="form__input"
                type="text"
                onChange={changeAddress}
                data-myname={"flat"}
                maxLength={5}
              />
              <label className="form__label">{Messages.t("apartment")}</label>
            </div>
          </div>
        </div>
      </div>
      {newPrice && (
        <div className="add-new-address__row">
          <h1 style={{ fontSize: "24px" }}>
            {Messages.t("DELIVER-PRICE")}: {newPrice} {Messages.t("SUM")}
          </h1>
        </div>
      )}
    </>
  );
};

export default MapComponent;
