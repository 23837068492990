import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Shop} from "../../types/Shop";

type Props = {
    shop: Shop
};

class ProductShopName extends Component<Props> {
    render = () => {
        const {shop} = this.props;
        return (
            (shop && shop.name ?
                <Link className="product-card__market" to="" onClick={(el) => {
                    el.preventDefault();
                }}>{shop.name}</Link>
                : '')

        );
    };
}

export default ProductShopName;
