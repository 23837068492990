import axios from "axios";
import React, { useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Header from "../header/Header";

const NotesShare = () => {
  const location = useLocation();
  const history = useHistory();
  console.log(location.search.split("="));

  let token = location.search.split("=")[1];
  let note_id = location.search.split("=")[2];
  const fetchData = async (token: string, id: string) => {
    await axios
      .get(
        `https://hammol.uz/api/commodity/note/${id}/share/confirm?key=${token}`,
        { headers: { token: `${localStorage.getItem("token")}` } }
      )
      .then(() => history.push("/notes"))
      .catch((error) => {
        if (error.response.status == 409) {
          history.push("/notes");
        }
      });
  };
  useEffect(() => {
    fetchData(token, note_id);
  }, []);
  return (
    <>
      <Header />
      <section className="py-2">
        <div className="container notes">
          <div
            className="notes__aside-cards"
            style={{ flexDirection: "column" }}
          >
            <h2 style={{ fontSize: 36, color: "red", textAlign: "center" }}>
              Xatolik yuz berdi.
            </h2>
            <NavLink to="/" style={{ textAlign: "center", fontSize: 18 }}>
              Asosiy sahifaga qaytish
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotesShare;
