import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as AllProductsActions from "../../allproducts/store/actions";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import * as CategoryActions from "../../category/store/actions";
import * as AuthActions from "../../login/store/actions";
import { connect } from "react-redux";
import { ROUTE_PATHS } from "../../common/config/route_paths";
import { ALL_PRODUCT_ITEMS_LIMIT } from "../../common/constants/constants";
import allProductsApi from "../../common/api/AllProductsApi";
import authApi from "../../common/api/AuthApi";
import { Product } from "../../common/types/Product";
import ShoppingCartProductAsItem from "../../common/components/ShoppingCartProductAsItem";
import Messages from "../../common/messages/Messages";
import { changeSearchUrl } from "../../common/helpers/Helper";
import Categories from "../../category/containers/Categories";
import { changeModalStateAcn } from "../store/actions";
import MyAddress from "../components/MyAddress";

class TopHeader extends Component<any, any> {
  itemsLimit = ALL_PRODUCT_ITEMS_LIMIT;

  constructor(props: any) {
    super(props);

    const name = this.getName();
    const category = this.getCategory();
    this.state = { nameValue: name };

    if (window.location.hash.indexOf("#/allproducts") !== -1) {
      if (this.props.category && !this.props.category.id) {
        this.props.actions.saveSearchParamsCategory({
          id: category,
          name: "",
          count: 1,
        });
      }
      this.searchProducts(
        name,
        this.props.searchParams.category.id || category
      );
    }
  }

  getName(): string {
    let search = this.getUrlParams();
    return search.get("name") || "";
  }

  getCategory(): string {
    let search = this.getUrlParams();
    return search.get("category") || "";
  }

  getUrlParams(): URLSearchParams {
    if (!this.props.location.search) return new URLSearchParams();
    return new URLSearchParams(this.props.location.search);
  }

  searchProducts(name: string, category: string) {
    const { actions, min_cost, max_cost } = this.props;

    let sendParams: any = {
      name: name,
      type: category,
      limit: this.itemsLimit,
      start: 0,
      sortBy: this.props.searchParams.filter.sort,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
    };
    let products: Product[] = [];
    let isThereAgain: boolean = false;

    actions.saveSearchParams(name);
    // actions.saveSearchParamsCategory(category);
    allProductsApi
      .getAll(sendParams)
      .then((res: any) => res.json())
      .then((result: any) => {
        if (result.products?.length > 0) {
          products = result.products;
          isThereAgain = products.length === this.itemsLimit;
        }
        this.setFetchResult(products, isThereAgain);
      });
  }

  setFetchResult(products: Product[] | [], isThereAgain: boolean) {
    const { actions } = this.props;
    actions.fetchAllProducts(products, isThereAgain);
  }

  searchParamsChange = (event: any) => {
    this.setState({
      ...this.state,
      nameValue: event.target.value,
    });
    event.preventDefault();
  };

  findProducts = async (e: any) => {
    e.preventDefault();
    let { nameValue: name } = this.state;
    let searchUrl: any = {
      pathname: ROUTE_PATHS.ALL_PRODUCTS,
    };

    let category = this.props.searchParams.category;
    searchUrl = await changeSearchUrl(
      name,
      category.id ? category.id : "",
      searchUrl
    );

    await this.searchProducts(name, category.id ? category.id : "");
    this.props.history.push(searchUrl);
  };

  logout = () => {
    const { actionsAuth, history, modalAction } = this.props;
    actionsAuth.authLogoutAcn();
    history.push("/");
    localStorage.removeItem("guestId");
  };

  changeModalState = () => {
    const { modalAction } = this.props;

    modalAction(true);
  };

  render = () => {
    let { nameValue } = this.state;
    const {
      totalQuantity,
      products,
      actionsShoppingCart,
      shop,
      authUser,
      actions,
      opensidebar,
      categories,
      categoryActions,
      isOpenModal,
    } = this.props;

    if (!products) return;

    const allCartItems = products.reduce(
      (sum: number, shop: any) =>
        sum +
        shop.products.reduce((sum: number, product: any) => {
          return sum + product.quantity;
        }, 0),
      0
    );
    const totalCost = products.reduce(
      (sum: number, shop: any) =>
        sum +
        shop.products.reduce((sum: number, product: any) => {
          return sum + product.quantity * +product.product.cost;
        }, 0),
      0
    );

    return (
      <>
        <div
          onClick={() => categoryActions.setOpenSidebar(false)}
          className={`overlay ${opensidebar ? "show" : ""}`}
        ></div>
        <Categories categories={categories} actions={actions} />
        <div className="header__top">
          <div className="container">
            <div className="header__row">
              <div className="header__logo">
                <Link to={"/"}>
                  <img src="static/img/logo.svg" alt="" />
                </Link>
                <div
                  onClick={() => categoryActions.setOpenSidebar(!opensidebar)}
                  className="header-sidebar_svg"
                >
                  <svg>
                    <use href="static/img/icons/sprite/sprite.svg#list-view" />
                  </svg>
                  <span>{Messages.t("KATALOG")}</span>
                </div>
              </div>
              <div className="header__search">
                <form
                  className="header__search-form"
                  onSubmit={this.findProducts}
                >
                  <input
                    className="header__search-input"
                    id="inp_name"
                    type="text"
                    placeholder={
                      shop
                        ? Messages.t("Search_by_shop") + shop.name
                        : Messages.t("Search_by_all_shops")
                    }
                    value={nameValue}
                    onChange={this.searchParamsChange}
                  />
                  <button className="header__search-btn" type="submit">
                    {Messages.t("Search")}
                  </button>
                </form>
              </div>

              <div className="header__nav">
                <div
                  className="header__nav-item"
                  onClick={this.changeModalState}
                >
                  <svg
                    strokeWidth="0"
                    stroke="#17216b"
                    viewBox="0 0 512 512"
                    height="22px"
                    width="22px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"
                    ></path>
                    <circle
                      cx="256"
                      cy="192"
                      r="48"
                      fill="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                    ></circle>
                  </svg>
                </div>
                <div className="header__profile-wrap">
                  <div className="header__nav-item">
                    <svg className="icon icon__profile" width="18" height="20">
                      <use href="static/img/icons/sprite/sprite.svg#profile" />
                    </svg>
                  </div>
                  <div className="header-profile-tooltip-wrap">
                    {authUser.user ? (
                      <div className="header-profile-tooltip">
                        <div className="header-profile-tooltip__title">
                          {Messages.t("Yoʻnalish")}
                        </div>
                        <ul className="header-profile-tooltip__list">
                          <li className="header-profile-tooltip__item">
                            <a
                              className="header-profile-tooltip__link"
                              href="#/my-orders"
                            >
                              {Messages.t("My_orders")}
                            </a>
                          </li>
                          <li className="header-profile-tooltip__item">
                            <a
                              className="header-profile-tooltip__link"
                              href="#/notes"
                            >
                              {Messages.t("notes")}
                            </a>
                          </li>
                          {/*TO DO*/}
                          {/*<li className="header-profile-tooltip__item"><a*/}
                          {/*    className="header-profile-tooltip__link" href="#">Мои данные</a></li>*/}
                          {/*<li className="header-profile-tooltip__item"><a*/}
                          {/*    className="header-profile-tooltip__link" href="#">Избранное</a></li>*/}
                          <li className="header-profile-tooltip__item">
                            <a
                              className="header-profile-tooltip__link"
                              href="#/profile"
                            >
                              {Messages.t("Settings")}
                            </a>
                          </li>
                        </ul>
                        <div
                          className="header-profile-tooltip__exit"
                          onClick={this.logout}
                        >
                          <span>{Messages.t("Logout")}</span>
                          <svg className="icon" width="20" height="20">
                            <use href="img/icons/sprite/sprite.svg#exit" />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <div className="profile-no-reg-tooltip">
                        <div className="profile-no-reg-tooltip__btns">
                          <Link
                            className={"profile-no-reg-tooltip__signin"}
                            to={"/login"}
                          >
                            {Messages.t("Login")}
                          </Link>
                          <Link
                            className={"profile-no-reg-tooltip__signup"}
                            to={"/registration"}
                          >
                            {Messages.t("Registration")}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/*TO DO*/}
                <div className="header__nav-item">
                  <Link to={"/favorites"}></Link>
                  <span className="header__favorite-icon">
                    <svg className="icon icon__heart" width="25" height="25">
                      <use href="static/img/icons/sprite/sprite.svg#heart" />
                    </svg>
                    <span className="header__favorite-products-count">3</span>
                  </span>
                </div>
                {window.location.href.indexOf("shoppingcart") === -1 ? (
                  <div className="header__cart-wrap">
                    <div className="header-cart-tooltip-wrap">
                      {totalQuantity === 0 ? (
                        <div className="header-cart-tooltip-empty">
                          <div className="header-favorite-tooltip__header">
                            <span className="header-favorite-tooltip__title">
                              {Messages.t("Shopping_cart")}
                            </span>
                          </div>
                          <div className="header-favorite-tooltip__content">
                            <p className="header-favorite-tooltip__desc">
                              {Messages.t("Your_shopping_cart_is_empty")}
                            </p>
                            <p className="header-favorite-tooltip__desc">
                              {Messages.t(
                                "Add_products_to_shopping_cart_and_buy"
                              )}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="header-cart-tooltip">
                          <div className="header-cart-tooltip__header">
                            <span className="header-cart-tooltip__title">
                              {Messages.t("Shopping_cart")}
                            </span>
                          </div>
                          <div className="header-cart-tooltip__footer">
                            <div className="header-cart-tooltip__footer-left">
                              <span className="header-cart-tooltip__total">
                                {Messages.t("Sum")}
                              </span>
                              <span className="header-cart-tooltip__total-cost">
                                {totalCost.toFixed(2)} сўм
                              </span>
                            </div>
                            <div className="header-cart-tooltip__footer-right">
                              <a
                                className="header-cart-tooltip__go-cart"
                                href={
                                  allCartItems > 0 ? "#/shoppingcart" : null
                                }
                              >
                                {Messages.t("GO_TO_SHOPPING_CART")}
                                <svg className="icon" width="20" height="20">
                                  <use href="static/img/icons/sprite/sprite.svg#shopping-cart" />
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div className="header-cart-tooltip__content">
                            {products &&
                              products.map((item: any, i: number) => {
                                return (
                                  <React.Fragment key={`${item.id}_${i}`}>
                                    {item &&
                                      item.products.map((item: Product) => {
                                        return (
                                          <ShoppingCartProductAsItem
                                            key={item.id}
                                            item={item}
                                            actions={actionsShoppingCart}
                                            quantity={item.quantity}
                                          />
                                        );
                                      })}
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        allCartItems > 0
                          ? "header__nav-item cart-has-product"
                          : "header__nav-item"
                      }
                    >
                      {allCartItems > 0 ? <a href="#/shoppingcart"> </a> : null}
                      <span className="header__cart-icon" id="cart">
                        {allCartItems === 0 ? (
                          <span className="header__cart-icon-tooltip">
                            {Messages.t("EMPTY_CART")}
                          </span>
                        ) : null}

                        <svg
                          className="icon icon__shopping-cart"
                          width="25"
                          height="25"
                        >
                          <use href="static/img/icons/sprite/sprite.svg#shopping-cart" />
                        </svg>
                        <span className="header__cart-products-count">
                          {allCartItems}
                        </span>
                      </span>
                      <div className="header__cart-has-product">
                        <span className="header__cart-sum-text">
                          {Messages.t("Sum")}
                        </span>
                        <span className="header__cart-sum">
                          {totalCost.toFixed(2)} сўм
                        </span>
                      </div>
                      <span className="header__cart-empty-product">
                        {Messages.t("Shopping_cart_is_")}
                        <br />
                        {Messages.t("_is_empty_BR")}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {isOpenModal ? <MyAddress /> : null}
      </>
    );
  };
}

const mapStateToProps = (state: any) => ({
  products: state.ShoppingCartReducer.shops,
  // totalQuantity: state.ShoppingCartReducer.totalQuantity,
  totalCost: state.ShoppingCartReducer.totalCost,
  shop: state.ShoppingCartReducer.shop,
  authUser: state.AuthReducer,
  searchParams: state.AllProductsReducer,
  opensidebar: state.CategoryReducer.opensidebar,
  categories: state.CategoryReducer.categories,
  isOpenModal: state.HeaderReducer.isOpenModal,
  category: state.AllProductsReducer.category,
  min_cost: state.AllProductsReducer.minCost,
  max_cost: state.AllProductsReducer.maxCost,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AllProductsActions, dispatch),
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
  actionsAuth: bindActionCreators(AuthActions, dispatch),
  categoryActions: bindActionCreators(CategoryActions, dispatch),
  modalAction: bindActionCreators(changeModalStateAcn, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopHeader));
