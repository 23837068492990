import React, { Component } from 'react';
import './About.css';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import HelmetComponent from '../helmet';
import Messages from '../../common/messages/Messages';

class About extends Component {
  render = () => {
    return (
      <div>
        <HelmetComponent title={'About'} />
        <Header />
        <section className="about-section">
          <div className="container">
            <div className="About-header">About</div>
          </div>
        </section>
        <Footer />
      </div>
    );
  };
}

export default About;
