import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as TopProductsActions from "../store/actions";

import TopProducts from "../components/TopProducts";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";

const mapStateToProps = (state: any) => {
  return {
    _page: state.TopProducts._page,
    products: state.TopProducts.products,
    // shops: state.ShopReducer.shops,
    // cartProducts: state.ShoppingCartReducer.products,
    cartState: state.ShoppingCartReducer.shops,
    messages: state.LangReducer.messages,
    productsLoading: state.TopProducts.loading,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  actions: bindActionCreators(TopProductsActions, dispatch),
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(TopProducts);
