import React, { useState, useEffect } from "react";
import "./css/UserCard.css";
import Messages from "../messages/Messages";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SaveCardForm from "./SaveCardForm";

function editCardNum(cardNum: string) {
  let text = cardNum;
  let group1 = text.slice(0, 4);
  let group2 = text.slice(4, 8);
  let group3 = text.slice(8, 12);
  let group4 = text.slice(-4);
  return group1 + " " + group2 + " " + group3 + " " + group4;
}

function editCardExp(cardExp: string) {
  let text = cardExp;
  let month = text.slice(-2);
  let year = text.slice(0, 2);
  return month + "/" + year;
}

function editCardBalance(sum: number) {
  const price = sum / 100;

  let dollarUSLocale = Intl.NumberFormat("en-US");
  return dollarUSLocale.format(price);
}

function handleCards(cards: any) {
  let filtered = cards.map((el: any) => {
    return {
      id: el.id,
      num: editCardNum(el.pan),
      exp: editCardExp(el.expiry),
      name: el.fullName,
      balance: editCardBalance(el.balance),
    };
  });
  return filtered;
}

const UserCard = React.memo(() => {
  const BASE_URL: string = "https://hammol.uz";
  let token: any = localStorage.getItem("token");

  let active = localStorage.getItem("activeCard");
  const [cards, setCards] = useState<any[]>([]);
  const [activeCard, setActiveCard] = useState(active);

  const selectCard = (el: any) => {
    let setActive = el.id != activeCard ? el.id : "";
    localStorage.removeItem("activeCard");
    localStorage.setItem("activeCard", setActive);
    localStorage.removeItem("activeCardObj");
    localStorage.setItem("activeCardObj", JSON.stringify(el));
    setActiveCard(setActive);
  };

  const getCards = async () => {
    setCards([]);
  };

  const deleteCard = async (el: any) => {
    let id = el.id;
    try {
      let data: any = await fetch(`${BASE_URL}/payment/api/v1/card/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });
      setCards([]);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (!cards || cards.length !== 0) {
      return;
    }
    try {
      fetch(`${BASE_URL}/payment/api/v1/card/`, {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.length === 0) {
            return;
          }
          let cardsEdited: any = handleCards(data);
          setCards(cardsEdited);
        });
    } catch (e) {
      console.log(e);
    }
  }, [cards]);

  return (
    <div className="cards-list">
      <SaveCardForm getCards={getCards} />
      {cards.map((el: any) => (
        <div
          key={el.id}
          id={el.id}
          style={el.id == activeCard ? { order: 1 } : { order: 2 }}
          className="card-container"
        >
          <div
            className={`card-item ${el.id == activeCard ? "active-card" : ""}`}
          >
            <div className="card-top">
              <div className="card-top--balance">
                {el.balance} {Messages.t("SUM")}
              </div>
              <img src="static/img/logo.svg" alt="Hammol Logo" />
            </div>
            <div className="card-center">
              <div className="card-center--card-number">{el.num}</div>
            </div>
            <div className="card-bottom">
              <div className="card-bottom--user-name">{el.name}</div>
              <div className="card-bottom--card-exp">{el.exp}</div>
            </div>
          </div>
          <div className="card-buttons">
            <div className="card-buttons--delete">
              <button onClick={() => deleteCard(el)}>
                {Messages.t("Delete")}
              </button>
            </div>
            {el.id == activeCard ? (
              <>
                <div className="card-buttons--selected">
                  <button disabled={true} onClick={() => selectCard(el)}>
                    {Messages.t("Select")}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="card-buttons--choose">
                  <button onClick={() => selectCard(el)}>
                    {Messages.t("Select")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
});

export default UserCard;
