import React from "react";
import Messages from "../../common/messages/Messages";

const ProductNotFound = ({ message }: { message?: string }) => {
  return (
    <div className="no-products">
      <img style={{ height: 250 }} src="/static/img/not_found3.png" alt="" />
      <h4>{message ? message : Messages.t("NO_DATA")}</h4>
    </div>
  );
};

export default ProductNotFound;
