import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import ProductRating from "../../common/components/details/ProductRating";
import Messages from "../../common/messages/Messages";

type Props = {
  height?: number;
  id: number;
  logo: {
    src: string | null;
  };
  name: string;
  place: string;
  width?: number;
  working: string;
  rate: string;
  shopactive: boolean;
  address: string;
};

const ShopsCard: FC<{ item: Props }> = ({ item }) => {
  const hostUrl = "https://hammol.uz";
  const { id, logo, name, address, shopactive, rate } = item;
  let history = useHistory();
  return (
    <div className="product-card" onClick={() => history.push(`/shop/${id}`)}>
      <div className="product-card__img">
        <>
          {logo ? (
            <>
              {logo.src && logo.src.includes("temp/") ? (
                <img src={hostUrl + logo.src} alt="rasm" />
              ) : (
                <>
                  {typeof logo === "object" ? (
                    <img
                      src={"data:image/png;base64, " + logo.src}
                      alt="rasm"
                    />
                  ) : (
                    <img
                      src={"https://hammol.uz/temp/noimage2.png"}
                      alt="rasm"
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <img src={"https://hammol.uz/temp/noimage2.png"} alt="rasm" />
            </>
          )}
        </>
      </div>
      <div className="product-card__content">
        <ProductRating rating={+rate} />
        <h3>{name}</h3>
        <p>{address}</p>
      </div>

      <div className="isactive__shop">
        <span className="shop-status">
          {shopactive ? Messages.t("Open") : Messages.t("Close")}
        </span>
      </div>
    </div>
  );
};

export default ShopsCard;
