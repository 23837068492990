export const ROUTE_PATHS = {
  HOME: "/",
  REGISTRATION: "/registration",
  LOGIN: "/login",
  ALL_PRODUCTS: "/allproducts",
  SHOPPING_CART: "/shoppingcart",
  BUY: "/buy",
  MY_ORDERS: "/my-orders",
  PROFILE: "/profile",
  RESTORE: "/restore",
  RESTORE_CONFIRM: "/restore-confirm",
  DETAIL: "/detail/:id",
  ALL_SHOPS: "/shops/",
  ALL_MARKETS: "/markets/",
  SHOP_PRODUCTS: "/shop/:id",
  MARKET_SHOPS: "/market/:id",
  NOTES: "/notes",
  SINGLE_NOTE: "/notes/:id",
  NOTES_SHARE: "/notes-share",
};
