import React, { Component } from "react";
import { withRouter } from "react-router";
import Messages from "../../common/messages/Messages";
import { CategoryItem } from "../../common/types/Category";
import MainLoader from "../../_temp/hammolLoader/MainLoader";
import Category from "./Category";

// To DO any
class SubCategories extends Component<any, any> {
  render = () => {
    let {
      actionsCategory,
      categoryLayer,
      allSubCategories,
      subCategoriesParent,
      saveCategoryToStore,
      subCategories,
      name,
    } = this.props;

    return (
      <div className={`submenu ${categoryLayer > 0 ? "show" : ""}`}>
        <div
          className="submenu_title"
          // onClick={() => actionsCategory.SetCategoryLayer(categoryLayer - 1)}
        >
          {subCategoriesParent &&
            subCategoriesParent.map((item: any, index: number) => {
              if (index < categoryLayer) {
                if (index === 0) {
                  return (
                    <div key={item.id} className="sidebar__aside-menu">
                      <h2 onClick={() => actionsCategory.SetCategoryLayer(0)}>
                        <svg className="icon arrow-icon">
                          <use href="static/img/icons/sprite/sprite.svg#arrow"></use>
                        </svg>
                        {Messages.t("TO_ALL")}
                      </h2>
                    </div>
                  );
                }
                return (
                  <React.Fragment key={item.id}>
                    <h2 onClick={() => actionsCategory.SetCategoryLayer(index)}>
                      {subCategoriesParent[index - 1].name.slice(0, 11)}
                      {subCategoriesParent[index - 1].name.length > 11
                        ? ".."
                        : ""}
                    </h2>
                  </React.Fragment>
                );
              }
            })}
          {subCategoriesParent && subCategoriesParent.length && (
            // <div className="aside-menu__header-icon" style={{ fill: "#fff" }}>
            //   <svg
            //     onClick={() => actionsCategory.setOpenSidebar(false)}
            //     style={{
            //       width: "25px",
            //       fill: "#fff",
            //     }}
            //   >
            //     <use href="static/img/icons/sprite/sprite.svg#close" />
            //   </svg>
            // </div>
            <div className="aside-menu__header-icon">
              <svg onClick={() => actionsCategory.setOpenSidebar(false)}>
                <use href="static/img/icons/sprite/sprite.svg#close" />
              </svg>
            </div>
          )}
        </div>
        <ul className="submenu__list">
          {subCategories?.length ? (
            subCategories?.map((item: CategoryItem, index: number) => {
              // if (item.count) {
              return (
                <Category
                  name={name}
                  subCategoriesParent={subCategoriesParent}
                  category={item}
                  key={index}
                  actionsCategory={actionsCategory}
                  categoryLayer={categoryLayer}
                  subCategories={allSubCategories}
                  saveCategoryToStore={saveCategoryToStore}
                />
              );
              // }
              // return (
              //   <li className="submenu__item" key={+item.id}>
              //     <a
              //       className="submenu__link"
              //       data-category={item.id}
              //       href="/#"
              //       onClick={actions.saveCategoryToStore}
              //     >
              //       {item.name}
              //     </a>
              //   </li>
              // );
            })
          ) : (
            <MainLoader />
          )}
        </ul>
      </div>
    );
  };
}

export default withRouter(SubCategories);
