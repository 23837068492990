import * as types from './constants';
// import {UserReg} from "../../common/types/UserReg";
// import {UserVary} from "../../common/types/UserVary";
import {
    IActionAuthSignUpSeek,
    IActionAuthSignUpTake,
    // IActionAuthSignUpVary,
    // IActionAuthSignUpKeep,
    // IActionAuthLogin,
    // IActionAuthSetAuthorized,
    // IActionAuthSetNotAuthorized,
    IActionAuthResetState,
    IActionAuthSetStepTo,
    IActionAuthSetUserRegName, IActionAuthSetUserRegMale, IActionAuthSetUserRegCode
} from "./actionsTypes";
import {PhoneReg} from "../../common/types/PhoneReg";
// import {User} from "../../common/types/User";

export function authSignUpSeekAcn(grant: string): IActionAuthSignUpSeek {
    return {type: types.AUTH_SIGN_UP_SEEK, grant}
}

export function authSignUpTakeAcn(phoneReg: PhoneReg, sent: string): IActionAuthSignUpTake {
    return {type: types.AUTH_SIGN_UP_TAKE, phoneReg, sent}
}

// export function authSignUpKeepAcn(userReg: UserReg): IActionAuthSignUpKeep {
//     return {type: types.AUTH_SIGN_UP_KEEP, userReg}
// }
//
// export function authSignUpVaryAcn(userVary: UserVary): IActionAuthSignUpVary {
//     return {type: types.AUTH_SIGN_UP_VARY, userVary}
// }
//
// export function authLoginAcn(user: User): IActionAuthLogin {
//     return {type: types.AUTH_LOGIN, user}
// }
//
// export function authSetAuthorizedAcn(token: string, user: User): IActionAuthSetAuthorized {
//     return {type: types.AUTH_AUTHORIZED, token, user}
// }
//
// export function authSetNotAuthorizedAcn(): IActionAuthSetNotAuthorized {
//     return {type: types.AUTH_NOT_AUTHORIZED}
// }
//
export function authResetStateAcn(): IActionAuthResetState {
    return {type: types.AUTH_RESET_STATE}
}
//
export function authSetStepToAcn(authStep: number): IActionAuthSetStepTo {
    return {type: types.AUTH_SET_STEP_TO, authStep}
}
//
export function authSetUserRegNameAcn(name: string): IActionAuthSetUserRegName {
    return {type: types.AUTH_SET_USER_REG_NAME, name}
}
//
export function authSetUserRegMaleAcn(male: boolean): IActionAuthSetUserRegMale {
    return {type: types.AUTH_SET_USER_REG_MALE, male}
}
//
export function authSetUserRegCodeAcn(code: number): IActionAuthSetUserRegCode {
    return {type: types.AUTH_SET_USER_REG_CODE, code}
}
