import { AUTH_INIT, AUTH_LOGIN, AUTH_LOGOUT, AUTH_USER } from "./constants";
import { IAuthActions } from "./actionsTypes";
import { User } from "../../common/types/User";

const initialState: User = {
  id: "",
  user: "",
  name: "",
  lang: "",
  rule: 1,
  access: 1,
  currency: "",
  token: "",
  version: "",
};

export default function AuthReducer(
  state: any = initialState,
  action: IAuthActions
): any {
  switch (action.type) {
    case AUTH_INIT:
      let authUserLS = localStorage.getItem(AUTH_USER) || "";
      return {
        ...state,
        ...(authUserLS ? JSON.parse(authUserLS) : initialState),
        user: authUserLS ? JSON.parse(authUserLS).id : "",
      };

    case AUTH_LOGIN:
      localStorage.setItem(
        AUTH_USER,
        JSON.stringify({ ...action.user, token: action.token })
      );
      return {
        ...state,
        ...action.user,
        token: action.token,
        user: action.user.id,
      };

    case AUTH_LOGOUT:
      localStorage.removeItem(AUTH_USER);
      localStorage.removeItem("token");
      return initialState;

    default:
      return state;
  }
}
