import { AbstractApi } from './AbstractApi';

class ShopRatingApi extends AbstractApi {
  setRating = (shopId: number, param: { rate: number }) => {
    const PATH = 'commodity/shop/' + shopId + '/rate';
    return this.fetchApi(PATH, this.REQUEST_METHOD_POST, param);
  };
}

const shopRatingApi = new ShopRatingApi();
export default shopRatingApi;
