import * as types from "./constants";
import { Product } from "../../common/types/Product";
import {
  AddToShoppingCartAcn,
  DeleteFromShoppingCartAcn,
  ResetShoppingCartAcn,
  InitShoppingCartFromLocStorageAcn,
  DeleteOneProductFromShoppingCartAcn,
  RemoveShopAcn,
} from "./actionsTypes";

export function addToShoppingCart(
  product: Product,
  count: number
): AddToShoppingCartAcn {
  return {
    type: types.ADD_TO_SHOPPING_CART,
    product,
    count,
  };
}
export function addOneProductToShoppingCart(product: Product, count: number) {
  return {
    type: types.ADD_ONE_PRODUCT_TO_SHOPPINGCART,
    product,
    count,
  };
}

export function deleteFromShoppingCart(
  product: Product,
  shop: any
): DeleteFromShoppingCartAcn {
  return {
    type: types.DELETE_FROM_SHOPPING_CART,
    product,
    shop,
  };
}

export function resetFromShoppingCart(): ResetShoppingCartAcn {
  return { type: types.RESET_SHOPPING_CART };
}

export function initShoppingCartFromLocStorage(): InitShoppingCartFromLocStorageAcn {
  return {
    type: types.INIT_SHOPPING_CART_FROM_LOCAL_STORAGE,
  };
}

export function deleteOneProductFromShoppingCart(
  product: Product,
  shop: any,
  count: number
): DeleteOneProductFromShoppingCartAcn {
  return {
    type: types.REMOVE_ONE_PRODUCT_FROM_SHOPPING_CART,
    product,
    shop,
    count,
  };
}

export function removeShop(shop_id: number): RemoveShopAcn {
  return {
    type: types.REMOVE_SHOP,
    shop_id,
  };
}
