import React, {Component} from 'react';

type Props = {
    cost: string,
    price: string,
    currency: string
};

class ProductCost extends Component<Props> {
    render = () => {
        const {cost, currency, price} = this.props;
        return (
            <>
                <span className="product-card__cost">{cost}<span>{currency}</span></span>
                <span className="product-card__prev-cost"><span>{price ? price : ''}</span></span>
            </>
        );
    };
}

export default ProductCost;
