import {CHOOSE_PRODUCT, RECEIVE_PRODUCT_DETAIL} from './constants'
import {IProductDetailState} from "./types";
import {ProductDetailActions} from "./actionsTypes";

const initialState: IProductDetailState = {
    product: {},
    details: {}
};

export default function ProductDetailReducer(state: any = initialState, action: ProductDetailActions): IProductDetailState {
    switch (action.type) {

        case RECEIVE_PRODUCT_DETAIL:
            return {
                ...state,
                details: action.details
            };

        case CHOOSE_PRODUCT:
            return {
                ...state,
                product: action.product
            };

        default:
            return state;
    }
};
