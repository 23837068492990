import { IFavoriteProductsState } from "./types";
import { favoriteProductsActions } from "./actionsTypes";
import {
  RECEIVE_FAVORITE_PRODUCTS,
  REMOVE_FAVORITE_PRODUCT,
} from "./constants";

const initialState: IFavoriteProductsState = {
  products: [],
};

export default function FavoriteProductsReducer(
  state: any = initialState,
  action: favoriteProductsActions
): any {
  switch (action.type) {
    case RECEIVE_FAVORITE_PRODUCTS:
      return {
        ...state,
        products: [...action.products],
      };
    case REMOVE_FAVORITE_PRODUCT: {
      return {
        ...state,
        products: state.products.filter(
          (product: any) => product.id !== action.id
        ),
      };
    }

    // RECEIVE_TOP_PRODUCTS
    default:
      return state;
  }
}
