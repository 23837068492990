import { AbstractApi } from './AbstractApi';

class ChatApi extends AbstractApi {
  getAll = (id: string) => {
    const PATH = 'commodity/chat/' + id;
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET);
  };
  getGuest = (guestId: string) => {
    const PATH = 'commodity/chat/guest/' + guestId;
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET);
  };
}
const allChats = new ChatApi();
export default allChats;
