import React, { Component } from 'react';
import Messages from '../../common/messages/Messages';
import HelmetComponent from '../../_temp/helmet';

class ServiceUnavailable extends Component {
  render = () => {
    return (
      <section>
        <HelmetComponent
          title={'503 Service Unavailable'}
        />
        503 Service Unavailable
        <br />
        <a href="#/">Main page</a>
      </section>
    );
  };
}

export default ServiceUnavailable;
