import React, { useEffect, useState } from "react";
import Messages from "../messages/Messages";

function SaveCardForm({ getCards }: any): any {
  const BASE_URL: string = "https://hammol.uz";
  let token: any = localStorage.getItem("token");

  const phoneNumberPattern = /^\(?([0-9]{2})\)?([0-9]{3})([0-9]{4})$/;
  const cardInitial = {
    num: "UZCARD / HUMO",
    phone: "993332211",
    exp: "00/00",
    expMonth: "",
    expYear: "",
  };
  const submitStatus = { num: false, phone: false, exp: false };
  const confirmCardInit = {
    id: "",
    masked_phone_number: "",
    time_out: 0,
    code: "123456",
  };

  const [cardData, setCardData] = useState(cardInitial);
  const [confirmData, setConfirmData] = useState(confirmCardInit);

  const [submitCheck, setSubmitCheck] = useState(submitStatus);
  const [step, setStep] = useState(1);

  const inputFocus = (el: any) => {
    let attribute = el.target.getAttribute("data-name");
    el.target.classList.add("not-valid");
    setCardData({ ...cardData, [attribute]: "" });
  };

  const codeInputFocus = (el: any) => {
    let attribute = el.target.getAttribute("data-name");
    el.target.classList.add("not-valid");
    setConfirmData({ ...confirmData, [attribute]: "" });
  };
  const changeHandler = (el: any) => {
    let value = el.target.value;
    let number = Number(value);
    let attribute = el.target.getAttribute("data-name");

    if (
      attribute === "exp" &&
      value.length === 2 &&
      cardData.exp.length === 1
    ) {
      value = value + "/";
    }

    if (isNaN(value) && attribute !== "exp") {
      return;
    }
    if (attribute === "num" && value.length === 16 && !NaN) {
      el.target.classList.remove("not-valid");
      setSubmitCheck((prevState) => {
        return { ...prevState, num: true };
      });
    } else if (attribute === "exp" && value.length === 5) {
      let split = value.split("/");
      let month = Number(split[0]);
      let year = Number(split[1]);
      let monthStr = split[0];
      let yearStr = split[1];
      if (isNaN(month) || isNaN(year)) {
        console.log("ISNAN", { month, year });
        return;
      }
      if (
        month &&
        year &&
        !isNaN(month) &&
        !isNaN(year) &&
        monthStr.length === 2 &&
        yearStr.length === 2
      ) {
        el.target.classList.remove("not-valid");
        setCardData((prevState) => {
          return {
            ...prevState,
            exp: monthStr + "/" + yearStr,
            expMonth: monthStr,
            expYear: yearStr,
          };
        });
        setSubmitCheck((prevState) => {
          return { ...prevState, exp: true };
        });
        return;
      }
    } else if (
      attribute === "phone" &&
      value.match(phoneNumberPattern) &&
      value.length === 9
    ) {
      el.target.classList.remove("not-valid");
      setSubmitCheck((prevState) => {
        return { ...prevState, phone: true };
      });
    } else {
      el.target.classList.add("not-valid");
    }
    setCardData((prevState) => {
      return { ...prevState, [attribute]: value };
    });
  };
  const confChangeHandler = (el: any) => {
    let value = el.target.value;
    let attribute = el.target.getAttribute("data-name");
    if (attribute === "code" && value.length === 6 && !NaN) {
      el.target.classList.remove("not-valid");
      setSubmitCheck((prevState) => {
        return { ...prevState, num: true };
      });
    } else {
      el.target.classList.add("not-valid");
    }
    setConfirmData((prevState) => {
      return { ...prevState, [attribute]: value };
    });
  };
  const saveCard = async (el: any) => {
    el.preventDefault();
    el.stopPropagation();
    if (submitCheck.phone && submitCheck.num && submitCheck.exp) {
      try {
        let data: any = await fetch(`${BASE_URL}/payment/api/v1/card/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify({
            phone: `998${cardData.phone}`,
            card_num: cardData.num,
            card_exp: cardData.expMonth + cardData.expYear,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            return data;
          });
        if (!data.error) {
          setConfirmData((prev) => {
            return {
              ...prev,
              id: data.id,
              masked_phone_number: data.masked_phone_number,
              time_out: data.time_out,
            };
          });
          setStep(2);
          setCardData(cardInitial);
        } else {
          console.log(data);
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    }
  };

  const confirmCard = async (el: any) => {
    el.preventDefault();
    el.stopPropagation();
    if (step === 2 && confirmData.id && confirmData.masked_phone_number) {
      try {
        let data = await fetch(`${BASE_URL}/payment/api/v1/card/confirm`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify({
            card_id: confirmData.id,
            code: confirmData.code,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            return data;
          });
        if (!data.error) {
          setConfirmData(confirmCardInit);
          getCards();
        } else {
          console.log(data);
        }
        console.log(data);
        setStep(1);
      } catch (e) {
        console.log(e);
      }
    }
  };

  if (step == 1) {
    return (
      <div className="save-container">
        <form onSubmit={saveCard} className="save-card-item">
          <div className="save-card">
            <div className="save-card--input">
              <div className="card-number">
                <label className="save-card--label" htmlFor="num">
                  Card number:
                </label>
                <input
                  type={"text"}
                  maxLength={16}
                  size={16}
                  name="num"
                  value={cardData.num}
                  data-name={"num"}
                  autoComplete="off"
                  onChange={changeHandler}
                  onFocus={inputFocus}
                  className="card-number--input card-save-input"
                />
              </div>
              <div className="card-exp">
                <label className="save-card--label" htmlFor="expMonth">
                  Exp. Month/Year
                </label>
                <div className="card-exp--inputs">
                  <input
                    type={"text"}
                    maxLength={5}
                    size={5}
                    name="exp"
                    value={cardData.exp}
                    data-name={"exp"}
                    autoComplete="off"
                    onChange={changeHandler}
                    onFocus={inputFocus}
                    className="card-exp--input card-save-input"
                  />
                  {/*<span style={{fontSize:'2.5rem',fontWeight:"normal", margin:'3px', textAlign:"center"}}>/</span>*/}
                  {/*<input type={'number'} maxLength={2} size={2} value={cardData.expYear} data-name={'expYear'} autoComplete="off" onChange={changeHandler} onFocus={inputFocus} className="card-exp--year card-save-input"/>*/}
                </div>
              </div>
            </div>
            <div className="save-card--phone">
              <div className="phone-code">+998</div>
              <input
                onChange={changeHandler}
                data-name={"phone"}
                autoComplete="off"
                onFocus={inputFocus}
                value={cardData.phone}
                type={"number"}
                className="phone-input card-save-input"
              />
            </div>
          </div>
          <input
            value={Messages.t("Get_SMS_code_to_order")}
            className="save-card--submit"
            type={"submit"}
          />
        </form>
      </div>
    );
  }
  if (step == 2) {
    return (
      <div className="save-container">
        <form onSubmit={confirmCard} className="save-card-item">
          <div className="save-card">
            <div className="conf-card--id">{confirmData.id}</div>
            <div className="conf-card--maskNum">
              {confirmData.masked_phone_number}
            </div>
            <div className="conf-card--code">
              <label className="save-card--label" htmlFor="confCode">
                Code
              </label>
              <input
                maxLength={6}
                size={6}
                name="confCode"
                value={confirmData.code}
                data-name={"code"}
                autoComplete="off"
                onFocus={codeInputFocus}
                onChange={confChangeHandler}
                className="code--input card-save-input"
              />
            </div>
          </div>
          <input
            value={Messages.t("SAVE")}
            className="save-card--submit"
            type={"submit"}
          />
        </form>
      </div>
    );
  }
}

export default SaveCardForm;
