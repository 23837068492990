import {
  IActiveShopAcn,
  IDriverInfoAcn,
  IResetBuyProductsAcn,
} from "./actionTypes";
import {
  RESET_BUY_PRODUCTS,
  SET_ACTIVE_SHOP,
  SET_DRIVER_INFO,
} from "./constants";

export function setActiveShop(activeShop: string = ""): IActiveShopAcn {
  return { type: SET_ACTIVE_SHOP, activeShop };
}

export function setDriverInfo(driverInfo: any): IDriverInfoAcn {
  return { type: SET_DRIVER_INFO, driverInfo };
}

export function resetBuyProducts(): IResetBuyProductsAcn {
  return { type: RESET_BUY_PRODUCTS };
}
