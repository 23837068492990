import { AbstractApi } from './AbstractApi';

class ProductDetailApi extends AbstractApi {
  getDetail = (id: string) => {
    const PATH = 'commodity/product/' + id;
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET);
  };
}

const productDetailApi = new ProductDetailApi();
export default productDetailApi;
