import { Product } from "../types/Product";
import { Shop } from "../types/Shop";
import { ProductCartType } from "../../shoppingcart/store/types";
import { Order } from "../types/Order";
import { WAIT_MS } from "../constants/constants";

export function calcDiscount(price: number, cost: number): number {
  const hasDiscount = price && +cost - +price < 0;
  return hasDiscount ? Math.floor(((price - cost) * price) / 100) : 0;
}

export function preRenderCalc(item: Product, className: string) {
  item.discount = calcDiscount(+item.price, +item.cost);
  if (item.favorite) {
    className += " product-in-favorite";
  }

  if (item.in_basket) {
    className += " product-in-cart ";
  }

  if (item.discount) {
    className += " product-card__discount--active";
  }
  return className;
}

export function productShopAndBasket(
  item: any,
  // shops: Shop[],
  cartState: any
): any {
  // console.log('backet', cartProducts[item.id]);
  // if (Array.isArray(shops)) {
  //   const shop = shops.find((shops_item: Shop) => {
  //     return item.shop === shops_item.id;
  //   });
  //   if (shop) {
  //     item.shopObject = shop;
  //   }
  // }
  // if (cartProducts) {
  //   item.in_basket = cartProducts[item.id]
  //     ? cartProducts[item.id].quantity
  //     : 0;
  // }
  // return item;
  let productInBasket: any;

  for (const shop of cartState) {
    if (item.shop === shop.id) {
      for (const product of shop.products) {
        if (product.id === item.id) {
          productInBasket = product;
          break;
        }
      }
    }
  }

  if (productInBasket) {
    return {
      ...item,
      in_basket: productInBasket.quantity,
    };
  }

  return item;
}

export function orderShop(item: Order, shops: Shop[]): Order {
  if (Array.isArray(shops)) {
    const shop = shops.find((shops_item: Shop) => {
      return item.shop === shops_item.id;
    });
    if (shop) {
      item.shopObject = shop;
    }
  }
  return item;
}

export function formInputActiveClassManage() {
  let elements: any = document.querySelectorAll(".form__input-wrap input");
  if (elements) {
    for (const r of elements) {
      r.addEventListener("focus", function (this: any) {
        this.parentElement.classList.add("form__input--active");
      });
      r.addEventListener("focusout", function (this: any) {
        "" !== this.value
          ? this.parentElement.classList.add("form__input--active")
          : this.parentElement.classList.remove("form__input--active");
      });
    }
    for (const r of elements) {
      "" !== r.value
        ? r.parentElement.classList.add("form__input--active")
        : r.parentElement.classList.remove("form__input--active");
    }
  }

  let textarea: any = document.querySelectorAll(
    ".order-comments__textarea textarea"
  );
  if (textarea) {
    for (const r of textarea) {
      r.addEventListener("focus", function (this: any) {
        this.parentElement.classList.add("order-comments__textarea--active");
      });
      r.addEventListener("focusout", function (this: any) {
        "" !== this.value
          ? this.parentElement.classList.add("order-comments__textarea--active")
          : this.parentElement.classList.remove(
              "order-comments__textarea--active"
            );
      });
    }
    for (const r of elements) {
      "" !== r.value
        ? r.parentElement.classList.add("order-comments__textarea--active")
        : r.parentElement.classList.remove("order-comments__textarea--active");
    }
  }
}

/**
 * generate uuid v4 as in PostgreSQL
 * @param user_id
 */
export function generateUUID(user_id: string) {
  let uuid = "";
  if (user_id) {
    let seed = Date.now(),
      pattern = "xxxxxxxx-xxxx-4xxx-yxxx-";
    if (window.performance && typeof window.performance.now === "function") {
      seed += performance.now();
    }
    // if (user_id) {
    let u = user_id.split("-");
    if (u.length === 5) pattern += u[4];
    // }
    if (pattern.length === 24) pattern += "xxxxxxxxxxxx";
    uuid = pattern.replace(/[xy]/g, function (c) {
      let r = (seed + Math.random() * 16) % 16 | 0;
      seed = Math.floor(seed / 16);
      return (c === "x" ? r : r & (0x3 | 0x8)).toString(16);
    });
  }
  return uuid;
}

export function pageTopManageStyle() {
  if (document.querySelector(".page-top__tabs")) {
    let p = document.querySelectorAll(".page-top__tabs-item"),
      m: any = document.querySelector(".effect"),
      v: any = document.querySelector(".page-top__tabs-item--active"),
      _: any = v.offsetTop,
      f = v.offsetLeft,
      h = v.getBoundingClientRect().width,
      y = v.getBoundingClientRect().height;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    m.style.width = h + "px";
    m.style.height = y + "px";
    m.style.top = _ + "px";
    m.style.left = f + "px";
    p.forEach(function (t: any) {
      t.addEventListener("click", function () {
        _ = t.offsetTop;
        f = t.offsetLeft;
        h = t.getBoundingClientRect().width;
        y = t.getBoundingClientRect().height;
        m.classList.add("effect--active");
        m.style.width = h + "px";
        m.style.height = y + "px";
        m.style.top = _ + "px";
        m.style.left = f + "px";
      });
      t.addEventListener("click", function (this: any) {
        let items = document.querySelectorAll(".page-top__tabs-item");
        items.forEach((item) => {
          item.classList.remove("page-top__tabs-item--active");
        });

        this.classList.add("page-top__tabs-item--active");
      });
    });
  }
}

function arrowClick(element: any) {
  let menu_item = element.target.closest(".aside-menu__item"),
    menu_item_sub_menu = element.target.closest(
      ".aside-menu__item-wrapper"
    ).nextElementSibling;
  let subMenuComputedStyle = window.getComputedStyle(menu_item_sub_menu);

  menu_item_sub_menu.style.display =
    subMenuComputedStyle.display === "none" ? "block" : "none";

  menu_item.classList.contains("aside-menu__item--active")
    ? menu_item.classList.remove("aside-menu__item--active")
    : menu_item.classList.add("aside-menu__item--active");
}

export function asideMenuListClickItem() {
  document
    .querySelectorAll(".aside-menu__list .aside-menu__arrow")
    .forEach((el) => {
      if (el.getAttribute("hasClickListener") !== "true") {
        el.setAttribute("hasClickListener", "true");
        el.addEventListener("click", arrowClick, false);
      }
    });
}

export async function delayMS(ms: number = WAIT_MS) {
  let start = new Date().getTime();
  while (new Date().getTime() < start + ms) {}
}

export async function wait(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function changeSearchUrl(
  name: string,
  category: string,
  searchUrl: any
) {
  if (name || category) {
    searchUrl.search =
      "?" +
      (name ? "name=" + name : "") +
      (name && category ? "&" : "") +
      (category ? "category=" + category : "");
  }
  return searchUrl;
}
