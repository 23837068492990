import {
  OPEN_SIDEBAR,
  OPEN_SUB_CATEGORY,
  RECEIVE_ALL_CATEGORIES,
  SET_CATEGORY_LAYER,
  SET_SUB_CATEGORIES,
} from "./constants";
import { IAllCategories } from "./types";
import { CategoryActions } from "./actionsTypes";

const initialState: IAllCategories = {
  categories: [],
  opensidebar: false,
  openSubCategory: false,
  subCategories: [],
  categoryLayer: 0,
  subCategoriesParent: [],
};

export default function CategoryReducer(
  state: any = initialState,
  action: CategoryActions
): IAllCategories {
  switch (action.type) {
    case RECEIVE_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case OPEN_SIDEBAR: {
      return {
        ...state,
        opensidebar: action.opensidebar,
      };
    }
    case OPEN_SUB_CATEGORY: {
      return {
        ...state,
        openSubCategory: action.openSubCategory,
      };
    }
    case SET_CATEGORY_LAYER: {
      return {
        ...state,
        categoryLayer: action.categoryLayer,
      };
    }
    case SET_SUB_CATEGORIES: {
      return {
        ...state,
        subCategories: action.subCategories,
        subCategoriesParent: action.subCategoriesParent,
      };
    }
    default:
      return state;
  }
}
