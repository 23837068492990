import fetch from "cross-fetch";
import {HEADER_WITH_API_KEY, LANG_FILE_PATH_PREFIX} from "../constants/constants";
import {delayMS} from "../helpers/Helper";

class LangApi {
    fetchApi = async (path: string) => {
        let token = localStorage.getItem('token') || '';

        let reqOptions: any = {method: "GET", headers: HEADER_WITH_API_KEY};

        if (token) reqOptions.headers.token = token;

        await delayMS(300);

        return await fetch('https://hammol.uz'+path,reqOptions);
    };

    /**
     * getFile - load file from server
     */
    getFile = async (lang: string) => {
        const PATH = LANG_FILE_PATH_PREFIX + lang + '.js' + '?' + Date.now();
        console.log(PATH);
        
        return await this.fetchApi(PATH);
    };
}

const langApi = new LangApi();
export default langApi;


