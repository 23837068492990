import { AbstractApi } from "./AbstractApi";
import axios from "axios";

class ShopFilterCategoryApi {
  getFilterCategory = (id: string, params: any = {}) => {
    const lang = localStorage.getItem("app_lang");
    const PATH = `https://hammol.uz/api/commodity/shop/${id}/${
      params.category ? `category/${params.category}/` : "category/"
    }${params.group ? `group/${params.group}/` : ""}${
      params.class ? `class/${params.class}/` : ""
    }`;
    return axios.get(PATH, {
      headers: {
        lang: lang !== null ? lang : "uz",
      },
    });
  };

  getMarketFilterCategory = (id: string, params: any = {}) => {
    const lang = localStorage.getItem("app_lang");
    const PATH = `https://hammol.uz/api/commodity/place/${id}/${
      params.category ? `category/${params.category}/` : "category/"
    }${params.group ? `group/${params.group}/` : ""}${
      params.class ? `class/${params.class}/` : ""
    }`;
    return axios.get(PATH, {
      headers: {
        lang: lang !== null ? lang : "uz",
      },
    });
  };
}

const shopFilterCategoryApi = new ShopFilterCategoryApi();
export default shopFilterCategoryApi;
