import React, { Component } from 'react';
import Messages from '../../common/messages/Messages';
import HelmetComponent from '../../_temp/helmet';

class Notfound extends Component {
  render = () => {
    return (
      <section>
        <HelmetComponent title={Messages.t('404')} />
        404 Page not found!
      </section>
    );
  };
}

export default Notfound;
