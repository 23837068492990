import React, { Component } from "react";
import { Product } from "../../common/types/Product";
import { ITopProductsState, TopProductsProps } from "../store/types";
import topProductsApi from "../../common/api/TopProductsApi";
import ProductAsItem from "../../common/components/ProductAsItem";
import { productShopAndBasket } from "../../common/helpers/Helper";
import Messages from "../../common/messages/Messages";
import SkeletonLoader from "../../_temp/skeletonLoader";

class TopProducts extends Component<TopProductsProps, ITopProductsState> {
  constructor(props: ITopProductsState) {
    super(props);
    this.state = { loading: true };
  }

  unmounted = false;
  itemsLimit = 10;
  hasGotAllTopProducts: boolean = false;
  fetchMoreProducts = () => {
    const { actions, _page, activeShop } = this.props;
    let offset = _page + this.itemsLimit;

    let params: any = {
      start: offset,
    };
    if (activeShop) {
      params.shop = activeShop.id;
    }
    this.setState({ loading: true });

    topProductsApi
      .getAll(params)
      .then((res) => res.json())
      .then((result: any) => {
        if (result.products && result.products.length > 0) {
          this.setState({ loading: false });

          actions.fetchMoreTopProducts(result.products, offset);
          this.hasGotAllTopProducts = false;
        } else {
          this.hasGotAllTopProducts = true;
        }
      });
  };

  componentDidMount(): void {
    this.getFirstProducts();
  }

  componentWillUnmount(): void {
    this.unmounted = true;
  }

  getFirstProducts = () => {
    const { actions, activeShop } = this.props;
    let params: any = {
      start: 0,
    };
    if (activeShop) {
      params.shop = activeShop.id;
    }
    this.setState({ loading: true });
    actions.setTopProductsLoading(true);
    topProductsApi
      .getAll(params)
      .then((res) => res.json())
      .then((result: any) => {
        if (result.products && result.products.length > 0) {
          if (this.unmounted) return;
          this.setState({ loading: false });

          actions.fetchTopProducts(result.products);
          this.hasGotAllTopProducts = false;
        } else {
          this.hasGotAllTopProducts = true;
        }
        actions.setTopProductsLoading(false);
      });
  };

  render = () => {
    let {
      products,
      actionsShoppingCart,
      // shops,
      cartState,
      // activeShop,
      from,
      productsLoading,
    } = this.props;

    return (
      <section className="products-section">
        <div className="container">
          <div className="section-title">
            <div className="section-title__text">
              <span className="section-title__main">
                {Messages.t("Top_sellers")}
              </span>
              {/*<span className="section-title__desc">*/}
              {/*    {activeShop ? activeShop.name : Messages.t('From_all_shops')}*/}
              {/*</span>*/}
            </div>
            <a href={"#/allproducts"} className="section-title__all-things">
              <span>
                {Messages.t("Categories")}
                <svg className="icon" width="6" height="10">
                  <use href="static/img/icons/sprite/sprite.svg#arrow" />
                </svg>
              </span>
            </a>
          </div>

          <div className="top-products">
            <div className="top-products__row">
              {productsLoading ? (
                <SkeletonLoader number={15} />
              ) : // <MainLoader />
              products && products.length > 0 ? (
                products.map((item: Product, index: number) => {
                  item = productShopAndBasket(
                    item,
                    // shops,
                    cartState
                  );
                  return (
                    item.productactive &&
                    item.shopactive && (
                      <ProductAsItem
                        key={item.id + index}
                        item={item}
                        actions={{
                          ...actionsShoppingCart,
                          getFirstProducts: this.getFirstProducts,
                        }}
                        // activeShop={activeShop}
                        from={from}
                      />
                    )
                  );
                })
              ) : (
                ""
              )}
            </div>
            {!productsLoading &&
              (this.state.loading ? (
                <div className="top-products__more">
                  <div>
                    <div className="lds-dual-ring"></div>
                  </div>
                  {Messages.t("Show_more_products_loading")}
                </div>
              ) : (
                <div
                  className="top-products__more"
                  onClick={this.fetchMoreProducts}
                >
                  {Messages.t("Show_more_products")}
                </div>
              ))}
          </div>
        </div>
      </section>
    );
  };
}

export default TopProducts;
