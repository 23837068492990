import React, { ReactElement } from "react";
import axiosInstance from "../../common/api/BuyingApi";
import Messages from "../../common/messages/Messages";
import BuyWithCard from "./BuyWithCard";
import BuyWithSaved from "./BuyWithSaved";
import MapComponent from "./MapComponent";

interface IShopItem {
  payment: string;
  hostUrl: any;
  shoppingCart: { shops: any[]; totalCost: number };
  index: number;
  paymentType: any;
  activeShop: number | null;
  driverInfo: any;
  setDriverInfo: Function;
  orderWithCashHandler: Function;
  showBtn: boolean;
  removeShop: Function;
  resetBuyProducts: Function;
}

const ShopItem: React.FunctionComponent<IShopItem> = ({
  payment,
  hostUrl,
  shoppingCart,
  index,
  paymentType,
  activeShop,
  driverInfo,
  setDriverInfo,
  orderWithCashHandler,
  showBtn,
  removeShop,
  resetBuyProducts,
}) => {
  const [methodOfPayment, setMethodOfPayment] = React.useState("withCash");

  // const orderWithCashHandler = async () => {
  //   let placeLocal: any = localStorage.getItem("userAddress");
  //   let placeJson = JSON.parse(placeLocal);
  //   if (placeJson && driverInfo.price > 0 && placeJson.fullName) {
  //     try {
  //       let productsLocal: any = localStorage.getItem("shoppingCart");
  //       let productsJson: any = JSON.parse(productsLocal);
  //       let products: any[] = [];

  //       let address = `Kirish:${
  //         placeJson.porch ? placeJson.porch : "Yo'q"
  //       } | Qavat:${placeJson.floor ? placeJson.floor : "Yo'q"} | Xonadon:${
  //         placeJson.flat ? placeJson.flat : "Yo'q"
  //       } | ${placeJson.address}`.slice(0, 115);

  //       let place = {
  //         address: address,
  //         route_id: driverInfo.id,
  //       };

  //       if (activeShop === null) {
  //         productsJson.shops.forEach((shop: any) => {
  //           shop.products.forEach((product: any) => {
  //             products.push({
  //               uuid: product.id,
  //               count: product.quantity,
  //               shop: product.shop_id,
  //             });
  //           });
  //         });
  //       } else {
  //         productsJson.shops[activeShop].products.forEach((product: any) => {
  //           products.push({
  //             uuid: product.id,
  //             count: product.quantity,
  //             shop: product.shop_id,
  //           });
  //         });
  //       }

  //       // let response: any = await axios.post(`order/`, {
  //       //   items: products,
  //       //   place: place,
  //       // });
  //       await axiosInstance.post(`order/cash`, {
  //         items: products,
  //         place: place,
  //       });

  //       if (activeShop === null) {
  //         localStorage.removeItem("shoppingCart");
  //       } else {
  //         resetBuyProducts();
  //         removeShop(productsJson.shops[activeShop].id);
  //       }
  //       window.location.href = "#/my-orders";
  //     } catch (e) {
  //       console.log("ERROR", e);
  //     }
  //   } else {
  //     alert("Адрес или данные вашей карты не правильны");
  //   }
  // };

  return (
    <div className="from-one_shop-item">
      {activeShop !== null && (
        <h1>Do'kon: {shoppingCart.shops[activeShop].shopName}</h1>
      )}
      <ul className="product-list mb-2">
        {activeShop !== null &&
          shoppingCart.shops[activeShop].products.map((product: any) => (
            <li key={product.id} className="product-list__item">
              <div className="product">
                <div className="product__img">
                  {Array.isArray(product.product.images) ? (
                    <>
                      {product.product.images[0] &&
                      product.product.images[0].src &&
                      product.product.images[0].src.includes("temp/") ? (
                        <img
                          src={hostUrl + product.product.images[0].src}
                          alt="rasm"
                        />
                      ) : (
                        <>
                          {console.log(product.product.image[0])}
                          {product.product.image[0] &&
                          product.product.image[0].src ? (
                            <img
                              src={
                                "data:image/png;base64, " +
                                product.product.images[0].src
                              }
                              alt="rasm"
                            />
                          ) : (
                            <img
                              src={"https://hammol.uz/temp/noimage2.png"}
                              alt="rasm"
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {Array.isArray(product.product.image) &&
                      product.product.image[0] &&
                      product.product.image[0].src &&
                      product.product.image[0].src.includes("temp/") ? (
                        <img
                          src={hostUrl + product.product.image[0].src}
                          alt="rasm"
                        />
                      ) : (
                        <>
                          {Array.isArray(product.product.image) &&
                          product.product.image[0] &&
                          product.product.image[0].src ? (
                            <img
                              src={
                                "data:image/png;base64, " +
                                product.product.image[0].src
                              }
                              alt="rasm"
                            />
                          ) : (
                            <img
                              src={"https://hammol.uz/temp/noimage2.png"}
                              alt="rasm"
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="product__content product__content-row">
                  <p className="product__summary">{product.product.name}</p>
                  <div>
                    <div className="text-right">
                      <span className="product__cost product__cost--desktop">
                        {+product.product.cost * product.quantity}
                      </span>
                    </div>
                    <div className="mt-1 product-count">
                      <p className="count-input"> {product.quantity}</p>
                      <label className="product__count-label">дона</label>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
      <div>
        <div className="receive-product__address-home" id="homeContent">
          <div className="add-new-address">
            <div className="add-new-address__title">
              {Messages.t("Cash_and_plastic_cards")}
            </div>
            <div className="payment__method mb-2">
              <input
                type="radio"
                name="payment__type"
                id="card"
                onClick={() => setMethodOfPayment("withCard")}
              />
              <label
                htmlFor="card"
                className={`${methodOfPayment === "withCard" ? "active" : ""}`}
              >
                {Messages.t("WITH-CARD")}
              </label>
              <input
                type="radio"
                name="payment__type"
                id="cash"
                onClick={() => setMethodOfPayment("withCash")}
              />
              <label
                htmlFor="cash"
                className={`${methodOfPayment === "withCash" ? "active" : ""}`}
              >
                {Messages.t("In cash")}
              </label>
            </div>
            <div className="add-new-address__title">
              {Messages.t("Specify_address")}
            </div>
            <MapComponent setDriverInfo={setDriverInfo} />
          </div>
          {/* {!driverInfo?.driver &&
            (showBtn ? (
              <button
                className={`save-card--submit`}
                onClick={(e) => findDriver(e, index)}
              >
                {Messages.t("Formalization_order")}
              </button>
            ) : (
              <div className="search-car px-2">
                <img src="/static/img/loading.svg" alt="loading" />
                <h3>{Messages.t("Waiting_order")}</h3>
              </div>
            ))} */}
        </div>
        {methodOfPayment === "withCard" ? (
          <div className="payment-method mt-2">
            <div className="payment-method__wrap">
              <div className="method-wrap">
                <div className="payment-option">
                  {/* <div className="payment-method--type">
                      <div
                        onClick={() => paymentType("card")}
                        className={`method-header${
                          payment === "card" ? "-active" : ""
                        }`}
                      >
                        Card
                      </div>
                    </div>
                    <div className="payment-method--type">
                      <div
                        onClick={() => paymentType("instant")}
                        className={`method-header${
                          payment === "instant" ? "-active" : ""
                        }`}
                      >
                        Instant
                      </div>
                    </div> */}
                </div>
                <div className="payment-body">
                  {
                    // payment === "card" ? (
                    //   <>
                    //     <BuyWithCard
                    //       activeShop={activeShop}
                    //       driverInfo={driverInfo}
                    //     />
                    //   </>
                    // ) : (
                    <>
                      <BuyWithSaved
                        activeShop={activeShop}
                        driverInfo={driverInfo}
                      />
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="payment-method mt-2 mb-0">
            <div className="package-order">
              <button
                onClick={() => orderWithCashHandler()}
                className="save-card--submit"
              >
                Buyurtma berish
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopItem;
