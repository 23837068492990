import React from "react";
import { Toaster } from "react-hot-toast";

const MyToaster = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      toastOptions={{
        style: {
          fontSize: 16,
          color: "#17216b",
          fontWeight: 500,
        },
      }}
    />
  );
};

export default MyToaster;
