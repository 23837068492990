import React, { FC } from 'react';

interface IProps {
  initialState: string;
  postHandler: (rate: number) => void;
}

const Rating: FC<IProps> = ({ initialState, postHandler }) => {
  const [rating, setRating] = React.useState(Math.floor(+initialState));

  const handleSetRate = (rate: number) => {
    setRating(rate);
  };

  React.useEffect(() => {
    if (Math.floor(+initialState) !== rating) postHandler(rating);
  }, [rating]);

  return (
    <div className="product-card__rating-large">
      {[...Array(5)].map((el, i) => (
        <span
          key={i}
          className={
            i + 1 <= rating
              ? 'product-card__rating-star-large product-card__rating-star--active-large'
              : 'product-card__rating-star-large'
          }
          onClick={() => handleSetRate(i + 1)}></span>
      ))}
    </div>
  );
};

export default Rating;
