import {FETCH_ALL_ORDERS, FETCH_DETAILS_ORDERS, RECEIVE_MORE_ORDERS} from '../declare/constants'
import {OrderActions, OrderState} from "../declare/types";

const initialState: OrderState = {
    _page: 1,
    orders: [],
    isThereAgain: false
};

export default function OrderReducer(state: any = initialState, action: OrderActions): OrderState {

    switch (action.type) {

        case FETCH_ALL_ORDERS:
            return {
                ...state,
                orders: action.data.orders,
                isThereAgain: action.data.isThereAgain
            };

            case FETCH_DETAILS_ORDERS:
            return {
                ...state,
                detailsOrders: action.detailsOrders
            };

        case RECEIVE_MORE_ORDERS:
            return {
                ...state,
                _page: action.data._page,
                orders: [...state.orders, ...action.data.orders],
                isThereAgain: action.data.isThereAgain
            };

        default:
            return state;
    }

};
