import React, { Component } from "react";
import { Product } from "../types/Product";
import config from "../config/config";
import ProductRating from "./details/ProductRating";
import ProductNameSummary from "./details/ProductNameSummary";
import ProductCost from "./details/ProductCost";
import ProductBasket from "./details/ProductBasket";
import ProductDiscount from "./details/ProductDiscount";
import { preRenderCalc } from "../helpers/Helper";
import ProductFavorite from "./details/ProductFavorite";
import Messages from "../messages/Messages";
import { Link } from "react-router-dom";

type Props = {
  item: Product;
  actions: any;
  // activeShop: any;
  from: string;
};

class ProductAsListItem extends Component<Props> {
  hostUrl = config.hostUrl;

  render = () => {
    const { item, actions, from } = this.props;
    let className = "product-card-list";

    className = preRenderCalc(item, className);

    return (
      <li className="all-products__list-item" key={item.id}>
        <div className={className}>
          <div className="product-card-list__img">
            {Array.isArray(item.images) ? (
              <>
                {item.images[0] &&
                item.images[0].src &&
                item.images[0].src.includes("temp/") ? (
                  <img src={this.hostUrl + item.images[0].src} alt="rasm" />
                ) : (
                  <>
                    {item.image[0] && item.image[0].src ? (
                      <img
                        src={"data:image/png;base64, " + item.images[0].src}
                        alt="rasm"
                      />
                    ) : (
                      <img
                        src={"https://hammol.uz/temp/noimage2.png"}
                        alt="rasm"
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {Array.isArray(item.image) &&
                item.image[0] &&
                item.image[0].src &&
                item.image[0].src.includes("temp/") ? (
                  <img src={this.hostUrl + item.image[0].src} alt="rasm" />
                ) : (
                  <>
                    {Array.isArray(item.image) &&
                    item.image[0] &&
                    item.image[0].src ? (
                      <img
                        src={"data:image/png;base64, " + item.image[0].src}
                        alt="rasm"
                      />
                    ) : (
                      <img
                        src={"https://hammol.uz/temp/noimage2.png"}
                        alt="rasm"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="product-card-list__content">
            <ProductNameSummary name={item.name} summary={item.summary} />
            <ProductRating rating={item.rating} />
          </div>
          <div className="product-card-list__actions">
            <div className="product-card-list__price">
              <ProductCost
                cost={item.cost}
                currency={item.currency}
                price={item.price}
              />
            </div>

            {/* <div className="product-card-list__tofavorite"> */}
            {/* <span>{Messages.t('Add to favourites')}</span> */}
            {/* </div> */}
            <ProductBasket
              actions={{
                addOneProductToShoppingCart:
                  actions.addOneProductToShoppingCart,
                getFirstProducts: actions.getFirstProducts,
              }}
              item={item}
              // activeShop={activeShop}
            />
          </div>

          <ProductDiscount
            discount={item.discount}
            cost={item.cost}
            price={item.price}
          />
          <ProductFavorite id={item.id} />

          <Link
            className="product-card__link"
            to={{
              pathname: "/detail/" + item.id + "/" + from.split("/")[1],
              state: {
                product: item,
              },
            }}
          >
            {" "}
          </Link>
        </div>
      </li>
    );
  };
}

export default ProductAsListItem;
