import * as types from "./constants";
import { Category } from "../../common/types/Category";
import {
  FetchCategoriesAcn,
  SetCategoryLayerAcn,
  SetOpenSidebarAcn,
  SetOpenSubCatAcn,
  SetSubCategoriesAcn,
} from "./actionsTypes";

export function getCategoriesList(categories: Category[]): FetchCategoriesAcn {
  return { type: types.RECEIVE_ALL_CATEGORIES, categories };
}

export function setOpenSubCat(openSubCategory: boolean): SetOpenSubCatAcn {
  return { type: types.OPEN_SUB_CATEGORY, openSubCategory };
}

export function setOpenSidebar(opensidebar: boolean): SetOpenSidebarAcn {
  return { type: types.OPEN_SIDEBAR, opensidebar };
}

export function SetCategoryLayer(categoryLayer: number): SetCategoryLayerAcn {
  return { type: types.SET_CATEGORY_LAYER, categoryLayer };
}

export function SetSubCategories(
  subCategories: number,
  subCategoriesParent: any
): SetSubCategoriesAcn {
  return { type: types.SET_SUB_CATEGORIES, subCategories, subCategoriesParent };
}
