import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SingleShopActions from "../store/actions";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import ShopProducts from "../components/ShopProducts";

// TO DO any
const mapStateToProps = (state: any) => {
  return {
    _page: state.ShopReducer._page,
    sort_by: state.ShopReducer.sort_by,
    view_mode: state.ShopReducer.view_mode,
    cartState: state.ShoppingCartReducer.shops,
    activeShop: state.ShoppingCartReducer.shop,
    messages: state.LangReducer.messages,
    singleShop: state.ShopReducer.singleShop,
    filter: state.ShopReducer.filter,
    min_cost: state.ShopReducer.minCost,
    max_cost: state.ShopReducer.maxCost,
    is_modal_open: state.ShopReducer.categoryModalOpen,
    shopitems_type: state.ShopReducer.shopitems_type,
    loading: state.ShopReducer.loading,
    error: state.ShopReducer.error,
    shopProductsLoading: state.ShopReducer.singleShop.loading,
    shopProductsError: state.ShopReducer.singleShop.error,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(SingleShopActions, dispatch),
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopProducts);
