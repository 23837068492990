import React, { Component } from "react";
import MobileApp from "../../common/components/static/MobileApp";
import Bubbles from "../../common/components/static/Bubbles";

import { bindActionCreators } from "redux";
import * as AuthActions from "../../login/store/actions";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import HelmetComponent from "../../_temp/helmet";
import Messages from "../../common/messages/Messages";
import { Link } from "react-router-dom";
import authApi from "../../common/api/AuthApi";
import Password from "../../common/components/Password";
import PhoneNumber from "../../common/components/PhoneNumber";
import signUpApi from "../../common/api/SignUpApi";
import statusApi from "../../common/api/StatusApi";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

class RestoreConfirm extends Component<any, any> {
  constructor(props: any) {
    super(props);

    const { authUser } = this.props;
    if (authUser) {
      window.location.href = "#/";
    }

    this.state = {
      phoneNumber: "",
      password: "",
      btnDisabled: true,
      count: 60,
    };
  }

  timer: any;

  login = (el: any) => {
    el.preventDefault();
    let params = {
      phone: "998" + this.state.phoneNumber,
      code: this.state.password,
    };
    const { history } = this.props;
    console.log(this.props);

    signUpApi
      .confirm(params)
      .then((res: any) => res.json())
      .then((result) => {
        localStorage.setItem("token", result.token);
        history.push("/");
        statusApi
          .status()
          .then((result: any) => result.json())
          .then((result) => {
            localStorage.setItem("authUser", JSON.stringify(result.user));
            this.props.actions.authInitAcn();
          });
      })
      .catch((error) => {
        toast("Yangi parolni tasdiqlashda xatolik yuz berdi.");
      });

    // authApi
    //   .login(params)
    //   .then((res: any) => {
    //     return res.json();
    //   })
    //   .then((result: any) => {
    //     localStorage.setItem("token", result.token);
    //     actions.authLoginAcn(result.user, result.token);
    //     window.location.href = "#/";
    //   })
    //   .then(() => {})
    //   .catch((error) => {
    //     console.log(error);
    //     alert("Tizimga kirishda xatolik yuz berdi.");
    //   });
  };

  setPhoneNumber = (phoneNumber: string) => {
    this.setState({
      ...this.state,
      phoneNumber: phoneNumber,
      btnDisabled: !(this.state.password && phoneNumber),
    });
  };

  setPassword = (password: string) => {
    this.setState({
      ...this.state,
      password: password,
      btnDisabled: !(this.state.phoneNumber && password),
    });
  };

  render = () => {
    const { actions } = this.props;

    return (
      <>
        <MyToaster />
        <HelmetComponent title={"Восстановление пароля"} />
        <section className="reg-section">
          <div className="container">
            <div className="reg-container">
              <div className="form-section">
                <a className="back-btn" href="#/">
                  <svg className="icon" width="6" height="10">
                    <use href="static/img/icons/sprite/sprite.svg#arrow" />
                  </svg>
                  {Messages.t("Come_back")}
                </a>
                <div className="form-wrap">
                  <div className="form-title-wrap">
                    <span className="form-title">{Messages.t("Login")} </span>
                  </div>
                  <form className="form" autoComplete="off">
                    <div className="form__input-wrap mb-2">
                      <PhoneNumber setPhoneNumber={this.setPhoneNumber} />
                    </div>
                    <div className="form__input-wrap mb-4">
                      <Password setPassword={this.setPassword} />
                    </div>
                    <div className="form__login-problem form__login-problem__restore">
                      <Link to="/restore">
                        {Messages.t("Forgot_your_password?")}{" "}
                      </Link>
                    </div>
                    <button
                      className={`form__button ${
                        this.state.btnDisabled ? "disabled" : ""
                      }`}
                      type="submit"
                      disabled={this.state.btnDisabled}
                      onClick={(el) => {
                        this.login(el);
                      }}
                    >
                      {Messages.t("LOGIN")}
                    </button>
                    <div className="cart-aside__reg">
                      <Link to="/registration">
                        {Messages.t("Registration")}
                      </Link>
                      <br />
                    </div>
                  </form>
                </div>
              </div>
              <MobileApp />
            </div>
            <Bubbles />
          </div>
        </section>
      </>
    );
  };
}

const mapStateToProps = (state: any) => ({
  authUser: state.AuthReducer.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestoreConfirm);
