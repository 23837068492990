import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swiper from "swiper";
import axios from "../common/api/BannerApi";
import config from "../common/config/config";

import "./hero.css";

type Props = {};
type IState = {
  list: Array<any>;
};

class Hero extends Component<Props, IState> {
  hostUrl = config.hostUrl;
  state = {
    list: [],
  };

  fetchBanner = async () => {
    await axios
      .getAll()
      .then((res) => res.json())
      .then(({ items }) => this.setState({ list: items }));
  };

  async componentDidMount() {
    await this.fetchBanner();
    new Swiper(".hero__slider .swiper-container", {
      pagination: { el: ".hero__slider-pagination", type: "fraction" },
      loop: true,
      autoplay: {
        delay: 5000,
      },

      spaceBetween: 20,
      navigation: {
        nextEl: ".hero__slider-btn-next",
        prevEl: ".hero__slider-btn-prev",
      },
      slidesPerView: 1,
    });
  }

  render = () => {
    return (
      <section className="hero-section">
        <div className="container">
          <div className="hero">
            <div className="hero__aside">
              <div className="hero__aside-cards">
                <div className="hero__card">
                  <div className="hero__card-title">Все товары</div>
                  <div className="hero__card-subtitle">Категории</div>
                  <span className="hero__card-link">
                    <svg className="hero__card-link-icon" width="6" height="10">
                      <use href="static/img/icons/sprite/sprite.svg#arrow" />
                    </svg>
                  </span>
                  <img src="static/img/3.png" alt="" />
                  <Link to={"/allproducts"}> </Link>
                </div>
                <div className="hero__card">
                  <span className="hero__card-title">Точки продаж</span>
                  <span className="hero__card-subtitle">Категории</span>
                  <span className="hero__card-link">
                    <svg className="hero__card-link-icon" width="6" height="10">
                      <use href="static/img/icons/sprite/sprite.svg#arrow" />
                    </svg>
                  </span>
                  <img src="static/img/4.png" alt="" />
                  <Link to="/markets">&nbsp;</Link>
                </div>
                <div className="hero__card">
                  <span className="hero__card-title">Все Магазины</span>
                  <span className="hero__card-subtitle">Категории</span>
                  <span className="hero__card-link">
                    <svg className="hero__card-link-icon" width="6" height="10">
                      <use href="static/img/icons/sprite/sprite.svg#arrow" />
                    </svg>
                  </span>
                  <img src="static/img/5.png" alt="" />
                  <Link to="/shops">&nbsp;</Link>
                </div>
              </div>
              <div className="mobile-category">
                <ul className="mobile-category__list">
                  <Link className="mobile-category__item" to="/shops">
                    <span className="mobile-category__link">
                      <svg className="icon" width="26" height="26">
                        <use href="static/img/icons/sprite/sprite.svg#slideshow" />
                      </svg>
                      <span>Магазины</span>
                    </span>
                  </Link>
                  <Link className="mobile-category__item" to="/allproducts">
                    <span className="mobile-category__link">
                      <svg className="icon" width="26" height="26">
                        <use href="static/img/icons/sprite/sprite.svg#shopping-bag" />
                      </svg>
                      <span>Все товары</span>
                    </span>
                  </Link>
                  <Link className="mobile-category__item" to="/markets">
                    <span className="mobile-category__link">
                      <svg className="icon" width="26" height="26">
                        <use href="static/img/icons/sprite/sprite.svg#archive" />
                      </svg>
                      <span>Точки продаж</span>
                    </span>
                  </Link>
                  <Link className="mobile-category__item" to="/shops">
                    <span className="mobile-category__link">
                      <svg className="icon" width="26" height="26">
                        <use href="static/img/icons/sprite/sprite.svg#pin" />
                      </svg>
                      <span>На карте</span>
                    </span>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="hero__slider">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  {this.state.list.map((item: any) => (
                    <div className="swiper-slide" key={item.id}>
                      <div className="main-slide">
                        <div className="main-slide__img-block">
                          {item?.image?.src?.includes("/temp") ? (
                            <img
                              src={`${
                                this.hostUrl + item.image.src
                              }?version=${Date.now()}`}
                            />
                          ) : (
                            <img
                              src={"https://hammol.uz/temp/noimage.png"}
                              alt="rasm"
                            />
                          )}
                          {/* <>
                            {Array.isArray(item.image) ? (
                              <>
                                {item.images[0] &&
                                item.images[0].src &&
                                item.images[0].src.includes("tmp/") ? (
                                  <img
                                    src={this.hostUrl + item.images[0].src}
                                    alt="rasm"
                                  />
                                ) : (
                                  <>
                                    {item.image[0] && item.image[0].src ? (
                                      <img
                                        src={
                                          "data:image/png;base64, " +
                                          item.images[0].src
                                        }
                                        alt="rasm"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          "https://hammol.uz/temp/noimage.png"
                                        }
                                        alt="rasm"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {Array.isArray(item.image) &&
                                item.image[0] &&
                                item.image[0].src &&
                                item.image[0].src.includes("temp/") ? (
                                  <img
                                    src={this.hostUrl + item.image[0].src}
                                    alt="rasm"
                                  />
                                ) : (
                                  <>
                                    {Array.isArray(item.image) &&
                                    item.image[0] &&
                                    item.image[0].src ? (
                                      <img
                                        src={
                                          "data:image/png;base64, " +
                                          item.image[0].src
                                        }
                                        alt="rasm"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          "https://hammol.uz/temp/noimage.png"
                                        }
                                        alt="rasm"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </> */}
                        </div>
                        {/* ============================== banner changes ============================= */}
                        {/* <div className="main-slide__text-block"> */}
                        {/* <h1 className="main-slide__title">{item.titleoz}</h1>
                          <p className="main-slide__desc">{item.textoz}</p> */}
                        {/* <div className="main-slide__text-block-img">
                            <img src="static/img/nespresso-logo.svg" alt="" />
                          </div> */}
                        {/* {item.link && (
                            <Link
                              to={item.link.split("#/")[1]}
                              className="hero-show_more-btn"
                              role="button"
                            >
                              Batafsil
                            </Link>
                          )} */}
                        {/* </div> */}
                        {/* ============================================= */}
                        {/* <a className="main-slide__link" href="google.com">
                        &nbsp;
                      </a> */}
                      </div>
                    </div>
                  ))}

                  {/* <div className="swiper-slide">
                    <div className="main-slide">
                      <div className="main-slide__img-block">
                        <img src="static/img/2.png" alt="" />
                        <img src="static/img/1.png" alt="" />
                      </div>
                      <div className="main-slide__text-block">
                        <h1 className="main-slide__title">123</h1>
                        <p className="main-slide__desc">
                          Получите запас капсул на 2 месяца, при покупке
                          кофе-машины Nespresso
                        </p>
                        <div className="main-slide__text-block-img">
                          <img src="static/img/nespresso-logo.svg" alt="" />
                        </div>
                      </div>
                      <a className="main-slide__link" href="google.com">
                        &nbsp;
                      </a>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="main-slide">
                      <div className="main-slide__img-block">
                        <img src="static/img/2.png" alt="" />
                        <img src="static/img/1.png" alt="" />
                      </div>
                      <div className="main-slide__text-block">
                        <h1 className="main-slide__title">
                          Заряд свежего кофе, каждое утро
                        </h1>
                        <p className="main-slide__desc">
                          Получите запас капсул на 2 месяца, при покупке
                          кофе-машины Nespresso
                        </p>
                        <div className="main-slide__text-block-img">
                          <img src="static/img/nespresso-logo.svg" alt="" />
                        </div>
                      </div>
                      <a className="main-slide__link" href="google.com">
                        {" "}
                        &nbsp;
                      </a>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="main-slide">
                      <div className="main-slide__img-block">
                        <img src="static/img/2.png" alt="" />
                        <img src="static/img/1.png" alt="" />
                      </div>
                      <div className="main-slide__text-block">
                        <h1 className="main-slide__title">123131231232</h1>
                        <p className="main-slide__desc">
                          Получите запас капсул на 2 месяца, при покупке
                          кофе-машины Nespresso
                        </p>
                        <div className="main-slide__text-block-img">
                          <img src="static/img/nespresso-logo.svg" alt="" />
                        </div>
                      </div>
                      <a className="main-slide__link" href="google.com">
                        &nbsp;
                      </a>
                    </div>
                  </div> */}
                </div>
                <div className="hero__slider-pagination" />
                <div className="hero__slider-btns">
                  <span className="hero__slider-btn-prev hero__slider-btn">
                    <svg className="icon" width="6" height="10">
                      <use href="static/img/icons/sprite/sprite.svg#arrow" />
                    </svg>
                  </span>
                  <span className="hero__slider-btn-next hero__slider-btn">
                    <svg className="icon" width="6" height="10">
                      <use href="static/img/icons/sprite/sprite.svg#arrow" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default Hero;
