import React, { Component } from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { Product } from "../../common/types/Product";
import ProductAsOrderListItem from "../../common/components/ProductAsOrderListItem";
import {
  formInputActiveClassManage,
  generateUUID,
} from "../../common/helpers/Helper";
import { IBuy } from "../store/types";
import Messages from "../../common/messages/Messages";
import { AUTH_USER } from "../../login/store/constants";
import MapComponent from "./MapComponent";
import MyAddress from "../../header/components/MyAddress";
import "./Buy.css";
import UserCard from "../../common/components/UserCard";
import SaveCardForm from "../../common/components/SaveCardForm";
import BuyWithCard from "./BuyWithCard";
import BuyWithSaved from "./BuyWithSaved";
import axios from "../../common/api/BuyingApi";
import driverAxios from "../../common/api/DriverApi";
import HelmetComponent from "../../_temp/helmet";
import ShopItem from "./ShopItem";
import "../../common/components/css/UserCard.css";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

// React.createContext({});

//TO DO center of Tashkent

class Buy extends Component<any, any> {
  hostUrl: any = "https://hammol.uz";
  constructor(props: any) {
    super(props);

    this.state = {
      address: null,
      porch: null,
      floor: null,
      flat: null,
      coords_lat: 41.311123,
      coords_lon: 69.279712,
      width: "100%",
      height: "400px",
      paymentMethod: "card",
      tab: null,
      showBtn: true,
      methodOfPayment: "withCash",
      showModal: false,
    };

    let authUser: any = localStorage.getItem(AUTH_USER);
    if (authUser == null) {
      window.location.href = "#/";
    }
    formInputActiveClassManage();
  }

  componentDidMount(): void {
    formInputActiveClassManage();
    //TO DO
    //let self = this;
    // if ("geolocation" in navigator) {
    //     navigator.geolocation.getCurrentPosition(function (position) {
    //         console.log("Latitude is :", position.coords.latitude);
    //         console.log("Longitude is :", position.coords.longitude);
    //         self.setState({
    //             ...self.state,
    //             coords_lat: position.coords.latitude,
    //             coords_lon: position.coords.longitude
    //         });
    //     });
    // } else {
    //     console.log("Not Available");
    // }
  }
  getAddress = () => {
    return (
      this.state.address +
      " " +
      (this.state.porch || "") +
      " " +
      (this.state.floor || "") +
      " " +
      (this.state.flat || "")
    ).trimRight();
  };

  addOrder = (element: any) => {
    element.preventDefault();
    const { authUser, shoppingCart, actionsShoppingCart } = this.props;

    if (authUser.user && shoppingCart.shop_id) {
      if (!this.state.address) {
        toast(Messages.t("Please enter an address"));
        return false;
      }

      let params: IBuy;
      let productsList: any = [];

      Object.keys(shoppingCart.products).map((key: string) => {
        let product = shoppingCart.products[key];
        let item = {
          thing: product.id,
          counts: product.quantity,
          costs: +product.product.cost * +product.quantity,
          times: shoppingCart.updated_at,
        };
        productsList.push(item);
        return 1;
      });

      params = {
        address: this.getAddress(),
        uuid: generateUUID(authUser.user),
        shop: shoppingCart.shop_id,
        cost: shoppingCart.totalCost,
        point: "41.342306, 69.2197516",
        list: JSON.stringify(productsList),
      };

      axios
        .post("buying", params)
        .then((result: any) => result?.json())
        .then((result) => {
          if (result?.hasOwnProperty("status")) {
            actionsShoppingCart.resetFromShoppingCart();
            window.location.href = "#/my-orders";
          } else {
          }
        });
    }
  };

  paymentType = (e: any) => {
    let value = e;
    if (value !== this.state.paymentMethod) {
      this.setState({
        ...this.state,
        paymentMethod: value,
      });
    }
  };

  findDriver = async (e: any, index: number | null) => {
    let elem = e.target;
    let placeLocal: any = localStorage.getItem("userAddress");
    let placeJson = JSON.parse(placeLocal);
    let shoppingCart: any = localStorage.getItem("shoppingCart");
    let shoppingCartObj = JSON.parse(shoppingCart);
    let driverInfo = this.props.driverInfo;

    let shops: any = [];
    if (index === null) {
      shoppingCartObj.shops.map((shop: any) => {
        shops.push(shop.id);
      });
    } else {
      shops.push(shoppingCartObj.shops[index].id);
    }
    if (!placeJson.point.length) {
      return toast("Imtimos manzilni belgilang!");
    }
    if (!shops.length) {
      return toast("Savatchangizda mahsulotlar mavjud emas!");
    }
    elem.disabled = true;
    this.setState({ showBtn: false });

    if (driverInfo?.id) {
      try {
        const response = await driverAxios
          .get(
            `route/confirm/${driverInfo.id}?address=${
              placeJson.address
            } | Kirish:${
              placeJson.porch ? placeJson.porch : "Yo'q\n"
            } | Qavat:${
              placeJson.floor ? placeJson.floor : "Yo'q\n"
            } | Xonadon:${placeJson.flat ? placeJson.flat : "Yo'q\n"}`
          )
          .then(({ data }: any) => data);
        if (+response.id === +driverInfo.id) {
          let stop = false;
          const checkTimer = setTimeout(() => {
            if (!driverInfo?.driver) {
              stop = true;
            } else {
              clearTimeout(checkTimer);
            }
          }, 60000);

          const timer = setInterval(async () => {
            if (!stop) {
              try {
                const response = await driverAxios
                  .get(`route/${driverInfo?.id}`)
                  .then(({ data }: any) => data.result);
                if (response.driver) {
                  this.props.actionsBuying.setDriverInfo(response);
                  this.setState({ showBtn: true });
                  clearInterval(timer);
                  clearTimeout(checkTimer);
                  if (this.state.methodOfPayment === "withCard") {
                    // order with card
                  } else {
                    this.orderWithCashHandler();
                  }
                }
              } catch (error) {
                toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
                clearTimeout(checkTimer);
                clearInterval(timer);
                console.clear();
                console.info(error);
                elem.disabled = false;
                this.setState({ showBtn: true });
              }
            } else {
              toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
              elem.disabled = false;
              this.setState({ showBtn: true });
              clearInterval(timer);
              clearTimeout(checkTimer);
            }
          }, 5000);
        }
      } catch (error) {
        toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
        console.clear();
        console.info(error);
        elem.disabled = false;
        this.setState({ showBtn: true });
      }
    } else {
      elem.disabled = false;
      this.setState({ showBtn: true });
      toast("Haydovchi topilmadi. Iltimos qaytadan urinib ko'ring!");
    }
  };

  orderWithCashHandler = async () => {
    const { activeShop, driverInfo } = this.props;
    let placeLocal: any = localStorage.getItem("userAddress");
    let placeJson = JSON.parse(placeLocal);
    if (placeJson && driverInfo.price > 0 && placeJson.fullName) {
      try {
        let productsLocal: any = localStorage.getItem("shoppingCart");
        let productsJson: any = JSON.parse(productsLocal);
        let products: any[] = [];

        let address = `Kirish:${
          placeJson.porch ? placeJson.porch : "Yo'q"
        } | Qavat:${placeJson.floor ? placeJson.floor : "Yo'q"} | Xonadon:${
          placeJson.flat ? placeJson.flat : "Yo'q"
        } | ${placeJson.address}`.slice(0, 115);

        let place = {
          address: address,
          route_id: Number(driverInfo.id),
        };

        if (activeShop === null) {
          productsJson.shops.forEach((shop: any) => {
            shop.products.forEach((product: any) => {
              products.push({
                uuid: product.id,
                count: product.quantity,
                shop: product.shop_id,
              });
            });
          });
        } else {
          productsJson.shops[activeShop].products.forEach((product: any) => {
            products.push({
              uuid: product.id,
              count: product.quantity,
              shop: product.shop_id,
            });
          });
        }

        await axios.post(`order/v2/cash`, {
          items: products,
          place: place,
        });

        const { actionsShoppingCart, actionsBuying } = this.props;
        if (activeShop === null) {
          localStorage.removeItem("shoppingCart");
          actionsShoppingCart.resetFromShoppingCart();
        } else {
          actionsBuying.resetBuyProducts();
          actionsShoppingCart.removeShop(productsJson.shops[activeShop].id);
        }
        window.location.href = "#/my-orders";
      } catch (error) {
        console.log("ERROR", error);
        toast.error(
          "To`lov qilishda xatolik yuz berdi, Iltimos qaytadan urinib ko`ring."
        );
      }
    } else {
      toast.error("Адрес или данные вашей карты не правильны");
    }
  };

  setShowModal = (bool: boolean) => {
    this.setState({ showModal: bool });
  };

  render = () => {
    const {
      products,
      shop,
      authUser,
      totalQuantity,
      shoppingCart,
      actionsBuying,
      activeShop,
      driverInfo,
    } = this.props;
    const payment = this.state.paymentMethod;
    const totalCost = shop.reduce(
      (sum: number, shop: any) =>
        sum +
        shop.products.reduce((sum: number, product: any) => {
          return sum + product.quantity * +product.product.cost;
        }, 0),
      0
    );

    formInputActiveClassManage();
    return (
      <>
        <HelmetComponent title={Messages.t("Clearance_of_product")} />
        <Header />
        <MyToaster />
        <div className="main">
          <section className="cart-section">
            <div className="container">
              <div className="grid-container-2">
                <div className="content">
                  <div>
                    <div className="order-page-top">
                      <div className="cart-top">
                        <span className="cart-top__title">
                          {Messages.t("Clearance_of_product")}
                        </span>
                        <span className="cart-top__store">
                          {Messages.t("Shop")}
                        </span>
                        <div className="cart-top__store-img">
                          {shop && (
                            <span className={"selected-shop"}>
                              <span className={"shop-name"}>{shop.name}</span>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="client-name">
                        <div className="client-name__inputs">
                          <div className="form__input-wrap form__input--active">
                            <label>{Messages.t("Name")}</label>
                            <h2> {authUser.name}</h2>
                          </div>
                        </div>
                        <div className="client-name__info">
                          <svg className="icon" width="15" height="15">
                            <use href="img/icons/sprite/sprite.svg#hint" />
                          </svg>
                          <p className="client-name__info-text">
                            {Messages.t("Personal_data_you_can_change")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="simple-title">
                      <span className="simple-title__text">
                        {Messages.t("Get_product")}
                      </span>
                    </div>
                    <div className="receive-product">
                      <div className="receive-product__options-wrap">
                        <div className="receive-product__option">
                          <input
                            type="radio"
                            id="home"
                            name="delivery-option"
                            defaultChecked={true}
                          />
                          <label
                            className="receive-product__option-label"
                            htmlFor="home"
                          >
                            <span className="receive-product__option-title">
                              {Messages.t("Delivery_to_home")}
                            </span>
                            <p className="receive-product__option-desc">
                              {Messages.t(
                                "Indicate_the_address_to_which_you_want_to_deliver_the_goods"
                              )}
                            </p>
                            <span className="receive-product__option-checkbox" />
                          </label>
                        </div>
                      </div>
                      <div className="receive-product__address-wrap mb-3">
                        <div className="receive-product__address-home">
                          <div className="from-one_shop">
                            <ul className="from-one_shop-tabs">
                              <li
                                className={
                                  this.state.tab === null ? "active" : ""
                                }
                                onClick={() => {
                                  this.setState({
                                    tab: null,
                                  });
                                  actionsBuying.setActiveShop(null);
                                }}
                              >
                                {Messages.t("PAYMENT-TABS")}
                              </li>
                              {Array(shoppingCart.shops.length)
                                .fill(null)
                                .map((value: any, i: number) => (
                                  <li
                                    key={`${value}_${i}`}
                                    className={
                                      this.state.tab === i ? "active" : ""
                                    }
                                    onClick={() => {
                                      this.setState({
                                        tab: i,
                                      });
                                      actionsBuying.setActiveShop(i);
                                    }}
                                  >
                                    {i + 1}.{shoppingCart.shops[i].shopName}
                                  </li>
                                ))}
                            </ul>
                            {this.state.tab === null ? (
                              shoppingCart.shops.map((shop: any, i: number) => {
                                return (
                                  <div
                                    key={shop?.id || i}
                                    className="from-one_shop-item"
                                  >
                                    <h1>Do'kon: {shop.shopName}</h1>
                                    <ul className="product-list mb-2">
                                      {shop.products.map((product: any) => (
                                        <li
                                          key={product.id}
                                          className="product-list__item"
                                        >
                                          <div className="product">
                                            <div className="product__img">
                                              {Array.isArray(
                                                product.product.images
                                              ) ? (
                                                <>
                                                  {product.product.images[0] &&
                                                  product.product.images[0]
                                                    .src &&
                                                  product.product.images[0].src.includes(
                                                    "temp/"
                                                  ) ? (
                                                    <img
                                                      src={
                                                        this.hostUrl +
                                                        product.product
                                                          .images[0].src
                                                      }
                                                      alt="rasm"
                                                    />
                                                  ) : (
                                                    <>
                                                      {console.log(
                                                        product.product.image[0]
                                                      )}
                                                      {product.product
                                                        .image[0] &&
                                                      product.product.image[0]
                                                        .src ? (
                                                        <img
                                                          src={
                                                            "data:image/png;base64, " +
                                                            product.product
                                                              .images[0].src
                                                          }
                                                          alt="rasm"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            "https://hammol.uz/temp/noimage2.png"
                                                          }
                                                          alt="rasm"
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {Array.isArray(
                                                    product.product.image
                                                  ) &&
                                                  product.product.image[0] &&
                                                  product.product.image[0]
                                                    .src &&
                                                  product.product.image[0].src.includes(
                                                    "temp/"
                                                  ) ? (
                                                    <img
                                                      src={
                                                        this.hostUrl +
                                                        product.product.image[0]
                                                          .src
                                                      }
                                                      alt="rasm"
                                                    />
                                                  ) : (
                                                    <>
                                                      {Array.isArray(
                                                        product.product.image
                                                      ) &&
                                                      product.product
                                                        .image[0] &&
                                                      product.product.image[0]
                                                        .src ? (
                                                        <img
                                                          src={
                                                            "data:image/png;base64, " +
                                                            product.product
                                                              .image[0].src
                                                          }
                                                          alt="rasm"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            "https://hammol.uz/temp/noimage2.png"
                                                          }
                                                          alt="rasm"
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                            <div className="product__content product__content-row">
                                              <p className="product__summary">
                                                {product.product.name}
                                              </p>
                                              <div>
                                                <div className="text-right">
                                                  <span className="product__cost product__cost--desktop">
                                                    {+product.product.cost *
                                                      product.quantity}
                                                  </span>
                                                </div>
                                                <div className="mt-1 product-count">
                                                  <p className="count-input">
                                                    {product.quantity}
                                                  </p>
                                                  <label className="product__count-label">
                                                    дона
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                );
                              })
                            ) : (
                              <ShopItem
                                resetBuyProducts={
                                  this.props.actionsBuying.resetBuyProducts
                                }
                                removeShop={
                                  this.props.actionsShoppingCart.removeShop
                                }
                                hostUrl={this.hostUrl}
                                index={this.state.tab}
                                payment={payment}
                                paymentType={this.paymentType}
                                shoppingCart={shoppingCart}
                                key={this.state.tab}
                                activeShop={activeShop}
                                driverInfo={driverInfo}
                                setDriverInfo={actionsBuying.setDriverInfo}
                                orderWithCashHandler={this.orderWithCashHandler}
                                showBtn={this.state.showBtn}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.tab === null && (
                        <>
                          <div className="receive-product__address-wrap">
                            <div
                              className="receive-product__address-home"
                              id="homeContent"
                            >
                              <div className="add-new-address">
                                <div className="add-new-address__title">
                                  {Messages.t("Cash_and_plastic_cards")}
                                </div>
                                <div className="payment__method mb-2">
                                  <input
                                    type="radio"
                                    name="payment__type"
                                    id="card"
                                    onClick={() =>
                                      this.setState({
                                        methodOfPayment: "withCard",
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="card"
                                    className={`${
                                      this.state.methodOfPayment === "withCard"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    {Messages.t("WITH-CARD")}
                                  </label>
                                  <input
                                    type="radio"
                                    name="payment__type"
                                    id="cash"
                                    onClick={() =>
                                      this.setState({
                                        methodOfPayment: "withCash",
                                      })
                                    }
                                  />
                                  <label
                                    htmlFor="cash"
                                    className={`${
                                      this.state.methodOfPayment === "withCash"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    {Messages.t("In cash")}
                                  </label>
                                </div>
                                <div className="add-new-address__title">
                                  {Messages.t("Specify_address")}
                                </div>
                                <MapComponent
                                  setShowModal={this.setShowModal}
                                  setDriverInfo={actionsBuying.setDriverInfo}
                                />
                                {this.state.showModal && (
                                  <div
                                    onClick={() =>
                                      this.setState({ showModal: false })
                                    }
                                    className="modal"
                                    style={
                                      this.state.showModal
                                        ? {
                                            opacity: 1,
                                            visibility: "visible",
                                            pointerEvents: "all",
                                            background: "rgba(0,0,0,0.8)",
                                          }
                                        : {}
                                    }
                                  >
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <MyAddress
                                        setShowModal={this.setShowModal}
                                        showModal={this.state.showModal}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              {!driverInfo?.driver &&
                                (this.state.showBtn ? (
                                  this.state.methodOfPayment === "withCash" && (
                                    <button
                                      className={`save-card--submit`}
                                      // onClick={(e) => this.findDriver(e, null)}
                                      onClick={(e) =>
                                        this.orderWithCashHandler()
                                      }
                                    >
                                      {Messages.t("Formalization_order")}
                                    </button>
                                  )
                                ) : (
                                  <div className="search-car px-2">
                                    <img src="/static/img/loading.svg" />
                                    <h3>{Messages.t("Waiting_order")}</h3>
                                  </div>
                                ))}
                            </div>
                          </div>
                          {/* {driverInfo.driver && (
                            <div>
                              <div className="simple-title">
                                <span className="simple-title__text">
                                  Haydovchi ma'lumotlari
                                </span>
                              </div>
                              <div className="payment-method">
                                <div className="payment-method__wrap">
                                  <p>
                                    <span>Haydovchi ismi</span>
                                    {driverInfo?.driver_name}
                                  </p>
                                  <p>
                                    <span>Telefon raqami</span>
                                    {driverInfo?.driver_phone}
                                  </p>
                                  <p>
                                    <span>Avtomobil raqami</span>
                                    {driverInfo?.car_number}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )} */}
                          {this.state.methodOfPayment === "withCard" && (
                            <div>
                              <div className="simple-title">
                                {/* <span className="simple-title__text">
                                  {Messages.t("Payment_method")}
                                </span> */}
                              </div>
                              <div className="payment-method">
                                <div className="payment-method__wrap">
                                  <div className="method-wrap">
                                    <div className="payment-option">
                                      {/* <div className="payment-method--type">
                                        <div
                                          // disabled
                                          onClick={() =>
                                            this.paymentType("card")
                                          }
                                          className={`method-header${
                                            payment === "card" ? "-active" : ""
                                          }`}
                                        >
                                          Card
                                        </div>
                                      </div> */}
                                      {/* <div className="payment-method--type">
                                        <div
                                          onClick={() =>
                                            this.paymentType("instant")
                                          }
                                          className={`method-header${
                                            payment === "instant"
                                              ? "-active"
                                              : ""
                                          }`}
                                        >
                                          Instant
                                        </div>
                                      </div> */}
                                    </div>
                                    <div className="payment-body">
                                      {
                                        // payment === "card" ? (
                                        //   <>
                                        //     <BuyWithCard
                                        //       setDriverInfo={
                                        //         this.props.actionsBuying
                                        //           .setDriverInfo
                                        //       }
                                        //       driverInfo={driverInfo}
                                        //       activeShop={activeShop}
                                        //     />
                                        //   </>
                                        // ) :
                                        <>
                                          <BuyWithSaved
                                            driverInfo={driverInfo}
                                            activeShop={activeShop}
                                          />
                                        </>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {/* {this.state.tab === null && (
                      
                    )} */}
                    {/*<div className="order-comments">*/}
                    {/*    <div className="order-comments__row">*/}
                    {/*        <div className="order-comments__btn">*/}
                    {/*            <button className="form__button" type="submit"*/}
                    {/*                    onClick={this.addOrder}>*/}
                    {/*                {Messages.t('Formalization_order')}*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*        <div className="order-comments__offer">*/}
                    {/*            <p className="order-comments__offer-text">*/}
                    {/*                {Messages.t('Approve_buying_')}*/}
                    {/*                <a href="/">*/}
                    {/*                    {Messages.t('_buying_condition')}*/}
                    {/*                </a>*/}
                    {/*                {Messages.t('_condition_site')}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <aside className="aside">
                  <div className="cart-aside">
                    <div className="cart-aside__title">
                      <div className="form-title-wrap">
                        <span className="form-title">
                          {Messages.t("Order")}
                        </span>
                        <span className="form-subtitle">
                          <a href="#/">&nbsp;</a>
                        </span>
                      </div>
                    </div>
                    <div className="order-products-wrap">
                      {products &&
                        totalQuantity > 0 &&
                        Object.keys(products).map((key) => {
                          let item: Product = products[key].product;
                          return (
                            <ProductAsOrderListItem
                              key={item.id}
                              item={item}
                              quantity={products[key].quantity}
                            />
                          );
                        })}
                    </div>
                    <div className="aside-order-info">
                      <span className="aside-order-info__name">
                        {Messages.t("Delivery amount depends on the address")}
                      </span>
                      <div className="aside-order-info__row">
                        <span className="aside-order-info__total">
                          {Messages.t("Total for goods")}
                        </span>
                        <span className="aside-order-info__total-sum">
                          {totalCost.toFixed(2)} сўм
                        </span>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  };
}

export default Buy;
