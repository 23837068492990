import {
  AUTH_AUTHORIZED,
  AUTH_LOGIN,
  AUTH_NOT_AUTHORIZED,
  AUTH_RESET_STATE,
  AUTH_SET_STEP_TO,
  AUTH_SET_USER_REG_CODE,
  AUTH_SET_USER_REG_MALE,
  AUTH_SET_USER_REG_NAME,
  AUTH_SIGN_UP_KEEP,
  AUTH_SIGN_UP_SEEK,
  AUTH_SIGN_UP_TAKE,
  AUTH_SIGN_UP_VARY,
} from "./constants";
import { IAuthState } from "./types";
import { IRegistrationActions } from "./actionsTypes";

const initialState: IAuthState = {
  user: {},
  token: "",
  grant: "",
  authStep: 1,
  userReg: {},
  phoneReg: {},
  sent: "",
};

export default function RegistrationReducer(
  state: any = initialState,
  action: IRegistrationActions
): any {
  switch (action.type) {
    case AUTH_SIGN_UP_SEEK:
      return {
        ...state,
        grant: action.grant,
        authStep: 1,
      };

    case AUTH_SIGN_UP_TAKE:
      return {
        ...state,
        phoneReg: action.phoneReg,
        sent: action.sent,
      };

    case AUTH_SIGN_UP_KEEP:
      return {
        ...state,
        userReg: action.userReg,
      };

    case AUTH_SIGN_UP_VARY:
      return {
        ...state,
        userVary: action.userVary,
      };

    case AUTH_LOGIN:
      return {
        ...state,
        user: action.user,
      };

    case AUTH_AUTHORIZED:
      return {
        ...state,
        token: action.token,
        user: action.user,
      };

    case AUTH_NOT_AUTHORIZED:
      return {
        ...state,
        token: "",
        user: {},
      };

    case AUTH_RESET_STATE:
      return {
        user: {},
        token: "",
        grant: "",
        authStep: 0,
        userReg: {},
        phoneReg: {},
        sent: "",
      };

    case AUTH_SET_STEP_TO:
      return {
        ...state,
        authStep: action.authStep,
      };

    case AUTH_SET_USER_REG_NAME:
      return {
        ...state,
        userReg: {
          ...state.userReg,
          name: action.name,
        },
      };

    case AUTH_SET_USER_REG_MALE:
      return {
        ...state,
        userReg: {
          ...state.userReg,
          male: action.male,
        },
      };

    case AUTH_SET_USER_REG_CODE:
      return {
        ...state,
        userReg: {
          ...state.userReg,
          code: action.code,
        },
      };

    default:
      return state;
  }
}
