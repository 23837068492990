import * as types from "./constants";
import { Product } from "../../common/types/Product";
import { IActionTopProductsTooMore } from "./actionsTypes";

export const fetchTopProducts = (products: Product[]) => {
  return { type: types.RECEIVE_TOP_PRODUCTS, products };
};

export function fetchMoreTopProducts(
  products: Product[],
  _page: number
): IActionTopProductsTooMore {
  return { type: types.RECEIVE_MORE_PRODUCTS, data: { products, _page } };
}

export const fetchTopProductsPending = () => ({
  type: types.FETCH_PRODUCTS_PENDING,
});
export const fetchTopProductsSuccess = () => ({
  type: types.FETCH_PRODUCTS_SUCCESS,
});
export const fetchTopProductsError = () => ({
  type: types.FETCH_PRODUCTS_ERROR,
});
export const setTopProductsLoading = (loading: boolean) => ({
  type: types.SET_TOP_PRODUCTS_LOADING,
  loading,
});
