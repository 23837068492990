import { AbstractApi } from "./AbstractApi";

class CategoryApi extends AbstractApi {
  getAll = (path: string, params: any = {}) => {
    const PATH = `commodity/v3/category${path}`;
    return this.fetchApi(PATH, this.REQUEST_METHOD_GET, params);
  };
}

const categoryApi = new CategoryApi();
export default categoryApi;
