import {
  RECEIVE_ALL_SHOPS,
  RECEIVE_SINGLE_SHOP,
  RECEIVE_MORE_SHOP_PRODUCTS,
  DEFAULT_VIEW_MODE,
  CHANGE_VIEW_MODE,
  DEFAULT_SORT_BY,
  SET_SORT_BY,
  SET_SHOPS_SORT_BY,
  RECEIVE_MORE_SHOPS,
  RECEIVE_SEARCH_PRODUCTS,
  SET_FILTER,
  SET_MIN_COST,
  SET_MAX_COST,
  SET_CATEGORYMODAL_OPEN,
  CHANGE_SHOPITEMS_TYPE,
  SET_SHOPCATEGORY_ITEMS,
  SET_SHOPCATEGORY_HEADER,
  SET_CATEGORY_PARAMS,
  SET_SHOPS_LOADING,
  SET_SHOPS_ERROR,
  SET_SHOP_PRODUCTS_LOADING,
  SET_SHOP_PRODUCTS_ERROR,
} from "./constants";
import { IAllShops } from "./types";
import { ShopActions } from "./actionsTypes";

const initialState: IAllShops = {
  shops: [],
  singleShop: {
    shopInfo: {},
    items: [],
    loading: false,
    error: null,
  },
  view_mode: DEFAULT_VIEW_MODE,
  sort_by: DEFAULT_SORT_BY,
  shops_sort_by: "",
  _page: 0,
  shops_page: 0,
  filter: {
    sort: "ratingDesc",
    type: true,
  },
  minCost: "",
  maxCost: "",
  shopitems_type: "",
  categoryModalOpen: false,
  categoryFilter: {
    headers: [],
    items: [],
    params: {},
  },
  loading: false,
  error: null,
};

function setFilter(state: IAllShops, key: string) {
  switch (key) {
    case "name":
      return {
        ...state,
        filter: {
          sort: state.filter.type ? "nameAsc" : "nameDesc",
          type: !state.filter.type,
        },
      };
    case "rating":
      return {
        ...state,
        filter: {
          sort: state.filter.type ? "ratingAsc" : "ratingDesc",
          type: !state.filter.type,
        },
      };
    case "price":
      return {
        ...state,
        filter: {
          sort: state.filter.type ? "priceAsc" : "priceDesc",
          type: !state.filter.type,
        },
      };
    case "sold":
      return {
        ...state,
        filter: {
          sort: state.filter.type ? "soldAsc" : "soldDesc",
          type: !state.filter.type,
        },
      };
    default:
      return state;
  }
}

export default function ShopReducer(
  state: any = initialState,
  action: ShopActions
): IAllShops {
  switch (action.type) {
    case RECEIVE_ALL_SHOPS:
      return {
        ...state,
        shops: action.shops.items,
        shops_page: 0,
      };
    case RECEIVE_SINGLE_SHOP:
      return {
        ...state,
        singleShop: {
          ...state.singleShop,
          shopInfo: action.singleShop.shopInfo,
        },
      };
    case SET_SHOPS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_SHOPS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case RECEIVE_MORE_SHOP_PRODUCTS:
      return {
        ...state,
        singleShop: {
          ...state.singleShop,
          items: [...state.singleShop.items, ...action.payload.items],
        },
        _page: action.payload._page,
      };

    case RECEIVE_SEARCH_PRODUCTS:
      return {
        ...state,
        singleShop: {
          ...state.singleShop,
          items: action.payload.items,
        },
        _page: action.payload._page,
      };
    case RECEIVE_MORE_SHOPS:
      return {
        ...state,
        shops: [...state.shops, ...action.shops],
        shops_page: action.page,
      };
    case SET_SHOP_PRODUCTS_LOADING:
      return {
        ...state,
        singleShop: {
          ...state.singleShop,
          loading: action.loading,
        },
      };
    case SET_SHOP_PRODUCTS_ERROR:
      return {
        ...state,
        singleShop: {
          ...state.singleShop,
          error: action.error,
        },
      };
    case CHANGE_VIEW_MODE:
      return {
        ...state,
        view_mode: action.view_mode,
      };
    case SET_SORT_BY:
      return {
        ...state,
        sort_by: action.sort_by,
      };
    case SET_SHOPS_SORT_BY:
      return {
        ...state,
        shops_sort_by: action.sort_by,
      };
    case SET_FILTER:
      return setFilter(state, action.payload);
    case SET_MIN_COST:
      return {
        ...state,
        minCost: action.payload,
      };
    case SET_MAX_COST:
      return {
        ...state,
        maxCost: action.payload,
      };
    case SET_CATEGORYMODAL_OPEN:
      return {
        ...state,
        categoryModalOpen: action.payload,
      };
    case CHANGE_SHOPITEMS_TYPE:
      return {
        ...state,
        shopitems_type: action.payload,
      };
    case SET_SHOPCATEGORY_HEADER:
      return {
        ...state,
        categoryFilter: {
          ...state.categoryFilter,
          headers: action.payload,
        },
      };
    case SET_SHOPCATEGORY_ITEMS:
      return {
        ...state,
        categoryFilter: {
          ...state.categoryFilter,
          items: action.payload,
        },
      };
    case SET_CATEGORY_PARAMS:
      return {
        ...state,
        categoryFilter: {
          ...state.categoryFilter,
          params: action.payload,
        },
      };
    default:
      return state;
  }

  // return action.type === RECEIVE_ALL_SHOPS ? {
  //     ...state,
  //     shops: action.shops
  // } : state;
}
