import config from "../config/config";
import fetch from "cross-fetch";
import {HEADER_WITH_API_KEY} from "../constants/constants";
import {delayMS} from "../helpers/Helper";

export class AbstractStatusApi {
    fetchApi = async (path: string) => {
        let token = localStorage.getItem('token') || '';

        let reqOptions: any = {method: "GET", headers: HEADER_WITH_API_KEY};

        if (token) reqOptions.headers.token = token;

        await delayMS(300);

        return await fetch(`${config.apiUrl}` + path + '/', reqOptions);
    };
}
