import * as types from './constants';
import {ChangeLangAcn, InitLangAcn, SetLangMessagesAcn} from "./actionsTypes";
import {IMessages} from "./types";

export function initLang(): InitLangAcn {
    return {type: types.INIT_LANG}
}

export function changeLang(lang: string): ChangeLangAcn {
    return {type: types.CHANGE_LANG, lang}
}

export function setLangMessages(messages: IMessages): SetLangMessagesAcn {
    return {type: types.SET_LANG_MESSAGES, messages}
}
