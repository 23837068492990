import axios from "axios";

const main_url = "https://hammol.uz/payment/api/v1/";
let token: any = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: main_url,
  headers: {
    "Content-type": "application/json",
    token: token,
  },
});

axiosInstance.interceptors.request.use(
  function (config: any) {
    config.headers.token = localStorage.getItem("token");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;

// class BuyingApi extends AbstractApi {

//     buying = (params: any = {}) => {
//         const PATH = 'buying';
//         return this.fetchApi(PATH, this.REQUEST_METHOD_POST, params);
//     };

//     buyingList = (params: any = {}) => {
//         const PATH = 'buying/list';
//         return this.fetchApi(PATH, this.REQUEST_METHOD_GET, params);
//     };

//     productDetail = (params: any = {}) => {
//         const PATH = 'detail';
//         return this.fetchApi(PATH, this.REQUEST_METHOD_VIEW, params);
//     };
// }

// const buyingApi = new BuyingApi();
// export default buyingApi;
