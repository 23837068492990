import { LANG_UZK, TOKEN_TIME } from '../constants/constants';

export type ILSItems = {
  tokenTime: string;
};

export type Config = {
  lang: typeof LANG_UZK;
  apiUrl: string;
  hostUrl: string;
  LSItems: ILSItems;
};

const config: Config = {
  lang: LANG_UZK,
  apiUrl: 'https://hammol.uz:443/api/',

  hostUrl: window.location.hostname === 'localhost' ? 'https://hammol.uz' : '',
  LSItems: {
    tokenTime: TOKEN_TIME,
  },
};

export default config;
