import React from "react";
import "./skeleton.scss";

interface ISkeletonProps {
  number?: number;
  className?: string;
}

const SkeletonLoader = ({ number, className }: ISkeletonProps) => {
  return (
    <>
      {Array(number ? number : 1)
        .fill(0)
        .map((_, index) => (
          <div key={index} className={`skeleton_card ${className}`}>
            <div className="skeleton_card__img skeleton"></div>
            <div className="skeleton_card__content">
              <div className="skeleton_card__rating skeleton"></div>
              <div className="skeleton_card__summary skeleton" />
              <div className="skeleton_card__summary skeleton" />
              <div className="skeleton_card__summary skeleton" />
              <div className="skeleton_card__summary skeleton last" />
              <div className="skeleton_card__cost skeleton"></div>
              <button className="skeleton_card__btn skeleton"></button>
            </div>
          </div>
        ))}
    </>
  );
};

export default SkeletonLoader;
