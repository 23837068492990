import { AbstractStatusApi } from "./AbstractStatusApi";

class StatusApi extends AbstractStatusApi {
  /**
   * Status - getting settings from state server
   */
  status = () => {
    const PATH = "auth/v1/me";
    return this.fetchApi(PATH);
  };
}

const statusApi = new StatusApi();
export default statusApi;
