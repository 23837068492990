import React, { Component } from 'react';

type Props = {
  discount: number;
  price: string;
  cost: string;
};

class ProductDiscount extends Component<Props> {
  render = () => {
    const { discount, price, cost } = this.props;

    const res = (
      (Number(price) / Number(cost)) * 100 -
      100
    ).toFixed(0);

    return discount ? (
      <div className="product-card__discount">
        <img src="static/img/discount.svg" alt="" />
        <span>
          Скидка
          <br />
          {res}%
        </span>
      </div>
    ) : (
      ''
    );
  };
}

export default ProductDiscount;
