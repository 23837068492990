import React from "react";
import MapComponent from "./MapComponent";

import "./myaddress.css";

const MyAddress = ({ showModal, setShowModal }) => {
  return showModal ? (
    <MapComponent showModal={showModal} setShowModal={setShowModal} />
  ) : (
    <div className="myaddress__wrapper">
      <MapComponent />
    </div>
  );
};

export default MyAddress;
