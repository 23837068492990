import React, { Component } from "react";
import "./LoginForm.css";
import PhoneNumber from "../../common/components/PhoneNumber";
import signUpApi from "../../common/api/SignUpApi";
import { withRouter } from "react-router-dom";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";
// import config from "../../config/config";

class RestoreForm extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      phoneNumber: "",
      password: "",
      btnDisabled: true,
    };
  }

  login = (el: any) => {
    el.preventDefault();
    let params = {
      phone: "998" + this.state.phoneNumber,
      //   grant: "",
    };
    // const {actions} = this.props;

    signUpApi
      .seek(params)
      .then((res: any) => res.json())
      .then((result: any) => {
        // if (!result.error) {
        this.props.history.push("/restore-confirm");
        // } else {
        //   alert("Parolni tiklashda xatolik yuz berdi.");
        // }
        // if (result && result.hasOwnProperty("grant")) {
        //   params.grant = result.grant;
        //   setTimeout(() => {
        //     this.lose(params);
        //   }, 200);
        // }
        // TO DO else
      })
      .catch((error) => {
        console.log(error);
        toast("Parolni tiklashda xatolik yuz berdi.");
      });
  };

  lose = (params: any) => {
    signUpApi
      .lose(params)
      .then((res: any) => {
        return res.json();
      })
      .then((result: any) => {
        if (result.hasOwnProperty("error")) {
          toast("Повторите через несколько минут!");
          return false;
        } else {
          // let wait, momentTime;
          // if(result.wait) wait = result.wait;
          // momentTime = Math.round((new Date()).getTime() / 1000);
          // localStorage.setItem(config.LSItems.tokenTime, JSON.stringify({
          //     [config.LSItems.tokenTime]: wait,
          //     momentTime
          // }))
          return true;
        }
      })
      .then((result) => {
        if (result) window.location.href = "#/login";
      });
  };

  setPhoneNumber = (phoneNumber: string) => {
    this.setState({
      ...this.state,
      phoneNumber: phoneNumber,
      btnDisabled: !phoneNumber,
    });
  };

  render = () => {
    return (
      <>
        <MyToaster />
        <form className="form" autoComplete="off">
          <div className="form__input-wrap mb-2">
            <PhoneNumber setPhoneNumber={this.setPhoneNumber} />
          </div>
          <button
            className={`form__button ${
              this.state.btnDisabled ? "disabled" : ""
            }`}
            type="submit"
            disabled={this.state.btnDisabled}
            onClick={(el) => {
              this.login(el);
            }}
          >
            Получить пароль
          </button>
          <div className="cart-aside__reg">
            <a href="#/registration">Зарегистрироваться</a>
            <br />
          </div>
        </form>
      </>
    );
  };
}

export default withRouter(RestoreForm);
