import React, { Component } from "react";
import { bindActionCreators } from "redux";
import * as RecommendedProductsActions from "../recommendations/store/actions";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import { connect } from "react-redux";
import {
  IRecommendedProductsState,
  IRecommendedProductsProps,
} from "./store/types";
import recommendedProductsApi from "../../common/api/RecommendedProductsApi";
import ProductAsItem from "../../common/components/ProductAsItem";
import "./Recommendations.css";
import Swiper from "swiper";

import { productShopAndBasket } from "../../common/helpers/Helper";
import SkeletonLoader from "../skeletonLoader";

class Recommendations extends Component<
  IRecommendedProductsProps,
  IRecommendedProductsState
> {
  addToShoppingCart = (productId: string) => {
    //
  };

  recommendationsOnLoad = () => {
    new Swiper(".recomendations-section .swiper-container", {
      slidesPerView: 5,
      navigation: {
        nextEl: ".recomendations-section .section-title__btn-next",
        prevEl: ".recomendations-section .section-title__btn-prev",
      },
      breakpoints: {
        300: { slidesPerView: 1 },
        450: { slidesPerView: 2 },
        768: { slidesPerView: 3 },
        1171: { slidesPerView: 5 },
      },
    });
  };

  componentDidMount(): void {
    const { actions } = this.props;
    actions.setRecommendedLoading(true);
    recommendedProductsApi
      .getAll()
      .then((res: any) => res.json())
      .then((result: any) => {
        actions.setRecommendedLoading(false);
        if (result.products && result.products.length > 0) {
          actions.fetchRecommendedProducts(result.products);
        }
      });
    this.getFirstProducts();
  }

  getFirstProducts = () => {
    const { activeShop } = this.props;
    let params: any = {
      start: 0,
    };
    if (activeShop) {
      params.shop = activeShop.id;
    }
  };
  render = () => {
    let { products, actionsShoppingCart, cartState, loading } = this.props;
    let productsInTags: any = [];
    if (products && products.length > 0) {
      let active_item_class: string = "";
      let item_class: string = "";
      for (let i = 0; i < products.length; i++) {
        let item = products[i];
        item_class = "swiper-slide ";
        active_item_class = "";

        item = productShopAndBasket(item, cartState);

        {
          item.productactive &&
            item.shopactive &&
            productsInTags.push(
              <div className={item_class} key={item.id}>
                <ProductAsItem
                  key={item.id}
                  item={item}
                  actions={{
                    ...actionsShoppingCart,
                  }}
                  from={"home"}
                />
              </div>
            );
        }
        if (active_item_class === "") {
          active_item_class = "";
        }
      }
    }

    return (
      <div className="container">
        <section className="recomendations-section">
          <div className="section-title px-2">
            <div className="section-title__text">
              <span className="section-title__main">Hammol рекомендует</span>
              <span className="section-title__desc">
                Только самое избранное и выгодное
              </span>
            </div>
            <div className="section-title__btns">
              <span className="section-title__btn section-title__btn-prev">
                <svg className="icon" width="6" height="10">
                  <use href="static/img/icons/sprite/sprite.svg#arrow" />
                </svg>
              </span>
              <span className="section-title__btn section-title__btn-next">
                <svg className="icon" width="6" height="10">
                  <use href="static/img/icons/sprite/sprite.svg#arrow" />
                </svg>
              </span>
            </div>
          </div>
          <div className="recomendations">
            {loading ? (
              <div className="swiper-container">
                <div
                  className="swiper-wrapper"
                  // onLoad={this.recommendationsOnLoad}
                >
                  {/* {productsInTags} */}
                  <SkeletonLoader number={5} />
                </div>
              </div>
            ) : (
              <div className="swiper-container">
                <div
                  className="swiper-wrapper"
                  onLoad={this.recommendationsOnLoad}
                >
                  {productsInTags}
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => ({
  products: state.RecommendedProducts.products,
  cartState: state.ShoppingCartReducer.shops,
  shops: state.ShopReducer.shops,
  loading: state.RecommendedProducts.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(RecommendedProductsActions, dispatch),
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
