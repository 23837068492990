export const AUTH_SIGN_UP_SEEK = 'AUTH_SIGN_UP_SEEK';
export const AUTH_SIGN_UP_TAKE = 'AUTH_SIGN_UP_TAKE';
export const AUTH_SIGN_UP_KEEP = 'AUTH_SIGN_UP_KEEP';
export const AUTH_SIGN_UP_VARY = 'AUTH_SIGN_UP_VARY';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_AUTHORIZED = 'AUTH_AUTHORIZED';
export const AUTH_NOT_AUTHORIZED = 'AUTH_NOT_AUTHORIZED';
export const AUTH_RESET_STATE = 'AUTH_RESET_STATE';
export const AUTH_SET_STEP_TO = 'AUTH_SET_STEP_TO';
export const AUTH_SET_USER_REG_NAME = 'AUTH_SET_USER_REG_NAME';
export const AUTH_SET_USER_REG_MALE = 'AUTH_SET_USER_REG_MALE';
export const AUTH_SET_USER_REG_CODE = 'AUTH_SET_USER_REG_CODE';
