import React from "react";
import "./skeleton.scss";

interface ISkeletonProps {
  number?: number;
  className?: string;
}

const SkeletonLoaderList = ({ number, className }: ISkeletonProps) => {
  return (
    <>
      {Array(number ? number : 1)
        .fill(0)
        .map(() => (
          <div className={`skeleton_card-list ${className ? className : ""}`}>
            <div className="skeleton_card-list__img skeleton"></div>
            <div className="skeleton_card-list__content">
              <div className="skeleton_card__summary skeleton" />
              <div className="skeleton_card__summary skeleton" />
              <div className="skeleton_card__summary skeleton" />
              <div className="skeleton_card__summary skeleton last" />
              <div className="skeleton_card-list__rating skeleton"></div>
            </div>
            <div className="skeleton_card-list__actions">
              <div className="skeleton_card-list__price skeleton"></div>
              <div className="skeleton_card-list__btn  skeleton"></div>
            </div>
          </div>
        ))}
    </>
  );
};

export default SkeletonLoaderList;
