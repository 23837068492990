import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent = ({ title }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Hammol uz" />
      <title>{title} - HAMMOL</title>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="googlebot" content="notranslate" />
    </Helmet>
  );
};

export default HelmetComponent;
