import React, { Component } from "react";
import { Product } from "../../common/types/Product";
import ShopApi from "../../common/api/ShopApi";
import ProductAsItem from "../../common/components/ProductAsItem";
import ProductAsListItem from "../../common/components/ProductAsListItem";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { withRouter } from "react-router";
import { SHOP_PRODUCTS_LIMIT } from "../../common/constants/constants";
import {
  SHOPS_SORT_BY_CLOSE,
  SHOPS_SORT_BY_NAME_ASC,
  SHOPS_SORT_BY_NAME_DESC,
  SHOPS_SORT_BY_OPEN,
  SHOPS_SORT_BY_RATING_ASC,
  SORT_BY_DATE,
  SORT_BY_POPULARITY,
  SORT_BY_PRICE,
  SORT_BY_RATING,
} from "../store/constants";
import {
  asideMenuListClickItem,
  // changeSearchUrl,
  productShopAndBasket,
} from "../../common/helpers/Helper";
// import Categories from "../../category/components/Categories";
import Messages from "../../common/messages/Messages";
import { ROUTE_PATHS } from "../../common/config/route_paths";
// import { Category, CategoryItem } from "../../common/types/Category";
// import categoryApi from "../../common/api/CategoryApi";
import HelmetComponent from "../../_temp/helmet";
import ProductsNotFound from "../../_temp/productNotFound/";
import "./shop-products.css";

import MarketInfo from "./MarketInfo";
import allProductsApi from "../../common/api/AllProductsApi";
import axios from "axios";
import ShopsCard from "../../shop/components/ShopsCard";
import ShopsCardAsList from "../../shop/components/ShopsCardAsList";
import FilterCategoryActivate from "./FilterCategory/FilterCategoryActivate";
import FilterCategoryModal from "./FilterCategory/FilterCategoryModal";
import Error from "../../_temp/error";
import SkeletonLoader from "../../_temp/skeletonLoader";
import SkeletonLoaderList from "../../_temp/skeletonLoader/loaderList";
import { toast } from "react-hot-toast";
import MyToaster from "../../_temp/toaster/MyToaster";

const ArrowDown = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 464c114.87 0 208-93.13 208-208S370.87 48 256 48 48 141.13 48 256s93.13 208 208 208zm-91.36-212.65a16 16 0 0122.63-.09L240 303.58V170a16 16 0 0132 0v133.58l52.73-52.32A16 16 0 11347.27 274l-80 79.39a16 16 0 01-22.54 0l-80-79.39a16 16 0 01-.09-22.65z"></path>
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 48C141.13 48 48 141.13 48 256s93.13 208 208 208 208-93.13 208-208S370.87 48 256 48zm91.36 212.65a16 16 0 01-22.63.09L272 208.42V342a16 16 0 01-32 0V208.42l-52.73 52.32A16 16 0 11164.73 238l80-79.39a16 16 0 0122.54 0l80 79.39a16 16 0 01.09 22.65z"></path>
    </svg>
  );
};

// TO DO any
class MarketShops extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      noMoreProducts: false,
      shopsName: "",
      nameValue: "",
      isShowMenu: false,
      isShops: true,
      sort: "",
      loading: false,
    };
  }
  VIEW_MODE_GRID = "GRID";
  VIEW_MODE_LIST = "LIST";
  itemsLimit = SHOP_PRODUCTS_LIMIT;

  // getQueries = () => {
  //   let name = new URLSearchParams(this.props.location.search).get("name");
  // };
  componentDidMount() {
    // this.getQueries();
    this.getSingleMarketFunc();
  }

  componentDidUpdate(prev: Readonly<any>) {
    if (prev.shops_sort_by !== this.props.shops_sort_by) {
      const { id } = this.props.match.params;
      const { actions } = this.props;
      actions.setMarketShopsLoading(true);
      axios
        .get(
          `https://hammol.uz/api/commodity/shop?place=${id}&name=${this.state.nameValue}&sortBy=${this.state.sort}`
        )
        .then((res: any) => {
          this.props.actionsShops.getShopsList(res.data);
        })
        .finally(() => actions.setMarketShopsLoading(false));
    }

    if (prev.filter.sort !== this.props.filter.sort) {
      this.getFirstProducts();
    }
  }

  getFirstProducts = () => {
    const {
      actionsShops,
      filter,
      min_cost,
      max_cost,
      actions,
      setAllProductsErrorAction,
    } = this.props;
    const { id } = this.props.match.params;
    let sendParams: any = {
      name: this.state.nameValue,
      limit: this.itemsLimit,
      start: 0,
      sortBy: filter.sort,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
      place: id,
    };
    actions.setMarketProductsLoading(true);
    allProductsApi
      .getAll(sendParams)
      .then((res: any) => res.json())
      .then((result: any) => {
        if (result.products && result.products.length > 0) {
          this.setState({ loading: false });
          actionsShops.fetchSearchProducts({
            items: result.products,
            _page: 0,
          });
        } else {
          actionsShops.fetchSearchProducts({
            items: result.products,
            _page: 0,
          });
        }
      })
      .catch((error) => {
        setAllProductsErrorAction(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => actions.setMarketProductsLoading(false));
  };

  changeMode = (view_mode: string) => {
    const { actions } = this.props;
    actions.changeMarketViewMode(view_mode);
  };
  setSortBy = (sort_by: string) => {
    const { actionsShops } = this.props;
    actionsShops.setSortBy(sort_by);
  };

  setShopsSortBy = (sort_by: string) => {
    const { actionsShops } = this.props;
    actionsShops.setShopsSortBy(sort_by);
  };

  fetchMoreProducts = () => {
    const {
      _page,
      min_cost,
      max_cost,
      marketItemsType,
      actionsShops,
      filter,
      setAllProductsErrorAction,
    } = this.props;
    const { id } = this.props.match.params;

    let offset = _page + this.itemsLimit;
    let sendParams: any = {
      start: offset,
      name: this.state.nameValue,
      place: id,
      limit: this.itemsLimit,
      sortBy: filter.sort,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
      type: marketItemsType,
    };
    this.setState({ loading: true });
    allProductsApi
      .getAll(sendParams)
      .then((res: any) => res.json())
      .then((result: any) => {
        actionsShops.fetchMoreSearchProducts({
          items: result.products,
          _page: offset,
        });
      })
      .catch((error) => {
        setAllProductsErrorAction(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => this.setState({ loading: false }));
  };

  getSingleMarketFunc = () => {
    const {
      actions,
      actionsShops,
      marketItemsType,
      setAllProductsErrorAction,
      min_cost,
      max_cost,
    } = this.props;
    const { id } = this.props.match.params;
    const PATH = `https://hammol.uz/api/commodity/place/${id}`;

    // SET LOADINGS
    actions.setMarketsLoading(true);
    actions.setMarketShopsLoading(true);
    actions.setMarketProductsLoading(true);

    // FETCH MARKET INFO
    axios
      .get(PATH)
      .then((result: any) => {
        actions.getSingleMarket(result.data.item);
      })
      .catch((error) => {
        console.log(error);
        actions.setMarketsError(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => actions.setMarketsLoading(false));

    // FETCH MARKET SHOPS
    axios
      .get(`https://hammol.uz/api/commodity/shop?place=${id}`)
      .then((res: any) => {
        console.log("B", res.data);
        actionsShops.getShopsList(res.data);
      })
      .catch((error) => {
        console.log(error);
        actionsShops.setShopsErrorAction(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => actions.setMarketShopsLoading(false));

    // FETCH MARKET PRODUCTS
    let params = {
      place: id,
      type: marketItemsType,
      start: 0,
      name: this.state.nameValue,
      limit: this.itemsLimit,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
    };
    allProductsApi
      .getAll(params)
      .then((res: any) => res.json())
      .then((result: any) => {
        actionsShops.fetchSearchProducts({
          items: result.products,
          _page: 0,
        });
      })
      .catch((error) => {
        setAllProductsErrorAction(Messages.t("FETCHING_ERROR"));
      })
      .finally(() => actions.setMarketProductsLoading(false));
  };

  findShopProducts = async (e: any) => {
    e.preventDefault();
    let { nameValue: name } = this.state;
    const {
      actionsShops,
      filter,
      min_cost,
      max_cost,
      marketItemsType,
      setAllProductsErrorAction,
      actions,
    } = this.props;
    const { id } = this.props.match.params;

    let params = {
      type: marketItemsType,
      start: 0,
      name: this.state.nameValue,
      place: id,
      limit: this.itemsLimit,
      sortBy: filter.sort,
      min_cost: min_cost > 0 ? min_cost : 0,
      max_cost: max_cost > 0 ? max_cost : 999999999999999,
    };

    if (this.state.isShops) {
      const res = await axios.get(
        `https://hammol.uz/api/commodity/shop?name=${name}&place=${id}`
      );
      actionsShops.getShopsList(res.data);
    } else {
      actions.setMarketProductsLoading(true);
      allProductsApi
        .getAll(params)
        .then((res: any) => res.json())
        .then((result: any) => {
          actionsShops.fetchSearchProducts({
            items: result.products,
            _page: 0,
          });
        })
        .catch((error) => {
          setAllProductsErrorAction(Messages.t("FETCHING_ERROR"));
        })
        .finally(() => actions.setMarketProductsLoading(false));
    }
  };

  render = () => {
    const {
      view_mode,
      singleMarket: marketInfo,
      shops,
      actionsShoppingCart,
      cartState,
      products,
      actionsShops,
      filter,
      shops_sort_by,
      min_cost,
      max_cost,
      marketCategoryModalOpen,
      loading,
      shopsLoading,
      productsLoading,
      productsError,
      shopsError,
      error,
    } = this.props;
    // const { view_mode, sort_by } = this.props;
    // const { cartState, shops } = this.props;
    // const { actionsShoppingCart } = this.props;

    const getFilterClass = (a: string, b: string) => {
      if (filter.sort === a || filter.sort === b) return "active";
      return "";
    };

    const getFilterIcons = (a: string, b: string) => {
      if (filter.sort === a || filter.sort === b) {
        if (filter.type === true) {
          return <ArrowDown />;
        }
        return <ArrowUp />;
      }
    };

    const handleFilter = (name: string) => {
      actionsShops.setFilterAction(name);
    };

    const handleChangeMinCost = (value: number | string) => {
      const { actionsShops } = this.props;

      const num = Number(value);
      if (value <= 0) actionsShops.setMinCostAction("");
      if (num) actionsShops.setMinCostAction(num);
    };

    const handleChangeMaxCost = (value: number | string) => {
      const { actionsShops } = this.props;

      const num = Number(value);
      if (value <= 0) actionsShops.setMaxCostAction("");
      if (num) actionsShops.setMaxCostAction(num);
    };

    const getProdsWithMinMaxCost = () => {
      if (!min_cost || !max_cost) {
        this.getFirstProducts();
      } else if (min_cost > 0 && max_cost > 0 && min_cost <= max_cost) {
        this.getFirstProducts();
      } else {
        toast("Iltimos Narxlarni to'g'ri kiriting");
      }
    };
    asideMenuListClickItem();
    return (
      <div>
        <HelmetComponent title="HAMMOL" />
        <MyToaster />
        <Header />
        <MarketInfo error={error} loading={loading} data={marketInfo} />
        {!this.state.isShops && <FilterCategoryActivate />}
        {marketCategoryModalOpen && <FilterCategoryModal />}
        <div className="container mt-2">
          <div className="show-filter-options p-2">
            <div className="shop__search w-100">
              <h2 className="mb-1">Поиск по этой точке продаж</h2>
              <form
                className="header__search-form"
                onSubmit={(e) => this.findShopProducts(e)}
              >
                <input
                  className="header__search-input"
                  id="inp_name"
                  type="text"
                  placeholder="Поиск по этой точке продаж"
                  value={this.state.nameValue}
                  onChange={(e) =>
                    this.setState({
                      nameValue: e.target.value,
                    })
                  }
                />
                <button className="header__search-btn" type="submit">
                  {Messages.t("Search")}
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <Hero /> */}
        <main className="main shop-products">
          <section className="products-section p-2">
            <div className="container">
              <div className="payment__method mb-2">
                <input
                  type="radio"
                  name="payment__type"
                  id="card"
                  onClick={() =>
                    this.setState({ isShops: true, nameValue: "" })
                  }
                />
                <label
                  htmlFor="card"
                  className={`${this.state.isShops ? "active" : ""}`}
                >
                  {Messages.t("IS_SHOPS")}
                </label>
                <input
                  type="radio"
                  name="payment__type"
                  id="cash"
                  onClick={() =>
                    this.setState({ isShops: false, nameValue: "" })
                  }
                />
                <label
                  htmlFor="cash"
                  className={`${!this.state.isShops ? "active" : ""}`}
                >
                  {Messages.t("IS_PRODUCTS")}
                </label>
              </div>

              {this.state.isShops ? (
                <div className="show-filter-options">
                  <ul className="show-filter">
                    <li
                      onClick={() => {
                        this.setShopsSortBy(SHOPS_SORT_BY_OPEN);
                        this.setState({ sort: SHOPS_SORT_BY_OPEN });
                      }}
                      className={
                        "show-filter__item " +
                        (shops_sort_by === SHOPS_SORT_BY_OPEN && "active")
                      }
                    >
                      {Messages.t("open")}
                    </li>
                    <li
                      onClick={() => {
                        this.setShopsSortBy(SHOPS_SORT_BY_CLOSE);
                        this.setState({ sort: SHOPS_SORT_BY_CLOSE });
                      }}
                      className={
                        "show-filter__item " +
                        (shops_sort_by === SHOPS_SORT_BY_CLOSE && "active")
                      }
                    >
                      {Messages.t("closed")}
                    </li>
                    <li
                      onClick={() => {
                        this.setShopsSortBy(SHOPS_SORT_BY_RATING_ASC);
                        this.setState({ sort: SHOPS_SORT_BY_RATING_ASC });
                      }}
                      className={
                        "show-filter__item " +
                        (shops_sort_by === SHOPS_SORT_BY_RATING_ASC && "active")
                      }
                    >
                      {Messages.t("ratingAsc")}
                    </li>
                    <li
                      onClick={() => {
                        this.setShopsSortBy(SHOPS_SORT_BY_NAME_ASC);
                        this.setState({ sort: SHOPS_SORT_BY_NAME_ASC });
                      }}
                      className={
                        "show-filter__item " +
                        (shops_sort_by === SHOPS_SORT_BY_NAME_ASC && "active")
                      }
                    >
                      {Messages.t("nameAsc")}
                    </li>
                    <li
                      onClick={() => {
                        this.setShopsSortBy(SHOPS_SORT_BY_NAME_DESC);
                        this.setState({ sort: SHOPS_SORT_BY_NAME_DESC });
                      }}
                      className={
                        "show-filter__item " +
                        (shops_sort_by === SHOPS_SORT_BY_NAME_DESC && "active")
                      }
                    >
                      {Messages.t("nameDesc")}
                    </li>
                  </ul>
                  <ul className="mobile__filter-list">
                    <li className="header__menu-item" id="langToggle">
                      <span
                        className="header__menu-link"
                        onClick={(k) => {
                          this.setState({ isShowMenu: !this.state.isShowMenu });
                        }}
                      >
                        {Messages.t("FILTER")}
                        <svg
                          className="icon change-lang-arrow"
                          width="5"
                          height="8"
                        >
                          <use href="static/img/icons/sprite/sprite.svg#arrow" />
                        </svg>
                      </span>
                      <div
                        className={
                          this.state.isShowMenu
                            ? "header__lang header__lang--open"
                            : "header__lang"
                        }
                      >
                        <ul className="header__lang-list">
                          <li
                            className="header__lang-item"
                            onClick={() => {
                              this.setShopsSortBy(SHOPS_SORT_BY_OPEN);
                              this.setState({ sort: SHOPS_SORT_BY_OPEN });
                              this.setState(false);
                            }}
                          >
                            <span className="header__lang-link">
                              {Messages.t("open")}
                            </span>
                          </li>
                          <li
                            className="header__lang-item"
                            onClick={() => {
                              this.setShopsSortBy(SHOPS_SORT_BY_CLOSE);
                              this.setState({ sort: SHOPS_SORT_BY_CLOSE });
                              this.setState(false);
                            }}
                          >
                            <span className="header__lang-link">
                              {Messages.t("closed")}
                            </span>
                          </li>
                          <li
                            className="header__lang-item"
                            onClick={() => {
                              this.setShopsSortBy(SHOPS_SORT_BY_RATING_ASC);
                              this.setState({ sort: SHOPS_SORT_BY_RATING_ASC });
                              this.setState(false);
                            }}
                          >
                            <span className="header__lang-link">
                              {Messages.t("ratingAsc")}
                            </span>
                          </li>
                          <li
                            className="header__lang-item"
                            onClick={() => {
                              this.setShopsSortBy(SHOPS_SORT_BY_NAME_ASC);
                              this.setState({ sort: SHOPS_SORT_BY_NAME_ASC });
                              this.setState(false);
                            }}
                          >
                            <span className="header__lang-link">
                              {Messages.t("nameAsc")}
                            </span>
                          </li>
                          <li
                            className="header__lang-item"
                            onClick={() => {
                              this.setShopsSortBy(SHOPS_SORT_BY_NAME_DESC);
                              this.setState({ sort: SHOPS_SORT_BY_NAME_DESC });
                              this.setState(false);
                            }}
                          >
                            <span className="header__lang-link">
                              {Messages.t("nameDesc")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>

                  <div className="view-filter">
                    <div
                      className={
                        view_mode === this.VIEW_MODE_LIST
                          ? "view-filter__option view_mode view-filter__option--selected"
                          : "view-filter__option view_mode"
                      }
                    >
                      <svg
                        className="icon"
                        width="20"
                        height="20"
                        onClick={() => this.changeMode(this.VIEW_MODE_LIST)}
                      >
                        <use href="static/img/icons/sprite/sprite.svg#list-view" />
                      </svg>
                    </div>
                    <div
                      className={
                        view_mode === this.VIEW_MODE_GRID
                          ? "view-filter__option view_mode view-filter__option--selected"
                          : "view-filter__option view_mode"
                      }
                    >
                      <svg
                        className="icon"
                        width="20"
                        height="20"
                        onClick={() => this.changeMode(this.VIEW_MODE_GRID)}
                      >
                        <use href="static/img/icons/sprite/sprite.svg#grid-view" />
                      </svg>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="show-filter-options">
                    <ul className="show-filter">
                      <li
                        className={`show-filter__item ${getFilterClass(
                          "priceAsc",
                          "priceDesc"
                        )}`}
                        onClick={() => handleFilter("price")}
                      >
                        {Messages.t("BY_COST")}{" "}
                        {getFilterIcons("priceAsc", "priceDesc")}
                      </li>
                      <li
                        className={`show-filter__item ${getFilterClass(
                          "nameAsc",
                          "nameDesc"
                        )}`}
                        onClick={() => handleFilter("name")}
                      >
                        {Messages.t("BY_NAME")}{" "}
                        {getFilterIcons("nameAsc", "nameDesc")}
                      </li>
                      <li
                        className={`show-filter__item ${getFilterClass(
                          "soldAsc",
                          "soldDesc"
                        )}`}
                        onClick={() => handleFilter("sold")}
                      >
                        {Messages.t("BY_SALES")}{" "}
                        {getFilterIcons("soldAsc", "soldDesc")}
                      </li>
                      <li
                        className={`show-filter__item ${getFilterClass(
                          "ratingAsc",
                          "ratingDesc"
                        )}`}
                        onClick={() => handleFilter("rating")}
                      >
                        {Messages.t("BY_RATING")}{" "}
                        {getFilterIcons("ratingAsc", "ratingDesc")}
                      </li>
                    </ul>
                    <ul className="mobile__filter-list">
                      <li className="header__menu-item" id="langToggle">
                        <span
                          className="header__menu-link"
                          onClick={(k) => {
                            this.setState({
                              showMenu: !this.state.showMenu,
                            });
                          }}
                        >
                          {Messages.t("FILTER")}
                          <svg
                            className="icon change-lang-arrow"
                            width="5"
                            height="8"
                          >
                            <use href="static/img/icons/sprite/sprite.svg#arrow" />
                          </svg>
                        </span>
                        <div
                          className={
                            this.state.showMenu
                              ? "header__lang header__lang--open"
                              : "header__lang"
                          }
                        >
                          <ul className="header__lang-list">
                            <li className="header__lang-item">
                              <span
                                className="header__lang-link"
                                onClick={() => handleFilter("price")}
                              >
                                {Messages.t("BY_COST")}
                                {getFilterIcons("priceAsc", "priceDesc")}
                              </span>
                            </li>
                            <li className="header__lang-item">
                              <span
                                className="header__lang-link"
                                onClick={() => handleFilter("name")}
                              >
                                {Messages.t("BY_NAME")}{" "}
                                {getFilterIcons("nameAsc", "nameDesc")}
                              </span>
                            </li>
                            <li className="header__lang-item">
                              <span
                                className="header__lang-link"
                                onClick={() => handleFilter("sold")}
                              >
                                {Messages.t("BY_SALES")}
                                {getFilterIcons("soldAsc", "soldDesc")}
                              </span>
                            </li>
                            <li className="header__lang-item">
                              <span
                                className="header__lang-link"
                                onClick={() => handleFilter("rating")}
                              >
                                {Messages.t("BY_RATING")}
                                {getFilterIcons("ratingAsc", "ratingDesc")}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    <div className="view-filter">
                      <div
                        className={
                          view_mode === this.VIEW_MODE_LIST
                            ? "view-filter__option view_mode view-filter__option--selected"
                            : "view-filter__option view_mode"
                        }
                      >
                        <svg
                          className="icon"
                          width="20"
                          height="20"
                          onClick={this.changeMode.bind(
                            null,
                            this.VIEW_MODE_LIST
                          )}
                        >
                          <use href="static/img/icons/sprite/sprite.svg#list-view" />
                        </svg>
                      </div>
                      <div
                        className={
                          view_mode === this.VIEW_MODE_GRID
                            ? "view-filter__option view_mode view-filter__option--selected"
                            : "view-filter__option view_mode"
                        }
                      >
                        <svg
                          className="icon"
                          width="20"
                          height="20"
                          onClick={this.changeMode.bind(
                            null,
                            this.VIEW_MODE_GRID
                          )}
                        >
                          <use href="static/img/icons/sprite/sprite.svg#grid-view" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="show-filter-options">
                    <div className="filter__by__cost">
                      <div className="filter__bycost-input">
                        <label>Минимальная цена</label>
                        <input
                          type="number"
                          placeholder="0.00"
                          value={min_cost}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeMinCost(e.target.value)
                          }
                        />
                      </div>
                      <div className="filter__bycost-input">
                        <label>Максимальная цена</label>
                        <input
                          type="number"
                          placeholder="0.00"
                          value={max_cost}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeMaxCost(e.target.value)
                          }
                        />
                      </div>
                      <button
                        className="filter__bycost-send"
                        onClick={() => getProdsWithMinMaxCost()}
                      >
                        Отправить
                      </button>
                    </div>
                  </div>
                </>
              )}

              {this.state.isShops ? (
                <div className="top-products">
                  {view_mode === this.VIEW_MODE_GRID ? (
                    <div className="top-products__row">
                      {shopsLoading ? (
                        <SkeletonLoader className="shop" number={5} />
                      ) : shopsError ? (
                        <Error />
                      ) : shops && shops.length ? (
                        shops.map((item: any, i: number) => (
                          <ShopsCard item={item} key={item.name + i} />
                        ))
                      ) : (
                        <ProductsNotFound message={Messages.t("NO_SHOPS")} />
                      )}
                    </div>
                  ) : (
                    <ul className="shop-products__list">
                      {shopsLoading ? (
                        <SkeletonLoaderList className="shop" number={5} />
                      ) : shopsError ? (
                        <Error />
                      ) : shops && shops.length ? (
                        shops.map((item: any, i: number) => (
                          <ShopsCardAsList item={item} key={item.name + i} />
                        ))
                      ) : (
                        <ProductsNotFound message={Messages.t("NO_SHOPS")} />
                      )}
                    </ul>
                  )}
                  {/* {!(shops.length < +shops_page + 20) &&
                  (loading ? (
                    <div className="top-products__more">
                      <div>
                        <div className="lds-dual-ring"></div>
                      </div>
                      {Messages.t("Show_more_products_loading")}
                    </div>
                  ) : (
                    <div
                      className="top-products__more"
                      onClick={() => fetchMoreShops()}
                    >
                      {Messages.t("Show_more_products")}
                    </div>
                  ))} */}
                </div>
              ) : (
                <div className="top-products">
                  {view_mode === this.VIEW_MODE_GRID ? (
                    <div className="top-products__row">
                      {productsLoading ? (
                        <SkeletonLoader number={15} />
                      ) : productsError ? (
                        <Error />
                      ) : products && products.length > 0 ? (
                        products.map((item: Product, index: number) => {
                          item = productShopAndBasket(item, cartState);
                          return (
                            <ProductAsItem
                              key={item.id}
                              item={item}
                              actions={{
                                ...actionsShoppingCart,
                              }}
                              from={ROUTE_PATHS.SHOP_PRODUCTS}
                            />
                          );
                        })
                      ) : (
                        <ProductsNotFound />
                      )}
                    </div>
                  ) : (
                    <ul className="shop-products__list">
                      {productsLoading ? (
                        <SkeletonLoaderList number={5} />
                      ) : productsError ? (
                        <Error />
                      ) : products && products.length > 0 ? (
                        products.map((item: Product) => {
                          item = productShopAndBasket(item, cartState);
                          return (
                            <ProductAsListItem
                              key={item.id}
                              item={item}
                              actions={{
                                ...actionsShoppingCart,
                              }}
                              from={ROUTE_PATHS.ALL_PRODUCTS}
                            />
                          );
                        })
                      ) : (
                        <ProductsNotFound />
                      )}
                    </ul>
                  )}
                  {this.state.loading ? (
                    <div className="top-products__more">
                      <div>
                        <div className="lds-dual-ring"></div>
                      </div>
                      {Messages.t("Show_more_products_loading")}
                    </div>
                  ) : (
                    products &&
                    products.length >= this.props._page + 10 && (
                      <div
                        className="top-products__more"
                        onClick={this.fetchMoreProducts}
                      >
                        {Messages.t("Show_more_products")}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  };
}

export default withRouter(MarketShops);
