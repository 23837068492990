import { AbstractApi } from './AbstractApi';

class RecommendedProductsApi extends AbstractApi {
  getAll = (params: any = {}) => {
    const PATH = 'commodity/product';
    return this.fetchApi(
      PATH,
      this.REQUEST_METHOD_POST,
      params,
    );
  };
}
const recommendedProductsApi = new RecommendedProductsApi();
export default recommendedProductsApi;
