import React, { Component } from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as AllProductsActions from "../../allproducts/store/actions";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import * as AuthActions from "../../login/store/actions";
import { connect } from "react-redux";
import { SITE_HOME_ALIAS } from "../../common/constants/constants";
import { useHistory } from "react-router-dom";

class Breadcrumb extends Component<any, any> {
  render = () => {
    const { from, history } = this.props;

    return (
      <div className="breadcrumbs">
        <div className="container">
          <nav className="breadcrumbs__nav">
            {from ? (
              <a
                className="breadcrumbs__back"
                href={"/#" + (from === SITE_HOME_ALIAS ? "" : from)}
              >
                <svg className="breadcrumbs__back-arrow" width="5" height="8">
                  <use href="static/img/icons/sprite/sprite.svg#arrow" />
                </svg>
                Вернуться назад
              </a>
            ) : (
              <span
                onClick={() => history.goBack()}
                className="breadcrumbs__back"
              >
                <svg className="breadcrumbs__back-arrow" width="5" height="8">
                  <use href="static/img/icons/sprite/sprite.svg#arrow" />
                </svg>
                Вернуться назад
              </span>
            )}
            {/*<ul className="breadcrumbs__list">*/}
            {/*    <li className="breadcrumbs__list-item"><a className="breadcrumbs__item-link"*/}
            {/*                                              href="#">Главная</a></li>*/}
            {/*    <li className="breadcrumbs__list-item"><a className="breadcrumbs__item-link"*/}
            {/*                                              href="#">Магазины</a></li>*/}
            {/*    <li className="breadcrumbs__list-item"><a className="breadcrumbs__item-link"*/}
            {/*                                              href="#">Texnomart</a></li>*/}
            {/*    <li className="breadcrumbs__list-item"><a className="breadcrumbs__item-link"*/}
            {/*                                              href="#">Электроника</a></li>*/}
            {/*    <li className="breadcrumbs__list-item"><a className="breadcrumbs__item-link"*/}
            {/*                                              href="#">Телевизоры</a></li>*/}
            {/*    <li className="breadcrumbs__list-item">Товар</li>*/}
            {/*</ul>*/}
          </nav>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => ({
  products: state.ShoppingCartReducer.products,
  totalQuantity: state.ShoppingCartReducer.totalQuantity,
  totalCost: state.ShoppingCartReducer.totalCost,
  shop: state.ShoppingCartReducer.shop,
  authUser: state.AuthReducer,
  searchParams: state.AllProductsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(AllProductsActions, dispatch),
  actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
  actionsAuth: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Breadcrumb));
