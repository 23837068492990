import React, { Component } from "react";
import {
  LANG_UZK,
  LANG_RU,
  LANG_UZ,
  LANG_CAPTIONS,
  CATEGORY_ITEM_PREFIX,
  CATEGORY_TYPE_PREFIX,
} from "../../common/constants/constants";
import "./BottomHeader.css";
import { LangTypes } from "../../common/messages/store/types";
import Messages from "../../common/messages/Messages";
import { ROUTE_PATHS } from "../../common/config/route_paths";
import { changeSearchUrl } from "../../common/helpers/Helper";
import { withRouter } from "react-router";

interface IHeadState {
  showMenu: boolean;
}

// class BottomHeader extends Component<IHeaderProps, IHeadState> {
class BottomHeader extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = { showMenu: false };
  }

  setLanguage = (lang: LangTypes) => {
    let { actionsLang } = this.props;

    actionsLang.changeLang(lang);

    this.setState({ showMenu: false });
    //localStorage.setItem(LANG_ALIAS, lang);
    window.location.reload();
  };

  componentDidMount(): void {
    let e = document.querySelector("#langToggle");
    if (e) {
      let t = document.querySelector(".header__lang");
      e.addEventListener("click", function (e) {
        e.preventDefault();
        if (t) t.classList.toggle("header__lang--open");
      });
    }
  }

  saveCategoryToStore = async (el: any) => {
    el.preventDefault();
    const { actionsAllProducts } = this.props;
    await actionsAllProducts.saveSearchParamsCategory({});
    let { name, category } = this.props;
    let searchUrl: any = { pathname: ROUTE_PATHS.ALL_PRODUCTS };
    searchUrl = await changeSearchUrl(
      name,
      category.id ? category.id : "",
      searchUrl
    );
    await this.props.history.push(searchUrl);
    window.location.reload();
  };

  render = () => {
    const { lang } = this.props;
    const self = this;
    const { activeShop, actionsShoppingCart, category, messages } = this.props;

    return (
      <div className="header__bottom">
        <div className="container">
          <div className="header__row">
            {activeShop ? (
              <>
                <span className="store-selected">
                  {Messages.t("You_are_in_shop")}
                  <a className="select__market" href="#/">
                    {activeShop.name}
                  </a>
                  ,{/*<a className="cancel-red" href="#/"*/}
                  {/*   onClick={actionsShoppingCart.resetFromShoppingCart}> отменить*/}
                  {/*</a>*/}
                </span>
                &nbsp;&nbsp;
                <a
                  className="cancel-red"
                  href="#/"
                  onClick={actionsShoppingCart.resetFromShoppingCart}
                >
                  {" "}
                  {Messages.t("cancel")}
                </a>
              </>
            ) : (
              // <span className="selected-shop">Товары в корзину выбраны из магазина
              //     <a href="#/shoppingcart" className="shop-name">{activeShop.name}</a>,
              //     <a className="cancel-red" href="#/"
              //        onClick={actionsShoppingCart.resetFromShoppingCart}> отменить
              //     </a>
              // </span>
              Messages.t("From_all_shops")
            )}
            {category.id ? (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="store-selected">
                  {Messages.t("Category")}:
                  <span className="select__market">
                    {category.name}
                    {/* {messages[CATEGORY_ITEM_PREFIX + category]
                      ? messages[CATEGORY_ITEM_PREFIX + category]
                      : messages[CATEGORY_TYPE_PREFIX + category]} */}
                  </span>
                  ,{/*<a className="cancel-red" href="#/"*/}
                  {/*   onClick={actionsShoppingCart.resetFromShoppingCart}> отменить*/}
                  {/*</a>*/}
                </span>
                &nbsp;&nbsp;
                <a
                  className="cancel-red"
                  href="#/"
                  onClick={self.saveCategoryToStore}
                >
                  {" "}
                  {Messages.t("cancel")}
                </a>
              </>
            ) : (
              // <span className="selected-shop">Товары в корзину выбраны из магазина
              //     <a href="#/shoppingcart" className="shop-name">{activeShop.name}</a>,
              //     <a className="cancel-red" href="#/"
              //        onClick={actionsShoppingCart.resetFromShoppingCart}> отменить
              //     </a>
              // </span>
              ""
            )}

            {/*<a className="select__market" href="#/"> Выбрать магазин </a>*/}
            <ul className="header__menu">
              {/*    <li className="header__menu-item">*/}
              {/*        <Link className="header__menu-link" to="/about"> О нас </Link>*/}
              {/*    </li>*/}
              {/*    <li className="header__menu-item">*/}
              {/*        <a className="header__menu-link" href="#/"> Доставка и оплата </a>*/}
              {/*    </li>*/}
              {/*    <li className="header__menu-item">*/}
              {/*        <a className="header__menu-link" href="#/"> Поддержка </a>*/}
              {/*    </li>*/}

              <li className="header__menu-item" id="langToggle">
                <a
                  className="header__menu-link"
                  href="/#"
                  onClick={(k) => {
                    this.setState({ showMenu: true });
                  }}
                >
                  {LANG_CAPTIONS[lang]}
                  <svg className="icon change-lang-arrow" width="5" height="8">
                    <use href="static/img/icons/sprite/sprite.svg#arrow" />
                  </svg>
                </a>
                <div
                  className={
                    self.state.showMenu
                      ? "header__lang header__lang--open"
                      : "header__lang"
                  }
                >
                  <ul className="header__lang-list">
                    <li key={LANG_UZK} className="header__lang-item">
                      <a
                        className="header__lang-link"
                        href="/#"
                        onClick={(event) => {
                          event.preventDefault();
                          self.setLanguage(LANG_UZK);
                        }}
                      >
                        Ўзбекча
                      </a>
                    </li>
                    <li key={LANG_UZ} className="header__lang-item">
                      <a
                        className="header__lang-link"
                        href="/#"
                        onClick={(event) => {
                          event.preventDefault();
                          self.setLanguage(LANG_UZ);
                        }}
                      >
                        Oʻzbekcha
                      </a>
                    </li>
                    <li key={LANG_RU} className="header__lang-item">
                      <a
                        className="header__lang-link"
                        href="/#"
                        onClick={(event) => {
                          event.preventDefault();
                          self.setLanguage(LANG_RU);
                        }}
                      >
                        Русский
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            {/*<a className="support__tel" href="tel:998712000002"> (998) 71 200 00 02*/}
            {/*    <svg className="icon support-center" width="17" height="17">*/}
            {/*        <use href="static/img/icons/sprite/sprite.svg#support-center"/>*/}
            {/*    </svg>*/}
            {/*</a>*/}
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(BottomHeader);
