import React, { Component } from "react";
import { Product } from "../types/Product";
import config from "../config/config";
import { Link } from "react-router-dom";
import ProductRating from "./details/ProductRating";
import ProductShopName from "./details/ProductShopName";
import ProductNameSummary from "./details/ProductNameSummary";
import ProductCost from "./details/ProductCost";
import ProductBasket from "./details/ProductBasket";
import ProductDiscount from "./details/ProductDiscount";
import ProductFavorite from "./details/ProductFavorite";
import { preRenderCalc } from "../helpers/Helper";
import { SITE_HOME_ALIAS } from "../constants/constants";

type Props = {
  item: Product;
  actions: any;
  // activeShop: any;
  from: string;
};

class ProductAsItem extends Component<Props> {
  hostUrl = config.hostUrl;

  render = () => {
    const { item, actions, from } = this.props;
    let className = "product-card";

    className = preRenderCalc(item, className);

    return (
      <div key={item.id} className={className}>
        <div className="product-card__img">
          <>
            {Array.isArray(item.images) ? (
              <>
                {item.images[0] &&
                item.images[0].src &&
                item.images[0].src.includes("temp/") ? (
                  <img src={this.hostUrl + item.images[0].src} alt="rasm" />
                ) : (
                  <>
                    {item.image[0] && item.image[0].src ? (
                      <img
                        src={"data:image/png;base64, " + item.images[0].src}
                        alt="rasm"
                      />
                    ) : (
                      <img
                        src={"https://hammol.uz/temp/noimage2.png"}
                        alt="rasm"
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {Array.isArray(item.image) &&
                item.image[0] &&
                item.image[0].src &&
                item.image[0].src.includes("temp/") ? (
                  <img src={this.hostUrl + item.image[0].src} alt="rasm" />
                ) : (
                  <>
                    {Array.isArray(item.image) &&
                    item.image[0] &&
                    item.image[0].src ? (
                      <img
                        src={"data:image/png;base64, " + item.image[0].src}
                        alt="rasm"
                      />
                    ) : (
                      <img
                        src={"https://hammol.uz/temp/noimage2.png"}
                        alt="rasm"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
        <div className="product-card__content">
          <ProductRating rating={item.rating} />
          <ProductShopName shop={item.shopObject} />
          <ProductNameSummary name={item.name} summary={item.summary} />
          <ProductCost
            cost={item.cost}
            price={item.price}
            currency={item.currency}
          />
          <ProductBasket
            actions={{
              addOneProductToShoppingCart: actions.addOneProductToShoppingCart,
              getFirstProducts: actions.getFirstProducts,
            }}
            item={item}
            // activeShop={activeShop}
          />
        </div>
        <ProductDiscount
          discount={item.discount}
          cost={item.cost}
          price={item.price}
        />
        <ProductFavorite id={item.id} />
        <Link
          className="product-card__link"
          to={{
            pathname: "/detail/" + item.id,
            state: {
              product: item,
            },
          }}
        >
          {" "}
        </Link>
      </div>
    );
  };
}

export default ProductAsItem;
