import { AbstractApi } from "./AbstractApi";

export const DEFAULT_LANG = "uz";
export const LANG_ALIAS = "app_lang";

class NoteApi extends AbstractApi {
  /**
   * Note functions
   */
  getAllNotes = async () => {
    const PATH = "commodity/note";
    return await this.fetchApi(PATH, this.REQUEST_METHOD_GET).then((res) =>
      res.json()
    );
  };
  createNote = async (title: string) => {
    const PATH = "commodity/note";
    return await this.fetchApi(PATH, this.REQUEST_METHOD_POST, { title }).then(
      (res) => res.json()
    );
  };
  deleteNote = async (id: number) => {
    const PATH = `commodity/note/${id}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_DELETE).then((res) =>
      res.json()
    );
  };
  editNote = async (id: number, title: string) => {
    const PATH = `commodity/note/${id}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_PUT, { title }).then(
      (res) => res.json()
    );
  };

  /**
   * Note List functions
   */
  getNoteList = async (noteId: number) => {
    const PATH = `commodity/note/${noteId}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_GET).then((res) =>
      res.json()
    );
  };
  createNoteList = async (noteId: number, title: string) => {
    const PATH = `commodity/note/${noteId}/list`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_POST, { title }).then(
      (res) => res.json()
    );
  };
  deleteNoteList = async (noteId: number, listId: number) => {
    const PATH = `commodity/note/${noteId}/list/${listId}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_DELETE).then((res) =>
      res.json()
    );
  };
  editNoteList = async (noteId: number, listId: number, title: string) => {
    const PATH = `commodity/note/${noteId}/list/${listId}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_PUT, { title }).then(
      (res) => res.json()
    );
  };

  /**
   * Note List functions
   */
  addProduct = async (noteId: number, listId: number, title: string) => {
    const PATH = `commodity/note/${noteId}/list/${listId}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_POST, { title }).then(
      (res) => res.json()
    );
  };
  deleteNoteListItem = async (
    noteId: number,
    listId: number,
    itemId: number
  ) => {
    const PATH = `commodity/note/${noteId}/list/${listId}/item/${itemId}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_DELETE).then((res) =>
      res.json()
    );
  };
  editNoteListItem = async (
    noteId: number,
    listId: number,
    itemId: number,
    title: string
  ) => {
    const PATH = `commodity/note/${noteId}/list/${listId}/item/${itemId}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_PUT, { title }).then(
      (res) => res.json()
    );
  };

  editItemId = async (
    noteId: number,
    listId: number,
    itemId: number,
    newId: number
  ) => {
    const PATH = `commodity/note/${noteId}/list/${listId}/item/${itemId}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_PUT, {
      list_id: newId,
    }).then((res) => res.json());
  };

  shareLink = async (noteId: number) => {
    const PATH = `commodity/note/${noteId}/share`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_GET).then((res) =>
      res.json()
    );
  };

  confirmWithNumber = async (noteId: number, phoneNumber: string) => {
    const PATH = `commodity/note/${noteId}/share/confirm-phone?phone=998${phoneNumber}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_GET).then((res) =>
      res.json()
    );
  };

  getNoteUsers = async (noteId: number) => {
    const PATH = `commodity/note/${noteId}/users`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_GET).then((res) =>
      res.json()
    );
  };
  deleteNoteUser = async (noteId: number, userId: number) => {
    const PATH = `commodity/note/${noteId}/users/${userId}`;
    return await this.fetchApi(PATH, this.REQUEST_METHOD_DELETE).then((res) =>
      res.json()
    );
  };
}

const noteApi = new NoteApi();
export default noteApi;
