import React, { Component } from "react";
import "./ShoppingCart.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { bindActionCreators } from "redux";
import * as ShoppingCartActions from "../store/actions";
import * as AuthActions from "../../login/store/actions";
import { connect } from "react-redux";
import { Product } from "../../common/types/Product";
import ShoppingCartProductAsListItem from "../../common/components/ShoppingCartProductAsListItem";
import { formInputActiveClassManage } from "../../common/helpers/Helper";
import LoginForm from "../../login/component/LoginForm";
import Messages from "../../common/messages/Messages";
import HelmetComponent from "../../_temp/helmet";

// import AllProducts from "../../allproducts/components/AllProducts";

class ShoppingCart extends Component<any> {
  componentDidMount(): void {
    formInputActiveClassManage();
  }

  render = () => {
    const { products, actions, shop, actionsAuth, authUser } = this.props;
    if (
      !(products.length > 0) &&
      window.location.href.indexOf("shoppingcart")
    ) {
      window.location.href = "#/";
    }
    const totalCost = products.reduce(
      (sum: number, shop: any) =>
        sum +
        shop.products.reduce((sum: number, product: any) => {
          return sum + product.quantity * +product.product.cost;
        }, 0),
      0
    );
    return (
      <div>
        <HelmetComponent title={Messages.t("Shopping_cart")} />

        <Header />
        <section className="cart-section">
          <div className="container">
            <div className="grid-container-2 cart-grid-container">
              <div className="content cart-grid-content">
                <div className="order-page-top">
                  <div className="cart-top cart-top-bg">
                    <span className="cart-top__title">
                      {Messages.t("Shopping_cart")}
                    </span>
                    <span className="cart-top__store">
                      {Messages.t("Shop")}
                    </span>
                    <div className="cart-top__store-img">
                      {
                        // shop.logo?
                        //     <img src={'http://hammol.uz'+shop.logo} alt=""/>
                        //
                        // :
                        shop ? (
                          <span className="shop-name">{shop.name}</span>
                        ) : (
                          ""
                        )
                      }
                    </div>
                  </div>
                </div>
                <ul className="product-list">
                  {products &&
                    products.map((item: any) => (
                      <React.Fragment key={item.id}>
                        {item &&
                          item.products.map((productItem: Product) => (
                            <ShoppingCartProductAsListItem
                              key={productItem.id}
                              item={productItem}
                              actions={actions}
                              quantity={productItem.quantity}
                            />
                          ))}
                      </React.Fragment>
                    ))}
                </ul>
              </div>
              <aside className="aside cart-grid-aside">
                <div className="cart-aside">
                  <div className="cart-aside__title">
                    <div className="form-title-wrap">
                      <span className="form-title">{Messages.t("Order")}</span>
                      {!authUser ? (
                        <span className="form-subtitle">
                          {Messages.t("Log_in_to_place_an_order")}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {!authUser ? <LoginForm actions={actionsAuth} /> : ""}
                  <div className="aside-order-info">
                    <div className="aside-order-info__row">
                      <span className="aside-order-info__total">
                        {Messages.t("Total")}
                      </span>
                      <span className="aside-order-info__total-sum">
                        {totalCost.toFixed(2)} сўм
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      authUser
                        ? "package-order"
                        : "package-order package-order--disabled"
                    }
                  >
                    <a href="#/buy">{Messages.t("Formalization_order")}</a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  };
}

// export default ShoppingCart;
const mapStateToProps = (state: any) => {
  return {
    // shop: state.ShoppingCartReducer.shop,
    products: state.ShoppingCartReducer.shops,
    // totalQuantity: state.ShoppingCartReducer.totalQuantity,
    totalCost: state.ShoppingCartReducer.totalCost,
    authUser: state.AuthReducer.user,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(ShoppingCartActions, dispatch),
  actionsAuth: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
