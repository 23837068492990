import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Header from "../../header/Header";
import HelmetComponent from "../../_temp/helmet";
import { useSelector, useDispatch } from "react-redux";
import {
  getShopsList,
  receiveMoreShops,
  setShopsLoadingAction,
  setShopsSortBy,
  setShopsErrorAction,
  changeViewMode,
} from "../store/actions";
import {
  SHOPS_SORT_BY_OPEN,
  SHOPS_SORT_BY_CLOSE,
  SHOPS_SORT_BY_RATING_ASC,
  SHOPS_SORT_BY_NAME_ASC,
  SHOPS_SORT_BY_NAME_DESC,
} from "../store/constants";
//styles
import "./shops.css";
import ShopsCard from "./ShopsCard";
import Messages from "../../common/messages/Messages";
import axios from "../../common/api/BuyingApi";
import ShopsCardAsList from "./ShopsCardAsList";
import MainLoader from "../../_temp/hammolLoader/MainLoader";
import ProductsNotFound from "../../_temp/productNotFound";
import Error from "../../_temp/error";
import SkeletonLoader from "../../_temp/skeletonLoader";
import SkeletonLoaderList from "../../_temp/skeletonLoader/loaderList";

const AllShops = () => {
  const {
    shops,
    shops_sort_by,
    view_mode,
    shops_page,
    loading: shopsLoading,
    error,
  } = useSelector((state: any) => state.ShopReducer);
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const VIEW_MODE_GRID = "GRID";
  const VIEW_MODE_LIST = "LIST";
  const [isShowMenu, setIsShowMenu] = useState(false);
  let start: number = 0;

  // const getShops = async () => {
  //   setLoading(true);
  //   const res = await axios.get(`https://hammol.uz/api/commodity/shop`, {
  //     params: {
  //       start,
  //     },
  //   });
  //   dispatch(getShopsList(res.data));
  //   setLoading(false);
  // };
  const getShopsWithParams = async (query: string, sort: string) => {
    try {
      dispatch(setShopsLoadingAction(true));
      const res = await axios.get(
        `https://hammol.uz/api/commodity/shop?name=${query}&sortBy=${sort}&start=${0}`
      );
      dispatch(getShopsList(res.data));
    } catch (error) {
      dispatch(setShopsErrorAction(Messages.t("FETCHING_ERROR")));
    } finally {
      dispatch(setShopsLoadingAction(false));
    }
  };

  const fetchMoreShops = async () => {
    setLoading(true);
    const offset = shops_page + 20;
    const res = await axios.get<any, any>(
      `https://hammol.uz/api/commodity/shop?name=${query}&sortBy=${sort}&start=${offset}`
    );
    dispatch(receiveMoreShops(res.data.items, offset));
    setLoading(false);
  };

  const setSortBy = (sort_by: string) => {
    dispatch(setShopsSortBy(sort_by));
  };
  const changeMode = (view_mode: string) => {
    dispatch(changeViewMode(view_mode));
  };

  // useEffect(() => {
  //   getShops();
  // }, []);

  useEffect(() => {
    // let fetchShopsTimeout: any;
    // if (query) {
    //   fetchShopsTimeout = setTimeout(
    //     () => getShopsWithParams(query, sort),
    //     1500
    //   );
    // } else
    // if (sort || query) {
    // } else {
    //   getShops();
    // }
    getShopsWithParams(query, sort);
    // return () => clearTimeout(fetchShopsTimeout);
  }, [sort]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getShopsWithParams(query, sort);
  };

  return (
    <div>
      <HelmetComponent title="HAMMOL" />
      <Header />
      <main className="main">
        <section className="products-section">
          <div className="container">
            <div className="searchform__container">
              <form onSubmit={handleSubmit} className="header__search-form">
                <input
                  className="header__search-input"
                  id="inp_name"
                  type="text"
                  placeholder={Messages.t("Search_by_allShops")}
                  value={query}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setQuery(e.target.value)
                  }
                />
                <button className="header__search-btn" type="submit">
                  {Messages.t("Search")}
                </button>
              </form>
            </div>
            <div className="show-filter-options">
              <ul className="show-filter">
                {/*TO DO*/}
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_OPEN);
                    setSort(SHOPS_SORT_BY_OPEN);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_OPEN && "active")
                  }
                >
                  {Messages.t("open")}
                </li>
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_CLOSE);
                    setSort(SHOPS_SORT_BY_CLOSE);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_CLOSE && "active")
                  }
                >
                  {Messages.t("closed")}
                </li>
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_RATING_ASC);
                    setSort(SHOPS_SORT_BY_RATING_ASC);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_RATING_ASC && "active")
                  }
                >
                  {Messages.t("ratingAsc")}
                </li>
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_NAME_ASC);
                    setSort(SHOPS_SORT_BY_NAME_ASC);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_NAME_ASC && "active")
                  }
                >
                  {Messages.t("nameAsc")}
                </li>
                <li
                  onClick={() => {
                    setSortBy(SHOPS_SORT_BY_NAME_DESC);
                    setSort(SHOPS_SORT_BY_NAME_DESC);
                  }}
                  className={
                    "show-filter__item " +
                    (shops_sort_by === SHOPS_SORT_BY_NAME_DESC && "active")
                  }
                >
                  {Messages.t("nameDesc")}
                </li>
              </ul>
              <ul className="mobile__filter-list">
                <li className="header__menu-item" id="langToggle">
                  <span
                    className="header__menu-link"
                    onClick={(k) => {
                      setIsShowMenu(!isShowMenu);
                    }}
                  >
                    {Messages.t("FILTER")}
                    <svg
                      className="icon change-lang-arrow"
                      width="5"
                      height="8"
                    >
                      <use href="static/img/icons/sprite/sprite.svg#arrow" />
                    </svg>
                  </span>
                  <div
                    className={
                      isShowMenu
                        ? "header__lang header__lang--open"
                        : "header__lang"
                    }
                  >
                    <ul className="header__lang-list">
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_OPEN);
                          setSort(SHOPS_SORT_BY_OPEN);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("open")}
                        </span>
                      </li>
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_CLOSE);
                          setSort(SHOPS_SORT_BY_CLOSE);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("closed")}
                        </span>
                      </li>
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_RATING_ASC);
                          setSort(SHOPS_SORT_BY_RATING_ASC);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("ratingAsc")}
                        </span>
                      </li>
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_NAME_ASC);
                          setSort(SHOPS_SORT_BY_NAME_ASC);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("nameAsc")}
                        </span>
                      </li>
                      <li
                        className="header__lang-item"
                        onClick={() => {
                          setSortBy(SHOPS_SORT_BY_NAME_DESC);
                          setSort(SHOPS_SORT_BY_NAME_DESC);
                          setIsShowMenu(false);
                        }}
                      >
                        <span className="header__lang-link">
                          {Messages.t("nameDesc")}
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

              <div className="view-filter">
                <div
                  className={
                    view_mode === VIEW_MODE_LIST
                      ? "view-filter__option view_mode view-filter__option--selected"
                      : "view-filter__option view_mode"
                  }
                >
                  <svg
                    className="icon"
                    width="20"
                    height="20"
                    onClick={() => changeMode(VIEW_MODE_LIST)}
                  >
                    <use href="static/img/icons/sprite/sprite.svg#list-view" />
                  </svg>
                </div>
                <div
                  className={
                    view_mode === VIEW_MODE_GRID
                      ? "view-filter__option view_mode view-filter__option--selected"
                      : "view-filter__option view_mode"
                  }
                >
                  <svg
                    className="icon"
                    width="20"
                    height="20"
                    onClick={() => changeMode(VIEW_MODE_GRID)}
                  >
                    <use href="static/img/icons/sprite/sprite.svg#grid-view" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="top-products">
              {shopsLoading ? (
                view_mode === VIEW_MODE_GRID ? (
                  <div className="top-products__row">
                    <SkeletonLoader className="shop" number={5} />
                  </div>
                ) : (
                  <ul className="shop-products__list">
                    <SkeletonLoaderList className="shop" number={5} />
                  </ul>
                )
              ) : error ? (
                <Error />
              ) : view_mode === VIEW_MODE_GRID ? (
                <div className="top-products__row">
                  {shops && shops.length ? (
                    shops.map((item: any, i: number) => (
                      <ShopsCard item={item} key={item.name + i} />
                    ))
                  ) : (
                    <ProductsNotFound message={Messages.t("NO_SHOPS")} />
                  )}
                </div>
              ) : (
                <ul className="shop-products__list">
                  {shops && shops.length ? (
                    shops.map((item: any, i: number) => (
                      <ShopsCardAsList item={item} key={item.name + i} />
                    ))
                  ) : (
                    <ProductsNotFound message={Messages.t("NO_SHOPS")} />
                  )}
                </ul>
              )}
              {!(shops.length < +shops_page + 20) &&
                (loading ? (
                  <div className="top-products__more">
                    <div>
                      <div className="lds-dual-ring"></div>
                    </div>
                    {Messages.t("Show_more_products_loading")}
                  </div>
                ) : (
                  <div
                    className="top-products__more"
                    onClick={() => fetchMoreShops()}
                  >
                    {Messages.t("Show_more_products")}
                  </div>
                ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AllShops;
