import {bindActionCreators} from "redux";
import * as HeaderActions from "../store/actions";
import * as ShoppingCartActions from "../../shoppingcart/store/actions";
import {connect} from "react-redux";
import BottomHeader from "../components/BottomHeader";
import * as LangActions from "../../common/messages/store/actions";
import * as AllProductsActions from "../../allproducts/store/actions";

const mapStateToProps = (state: any) => ({
    lang: state.LangReducer.lang,
    activeShop: state.ShoppingCartReducer.shop,
    name: state.AllProductsReducer.name,
    category: state.AllProductsReducer.category,
    messages: state.LangReducer.messages
});

const mapDispatchToProps = (dispatch: any) => ({
    actions: bindActionCreators(HeaderActions, dispatch),
    actionsShoppingCart: bindActionCreators(ShoppingCartActions, dispatch),
    actionsLang: bindActionCreators(LangActions, dispatch),
    actionsAllProducts: bindActionCreators(AllProductsActions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BottomHeader);
