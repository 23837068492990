import React, { Component } from "react";
import TopHeader from "./containers/TopHeader";

import BottomHeader from "./containers/BottomHeader";

class Header extends Component {
  render = () => {
    return (
      <header className="header">
        <TopHeader />
        <BottomHeader />
      </header>
    );
  };
}

export default Header;
