import React, {Component} from 'react';

class Copyright extends Component {
    render = () => {
        const currentYear = (new Date()).getFullYear();
        return (
            <div className="footer__copyright">
                <div className="footer__logo"><img src="static/img/logo.svg" alt="none"/></div>
                <div className="footer__site-name">©Hammol.uz - {currentYear}</div>
                <a className="footer__developer" href="https://hammol.uz"> </a>
            </div>
        );
    }
}

export default Copyright;
