export const RECEIVE_ALL_MARKETS = "RECEIVE_ALL_MARKETS";
export const RECEIVE_SINGLE_MARKET = "RECEIVE_SINGLE_MARKET";
export const RECEIVE_MORE_MARKET_SHOPS = "RECEIVE_MORE_MARKET_SHOPS";
export const RECEIVE_MORE_MARKET_PRODUCTS = "RECEIVE_MORE_MARKET_PRODUCTS";
export const CHANGE_VIEW_MODE = `CHANGE_MARKET_VIEW_MODE`;

export const DEFAULT_VIEW_MODE = "GRID";

export const SORT_BY_RATING = "SORT_BY_RATING";
export const SORT_BY_PRICE = "SORT_BY_PRICE";
export const SORT_BY_DATE = "SORT_BY_DATE";
export const SORT_BY_POPULARITY = "SORT_BY_POPULARITY";

export const DEFAULT_SORT_BY = SORT_BY_PRICE;
export const SET_SORT_BY = "SORT_BY_PRICE";

export const SHOPS_SORT_BY_OPEN = "open";
export const SHOPS_SORT_BY_CLOSE = "close";
export const SHOPS_SORT_BY_RATING_ASC = "ratingAsc";
export const SHOPS_SORT_BY_RATING_DESC = "ratingDesc";
export const SHOPS_SORT_BY_NAME_ASC = "nameAsc";
export const SHOPS_SORT_BY_NAME_DESC = "nameDesc";

export const SHOPS_DEFAULT_SORT_BY = SHOPS_SORT_BY_OPEN;
export const SET_SHOPS_SORT_BY = "SET_SHOPS_SORT_BY";
export const RECEIVE_MORE_SHOPS = "RECEIVE_MORE_SHOPS";
export const RECEIVE_SEARCH_PRODUCTS = "RECEIVE_SEARCH_PRODUCTS";

export const SET_CATEGORYMODAL_OPEN = "SET_MARKET_CATEGORYMODAL_OPEN";
export const CHANGE_MARKET_ITEMS_TYPE = "CHANGE_MARKET_SHOPITEMS_TYPE";
export const SET_MARKET_CATEGORY_HEADER = "SET_MARKET_CATEGORY_HEADER";
export const SET_MARKET_CATEGORY_ITEMS = "SET_MARKET_CATEGORY_ITEMS";
export const SET_MARKET_CATEGORY_PARAMS = "SET_MARKET_CATEGORY_PARAMS";

export const SET_MARKETS_LOADING = "SET_MARKETS_LOADING";
export const SET_MARKET_SHOPS_LOADING = "SET_MARKET_SHOPS_LOADING";
export const SET_MARKET_PRODUCTS_LOADING = "SET_MARKET_PRODUCTS_LOADING";

export const SET_MARKETS_ERROR = "SET_MARKETS_ERROR";
