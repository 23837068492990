import React, { Component } from "react";

type Props = {
  name: string;
  summary?: string;
};

class ProductNameSummary extends Component<Props> {
  render = () => {
    const { name, summary } = this.props;
    return (
      <>
        <p className="product-card__summary">
          {name?.slice(0, 45)}
          {name?.length > 45 ? "..." : ""}
          <span>
            {summary?.slice(0, 60)}
            {summary && summary?.length > 60 ? "..." : ""}
          </span>
        </p>
        {/* <p className="product-card__summary"></p> */}
      </>
    );
  };
}

export default ProductNameSummary;
