import React from "react";

import ChatDialog from "./ChatDialog";
import { v4 as v4Generator } from "uuid";

import "./chat.css";
import { IChat } from "../../types/ChatTypes";
import allChats from "../../api/getAllChats";
import { useSelector } from "react-redux";
const url = "wss://hammol.uz/socket/";

export const getUserInfo = (user: any, guest: string | null) => {
  if (user.user) {
    return user.user;
  } else {
    return guest;
  }
};

const Chat = () => {
  const [isChatDialogVisible, setIsChatDialogVisible] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [messages, setMessages] = React.useState([] as IChat[]);
  const [isReadyStatus, setIsReadyStatus] = React.useState<"pending" | "ready">(
    "pending"
  );
  const clientRef = React.useRef<WebSocket | null>(null);
  const [waitingToReconnect, setWaitingToReconnect] = React.useState<
    boolean | null
  >(null);
  const [loadingChats, setLoadingChats] = React.useState(false);
  const user = useSelector((state: any) => state?.AuthReducer);
  const guestId = localStorage.getItem("guestId");

  const goToBottom = () => {
    const section = document.querySelector("#last");
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const getAllChats = async () => {
    try {
      setLoadingChats(true);
      if (user.user) {
        const res = await allChats.getAll(user.user);
        const resToJson = await res.json();
        setMessages(resToJson.items);
      }
      if (!user.user && guestId !== null) {
        const res = await allChats.getGuest(guestId);
        const resToJson = await res.json();
        setMessages(resToJson.items);
      }
    } catch {
      throw new Error("error in fetching chat history");
    } finally {
      setLoadingChats(false);
    }
  };

  React.useEffect(() => {
    if (waitingToReconnect) {
      return;
    }

    if (!clientRef.current) {
      const client = new WebSocket(url);
      clientRef.current = client;

      client.onerror = (e) => console.error(e);

      client.onopen = () => {
        const userToken = localStorage.getItem("token");
        setIsReadyStatus("ready");

        if (userToken) {
          clientRef?.current?.send(
            JSON.stringify({
              meta: "join",
              token: userToken,
            })
          );
        }

        if (!userToken && guestId === null) {
          const generatedID = v4Generator();
          localStorage.setItem("guestId", generatedID);
          clientRef?.current?.send(
            JSON.stringify({
              meta: "guest",
              uuid: generatedID,
            })
          );
        }

        if (!userToken && guestId !== null) {
          clientRef?.current?.send(
            JSON.stringify({
              meta: "guest",
              uuid: guestId,
            })
          );
        }
      };

      client.onclose = () => {
        if (clientRef.current) {
        } else {
          return;
        }

        if (waitingToReconnect) {
          return;
        }
        setWaitingToReconnect(true);

        setTimeout(() => setWaitingToReconnect(null), 5000);
      };

      clientRef.current.onmessage = (message) => {
        const res = JSON.parse(message?.data);
        if (res.meta === "message_from") {
          setMessages((prev) => [...prev, res]);
          goToBottom();
        }
      };

      return () => {
        clientRef.current = null;

        client.close();
      };
    }
  }, [waitingToReconnect]);

  const handleSendMess = (event: React.FormEvent) => {
    event.preventDefault();

    if (message === "") return;
    clientRef?.current?.send(
      JSON.stringify({
        meta: "message_to",
        to: "ADMIN",
        msg: message,
      })
    );
    setMessages((prev) => [
      ...prev,
      {
        date: new Date().getDate().toLocaleString(),
        id: "000",
        message_type: 1,
        msg: message,
        name: user.name,
        read: false,
        room_id: "36447960-37e2-4a75-beca-ec8d1e41e7dd",
        sender: getUserInfo(user, guestId),
      },
    ]);
    setMessage("");
    setTimeout(() => goToBottom(), 100);
  };

  React.useEffect(() => {
    getAllChats();
  }, [user, guestId]);

  React.useEffect(() => {
    goToBottom();
  }, []);

  return (
    <div
      onClick={() => setIsChatDialogVisible(false)}
      className={`chat ${!isChatDialogVisible ? "chat__display-none" : ""}`}
    >
      {!isChatDialogVisible ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsChatDialogVisible((state) => !state);
          }}
          className="chat__button"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 1024 1024"
            height="1.2em"
            width="1.2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M924.3 338.4a447.57 447.57 0 0 0-96.1-143.3 443.09 443.09 0 0 0-143-96.3A443.91 443.91 0 0 0 512 64h-2c-60.5.3-119 12.3-174.1 35.9a444.08 444.08 0 0 0-141.7 96.5 445 445 0 0 0-95 142.8A449.89 449.89 0 0 0 65 514.1c.3 69.4 16.9 138.3 47.9 199.9v152c0 25.4 20.6 46 45.9 46h151.8a447.72 447.72 0 0 0 199.5 48h2.1c59.8 0 117.7-11.6 172.3-34.3A443.2 443.2 0 0 0 827 830.5c41.2-40.9 73.6-88.7 96.3-142 23.5-55.2 35.5-113.9 35.8-174.5.2-60.9-11.6-120-34.8-175.6zM312.4 560c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48 47.9 21.5 47.9 48-21.4 48-47.9 48zm199.6 0c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48 47.9 21.5 47.9 48-21.5 48-47.9 48zm199.6 0c-26.4 0-47.9-21.5-47.9-48s21.5-48 47.9-48 47.9 21.5 47.9 48-21.5 48-47.9 48z" />
          </svg>
        </button>
      ) : null}
      {isChatDialogVisible && (
        <ChatDialog
          onClose={() => setIsChatDialogVisible(false)}
          handleSendMess={handleSendMess}
          messages={messages}
          setMessages={setMessages}
          setValue={setMessage}
          value={message}
          isDisabled={isReadyStatus}
          isLoading={loadingChats}
          setLoading={setLoadingChats}
        />
      )}
    </div>
  );
};

export default Chat;
