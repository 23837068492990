import React, { Component } from "react";
import { withRouter } from "react-router";
import CategoryComponent from "./Category";
import Messages from "../../common/messages/Messages";
import { Category } from "../../common/types/Category";
import SubCategories from "./SubCategories";
import MainLoader from "../../_temp/hammolLoader/MainLoader";

// TO DO any
class Categories extends Component<any, any> {
  generateCategories = (
    categories: Category[],
    actionsCategory: any,
    categoryLayer: number,
    subCategories: any,
    saveCategoryToStore: any,
    name: string
  ) => {
    if (categories.length) {
      return categories.map((item) => {
        // if (item.count) {
        return (
          <CategoryComponent
            name={name}
            key={item.id}
            category={item}
            actionsCategory={actionsCategory}
            categoryLayer={categoryLayer}
            subCategories={subCategories}
            saveCategoryToStore={saveCategoryToStore}
          />
        );
        // }
      });
    }
    return <MainLoader />;
  };

  render = () => {
    const {
      opensidebar,
      subCategories,
      actionsCategory,
      categories,
      openSubCategory,
      categoryLayer,
      subCategoriesParent,
      saveCategoryToStore,
      name,
    } = this.props;
    return (
      <div className={`sidebar  ${opensidebar ? "sidebar--open" : ""}`}>
        {/* <div
          onClick={() => actions.setOpenSidebar(!opensidebar)}
          className="sidebar__open-btn"
        >
          <button>{Messages.t("Product_categories")}</button>
        </div> */}
        <div className="sidebar__aside-menu">
          <nav onClick={(e) => e.stopPropagation()} className="aside-menu">
            <div className="aside-menu__header">
              <span className="aside-menu__header-inner">
                <span>{Messages.t("Categories")}</span>
              </span>
              <div className="aside-menu__header-icon">
                <svg onClick={() => actionsCategory.setOpenSidebar(false)}>
                  <use href="static/img/icons/sprite/sprite.svg#close" />
                </svg>
              </div>
            </div>
            <ul className="aside-menu__list">
              {this.generateCategories(
                categories,
                actionsCategory,
                categoryLayer,
                subCategories,
                saveCategoryToStore,
                name
              )}
            </ul>
            <SubCategories
              name={name}
              subCategoriesParent={subCategoriesParent}
              allSubCategories={subCategories}
              subCategories={subCategories[categoryLayer - 1]}
              actionsCategory={actionsCategory}
              openSubCategory={openSubCategory}
              categoryLayer={categoryLayer}
              saveCategoryToStore={saveCategoryToStore}
            />
          </nav>
        </div>
      </div>
    );
  };
}

export default withRouter(Categories);
