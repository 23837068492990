import React, { Component } from "react";
import { withRouter } from "react-router";
import categoryApi from "../../common/api/CategoryApi";
import { ROUTE_PATHS } from "../../common/config/route_paths";
import { CategoryItem } from "../../common/types/Category";

// TO DO any
class CategoryComponent extends Component<any, any> {
  // constructor() {
  //   super();
  //   const {actionsCategory, categoryLayer} = this.props
  // }
  clickCategoryHandler = async () => {
    const {
      categoryLayer,
      actionsCategory,
      category,
      subCategories,
      subCategoriesParent,
      saveCategoryToStore,
      history,
      name,
    } = this.props;
    if (!category.count) return;
    actionsCategory.setOpenSubCat(true);
    if (+categoryLayer < 3) {
      actionsCategory.SetSubCategories([], subCategoriesParent);
      if (+categoryLayer === 0) {
        const newSubCategories = [...subCategories];
        let parentArr: any[] = [];
        actionsCategory.SetCategoryLayer(categoryLayer + 1);
        await categoryApi
          .getAll(`/${category.id}`)
          .then((response) => response.json())
          .then((categories) => {
            newSubCategories[categoryLayer] = categories.items;
            parentArr = categories.parent || [[]];
          })
          .finally(() => {
            actionsCategory.SetSubCategories(newSubCategories, parentArr);
          });
      }
      if (+categoryLayer === 1) {
        const newSubCategories = [...subCategories];
        let parentArr: any[] = [];
        actionsCategory.SetCategoryLayer(categoryLayer + 1);
        await categoryApi
          .getAll(`/${subCategoriesParent[0].id}/group/${category.id}`)
          .then((response) => response.json())
          .then((categories) => {
            newSubCategories[categoryLayer] = categories.items;
            parentArr = categories.parent || [[]];
          })
          .finally(() => {
            actionsCategory.SetSubCategories(newSubCategories, parentArr);
          });
      }
      if (+categoryLayer === 2) {
        const newSubCategories = [...subCategories];
        let parentArr: any[] = [];
        actionsCategory.SetCategoryLayer(categoryLayer + 1);
        await categoryApi
          .getAll(
            `/${subCategoriesParent[0].id}/group/${subCategoriesParent[1].id}/class/${category.id}`
          )
          .then((response) => response.json())
          .then((categories) => {
            newSubCategories[categoryLayer] = categories.items;
            parentArr = categories.parent || [[]];
          })
          .finally(() => {
            actionsCategory.SetSubCategories(newSubCategories, parentArr);
          });
      }
    } else {
      history.push(
        `${ROUTE_PATHS.ALL_PRODUCTS}?category=${category.ofd_id}${
          name ? "&name=" + name : ""
        }`
      );
      actionsCategory.setOpenSidebar(false);
      const newCategory: CategoryItem = {
        id: category.ofd_id,
        name: category.name,
        count: category.count,
      };
      saveCategoryToStore(newCategory);
    }
  };
  render = () => {
    const { category, categoryLayer } = this.props;
    // const hasSubItems = category?.itemsAsObjects?.length > 0;
    return (
      <li
        className={`aside-menu__item ${!category.count ? "disabled" : ""}`}
        onClick={this.clickCategoryHandler}
      >
        <div className="aside-menu__item-wrapper">
          <span
            className={`aside-menu__item-link ${
              !category.count ? "disabled" : ""
            }`}
            // onClick={() => saveCategoryToStore(category.id)}
          >
            {category.name}
            {/* <span data-category={category.id}>
            </span> */}
          </span>
          {categoryLayer < 3 && (
            <div className="aside-menu__arrow">
              <svg className="icon arrow-icon" width="9" height="9">
                <use href="static/img/icons/sprite/sprite.svg#arrow" />
              </svg>
            </div>
          )}
        </div>
        {/* {hasSubItems && (
          <SubCategories
            subCategories={category.itemsAsObjects}
            actions={actions}
          />
        )} */}
      </li>
    );
  };
}

export default withRouter(CategoryComponent);
