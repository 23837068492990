import React, {Component} from 'react';
import FooterMain from "./containers/FooterMain";
import Copyright from "./containers/Copyright";

type Props = {};
type IState = {}

class Footer extends Component<Props, IState> {

    render = () => {
        return (
            <footer className="footer">
                <div className="container">
                    <FooterMain/>
                    <Copyright/>
                </div>
            </footer>
        );
    }
}

export default Footer;
