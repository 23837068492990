import * as types from "./constants";
import { Product } from "../../../common/types/Product";

export const fetchRecommendedProducts = (products: Product[]) => {
  return { type: types.RECEIVE_RECOMMENDED_PRODUCTS, products };
};

export const setRecommendedLoading = (loading: boolean) => {
  return { type: types.SET_RECOMMENDED_LOADING, loading };
};
